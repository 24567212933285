<template>
    <div class="W75">
        <el-carousel height="300px" @change="SwiperChange">
            <el-carousel-item v-for="(LoopA,index) in LoopAs" :key="index">
                <div class="XLR YC">
                    <div class="W25 H86 XYC BDLightGray TC">{{Cell.L2s[index*3+0]}}</div>
                    <div class="W25 H86 XYC BDLightGray TC">{{Cell.L2s[index*3+1]}}</div>
                    <div class="W25 H86 XYC BDLightGray TC">{{Cell.L2s[index*3+2]}}</div>
                </div>
                <div class="XLR YC" v-for="(ZG,index2) in Cell.ZGs" :key="index2">
                    <div v-for="(I,index3) in [0,1,2]" :key="index3">
                        <div class="W25 H86 XYC BDLightGray" @click="SetShowTDBF(index2,index*3+I)">
                            <div v-if="ZG[index*3+I]==='？:？'" class="TB">{{ZG[index*3+I]}}</div>
                            <div v-else class="TG">{{ZG[index*3+I]}}</div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    export default {
        name: "DLCell2",
        props: ['CurrI', 'SwiperH', 'LoopAs', 'Cell'],
        methods: {
            SwiperChange(current, prev) {
                console.log(current, prev)
            },
            SetShowTDBF(X, Y) {
                this.$emit("FromDLCell2", {X: X, Y: Y})
            }
        }
    }
</script>

<style scoped>

</style>