<template>
    <div class="BoxDot AlignC MP4">
        <MyPicker :data='WPData' v-model='WP' @on-change='WPChanged'></MyPicker>
    </div>
</template>

<script>
    import MyPicker from "./MyUiForVue3/MyPicker/MyPicker";

    export default {
        name: "MyTest",
        components: { MyPicker},
        data() {
            return {
                WPData: [['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'], ['晨练时间', '早点', '上午', '午餐', '下午', '课外活动', '晚餐', '晚上']],
                WP: ['星期四', '下午'],
                TM:'1'
            }
        },
        methods: {
            WPChanged(value) {
                let week = value[0] + '-' + this.WPData[0].indexOf(value[0]);
                let period = value[1] + '-' + this.WPData[1].indexOf(value[1]);
                let WP2 = value;
                console.log(week, period, WP2)
            },//某日时段
            Selected2(value){console.log(value,this.TM)}
        }
    }
</script>

<style scoped>

</style>