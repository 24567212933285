<template>
    <div class="vux-picker">
        <div class="XLR">
            <div  v-for="(one, index) in currentData" :key="index" >
                <div class="vux-picker-item" :id="`vux-picker-${uuid}-${index}`" style="width: 40vw;border-width: 0 0 1px 0"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Scroller from './scroller'
    import Manager from './chain'
    import value2name from '../VuxFilters/value2name'
    import isArray from '../VuxLibs/is-array'

    export default {
        name: 'MyPicker',
        props: ['data'],
        data() {
            return {
                scroller: [],
                count: 0,
                uuid: '',
                currentData: this.data,
                currentValue: this.data,
                columns:0,
                fixedColumns:2,
                value: this.data,
                itemClass: 'scroller-item',
                columnWidth: [1/3,1/4]
            }
        },
        created() {
            if (this.columns !== 0) {
                const length = this.columns
                this.store = new Manager(this.data, length, this.fixedColumns || this.columns)
                this.currentData = this.store.getColumns(this.value)
            }
        },
        mounted() {
            this.uuid = Math.random().toString(36).substring(3, 8)
            this.$nextTick(() => {
                this.render(this.currentData, this.currentValue)
            })
        },
        methods: {
            getNameValues() {
                return value2name(this.currentValue, this.data)
            },
            getId(i) {
                return `#vux-picker-${this.uuid}-${i}`
            },
            render(data, value) {
                this.count = this.currentData.length
                const that = this
                if (value === undefined || !data || !data.length) {
                    return
                }
                let count = this.currentData.length
                // set first item as value
                if (value && value.length < count) {
                    for (let i = 0; i < count; i++) {
                        if (process.env.NODE_ENV === 'development' &&
                            typeof data[i][0] === 'undefined' &&
                            isArray(this.data) &&
                            this.data[0] &&
                            typeof this.data[0].value !== 'undefined' &&
                            !this.columns) {
                            console.error('[VUX error] 渲染出错，如果为联动模式，需要指定 columns(列数)')
                        }
                        that.currentValue[i]=data[i][0].value || data[i][0];
                    }
                }

                for (let i = 0; i < data.length; i++) {
                    /**
                     * Still don't know why this happens
                     */
                    if (!document.querySelector(that.getId(i))) {
                        return
                    }

                    that.scroller[i] && that.scroller[i].destroy()
                    that.scroller[i] = new Scroller(that.getId(i), {
                        data: data[i],
                        defaultValue: value[i] || data[i][0].value,
                        itemClass: that.itemClass,
                        onSelect(value) {
                            that.currentValue[i]=value;
                            if (!this.columns || (this.columns && that.getValue().length === that.store.count)) {
                                that.$nextTick(() => {
                                    that.$emit('on-change', that.getValue())
                                })
                            }
                            if (that.columns !== 0) {
                                that.renderChain(i + 1)
                            }
                        }
                    })
                    if (that.currentValue) {
                        that.scroller[i].select(value[i])
                    }
                }
            },
            renderChain(i) {
                if (!this.columns) {
                    return
                }

                // do not render for last scroller
                if (i > this.count - 1) {
                    return
                }

                const that = this
                let ID = this.getId(i)
                // destroy old one
                this.scroller[i].destroy()
                let list = this.store.getChildren(that.getValue()[i - 1])
                this.scroller[i] = new Scroller(ID, {
                    data: list,
                    itemClass: that.item_class,
                    onSelect(value) {
                        that.currentValue[i]=value;
                        that.$nextTick(() => {
                            that.$emit('on-change', that.getValue())
                        })
                        that.renderChain(i + 1)
                    }
                })
                // list is Array(empty) as maybe
                if (list && list.length) {
                    that.currentValue[i]=list[0].value;
                    this.renderChain(i + 1)
                } else {
                    that.currentValue[i]=null;
                }
            },
            getValue() {
                let data = []
                for (let i = 0; i < this.currentData.length; i++) {
                    if (this.scroller[i]) {
                        data.push(this.scroller[i].value)
                    } else {
                        return []
                    }
                }
                return data
            },
            emitValueChange(val) {
                if (!this.columns || (val && this.columns && val.length === this.store.count)) {
                    this.$emit('on-change', val)
                }
            }
        },

        watch: {
            value(val) {
                if (JSON.stringify(val) !== JSON.stringify(this.currentValue)) {
                    this.currentValue = val
                }
            },
            currentValue(val, oldVal) {
                this.$emit('input', val)
                // render all the scroller for chain datas
                if (this.columns !== 0) {
                    if (val && val.length > 0) {
                        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
                            this.currentData = this.store.getColumns(val)
                            this.$nextTick(function () {
                                this.render(this.currentData, val)
                            })
                        }
                    }
                } else {
                    if (val && val.length) {
                        for (let i = 0; i < val.length; i++) {
                            if (this.scroller[i] && this.scroller[i].value !== val[i]) {
                                this.scroller[i].select(val[i])
                            }
                        }
                    } else {
                        this.render(this.currentData, [])
                    }
                }
            },
            data(val) {
                if (JSON.stringify(val) !== JSON.stringify(this.currentData)) {
                    this.currentData = val
                }
            },
            currentData(newData) {
                if (Object.prototype.toString.call(newData[0]) === '[object Array]') {
                    this.$nextTick(() => {
                        this.render(newData, this.currentValue)
                        // emit on-change after rerender
                        this.$nextTick(() => {
                            this.emitValueChange(this.getValue())

                            if (JSON.stringify(this.getValue()) !== JSON.stringify(this.currentValue)) {
                                if (!this.columns || (this.columns && this.getValue().length === this.store.count)) {
                                    this.currentValue = this.getValue()
                                }
                            }
                        })
                    })
                } else {
                    if (this.columns !== 0) {
                        if (!newData.length) {
                            return
                        }
                        const length = this.columns
                        this.store = new Manager(newData, length, this.fixedColumns || this.columns)
                        this.currentData = this.store.getColumns(this.currentValue)
                    }
                }
            }
        },
        beforeUnmount() {
            for (let i = 0; i < this.count; i++) {
                this.scroller[i] && this.scroller[i].destroy()
                this.scroller[i] = null
            }
        }
    }
</script>

<style>
    @import './scroller.css';
</style>

