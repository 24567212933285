<template>
    <div class="XLR">
        <div class="H4 W50 BGBlue XLR YC">
            <div></div>
            <div class="TW BottWhite" @click="Show=1">&nbsp;照片&nbsp;</div>
            <div></div>
            <div class="LH100 AlignC WHaa BDCircle BGWhite TB" @click="Active=true,VPBool='1'">
                <div class="T5">+</div>
                <div class="T2">照片</div>
            </div>
            <div></div>
        </div>
        <div class="H4 W50  BGBlue XLR YC" style="margin-left: 1px">
            <div></div>
            <div class="TW BottWhite" @click="Show=2">&nbsp;视频&nbsp;</div>
            <div></div>
            <div class="LH100 AlignC WHaa BDCircle BGWhite TB" @click="Active=true,VPBool='2'">
                <div class="T5">+</div>
                <div class="T2">视频</div>
            </div>
            <div></div>
        </div>
    </div>
    <UpFiles1 v-if="Active" :VP="VPBool" @FromU1="UpEnd"></UpFiles1>
    <PopTitle v-if="Show>0" :Title="ItemStr.split(':')[1]+'>'+ActFrom+'>'+SiteShortName+'>'+Key" @FromPopUp="Show=-1">
        <div v-for="(PVDoc,index1) in PVs" :key="index1">
            <div class="BottGray W75 ML2">{{index1+1}}.{{PVDoc.Year}}年{{PVDoc.Month}}月{{PVDoc.Day}}{{'日>'+UpUserName+'发布'}}</div>
            <el-carousel v-if="Show===1" height="auto" autoplay>
                <el-carousel-item v-for="(P,index2) in PVDoc.Pics" :key="index2" style="height:30vh">
                    <ShowP :Pic="P"></ShowP>
                </el-carousel-item>
            </el-carousel>
            <el-carousel v-if="Show===2">
                <el-carousel-item v-for="(V,index2) in PVDoc.Videos" :key="index2" style="height:52vw">
                    <ShowVFromLargeThumb :Video="V"></ShowVFromLargeThumb>
                </el-carousel-item>
            </el-carousel>
            <hr/>
        </div>
        <br><br><br>
    </PopTitle>
</template>

<script>
    import UploadPV from "./UploadPV";
    import {myMongoDBPost, Compare} from '@/components/SharedVues/Shared0.js'
    import PopTitle from "../MyPopWin/PopTitle";
    import ShowUpdateP from "./ShowUpdateP";
    import ShowUpdateV from "./ShowUpdateV";
    import UpFiles1 from "./UpFiles1";
    import ShowP from "./ShowP";
    import ShowV from "./ShowV";
    import ShowVFromLargeThumb from "./ShowVFromLargeThumb";

    export default {
        name: "PVs",
        components: {ShowVFromLargeThumb, ShowV, ShowP, UpFiles1, ShowUpdateV, ShowUpdateP, PopTitle, UploadPV},
        props: ['ItemStr','SiteId', 'ActFrom', 'Key', 'UpUserName'],
        data() {
            return {
                PVs: [],
                Ps: [],
                Vs: [],
                Show: -1,
                Active: false,
                VPBool: '',
                SiteShortName: ''
            }
        },
        mounted() {
            let that = this;
            let TJ = {
                ItemStr: this.ItemStr,
                UpUserName: this.UpUserName,
                SiteId: this.SiteId,
                ActFrom: this.ActFrom,
                Key: this.Key
            };
            myMongoDBPost('GetDocs/PVs', TJ, function (Docs) {
                if (Docs.length > 0) that.PVs = Docs;
            });
            myMongoDBPost('GetDocs/fySites', {_id: this.SiteId}, function (Docs) {
                that.SiteShortName = Docs[0].SiteShortName;
            })
        },
        methods: {
            UpEnd(Val) {
                let that = this, PV = this.VPBool, Now = new Date();
                console.log(Now.getFullYear(), typeof Now.getFullYear(), Now.getMonth(), Now.getDate());
                this.Active = false;
                let TJ = {
                    UpUserName: this.UpUserName,//发布者
                    Year: Now.getFullYear(),
                    Month: Now.getMonth() + 1,
                    Day: Now.getDate(),
                    SiteId: this.SiteId,
                    ItemStr: this.ItemStr,
                    ActFrom: this.ActFrom,
                    Key: this.Key
                };
                myMongoDBPost('GetDocs/PVs', TJ, function (Docs) {
                    if (Docs.length > 0) {
                        if (PV === '1') Docs[0].Pics.push(Val);
                        if (PV === '2') Docs[0].Videos.push(Val);
                        myMongoDBPost("DocPutBack/PVs", Docs[0], function (data) {
                        })
                    } else {
                        TJ.Stamp = Now.getTime();
                        if (PV === '1') TJ.Pics = [Val];
                        if (PV === '2') TJ.Videos = [Val];
                        myMongoDBPost("PutDoc/PVs", TJ, function (res) {
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>