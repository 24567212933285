<template>
    <div>
        <div v-if="$store.state.UserName === $store.state.HTM0">
            后台管理:
            <el-divider/>
            <div class="XLR MP4 TB" @click="ShowG = true">
                用户实名认证审核
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <PopTitle v-if="ShowG" Title="用户实名认证审核" @FromPopUp="ShowG=false">
                <UserCertify></UserCertify>
            </PopTitle>
            <el-divider/>


            <div class="XLR MP4 TB" @click="ShowF = true">
                权限资格审核
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <PopTitle v-if="ShowF" Title="权限资格审核" @FromPopUp="ShowF=false">
                <QXCheck></QXCheck>
            </PopTitle>
            <el-divider/>

            <div class="XLR MP4 TB" @click="ShowA = true">
                用户建议审核
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <PopTitle v-if="ShowA" Title="用户建议审核" @FromPopUp="ShowA=false">
                <CheckJY0></CheckJY0>
            </PopTitle>
            <el-divider/>

            <div class="XLR MP4 TB" @click="ShowB = true">
                用户申请注销审核
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <PopTitle v-if="ShowB" Title="用户申请注销审核" @FromPopUp="ShowB=false">
                <UserWithdrawCheck></UserWithdrawCheck>
            </PopTitle>
            <el-divider/>

            <div class="XLR MP4 TB" @click="ShowC = true">
                数据库原始数据
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <PopTitle v-if="ShowC" Title="数据库原始数据" @FromPopUp="ShowC=false">
                <HTDatabases></HTDatabases>
            </PopTitle>
            <el-divider/>


            <div class="XLR MP4 TB" @click="ShowD = true">
                用户信息数据
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <PopTitle v-if="ShowD" Title="用户信息数据" @FromPopUp="ShowD=false">
                <HTUserRegMsg></HTUserRegMsg>
            </PopTitle>
            <el-divider/>


            <div class="XLR MP4 TB" @click="ShowE = true">
                项目名称与分类数据
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <PopTitle v-if="ShowE" Title="项目名称与分类数据" @FromPopUp="ShowE=false">
                <HTItems></HTItems>
            </PopTitle>
            <el-divider/>

        </div>
        <BasicSetup></BasicSetup>
        <div class="XLR MP4 TB" @click="Show6 = true">
            泛约币收支明细
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <el-divider/>
        <PopTitle v-if="Show6" Title="泛约币收支记录明细" @FromPopUp="Show6=false">
            <ZXAccounts></ZXAccounts>
        </PopTitle>
        <SiteManage></SiteManage>         　
        <FYDT></FYDT>
        <el-divider/>
        <QXZG></QXZG>
        <QuitFY v-if="$store.state.UserName!=='游客'" @FromQuitFY="$emit('FromSetupMain')"></QuitFY>
        <br>
    </div>
</template>

<script>
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import ShowUpdatePV from "../MyPVTs/ShowUpdatePV";
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import QXAsk from "./QXAsk";
    import UserCertify from "../SetupManage/UserCertify";
    import QXCheck from "../SetupManage/QXCheck";
    import UserWithdrawCheck from "../SetupManage/UserWithdrawCheck";
    import HTDatabases from "../SetupManage/HTDatabases";
    import HTUserRegMsg from "../SetupManage/HTUserRegMsg";
    import HTItems from "../SetupManage/HTItems";
    import DLandReg from "./DLandReg";
    import FJXX from "./FJXX";
    import SiteCreate from "../M4_Sites/SiteCreate";
    import SitesGetTJ from "../M4_Sites/SitesGetTJ";
    import ZXAccounts from "../IndependentVues/ZXAccounts";
    import SitesEdit from "../M4_Sites/SitesEdit";
    import MyIndexs from "./MyIndexs";
    import QXZG from "./QXZG";
    import QuitFY from "./QuitFY";
    import FYDT from "./FYDT";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import BasicSetup from "./BasicSetup";
    import SiteManage from "./SiteManage";

    export default {
        name: "SetupMain",
        components: {
            SiteManage,
            BasicSetup,
            PopUpDown,
            QuitFY,
            QXZG,
            MyIndexs,
            SitesEdit,
            ZXAccounts,
            SitesGetTJ,
            SiteCreate,
            FJXX,
            DLandReg,
            HTItems,
            HTUserRegMsg,
            HTDatabases,
            UserWithdrawCheck,
            QXCheck,
            UserCertify,
            QXAsk,FYDT,
            ShowUpdatePV,
            PopTitle
        },
        data() {
            return {
                Show3: false,
                Show4: false,
                Show5: false,
                Show6: false,
                Show7: false,
                ShowA: false,
                ShowB: false,
                ShowC: false,
                ShowD: false,
                ShowE: false,
                ShowF: false,
                ShowG: false,
                MyDBUserName: ''
            }
        }
    }
</script>

<style scoped>

</style>
