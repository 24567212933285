<template>
  <div>
    <div class="XLR YC">
      <div>
        <MySelectStr :CallFrom="CallFrom" :Msg="['性别','男','女']" :CurrN="0" @eventFromChild="RefreshBYs2"></MySelectStr>
      </div>
      <div >
        <MySelectRange :CallFrom="CallFrom" :Msg="['年龄',Age+'岁']" @eventFromChild="RefreshBYs3"></MySelectRange>
      </div>
      <div v-if="CallFrom==='0'" >
        <MySelectStr :CallFrom="CallFrom" :Msg="['积分','相近','>0','>1600']" :CurrN="0" @eventFromChild="RefreshBYs4"></MySelectStr>
      </div>
      <div class="  PA2 TF T3 NoWrap LH120">
        查看空闲：
        <div class="T4 TG" @click="Show3=true">{{TimeWinSele.Show}}</div>
      </div>
    </div>
    <TimeWindowSele v-if="Show3" @eventFromTimeWindowSele="SetTimeWindowSele"></TimeWindowSele>
  </div>
</template>

<script>
  import MySelectStr from "@/components/SharedVues/MySelectStr";
  import MySelectRange from "@/components/SharedVues/MySelectRange";
  import TimeWindowSele from "../SharedVues/TimeWindowSele";

  export default {
    name: "TimeSexAge",
    components: {TimeWindowSele, MySelectStr, MySelectRange},
    props: ['BYs', 'CallFrom','ZyJF'],
    data() {
      return {
        TimeWinSele: {Show: '不限[切换]', SubStr: '初值'},
        Show3: false,
        Sex: '',
        Age: '10-80',
        JFStr: '积分',
        CurrN:0
      }
    },
    mounted() {

      this.RefreshBYs();
    },
    computed: {
      CurrTimeWinSele() {
        //console.log("this.TimeWinSele.SubStr=" + this.TimeWinSele.SubStr);
        return this.TimeWinSele.SubStr;
      }
    },
    watch: {
      CurrTimeWinSele(Val) {
        this.RefreshBYs();
      }
    },
    methods: {
      RefreshBYs() {
        let that = this;
        //console.log(typeof []);
        if ((typeof this.BYs) === 'string') return;
        this.BYs.forEach(function (aDoc) {
          console.log(aDoc);
          console.log("that.TimeWinSele.SubStr=" + that.TimeWinSele.SubStr);
          let TJ1 = (aDoc.timeWindow.split(";").indexOf(that.TimeWinSele.SubStr) >= 0), TJ2 = true, TJ3 = true, TJ4 = true;
          if (TJ1) aDoc.TimeWinState = "开放"; else aDoc.TimeWinState = "关闭";
          if (aDoc.timeWindow === '' || that.TimeWinSele.SubStr === '初值') TJ1 = true;//若aDoc.timeWindow==='',则表示未设时间窗
          if (that.Sex === '男' || that.Sex === '女') TJ2 = (aDoc.sex === that.Sex);
          if (that.Age !== '年龄') TJ3 = (aDoc.age >= parseInt(that.Age.split("-")[0]) && aDoc.age <= parseInt(that.Age.split("-")[1]));
          if (that.JFStr !== '积分') {
            if (that.JFStr === '>0') TJ4 = aDoc.JF > 0;
            if (that.JFStr === '>1600') TJ4 = aDoc.JF > 1600;
            if (that.JFStr === '相近') TJ4 = Math.abs(aDoc.JF-that.ZyJF)<2;
          }
          //if (that.$store.state.LandTo.split("XMYY").length>2) TJ5=aDoc.AppearanceFee>0;//对赌时只显示设了对赌泛约币的
          aDoc.ShowBool = TJ1 && TJ2 && TJ3 && TJ4;// && TJ5;
        });
        console.log(this.BYs);
        this.$emit("FromChild", this.BYs);
      },
      SetTimeWindowSele(Val) {
        this.Show3 = false;
        if (Val === -1) {
          this.TimeWinSele.Show = '不限[切换]';
          this.TimeWinSele.SubStr = '初值';
        } else {
          this.TimeWinSele.Show = Val.showValue;
          this.TimeWinSele.SubStr = Val.countValue;
        }
        console.log("Val=" + Val);
        console.log(this.TimeWinSele)
      },//从子组件向父组件传递参数时,<TimeWindowSele...>注意不要写成@eventFromTimeWindowSele="SetTimeWindowSele(data)"

      RefreshBYs2(Val) {
        this.Sex = Val;
        this.RefreshBYs()
      },
      RefreshBYs3(Val) {
        this.Age = Val;
        this.RefreshBYs()
      },
      RefreshBYs4(Val) {
        this.JFStr = Val;
        this.RefreshBYs()
      },
    }
  }
</script>

