<template>
  <div>
    <div class="Header" :style="'z-index: '+($store.state.CurrZindex+1)">
      <div class="XLR YC MT2">
        <div class="W12"></div>&nbsp;
        <div :class="'MT2 '+(Selected===index ? 'TF':'TG')" v-for="(M,index) in ['报名','比分登记','排行榜','影像记录']" :key="index"
             @click="SetSelected(index)">{{M}}
        </div>
        &nbsp;
      </div>
    </div>
    <div class="H4"></div>
    <Act0BM v-if="Selected===0" :ActDoc="ActDoc"></Act0BM>
    <Act0FillBF v-if="Selected===1" :ActDoc="ActDoc"></Act0FillBF>
    <div v-if="Selected===2">
      <el-divider> • 积分名次排行榜 •</el-divider>

      <div class="XR YC MR4">
        积分算法:&nbsp;
        <div class="TG BDGreen">
          <MySelectStr CallFrom="Act0Main" :Msg="['按胜场次', '按赢人头','按大小分']" :CurrN="CurrN"
                       @eventFromChild="SetScoringMethod"></MySelectStr>
        </div>
      </div>
      <div v-if="ScoringMethod==='按胜场次'">
        <table class="MT2">
          <thead>
          <tr>
            <th>名次</th>
            <th>参赛选手</th>
            <th>胜场次</th>
            <th>负场次</th>
            <th>积分</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(PHB,index) in PHBs" :key="index">
            <td>{{index+1}}</td>
            <td>{{PHB.UserName}}</td>
            <td>{{PHB.Vn}}</td>
            <td>{{PHB.Fn}}</td>
            <td>{{PHB.JF}}</td>
          </tr>
          </tbody>
        </table>
        <p v-if="LoopBool" class="TF T2 PA2">备注：参照国际惯例，每对选手可以打多次，胜1场得2分，负1场得1分，弃权者得0分。适用于各类正规的多循环赛。</p>
        <p v-else class="TF T2 PA2">备注：参照国际惯例，每对选手打1次，胜1场得2分，负1场得1分，弃权者得0分。适用于各类正规的单循环赛。</p>
      </div>
      <div v-if="ScoringMethod==='按赢人头'">
        <table class="MT2">
          <thead>
          <tr>
            <th rowspan="2">名次</th>
            <th rowspan="2">参赛选手</th>
            <th class="TF" colspan="3">交手记录</th>
            <th rowspan="2">积分</th>
          </tr>
          <tr>
            <th>胜</th>
            <th>负</th>
            <th>最新</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(PHB,index) in PHBs" :key="index">
            <td>{{index+1}}</td>
            <td>
              <div :class="$store.state.UserName===PHB.UserName ? 'TF':'TC'">{{PHB.UserName}}</div>
            </td>
            <td class="TF">
              <div v-if="$store.state.UserName===PHB.UserName">―</div>
              <div v-else>{{PHB.Vn}}</div>
            </td>
            <td class="TF">
              <div v-if="$store.state.UserName===PHB.UserName">―</div>
              <div v-else>{{PHB.Fn}}</div>
            </td>
            <td class="TF">
              <div v-if="$store.state.UserName===PHB.UserName">―</div>
              <div v-else>{{PHB.BF}}</div>
            </td>
            <td>{{PHB.JF}}</td>
          </tr>
          </tbody>
        </table>
        <p class="TF T2 PA2">备注：每对选手可以打多次，但最后一场谁赢谁得1分。适用于各种能按真实水平排列名次的擂台赛、挑战赛。</p>
      </div>
      <div v-if="ScoringMethod==='按大小分'">
        <table class="MT2">
          <thead>
          <tr>
            <th>当前排名</th>
            <th>运动员</th>
            <th>获胜局数</th>
            <th>小分合计</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(PHB,index) in PHBs" :key="index">
            <td>{{PHB.MC}}</td>
            <td>{{ActDoc.ActForm === '单打' ? PHB.UserName:PHB.BM2}}</td>
            <td class="TF">{{PHB.Vn}}</td>
            <td class="TF">{{PHB.XF}}</td>
          </tr>
          </tbody>
        </table>
        <p class="TF T2 PA2">备注：优先按获胜场次排名，当出现并列时再按小分区别名次。</p>
      </div>
    </div>
    <Act0PVs v-if="Selected===3" :ActDoc="ActDoc"></Act0PVs>
    <FYQ Title="以上活动关联微信群"></FYQ>
    <WXLink :WLandTo="'Act_'+ItemStr+'Act_' + ActDoc._id" Pic="UserUpload/static/SY/DPP.jpg"
            :WTitle="ActDoc.Title+'入口链接'"
            :WText="'点击可进行约球或参赛报名、比分登记、名次查询。'"></WXLink>
  </div>
</template>

<script>
  import {myMongoDBPost, Compare} from '@/components/SharedVues/Shared0.js'


  import PopTitle from "../MyPopWin/PopTitle";
  import Act0BM from "./Act0BM";
  import Act0FillBF from "./Act0FillBF";
  import Act0PVs from "./Act0PVs";
  import WXLink from "../IndependentVues/WXLink";
  import MySelectStr from "../SharedVues/MySelectStr";
  import FYQ from "../IndependentVues/FYQ";

  export default {
    name: "Act0Main",
    components: {
      FYQ,
      MySelectStr,
      WXLink,
      Act0PVs,
      Act0FillBF,
      Act0BM,
      PopTitle,


    },
    props: ['ActDoc', 'ItemStr'],
    data() {
      return {
        Selected: -1,
        CurrN: 0,
        ScoringMethod: this.ActDoc.ScoringMethod,
        ScoringMethods: [],
        PHBs: [],//积分名次排行表,
        LoopBool: this.ActDoc.ActStyle.indexOf('多循环') >= 0,
        CurrPKs: []
      }
    },
    mounted() {
      //document.getElementById('TitleId').innerText = this.ActDoc.Title;//'开放式单循环赛事管理子平台';
      this.Selected = 0;
      if (this.ActDoc.ScoringMethod === '按胜场次') this.CurrN = 0;
      if (this.ActDoc.ScoringMethod === '按赢人头') this.CurrN = 1;
      if (this.ActDoc.ActForm !== '单打') this.CurrN = 2,this.ScoringMethod = '按大小分';
      //console.log('Act0Main:this.$store.state.CurrZindex > '+this.$store.state.CurrZindex);
      this.RefreshPHBs();
    },
    methods: {
      ShowWhat(UserName) {
        let aIndex = this.CurrCell.BMs.indexOf(UserName);
        return this.ActDoc.ActForm === '单打' ? UserName : this.CurrCell.BM2s[aIndex]
      },
      SetScoringMethod(Val) {
        this.ScoringMethod = Val;
        if (Val === '按胜场次') this.CurrN = 0;
        else if (Val === '按赢人头') this.CurrN = 1;
        this.CurrN = 2;
        this.RefreshPHBs();
      },
      SetSelected(index) {
        this.Selected = (this.Selected === index ? -1 : index);
        if (this.Selected === 2) this.RefreshPHBs();
      },
      RefreshPHBs() {
        let that = this;
        this.PHBs = [];
        myMongoDBPost("GetDocs/Act0Main", {ActId: that.ActDoc._id}, function (Docs) {
            let UserNames = [];
            for (let i = 0; i < Docs.length; i++)
              if (Docs[i].ZGs) for (let j = 0; j < Docs[i].ZGs.length; j++) {
                let ZG = Docs[i].ZGs[j], PK1 = ZG.Winner, PK2 = ZG.Loser, aBFs = ZG.BF.split(":");
                let PK1index=Docs[i].BMs.indexOf(PK1),PK2index=Docs[i].BMs.indexOf(PK2);
                if (ZG.BFCertified.charAt(0) === 'C') {//发现一个有效比分
                  if (UserNames.indexOf(PK1) === -1) {
                    UserNames.push(PK1);
                    if (that.ScoringMethod === '按胜场次') that.PHBs.push({UserName: PK1, Vn: 0, Fn: 0, JF: 0});
                    if (that.ScoringMethod === '按赢人头') that.PHBs.push({UserName: PK1, Vn: 0, Fn: 0, BF: '未决', JF: 0});//交手记录=Vn Fn,
                    if (that.ScoringMethod === '按大小分') that.PHBs.push({MC: 1, UserName: PK1,BM2:Docs[i].BM2s[PK1index], Vn: 0, XF: 0, VX: 0});
                  }
                  if (UserNames.indexOf(PK2) === -1) {
                    UserNames.push(PK2);
                    if (that.ScoringMethod === '按胜场次') that.PHBs.push({UserName: PK2, Vn: 0, Fn: 0, JF: 0});
                    if (that.ScoringMethod === '按赢人头') that.PHBs.push({UserName: PK2, Vn: 0, Fn: 0, BF: '未决', JF: 0});
                    if (that.ScoringMethod === '按大小分') that.PHBs.push({MC: 1, UserName: PK2,BM2:Docs[i].BM2s[PK2index], Vn: 0, XF: 0, VX: 0});
                  }
                }
              }
            for (let i = 0; i < Docs.length; i++)
              if (Docs[i].ZGs) for (let j = 0; j < Docs[i].ZGs.length; j++) {
                let ZG = Docs[i].ZGs[j], PK1 = ZG.Winner, PK2 = ZG.Loser, aBFs = ZG.BF.split(":");
                if (ZG.BFCertified.charAt(0) === 'C') {//发现一个有效比分
                  let PK1Obj = that.PHBs[UserNames.indexOf(PK1)], PK2Obj = that.PHBs[UserNames.indexOf(PK2)];
                  if (that.ScoringMethod === '按胜场次') {
                    if (that.LoopBool || !that.LoopBool && ZG.MeetBool.indexOf('首次') >= 0) {//多循环或单循环是第一次相遇
                      if (parseInt(aBFs[0]) > parseInt(aBFs[1])) PK1Obj.Vn += 1, PK2Obj.Fn += 1, PK1Obj.JF += 2, PK2Obj.JF += 1;//如果当前胜者是PK1
                      if (parseInt(aBFs[0]) < parseInt(aBFs[1])) PK2Obj.Vn += 1, PK1Obj.Fn += 1, PK2Obj.JF += 2, PK1Obj.JF += 1;//如果当前胜者是PK2
                    } //单循环的再次相遇忽略掉
                  }
                  if (that.ScoringMethod === '按赢人头') {//按赢人头时
                    console.log(ZG);
                    if (ZG.MeetBool.indexOf('最新') >= 0) {
                      if (PK1 === that.$store.state.UserName) PK2Obj.BF = aBFs[1] + ":" + aBFs[0];
                      if (PK2 === that.$store.state.UserName) PK1Obj.BF = ZG.BF;
                      if (parseInt(aBFs[0]) > parseInt(aBFs[1])) PK1Obj.JF += 1;//如果当前胜者是PK1
                      if (parseInt(aBFs[0]) < parseInt(aBFs[1])) PK2Obj.JF += 1;//如果当前胜者是PK2
                    }
                    if (PK1 === that.$store.state.UserName) {
                      if (parseInt(aBFs[0]) > parseInt(aBFs[1])) PK2Obj.Fn += 1;//如果当前胜者是PK1
                      if (parseInt(aBFs[0]) < parseInt(aBFs[1])) PK2Obj.Vn += 1;//如果当前胜者是PK2
                    }
                    if (PK2 === that.$store.state.UserName) {
                      if (parseInt(aBFs[0]) > parseInt(aBFs[1])) PK1Obj.Vn += 1;//如果当前胜者是PK1
                      if (parseInt(aBFs[0]) < parseInt(aBFs[1])) PK1Obj.Fn += 1;//如果当前胜者是PK2
                    }
                    for (let k = 0; k < that.PHBs.length; k++) console.log(that.PHBs[k].UserName, that.PHBs[k].BF, that.PHBs[k].JF);
                    console.log('--------------------------------------------------------------------');
                  }
                  if (that.ScoringMethod === '按大小分') {
                    if (aBFs && parseInt(aBFs[0]) > parseInt(aBFs[1])) PK1Obj.Vn += 1,PK1Obj.XF += parseInt(aBFs[0]);
                    if (aBFs && parseInt(aBFs[0]) < parseInt(aBFs[1])) PK2Obj.Vn += 1,PK2Obj.XF += parseInt(aBFs[1]);
                  }
                }
              }
            if (that.ScoringMethod === '按大小分') {
              for (let i = 0; i < that.PHBs.length; i++) that.PHBs[i].VX = that.PHBs[i].Vn * 1000 + that.PHBs[i].XF;
              that.PHBs.sort(Compare('VX')).reverse();
              let LastMC = 1;
              for (let i = 1; i < that.PHBs.length; i++) {
                if (that.PHBs[i].VX !== that.PHBs[i - 1].VX) LastMC += 1;
                that.PHBs[i].MC = LastMC;
              }
            } else that.PHBs.sort(Compare('JF')).reverse();
            /*
            if (that.ScoringMethod === '按胜场次') that.PHBs.sort(Compare('JF')).reverse();
            if (that.ScoringMethod === '按赢人头') {
              myMongoDBPost("Act0GetPHBs2", {
                ItemStr: that.ActDoc.ItemStr,
                CurrUserName: that.$store.state.UserName,
                UserNames: UserNames,
                PHBs: that.PHBs
              }, function (NewPHBs) {
                that.PHBs = NewPHBs.sort(Compare('JF')).reverse();
              })
            }
            */
          }
        );
      },
    }
  }
</script>

<style scoped>
</style>


