<template>
    <div>
        <div class="Header XLR YC BottO" style="height: 3rem;">
            <div></div>
            <div class="XR YC PA3 TG  MT2" @click="Show1=true">
                <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;创建一个相册
            </div>
        </div>
        <div class="H4"></div>
        <GXXCContent v-if="ShowWXLink" :ToolDoc="WXLinkDoc"></GXXCContent>
        <div v-else>
            <div v-if="Tools.length>20" class="XLR YC BottGray MP4">
                筛选： <input type="text" v-model="TitleKey" placeholder="标题关键词" style="width:60vw;height: 2.4rem"/>
                <div class="AN ANGreen T2" @click="Refresh()">刷新</div>
            </div>
            <GXXCPub v-if="Show1" ToolDoc="新增" @GXXCPub="Refresh1"></GXXCPub>
            <GXXCPub v-if="Show2" :ToolDoc="Tools[SeleI]" @GXXCPub="Refresh1"></GXXCPub>
            <div v-if="Loaded">
                <div v-for="(Tool,index) in Tools" :key="index">
                    <div v-if="$store.state.UserName === $store.state.HTM0" class="TE BDCircle BoxBR WH77 XYC"
                         @click="Delete(Tool.Title,Tool._id)">删
                    </div>
                    <div class="BottGray XLR YC PA3 MT2">
                        <div v-if="$store.state.UserName === Tool.UserName" class="XLR YC">
                            <div class="TE BDCircle BoxBR WH77 XYC" @click="Delete(Tool.Title,Tool._id)">删</div>&nbsp;
                            <div class="TE BDCircle BoxBR WH77 XYC" @click="SetShow2(index)">改</div>&nbsp;
                            <div class="TG" @click="SetShowPop(index)">{{index+1}}.{{Tool.Title.substr(0,15)}}<br>{{Tool.Title.substr(15,15)}}
                            </div>
                        </div>
                        <div v-else>{{index+1}}.{{Tool.Title}}</div>
                        <div v-if="Tool.ScopeBool==='凭授权口令'" @click="SetInputI(index)" class="XLR YC TG">
                            <div></div>
                            <div style="white-space: nowrap;">口令</div>
                            <el-icon>
                                <ArrowRight/>
                            </el-icon>
                        </div>
                        <div v-else @click="SetShowPop(index)">
                            <el-icon>
                                <ArrowRight/>
                            </el-icon>
                        </div>
                    </div>

                </div>
            </div>
            <br>
            <MyInput v-if="InputI" Title="授权口令" Type="text" :Begin="CurrCode" @FromMyI="MyInputVal"></MyInput>
            <PopTitle v-if="ShowSeleI" :Title="ToolTitle" @FromPopUp="ShowSeleI=false">
                <br>
                <GXXCContent :ToolDoc="Tools[SeleI]"></GXXCContent>
                <WXLink :WTitle="Tools[SeleI].Title" WText="来源：微信公众号『泛约大厅』➤共享相册"
                        :WLandTo="'GXXCFromWXLink'+Tools[SeleI]._id" Pic="UserUpload/static/WXLink/GXXC.png"></WXLink>
            </PopTitle>
        </div>
    </div>
</template>

<script>
    import GXXCPub from "./GXXCPub";
    import PopTitle from "../MyPopWin/PopTitle";
    import {Compare, myMongoDBPost} from '@/components/SharedVues/Shared0.js'

    import MyInput from "../SharedVues/MyInput";


    import WXLink from "../IndependentVues/WXLink";
    import SubEntrance from "../SharedVues/SubEntrance";
    import GXXCContent from "./GXXCContent";

    export default {
        name: "GXXC",
        components: {GXXCContent, SubEntrance, WXLink, MyInput, PopTitle, GXXCPub},
        data() {
            return {
                Show1: false,
                Show2: false,
                InputI: false,
                ShowSeleI: false,
                Loaded: false,
                Tools: [],
                SeleI: -1,
                PubTip: '',
                PubTip2: '',
                CurrCode: '',
                ToolTitle: '',
                TitleKey: '',

                MsgId: '',
                ShowWXLink: false,
                WXLinkDoc: null,
            }
        },
        watch: {
            ToolM2(Val) {
                this.Refresh();
            }
        },
        mounted() {
            this.Refresh();
            let that = this, LandTo = this.$store.state.LandTo;
            if (LandTo && LandTo.split('YYFWM').length > 2)
                myMongoDBPost("GetDocs/Tools", {_id: LandTo.split('YYFWM')[2]}, function (Docs) {
                    if (Docs.length === 0) alert('当前链接已失效，不影响浏览其它内容'); else {
                        that.ShowWXLink = true;
                        that.WXLinkDoc = Docs[0];
                        that.ToolTitle = that.WXLinkDoc.Title;
                    }
                });
        },
        methods: {
            SetInputI(index) {
                this.SeleI = index;
                this.InputI = true;
                this.ShowSeleI = false;
            },
            SetShow2(index) {
                this.SeleI = index;
                this.Show2 = true;
            },
            SetShowPop(index) {
                this.SeleI = index;
                this.ShowSeleI = true;
                this.ToolTitle = this.Tools[this.SeleI].Title;
            },
            MyInputVal(Val) {
                let that = this;
                this.InputI = false;
                if (Val === this.Tools[this.SeleI].PassCode) {
                    this.$nextTick(() => {
                        this.SetShowPop(that.SeleI);//内部可以用this
                    });//此处这样用很重要：如果不放在$nextTick中，PopTitle内的Title就显示不出来
                } else alert('授权口令错误！，您无权进入！');
            },
            Delete(Title, Id) {//删除相册
                let that = this;
                let YN = confirm(Title + '===>您确认要删除吗?');
                if (YN) myMongoDBPost("DeleteOneXC", {ToolsId: Id}, function (Docs) {
                    that.Refresh();
                });

            },
            Refresh1(Val) {
                this.CurrCode = Val;
                this.Refresh();
            },
            Refresh() {
                let that = this;
                that.Loaded = false;
                that.Tools = [];
                myMongoDBPost("GetDocs/Tools", {}, function (Docs) {//全部相册
                    that.Loaded = true;
                    that.Show1 = false;
                    that.Show2 = false;
                    if (Docs !== '网络出错啦!' && Docs.length > 0) {
                        for (let i = 0, len = Docs.length; i < len; i++)
                            if (Docs[i].Title.indexOf(that.TitleKey) >= 0) that.Tools.push(Docs[i]);//that.Tools = Docs;
                        that.Tools.sort(Compare('Title'));
                    }
                });
            },
        }
    }
</script>
<style scoped></style>
