<template>

    <div v-if="$store.state.UserName === '游客'" class="MP4 LH200">
        真实姓名：我是游客<br>
        身份证号：12345679012345678<br>
        常&nbsp;驻&nbsp;地 ：世界各地<br>
        注册时间：我应该马上注册<br>
        泛约密码：不告诉你 <br>　
    </div>
    <div v-else class="MP4 MT2 LH200">
        <div class="BottO XL YC">
            <div>
                <HeadShot></HeadShot><br>
                上传头像
            </div>

            <div class="ML2">
                泛约昵称:{{$store.state.UserName}}
                <div class="XL YC">性别：
                    <span v-if="CurrSex==='男' || CurrSex==='女'">{{CurrSex}}</span>
                    <MySelectStr v-else CallFrom="FYReg" :Msg="['？','男','女']" :CurrN="0"
                                 @eventFromChild="SetSex"></MySelectStr>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    年龄：<input type="number" class="W12" v-model.number="CurrAge" @focus="CurrAge=null"/>岁
                </div>
            </div>
        </div>
        <div class="XLR LH200 MT2">
            真实姓名：{{CurrRealName}}
            <div>认证情况：<span class="TE">{{UserDoc0.Certified}}</span></div>
        </div>
        身份证号：{{CurrID}} <br>
        常 驻 地：{{UserDoc0.FixedAddr.N}}{{UserDoc0.FixedAddr.P}}{{UserDoc0.FixedAddr.C}}{{UserDoc0.FixedAddr.DC}}<br>
        注册时间：{{UserDoc0UserRegTime}}

            <div class="MTB2">
                用户实名: <input type="text" v-model="CurrRealName" style="height: 2.4rem"/></div>
            <div>
                身份证号: <input title="" v-model='CurrID' :is-type="CheckId" style="height: 2.4rem"/></div>
            <div v-if="ShowTip===2" class="TE MP4 T5">请输入您本人的有效身份证号!</div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show1 = !Show1">
                更改密码
                <el-icon v-if="Show1">
                    <ArrowUp/>
                </el-icon>
                <el-icon v-else>
                    <ArrowDown/>
                </el-icon>
            </div>
            <el-divider/>

            <div v-if="Show1" class="BottGray PA3 ">
                <div class="BottGray">若要更改密码，请输入:</div>
                    <div>原密码:<input type="password" v-model="OldPassword"/></div>
                    <div>新密码:<input type="password" v-model="Newpassword1"/></div>
                    <div>确认新密码:<input type="password" v-model="Newpassword2" :iconType="Newpassword1"
                                      :is-type="CheckPassword"/></div>
                <div class="XR">
                    <div class="TG" @click="ZCBGChangePassword(OldPassword,Newpassword1,Newpassword2)">确认更改</div>
                </div>
            </div>
        手机号码: <input type="text" mask="999 9999 9999" v-model="CurrMobile" :max="13"
                   is-type="china-mobile" style="height: 2.4rem"/>
            <el-divider/>

            <div class="XLR MP4 TB" @click="Show2 = !Show2">
                更改常住地址
                <el-icon v-if="Show2">
                    <ArrowUp/>
                </el-icon>
                <el-icon v-else>
                    <ArrowDown/>
                </el-icon>
            </div>
            <div v-if="Show2" class="BottGray PA3 T4 LH150 TF">
                <div class="BottO">若要更改常住地址，请输入新址：</div>
                <FyAddr @FromFyAddr="ZCBGChangeAddress1"></FyAddr>
                <div class="XR">
                    <div class="TG" @click="ZCBGChangeAddress2()">确认更改</div>
                </div>
            </div>
            <el-divider/>
            <!--Cell title="修改泛约昵称" is-link :arrow-direction="Show3 ? 'up' : 'down'" @click.native="Show3 = !Show3">
            </Cell>
            <div v-if="Show3" class="BottGray PA3 T4 TF">
              <p>若要修改泛约昵称，必须先注销，再重新注册！但如果您要注销用户，那么，您在泛约网的所有数据将会永久性丢失。</p>
            </div-->


    </div>

</template>
<script>


    import {
        myMongoDBPost,
        Tofixed,
        StampToTimeStr
    } from '@/components/SharedVues/Shared0.js'
    import FyAddr from "@/components/SharedVues/FyAddr";

    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MySelectStr from "../SharedVues/MySelectStr";
    import {IdCheck} from '@/components/SharedVues/IdCheck.js'
    import HeadShot from "../SharedVues/HeadShot";

    export default {
        name: "DLandReg",
        components: {
            HeadShot,
            MySelectStr,
            PopTitle,
            FyAddr
        },
        data() {
            return {
                Show1: false,
                Show2: false,
                Show3: false,

                FirstUserLogo: false,
                FirstRealName: false,
                FirstID: false,
                FirstMobile: false,
                FirstAddress: false,

                CurrRealName: '',
                CurrID: '',
                CurrMobile: '1',

                ThisYear: (new Date).getFullYear(),
                CurrSex: '？',
                CurrAge: 0,

                Timer: 0,
                UserDoc0: {
                    'FixedAddr': {'N': '中国', P: 'ASD', C: 'DGF', DC: 'DFGXCVB'}
                },
                UserDoc0UserRegTime: 0,
                OldPassword: '',
                Newpassword1: '',
                Newpassword2: '',
                CheckPassword: function (value) {
                    let Valid = (this.iconType === value);
                    if (Valid && value !== '') return {
                        valid: true
                    }; else return {
                        valid: false,
                        msg: '两次输入的密码不一致!'
                    }
                },//此处技巧:借用iconType属性传递Password1的值,至于Password2的值则就是value

                CheckId: function (value) {
                    let Valid = true;

                    let Value = IdCheck.getId18(value);//此处检查身份证号,如果是15位的,则统一转换为18位的
                    if (Value === null) Valid = false; else Valid = IdCheck.check18IdCardNo(Value);


                    if (Valid) {
                        this.$store.commit('ChangeBool1', 'ValidId');
                        return {
                            valid: true
                        };
                    } else {
                        this.$store.commit('ChangeBool1', 'InvalidId');
                        return {
                            valid: false,
                            msg: '请输入有效身份证号:'
                        }
                    }
                },
            }
        },
        beforeUnmount() {
            let that = this, Changed = false, UPTJ = {UserName: that.$store.state.UserName}, UPObj = {};
            if (this.CurrSex !== that.UserDoc0.Sex) {
                UPObj.Sex = this.CurrSex;
                Changed = true;
            }
            if (that.CurrAge !== that.ThisYear - that.UserDoc0.BirthYear) {
                UPObj.BirthYear = that.ThisYear - that.CurrAge;
                Changed = true;
            }

            if (this.CurrRealName !== that.UserDoc0.RealName) {
                UPObj.RealName = this.CurrRealName;
                Changed = true;
            }
            if (this.CurrID !== that.UserDoc0.IDNumber) {
                UPObj.IDNumber = this.CurrID;
                Changed = true;
            }
            if (this.CurrMobile !== that.UserDoc0.Mobile) {
                UPObj.Mobile = this.CurrMobile;
                Changed = true;
            }
            if (Changed) myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: UPTJ, UPObj: UPObj}, function (data) {
                if (that.CurrRealName !== '') that.$store.commit('ChangeRealName', that.CurrRealName);
                if (that.CurrMobile !== '') that.$store.commit('ChangeMobile', that.CurrMobile);
            });
            if (this.Timer) clearTimeout(this.Timer);
        },//如果定时器还在运行 或者直接关闭，不用判断
        mounted() {
            let that = this;
            if (this.$store.state.UserName !== '游客')
                myMongoDBPost("GetDocs/fyUser0", {UserName: this.$store.state.UserName}, function (data) {
                    that.UserDoc0 = data[0];//注册信息
                    that.CurrRealName = that.UserDoc0.RealName;
                    that.CurrID = that.UserDoc0.IDNumber;

                    that.CurrMobile = that.UserDoc0.Mobile || '';

                    that.CurrSex = that.UserDoc0.Sex;
                    if (that.CurrSex === '' || that.CurrSex === undefined) that.CurrSex = '？';
                    that.CurrAge = that.ThisYear - that.UserDoc0.BirthYear;

                    if (that.UserDoc0.UserLogo === '') that.FirstUserLogo = true;
                    if (that.CurrRealName === '') that.FirstRealName = true;
                    if (that.CurrID === '') that.FirstID = true;
                    if (that.CurrMobile === '') that.FirstMobile = true;
                    if (that.UserDoc0.FixedAddr.C === '不限' || that.UserDoc0.FixedAddr.C === '') that.FirstAddress = true;

                    that.UserDoc0UserRegTime = StampToTimeStr(that.UserDoc0.RegTimeStamp);
                });//到数据库中取出当前用户的用户资料数据,进行初始化工作;游客读取的是fyAdministrator的信息
        },
        methods: {
            Tofixed(x, y) {
                return Tofixed(x, y)
            }
            ,
            SetSex(Val) {
                this.CurrSex = Val;
            },
            ZCBGChangePassword(OldPassword, Newpassword1, Newpassword2) {
                if (Newpassword1 === Newpassword2) myMongoDBPost('ZCBGChangePassword', {
                    UserName: this.$store.state.UserName,
                    OldPassword: OldPassword,
                    Password: Newpassword1
                }, function (data) {
                    alert(data);
                }); else alert('您两次输入的密码不一致,请重新输入!');

            }
            ,
            ZCBGChangeAddress1(Val) {
                this.UserDoc0.FixedAddr = {N: '中国', P: Val.P, C: Val.C, DC: Val.DC};
            },
            ZCBGChangeAddress2() {
                let that = this;
                myMongoDBPost('ZCBGChangeAddress', {
                    UserName: this.$store.state.UserName,
                    FixedAddr: this.UserDoc0.FixedAddr
                }, function (data) {
                    that.Show2 = false;
                })
            }
        }
    }
</script>
<style scoped>

</style>
