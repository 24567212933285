<template>
    <div>
        <div class="AlignC BottGray MTB2">

            <el-radio-group @change="Selected">
                <el-radio-button v-for="(Sele,i) in SeleStrs" :key="i" :value="Sele"/>
            </el-radio-group>
        </div>
        <div v-if="Show1" class="XR YC MT2">
            添加好友：<input type="text" v-model="NewFriend" class="MP4 W50" @input="NowFind(NewFriend)">
            <div class="TB" @click="AddFriend(NewFriend)">确认</div>&nbsp;&nbsp;
        </div>
        <div v-if="Show4 && UserNames.length>0" class="BoxGray1 W50 TF PA3" style="text-align: left;margin-left: 40vw">
            <div v-for="(UN,index) in UserNames" :key="index" @click="GetUN(UN)">{{UN}}</div>
        </div>
        <table class="MT2" style="background-color:#fff;">
            <thead>
            <tr class="TF">
                <th>序号</th>
                <th>关联用户</th>
                <th>操作</th>
                <th>打开</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(MU,index) in MyUsers" :key="index" style="line-height:1.3rem;text-align: left">
                <td>{{index+1}}</td>
                <td>{{MU}}</td>
                <td>
                    <div v-if="Seles.indexOf('好友用户')===-1" class="TB DispIB" @click="AddFriend(MU)">设为好友</div>
                    <div class="TG DispIB MP4" @click="DeleteR(MU)">解除关联</div>
                </td>
                <td>
                    <div @click="SeleI = index">
                        <el-icon>
                            <ArrowRight/>
                        </el-icon>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <PopTitle v-if="SeleI>=0" :Title="'关联用户：'+MyUsers[SeleI]" @FromPopUp="SeleI = -1">
            <OpenUser :He="MyUsers[SeleI]"></OpenUser>
        </PopTitle>
    </div>

</template>

<script>
    import {myMongoDBPost, ArrayUnique} from '@/components/SharedVues/Shared0.js'
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import OpenUser from "@/components/FrontPage/OpenUser";

    export default {
        name: "RUsers",
        components: {OpenUser, PopTitle},
        data() {
            return {
                Seles: [],
                SeleStrs: ['好友用户', '我约过的', '约过我的', '求缘响应'],
                MyUsers: [],
                Show1: false,
                NewFriend: '',
                UserNames: [],
                Show4: false,
                SeleI: -1,
            }
        },
        methods: {
            Selected(Val) {
                console.log(Val);
                let that = this;
                this.Show1 = Val[0] === '好友用户' && Val.length === 1;

                myMongoDBPost("GetDocs/RUsers", {UserName: this.$store.state.UserName, RType: Val}, function (Docs) {
                    that.MyUsers = [];
                    for (let i = 0, len = Docs.length; i < len; i++)
                        for (let j = 0, len2 = Docs[i].RUsers.length; j < len2; j++)
                            that.MyUsers.push(Docs[i].RUsers[j]);
                    console.log(Docs);
                    console.log(that.MyUsers)
                    that.MyUsers = ArrayUnique(that.MyUsers)
                });
            },
            AddFriend(NewName) {
                let that = this;
                if (NewName === '' || this.$store.state.UserName === '游客') alert("请先登录!"); else {
                    myMongoDBPost("GetDocs/fyuser0", {UserName: NewName}, function (Docs) {
                        if (Docs.length > 0) {
                            myMongoDBPost("AddRUser", {
                                UserName: that.$store.state.UserName,
                                RType: '好友用户',
                                RUser: NewName
                            }, function (data) {
                                that.Selected(['好友用户']);
                                that.Show1 = false;
                            });//追加一个关联用户
                        } else alert("您要添加的泛约昵称不存在!")
                    })
                }
            },
            NowFind(NewFriend) {
                let that = this;
                that.Show4 = true;
                myMongoDBPost("GetDocs/fyuser0", {UserName: {$regex: NewFriend}}, function (Docs) {
                    that.UserNames = [];
                    let len = Math.min(Docs.length, 20);
                    for (let i = 0; i < len; i++) that.UserNames.push(Docs[i].UserName)
                    console.log(that.UserNames)
                })
            },
            GetUN(UN) {
                this.Show4 = false;
                this.NewFriend = UN;
            },
            DeleteR(MU) {
                let that = this;
                let iN = 0;
                for (let i = 0, len = this.Seles.length; i < len; i++)
                    (function (i) {
                        myMongoDBPost("DeleteRUser", {
                            UserName: that.$store.state.UserName,
                            RType: that.Seles[i],
                            RUser: MU
                        }, function (data) {
                            iN++;
                            if (iN === len) that.Selected(that.Seles);
                        });//追加一个关联用户
                    })(i) //采用匿名函数闭包的方法解决循环内有回调的问题
            }//解除关联
        }
    }
</script>

<style scoped>

    .W50 {
        width: 50vw;
    }
</style>
