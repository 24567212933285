<template>
  <div style="background-color: #FFF;padding-top: 2mm">
    <div class="XLR">
      <div class="Sider">
        <div v-for="(Menu, index) in Menus" :key="index">
          <div v-if="index===SideSele" class="TC BGWhite" style="padding: 3mm 2mm;white-space: nowrap">{{Menu}}</div>
          <div v-else class="HalfANL" @click="SetSideSele(index)">{{Menu}}</div>
        </div>
      </div>
      <div style="width: 75vw;">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SideMenu",
    props: ['Menus','Begin'],
    data() {
      return {
        SideSele: this.Begin
      }
    },
    mounted(){
      console.log("1.SideSele="+this.SideSele)
    },
    unmounted(){
      console.log("2.SideSele="+this.SideSele)
    },
    methods:{
      SetSideSele(Val){
        this.SideSele=Val;
        this.$emit('SideMenuR', Val);
      }
    }
  }
</script>

<style lang="less" scoped>
  .Sider {
    width: 24vw;
  }

  .SideBox {
    padding: 2mm;
    text-align: center;
    white-space: nowrap;
  }

  .SideBox0 {
    border: 1px solid #636363;
    color: #083c65;
    background-color: #fff9dc;
    .SideBox;
  }

  .SideBox1 {
    border: solid #636363;
    border-width: 0 0 1px 1px;

    color: #1c1c1c;
    background-color: #FFF;/*#f3fff2*/
    .SideBox;
  }

  .SideBox2 {
    border: solid #636363;
    border-width: 0 1px 1px 1px;
    color: #083c65;
    background-color: #fffae9;
    .SideBox;
  }
</style>
