<template>
    <div class="AlignC MT2">
        <h5 class="BottGray">&nbsp;&nbsp;重设打擂器签约场地&nbsp;&nbsp; </h5>
        <div class="MP2 XLR YC BottGray" v-for="(Site,index) in Sites" :key="index">
            <div class="TC">{{index+1}}.{{Site.SiteName}}</div>
            <div class="TB" @click="SeleSite(index)">
                <el-icon v-if="Site.Country==='选中'"><Check/></el-icon>
                选中
            </div>
        </div>
        <div class="MP2">
            <div v-if="Changed" class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="SaveSitesToDLQsZGs">提 交</div>
            <div v-else class="BD_Radius1 BGLightGray T5 TW XYC H3 MT2">提 交</div>
        </div>
    </div>
</template>

<script>
    import {myMongoDBPost,Compare} from '../SharedVues/Shared0.js'
    export default {
        name: "DLQSetup1",
        data() {
            return {
                Sites: [],
                ZGs: [],
                OldZGs: [],
                CurrItemStr: 'A000:乒乓球',
                Changed: false
            }
        },
        mounted() {
            this.GetSites();
            this.GetOldZGs();
        },
        methods: {
            GetOldZGs() {
                let that = this, asd = {N: "中国", P: this.$store.state.UserP, C: this.$store.state.UserC, DC: this.$store.state.UserDC};
                let TJ = {P: asd.P, C: asd.C, ItemStr: this.$store.state.DefaultItem, Judge: asd.UserName, DocBool: 99};
                myMongoDBPost('GetDocs/DLQs', TJ, function (Docs) {
                    if (Docs.length > 0) {
                        that.OldZGs = Docs[0].ZGs;
                    }
                })
            },
            GetSites() {
                let that = this, asd = {N: "中国", P: this.$store.state.UserP, C: this.$store.state.UserC, DC: this.$store.state.UserDC};
                let TJ = {Province: asd.P, City: asd.C, 'Items.ItemName': this.$store.state.DefaultItem.split(":")[1]};
                myMongoDBPost('GetDocs/fySites', TJ, function (Docs) {
                    if (Docs.length > 0) {
                        that.Sites = Docs;
                    } else {
                        alert('您所在的城市没有泛约场地数据,请关注微信公众号泛约网，建立场地!');
                        that.$emit("FromSetup1")
                    }

                })
            },
            SeleSite(index) {
                let  asd = this.Sites[index], ZGs = [];
                asd.Country = (asd.Country === '选中' ? '中国' : '选中');
                this.Sites.splice(index, 1, asd);
                for (let i = 0; i < this.Sites.length; i++) {
                    let qwe = this.Sites[i];
                    let aOBJ = {
                        aIndex: qwe.XYedUserN, MySite: false, SiteId: qwe._id, SiteShortName: qwe.SiteShortName, Sn: 0,//Sn=所有预报名人数
                        Judges: [], DLPres: [
                            {DLStyle: '单打挑战', PreBMs: []}, {DLStyle: '双打挑战', PreBMs: []}, {DLStyle: '团体挑战', PreBMs: []},
                            {DLStyle: '团体对抗', PreBMs: []}, {DLStyle: '名次争夺', PreBMs: []}, {DLStyle: '擂台争霸', PreBMs: []},]
                    }
                    if (qwe.Country === '选中') {//选中时，如果在OldZGs中已存在，则拿过来
                        for (let j = 0; j < this.OldZGs.length; j++)
                            if (this.OldZGs[j].SiteId === qwe._id) aOBJ = this.OldZGs[j];
                        ZGs.push(aOBJ);
                    }
                }
                this.Changed = true;
                this.ZGs=ZGs;
            },
            SaveSitesToDLQsZGs() {
                let that = this, asd = {N: "中国", P: this.$store.state.UserP, C: this.$store.state.UserC, DC: this.$store.state.UserDC};

                let TJ = {P: asd.P, C: asd.C, ItemStr: this.$store.state.DefaultItem, DocBool: 99};
                that.ZGs.sort(Compare('aIndex')).reverse();
                let Obj = {P: asd.P, C: asd.C, ItemStr: this.$store.state.DefaultItem, DocBool: 99, Judge: this.$store.state.UserName, ZGs: that.ZGs};
                myMongoDBPost('UpdateDoc/DLQs', {UPTJ: TJ, UPObj: Obj}, function (Docs) {
                    that.$emit("FromSetup1")
                })
            }
        }
    }
</script>

<style scoped>
    .imgbox {
        width: 100%;
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        }
    
    .imgbox img {
        width: 70px;
        height: 70px;
        }
    
    .add {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
        }
    
    .delete {
        font-size: 24px;
        color: #E60000;
        }
</style>