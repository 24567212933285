<template>
  <div ref="BY">
    <div v-if="$store.state.LandTo === 'BY2'">
      <BY3 v-if="CurrYY3" :Msg="{CurrYY:CurrYY3}" @FromBY3="$emit('FromBY')"></BY3>
      <MyToast v-else :Tip="'已失效!'" @Tiped="$emit('FromBY')"></MyToast>
    </div>
    <div v-else><br>
      <div class="XLR YC"><div class="W12"></div>
        <div class="TF">被约信息：{{BYs.length}}条</div>


        <el-button-group>
          <el-button type="primary" @click="ToCurr">当前</el-button>
          <el-button type="primary" @click="ToHistory">历史</el-button>
        </el-button-group>

      </div>
      <div class="BottGray">&nbsp;</div>
      <div v-if="Show">
        <div v-for="(y,I1) in BYs" :key="I1">
          <div @click="SeleI=I1" class="XLR YC T4 TG MP4 BottGray">
            {{I1+1}}.{{y.meetTimeStr.substr(5,20)+'➱'+y.zyUserName+":"+y.ItemStr.split(':')[1]}}
            <el-icon><ArrowRight /></el-icon>
          </div>
        </div>
        <PopTitle v-if="SeleI !== -1" :Title="'项目：'+Y.ItemStr.split(':')[1]" @FromPopUp="SeleI=-1">
          <BY3 :Msg="{CurrYY:Y}" @FromBY3="SeleI=-1"></BY3>
        </PopTitle>
      </div>
      <div v-else>

        <div v-for="(y,I1) in BYs" :key="I1">
          <div @click="SeleI=I1" class="XLR YC T4 TB MP4 BottGray">
            {{I1+1}}.{{y.meetTimeStr.substr(5,20)+'➱'+y.zyUserName+":"+y.ItemStr.split(':')[1]}}
            <el-icon><ArrowRight /></el-icon>
          </div>
        </div>

        <PopTitle v-if="SeleI !== -1" :Title="'项目：'+Y.ItemStr.split(':')[1]" @FromPopUp="SeleI=-1">

          <div class="MP4 LH150 TF">
            时间:{{Y.meetTimeStamp}}<br>
            地点：{{Y.meetAddrStr}}
            <div v-if="Y.fySitefee>0" class="XLR">
              <div>场地费：{{Y.fySitefee}}</div>
              <div>付费方式：{{Y.paymentWay}}</div>&nbsp;
            </div>
            <div v-if="Y.jdfy.length>0">简短附言：{{Y.jdfy}}</div>
            <div class="XR MR4 YC MT2">
              <div class="TC">线下活动情况：&nbsp;</div>
              <ShowPVT :PVT="{P:Y.Pic,V:Y.Video,T:Y.Text,Update:false}"></ShowPVT>
            </div>
          </div>
          <table  style="background-color:#fff;">
            <thead>
            <tr class="LH120">
              <th>主约</th>
              <th>响应<br>情况</th>
              <th>战果</th>
              <th>履约情况</th>
              <th>评价反馈</th>
            </tr>
            </thead>
            <tbody>
            <tr class="TF">
              <td>{{Y.zyUserName}}</td>
              <td>{{Y.by.xy}}</td>
              <td class="W26 LH150">{{Y.by.zg}}</td>
              <td>{{Y.by.z_bylyqk}}</td>
              <td>{{Y.by.z_ts}}</td>
            </tr>
            </tbody>
          </table>

          <div class="XR PA3 YC">
            <div class="TC">线下活动情况：&nbsp;</div>
            <ShowPVT :PVT="{P:Y.by.Pic,V:Y.by.Video,T:Y.by.Text,Update:false}"></ShowPVT>
          </div>
        </PopTitle>
      </div>
    </div>
  </div>
</template>

<script>
  import { Compare, myDataFileGet, myMongoDBPost, SendWXMsg, UrlParse} from '@/components/SharedVues/Shared0.js'
  import ShowPVT from "@/components/MyPVTs/ShowPVT";
  import BY3 from "./BY3";
  import MyToast from "@/components/SharedVues/MyToast";
  import PopTitle from "@/components/MyPopWin/PopTitle";

  export default {
    name: "BY",
    components: {
      PopTitle,
      MyToast,
      BY3,
      ShowPVT
    },
    data() {
      return {
        Show: true,
        BYs: this.$store.state.BYMsgs,//初值
        CurrYY3: null,
        SeleI: -1
      }
    },
    computed: {
      Y() {
        return this.BYs[this.SeleI]
      }
    },

    mounted() {
      let CurrYYId = this.$store.state.Bool3;//借助Bool3保存CurrYYId，在DL中赋值
      if (this.$store.state.LandTo === 'BY2') {//如果从后台读入的BYMsgs中，不包含该url中的CurrYYId，则提示“已失效!”
        for (let i = 0, len = this.BYs.length; i < len; i++) {
          if (this.BYs[i]._id === CurrYYId) {
            this.CurrYY3 = this.BYs[i];
            break;
          }
        }
      } else this.ToCurr();
    },
    methods: {
      SetSeleI(Val) {
        this.SeleI = Val;
      },
      ToCurr() {
        let that = this;
        myMongoDBPost("GetMyYYs", {
          fyUserName: that.$store.state.UserName,
          EndBool: false
        }, function (data) {//读取邀约信息数据
          data.bys.sort(Compare('Stamp'));
          that.BYs = data.bys.reverse();
          that.Show = true
        });

      },
      ToHistory() {
        let that = this;
        myMongoDBPost("GetMyYYs", {
          fyUserName: that.$store.state.UserName,
          EndBool: true
        }, function (data) {//读取邀约信息数据
          data.bys.sort(Compare('Stamp'));
          that.BYs = data.bys.reverse();//data.bys.sort(Compare('Stamp'));
          //console.log(that.BYs);
          //for (let i = 0, len = data.bys.length; i < len; i++) that.BYs.splice(data.bys.length - 1 - i, 1, data.bys[i]);
          //that.BYs = [];
          //for (let i = data.bys.length - 1; i > 0; i--) that.BYs.push(data.bys[i]);
          that.Show = false
        });

      },
    }
  }
</script>

<style scoped>
  .W20 {
    width: 20vw;
  }
</style>
