<template>
    <div>
        <div class="MP4"></div>
        <div class="XLR YC TG MP4">
            <div class="AlignC" v-for="(i,index) in [4,5,6,7]" :key="index" @click="M=i">
                <img :src="'UserUpload/static/WXLink/'+MPics[i]" class="WHcc">
                <div>{{MSs[i]}}</div>
            </div>
        </div>
        <div class="XLR YC TG MP4">
            <div class="AlignC" v-for="(i,index) in [0,1,2,3]" :key="index" @click="M=i">
                <img :src="'UserUpload/static/WXLink/'+MPics[i]" class="WHcc">
                <div>{{MSs[i]}}</div>
            </div>
        </div>
        <PopOneAN v-if="[0,1,2,3,4,5,6,7].indexOf(M)>=0" @FromPopUp="M=-1">
            <ToolMain :ToolM="Ms[M]" :M="M" :WXLinkPic="'UserUpload/static/WXLink/'+MPics[M]" :To="To"></ToolMain>
            <div v-if="$store.state.UserName !== '游客'">
                <div v-for="(i,index) in [0,1,2,3,4,5,6,7]" :key="index">
                    <WXLink v-if="ShowWXLink && M===i" :WTitle="'您是不是想找一款免费的'+MTs[M]+'工具？'"
                            WText="不妨试试这一款，还可以个性化订制...." :WLandTo="'YYFWM'+M"
                            :Pic="'UserUpload/static/WXLink/'+MPics[M]"></WXLink>
                </div>
            </div>
        </PopOneAN>
    </div>
</template>
<script>
    import PopOneAN from "../MyPopWin/PopOneAN";
    import PopTitle from "../MyPopWin/PopTitle";
    import PopMenu from "../MyPopWin/PopMenu";
    import QYsSele from "./QYsSele";
    import FYBBing from "./FYBBing";
    import FYBB from "./FYBB";
    import WXLink from "../IndependentVues/WXLink";


    export default {
        name: "YYFW",
        components: {WXLink, FYBB, FYBBing, QYsSele, PopMenu, PopTitle, PopOneAN},
        data() {
            return {
                Ms: ['VIP卡', '记账', '投票', '共享相册', '登记', '问卷', '图文DIY', '表格DIY'],//关联集合：Tools Objs系列
                M: -1,
                MSs: ['VIP专享卡', '流水记账', '投票•打分', '共享相册', '报名•登记', '问卷调查', '图文DIY', '表格DIY'],
                MTs: ['VIP会员卡管理', '流水记账', '投票打分', '共享相册管理', '报名登记', '问卷调查', '图文微信链接DIY', '表格微信链接DIY'],
                MPics: ['KB.gif', 'X_JYJZ.gif', 'X_TPDF.gif', 'GXXC.png', 'X_DJBM.gif', 'X_WJDC.gif', 'X_TWDIY.gif', 'X_BGDIY.png'],


                N: -1,
                SeleM: 0,
                To: '当前',
                CurrCode: '123654',
                ShowWXLink: true,//外层显示微信链接标志
            }
        },
        mounted() {
        },
        methods: {}
    }
</script>

<style scoped>

</style>
