<template>
    <div>
        <div v-if="IsLoading" class="T8 AlignC MT2">IsLoading...</div>
        <div v-else class="TF">
            时间：
            <el-date-picker v-model="CurrYYDoc.meetTimeStr" format="YYYY-MM-DD HH:mm" type="datetime"/>
            <div class="MP4 LH150">
                地点:
                <p>{{CurrYYDoc.meetAddrStr}}</p>
                场地器械费用: {{CurrYYDoc.fySitefee}}元/人<br>
                付费方式: {{CurrYYDoc.paymentWay}}<br>
                简短附言:
                <p>{{CurrYYDoc.jdfy}}</p>
                被约:
                <div class="XL ML4">
                    <div v-for="(x,index) in CurrYYDoc.by" :key="index" class="ML4 YC">
                        <input :id="'FQYY2a'+index" type="checkbox" v-model="x.xy">
                        <label :for="'FQYY2a'+index">{{x._id}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="XLR">
            <div class="T5 TW XYC H3 W50 BGBlue" @click="DeleteCurrMB">删除该模板</div>
            <div class="T5 TW XYC H3 W50 BGDeepGreen" @click="SendYY()">发送邀约</div>
        </div>
        <MyToast v-if="Show4" :Tip="'邀约信息已发出!'" @Tiped="Quit"></MyToast>
        <MyToast v-if="Show5" :Tip="'当前模板已删除!'" @Tiped="Quit"></MyToast>
    </div>
</template>
<script>
    import {
        CloneObject,
        GetC_I,
        GetItemStr3,
        GetItemType,
        GetRelativeTime,
        myMongoDBPost,
        SendWXMsg, MyUrl
    } from '@/components/SharedVues/Shared0.js'
    import MyToast from "@/components/SharedVues/MyToast";

    export default {
        name: "MBYY",
        components: {MyToast},
        data() {
            return {
                IsLoading: true,//数据准备不就绪,就不让它显示
                CurrYYDoc: null,
                Show4: false,
                Show5: false,
                Show6: false
            }
        },
        mounted() {
            let that = this;
            console.log("当前组件:MBYY")
            myMongoDBPost("GetDocs/YYMBs", {
                zyUserName: this.$store.state.UserName,
                ItemStr: this.$store.state.CurrItemStr
            }, function (data) {
                if (data.length > 0) {
                    that.CurrYYDoc = data[0];
                    that.CurrYYDoc.meetTimeStr = GetRelativeTime('h', 1, "yyyy-MM-ddd hh:mm")
                    for (let i = that.CurrYYDoc.by.length - 1; i > -1; i--) that.CurrYYDoc.by[i].xy = true;
                    that.IsLoading = false;
                } else alert( "您选择的经常性邀约模板已经失效!")
            });
        },
        methods: {
            Quit() {
                this.$emit('FromMBYY');
            },

            SendYY() {
                if (!this.Show6) {
                    this.Show6 = true;
                    let that = this, CurrYY = that.CurrYYDoc;
                    let ItemCode = CurrYY.ItemStr.split(":")[0], ItemName = CurrYY.ItemStr.split(":")[1];
                    if (that.$store.state.UserName === '游客') alert("游客操作无效!");
                    else if (CurrYY.by.length === 0) alert('未选择被约，发送无效!'); else {
                        for (let i = CurrYY.by.length - 1; i > -1; i--)
                            if (CurrYY.by[i].xy === false) CurrYY.by.splice(i, 1); else CurrYY.by[i].xy = '未响应';
                        delete CurrYY._id;
                        //CurrYY.YYTempletBool = false;
                        CurrYY.jdfy += '来自模板！';
                        CurrYY.yyBool = '模板';
                        myMongoDBPost("PutDoc/yy-" + ItemCode, CurrYY, function (aDoc) {
                            for (let i = 0, len = CurrYY.by.length; i < len; i++)
                                (function (i) {

                                    let Url = MyUrl('LandTo=BY2QWERItemCode=' + ItemCode + 'QWERItemName=' + ItemName + 'QWERCurrYYId=' + aDoc._id + '___');
                                    let Msg = GetItemStr3(CurrYY.ItemStr) + "☞“" + that.$store.state.UserName + '”向您发起邀约。\n[ <a href="' + Url + '">立即查看/回复</a> ]';
                                    SendWXMsg(CurrYY.by[i]._id, Msg);

                                    let Obj1 = {
                                        UPTJ: {UserName: that.$store.state.UserName, RType: '我约过的'},
                                        UPObj: {$addToSet: {RUsers: CurrYY.by[i]._id}}
                                    }, Obj2 = {
                                        UPTJ: {UserName: CurrYY.by[i]._id, RType: '约过我的'},
                                        UPObj: {$addToSet: {RUsers: that.$store.state.UserName}}
                                    };
                                    myMongoDBPost("UpdateArrayAttr/RUsers", Obj1, function (data) {
                                    });//追加一个关联用户
                                    myMongoDBPost("UpdateArrayAttr/RUsers", Obj2, function (data) {
                                    });//追加一个关联用户:往指定文档的数组属性中增加一个数组元素


                                })(i)//向每一个被选中的被约发送消息
                            that.Show4 = true;
                        })
                    }
                }
            },
            DeleteCurrMB() {
                let that = this;
                myMongoDBPost("RemoveDoc/YYMBs", {
                    zyUserName: this.$store.state.UserName,
                    ItemStr: this.$store.state.CurrItemStr
                }, function (data) {
                    that.$store.commit('ChangeCurrItemStr', "Deleted");
                    that.Show5 = true;
                })
            }
        },

    }
</script>

<style scoped>
</style>
