<template>
    <div>

    </div>
</template>

<script>
    import {ElMessage} from 'element-plus'

    export default {
        name: "MyToast",
        props: ["Tip"],
        mounted() {
            ElMessage(this.Tip)
        },
        updated() {
            this.$emit("Tiped")
        }

    }
</script>
