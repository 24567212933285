let ItemIcons = {//应用注意:1.不要有重复
  A000: 'icondapingpangqiu',//乒乓球",
  A001: 'iconyumaoqiu',//羽毛球",
  A002: 'icontaiqiu-',//台球",
  A003: 'iconbaolingqiu',//保龄球",
  A004: 'A0/TS.png',//跳绳",
  A005: 'icontaiquandao',//踢毽子",
  A006: 'A0/HLQ.png',//晃呼拉圈",
  A007: 'A0/QJ.png',//拳击",
  A008: 'A0/格斗.png',//格斗",
  A009: 'iconganlanqiu',//摔跤",
  A010: 'iconfencing',//击剑",
  A011: 'iconshejian',//射箭",
  A012: 'iconfeibiao',//飞镖"
  A013: 'iconlanqiu_basketball4',//篮球"
  A014: 'A0/PQ.png',//排球"
  A015: 'iconwangqiu',//网球"



  A100: 'iconlanqiu_basketball4',//篮球",
  A101: 'iconzuqiu',//足球",
  A102: 'A0/PQ.png',//排球",
  A103: 'iconwangqiu',//网球",
  A104: 'icontiyu-gaoerfuqiu',//高尔夫球",
  A105: 'iconyundong66',//竞走",
  A106: 'iconyundong66',//赛跑",
  A107: 'A0/TGTY.png',//跳高跳远",
  A108: 'A0/TS.png',//跳绳",
  A109: 'iconfengzheng',//放风筝",
  A110: 'iconlunhua1',//轮滑",
  A111: 'iconhuaban',//' ,//滑板"

  A200: 'icon595kuaizhuanghuabing',//滑冰",
  A201: 'iconhuaxue2',//滑雪",
  A202: 'iconyouyong',//游泳",
  A203: 'iconfishingfill',//钓鱼",
  A204: 'iconjineng_icon-1',//潜水",
  A205: 'iconchonglang',//冲浪漂流",
  A206: 'iconlunchuan',//航海模型"

  A300: 'iconpanyan',//登山攀岩",icontiyu-dengshan
  A301: 'A1/蹦极.png',//蹦极",
  A302: 'A1/定向运动.png',//定向运动",
  A303: 'A1/探险.png',//探险",
  A304: 'iconsizhoufeihangqi',//航空模型",
  A305: 'iconwuxiandianbo',//无线电",
  A306: 'iconsaiche',//赛车",
  A307: 'iconsaima',//赛马",
  A308: 'iconqiche1',//自行车"

  A400: 'iconxiangqi',//中国象棋",
  A401: 'iconguojixiangqi',//国际象棋",
  A402: 'iconweiqi',//围棋",
  A403: 'A0/JQ.png',//军棋",
  A404: 'iconX204',//打麻将",
  A405: 'iconpuke',//升级",
  A406: 'iconpuke',//跑得快",
  A407: 'iconpuke',//拱猪",
  A408: 'iconpuke',//桥牌",
  A409: 'iconpuke',//砸六家",
  A410: 'iconpuke',//斗地主",
  A411: 'iconpuke',//21点",
  A412: 'iconpuke',//五十K",
  A413: 'iconpuke',//炸金花",
  A414: 'iconpuke',//梭哈",
  A415: 'iconpuke',//德州扑克",
  A416: 'iconpuke',//掼蛋",


  B101: 'iconxuexi',//"功课",
  B102: 'iconsikao',//"创业",
  B103: 'iconyulingfunv',// "家常",
  B104: 'icongongzuo',//"工作",
  B105: 'iconlicai',//"理财",
  B106: 'icongudongzhongbiao',//"收藏",
  B107: 'iconxiangshengxiaopin',//"文艺",
  B108: "iconiconfont-shishizixun",
  B109: "iconaiqingyulangman-",
  B110: "iconservice",
  B111: "iconbagua",
  B112: 'iconjingchanghejiu',// "喝酒",
  B113: 'iconhechaxiuxi',// "喝茶",
  B114: 'iconkafei',// "喝咖啡",

  B001: "iconxiyuzhongxin",
  B002: "iconjiatingktv",
  B0021: "iconjiatingktv",
  B003: "iconfishingfill",
  B004: "iconX204",
  B005: "iconpuke",
  B006: "iconyundongqipai",
  B007: "iconchuangye",
  B008: "iconladingwu",
  B009: "iconguangchangwu",
  B010: 'iconyouxiji',//"游戏机 ",
  B011: "iconjianchengqugongyuanlvdimifuwubanjingfugaishuai",
  B012: "iconmeishi",
  B013: "icongouwu1",
  B014: "icongudandelan_qiuqian",
  B015: "iconweibiaoti-xishuai",
  B016: "iconshuji",
  B017: "iconxibanyayu",
  B018: "iconqiche",
  B019: "iconjianzi1",
  B020: 'iconxinshouketang',//"玩读心术 ",
  B021: 'iconlanqiu_basketball4',//"花式篮球 ",
  B022: 'iconlogo',//"花样滑冰 ",
  B023: 'iconwudaodaoli',//"街舞",

  B401: 'icon02',// "英语",
  B402: 'iconriyu1',//"日语",
  B403: 'iconeiffel1162713easyiconnet1',// "法语",
  B404: 'iconyidaliyu',//"意大利语",
  B405: 'iconshatealaboyu',//"阿拉伯语",
  B406: 'icondeyu',//"德语",
  B407: 'iconeyu',//"俄语",
  B408: 'iconhanyu',//"韩语",
  B409: "iconxibanyayu",
  B410: 'iconputaoya',//"葡萄牙语",

  B601: 'B6/相声.png',//'相声',
  B602: 'iconshi',//"小品",
  B603: 'iconjingju',// "京剧 ",
  B604: 'iconyu',//"豫剧 ",
  B605: 'B6/越剧.png',//'越剧 ',
  B606: 'B6/黄梅戏.png',//'黄梅戏 ',
  B607: 'B6/评剧.png',//'评剧 ',
  B608: 'B6/粤剧.png',//'粤剧' ,
  B609: 'B6/川剧.png',// '川剧 ',
  B610: 'B6/淮剧.png',// '淮剧 ',
  B611: 'B6/绍剧.png',//'绍剧 ',
  B612: 'B6/琼剧.png',//'琼剧',
  B613: 'B6/沪剧.png',// '沪剧 ',
  B614: 'B6/汉剧.png',// '汉剧 ',
  B615: 'B6/潮剧.png',//'潮剧 ',
  B616: 'B6/滇剧.png',//'滇剧 ',
  B617: 'B6/徽剧.png',//'徽剧',
  B618: 'B6/桂剧.png',//'桂剧 ',
  B619: 'B6/昆曲.png',//'昆曲',
  B620: 'B6/花鼓戏.png',//'花鼓戏'
  B621: 'B6/采茶戏.png',//'采茶戏' ,
  B622: 'B6/河北梆子.png',//'河北梆子 ',
  B623: 'B6/河南坠子.png',//'河南坠子 ',
  B624: 'B6/山东快书.png',//'山东快书 ',
  B625: 'B6/秦腔.png',// '秦腔',
  B626: 'B6/二人转.png',// '二人转',iconerrenzhuan
  B627: 'B6/评书.png',//'评书 ',
  B628: 'B6/琴书.png',//'琴书',
  B629: 'B6/双簧.png',//'双簧 ',
  B630: 'B6/单弦儿.png',//'单弦儿 ',
  B631: 'B6/龙头琴.png',//'龙头琴弹唱 ',
  B632: 'B6/都它尔.png',// '都它尔弹唱 ',
  B633: 'B6/苏州弹词.png',// '苏州弹词 ',
  B634: 'B6/大鼓.png',//'大鼓 ',
  B635: 'B6/腰鼓.png',//'腰鼓',

  B201: "iconzoulu",
  B202: 'iconz056',//"自行车",
  B203: 'iconyueyeche',// "自驾",
  B204: 'icondaoyouliebiao',//"跟团游",
  B205: 'icongongyuan',//"郊游",
  B206: 'iconnongjiale',//"农家乐",
  B207: 'iconfeiji',//"出国游",
  B208: 'icongaotie',// "火车远游",
  B209: 'iconlunchuan',// "轮船远游",

  B701: 'iconzajizuopin',//"杂技综合",
  B702: 'iconmoshumaozi',//"小微魔术",
  B703: 'iconmoshu_magic',//"中型魔术",
  B704: 'iconbaojianfuwu',//"大型魔术 ",
  B705: 'iconpipa',//"街头艺术 ",
  B706: 'iconHalloween-1',// "巫术表演 ",
  B707: 'B7/踩高跷.png',//'踩高跷',
  B708: 'B7/舞龙.png',
  B709: 'B7/舞狮.png',

  B501: "icondiyinpuhao",
  B502: "iconzhongyin",
  B503: "icongaoyingaobadu",
  B504: 'iconweibiaoti--',//"民族舞 ",
  B505: 'iconladingwu',//"现代舞 ",
  B506: 'iconwudaobalei',//"芭蕾舞" ,
  B507: 'iconvipyunketang',//'"歌剧 ",
  B508: 'iconxiaotiqin',//"西洋乐器 ",
  B509: 'iconicon-test-copy',//"民族乐器 ",
  B510: 'B5/管弦乐队.png',//'管弦乐队 ',
  B511: 'B5/摇滚乐队.png',//'摇滚乐队 ',
  B512: 'B5/流行乐队.png',//'流行乐队 ',
  B513: 'B5/电声乐队.png',// '电声乐队 ',
  B514: 'B5/爵士乐队.png',//'爵士乐队',

  B301: 'icondangao',//"家庭派对",
  B302: 'icon19',//"大型派对",
  B303: 'iconshenqingchengweidaoyou',//"合法游行",
  B304: 'iconmonidaoyouzonghexunlian',//"合法集会",
  B305: 'iconkaihui1',//"工作会议",
  B306: 'iconhuiyikaihuitaolun',//"饭局聚餐 ",
  B307: 'iconshaokaojia-',//"野外聚餐 ",
  B308: 'icontiaowu',//"联欢会 ",
  B309: 'iconkanzhanlan',//"展览会",
  B801: 'iconqiudui',

  D101: 'iconzhongyiketang',//"健康教育",
  D102: 'iconrenwuxinwen',//"时事政治",
  D103: 'iconzaixianketang',//"家庭理财",
  D104: 'iconwurenji',//"科普知识",
  D105: 'iconiconfonthongbao',//"电子商务 ",
  D106: 'iconwudao',// 艺术欣赏
  D107: 'iconciqi',//"收藏艺术",
  D108: 'iconqunzu',//"做人之道 ",
  D109: 'iconbagua',//"生命预测 ",
  D110: 'iconzhandouji',//'"现代军事 ",
  D111: 'iconsixiangmengya',//"精神世界 ",
  D112: 'iconguanli',//"管理艺术 ",
  D113: 'icontechanmeishiyingyangbaojian',//"膳食营养 ",
  D114: 'iconfalv',//"法律普及",
  D115: 'iconjinrong',//金融证券
  D116: 'icongaokeji',//科技前沿
  D117: 'icondili',//历史地理

  D001: 'iconPerformance',//"戏曲演员",
  D002: 'iconfuyoubaojian',//"影视演员",
  D003: 'iconrenwu1',//"歌唱演员",
  D004: 'iconzuojia',//作家
  D005: "icondianzanrenwu",
  D006: 'iconmingshiketang',//"企业家",
  D007: "iconrw-qt-t",
  D008: 'iconrenwu',//"画家",
  D009: 'iconyundong1',//"运动员",
  D010: 'D0/相声演员.png',//"相声演员",
  D011: 'iconrenwufangtan',//"小品演员",

  D201: 'iconlanqiu_basketball4',//"篮球",
  D202: 'iconzuqiu_football118',//"足球",
  D203: 'icondapingpangqiu',//"乒乓球 ",
  D204: 'iconyumaoqiu',//"羽毛球 ",
  D205: 'icontiyu-gaoerfuqiu',//"高尔夫球 ",
  D206: 'iconpaiqiu',//"排球",
  D207: 'icondawangqiu',//"网球 ",
  D208: 'icontaiqiu-',//"桌球",
  D209: 'iconicon-test1',//"橄榄球 ",
  D210: 'iconbangqiu',//"棒球 ",
  D211: 'iconyouyong',//"游泳 ",
  D212: 'iconqipai',//"棋牌 ",
  D213: 'iconsaiche',//"赛车",
  D214: "iconchangge",
  D215: 'iconertongbaojian',//'"影视作品 ",
  D216: 'iconyundong66',//"田径运动会",
  D217: 'iconzhihuiyuan',//"歌咏比赛 ",
  D218: 'iconcanjirenzuo_hover',//"残运会 ",
  D219: 'iconnongminbao',//"农运会 ",
  D220: "iconshaoshuminzu",

  D301: "iconnonglinmuyuye",
  D302: "iconyejinkuangchan",
  D303: 'iconenergy_02',//"能源开发",
  D304: 'iconjixie',//"机械机电",
  D305: 'icontubiaozhizuomoban',//"化工制药",
  D306: 'iconfuzhuangfangzhihepigezhipinshishangpeishizhongbiaozhu',//"纺织服装",
  D307: 'iconjianzhu',//"建筑建材",
  D308: 'iconjiadianweixiuzhan',//"轻工家用",
  D309: 'iconyunshu',// "物流运输",
  D310: 'icongongzuo',//"商务社保",
  D311: 'iconduomeiti-',//' "信息传媒",
  D312: 'iconbaojian3',// "药医保健",
  D313: 'iconzaixianketang',//"教育培训",
  D314: 'iconbaojianketang',//'"吃住旅游",
  D315: "iconanquanmao",
  D316: 'iconlicai',//"金融投资",
  D317: 'iconlogo',//"文体艺术",
  D318: "iconjingchaliecheyuan",
  D319: 'iconhuodongguanli',//"社会服务"

  C200: 'iconyiliaobaojianlei',//医疗保健,
  C201: 'iconjianzhugongcheng',//建筑工程,
  C202: 'iconhuodong',//活动会议,
  C203: 'icontiaocha',//市场调查,
  C204: 'iconweibiaoti-qiming',//起名,
  C205: 'iconiconfontzhuangxiucopy',//装修装饰,
  C206: 'iconfalv1',//装修装饰,

  C300: 'iconwenxue',//文学创作,
  C301: 'icontranslation',//资料翻译,
  C302: 'iconruanwen',//软文口号,
  C303: 'iconruanwenzhuanxie',//应用写作,
  C304: 'iconshangyejihuashuzhuanxie',//商业计划书
  C305: 'iconwj-fa',//方案攻略

  C400: 'iconzimeiti',//自媒体,
  C401: 'iconluntan',//论坛评论,
  C402: 'iconqun',//群圈空间,
  C403: 'iconwangzhan',//分类网站,
  C404: 'iconduomeiti',//海报传单,
  C405: 'iconlianjie',//友情链接,
  C406: 'iconduanxin-cehua-xinxixiangqing',//短信邮件,
  C407: 'icondianshi',//电台电视,
  C408: 'iconguanggao-',//创意广告,
  C409: 'icontoufang',//精准投放,
  C410: 'iconbaitanjingling',//微商摊贩

  C500: 'iconhuoche',//车务票务,
  C501: 'iconlicai2',//保险理财,
  C502: 'iconchanquanguanli',//知识产权,
  C503: 'iconshuiwuganbuweijijianju',//工商税务,
  C504: 'iconq',//海关,
  C505: 'iconiconcopy',//海外代购,

  C600: 'iconyuwen',//小学语文,
  C601: 'iconshuxue1',//小学数学,
  C602: 'iconshaoeryingyu',//小学英语,
  C603: 'iconchinse',//初中语文,
  C604: 'iconshuxue',//初中数学,
  C605: 'icon13tinglitiku',//初中英语,
  C606: 'iconyuwen1',//高中语文,
  C607: 'iconshuxuegongshi',//高中数学,
  C608: 'iconduihua',//高中英语,
  C609: 'iconwuli',//物理化学,
  C610: 'icondiqiuyi',//史地政生,
  C611: 'iconjiangbei',//各类竞赛,
  C612: 'iconerrenzhuan',//体育舞蹈,
  C613: 'iconxiaotiqin',//乐理乐器,
  C614: 'icon-huihua',//美术手工,

  C700: 'icondapingpangqiu',//乒乓球,
  C701: 'iconyumaoqiu',//羽毛球,
  C702: 'iconweiqi',//围棋,
  C703: 'icontaiquandao',//跆拳道,
  C704: 'iconlunhua1',//轮滑,

  C100: 'iconwangzhan',//网站开发,
  C101: 'iconjiemiansheji',//软件界面,
  C102: 'iconmianliaopingtaiicon_tuanbiaoqian',//图案标志,
  C103: 'icondonghua',//课件动画,
  C104: 'iconshipin',//视频音频,
  C105: 'iconSong',//谱曲填词,
  C106: 'iconshufazidian',//字画定制,
  C107: 'iconmoxing',//造型模型,

  C000: 'iconbanjia',//搬家运输,
  C001: 'iconqingxi',//保洁清洗,
  C002: 'iconbaomu',//保姆陪护,
  C003: 'iconhuishou',//二手回收,
  C004: 'iconflower',//鲜花绿植,
  C005: 'iconkuaidi',//跑腿配送,
  C006: 'iconfuzhuangfangzhihepigezhipinshishangpeishizhongbiaozhu',//衣物养护,
  C007: 'iconjiadianweixiuzhan',//家电维修,
  C008: 'iconfangwu1',//房屋修缮,
  C009: 'iconsuokongxinkaisuo',//家具锁具,
  C010: 'iconhunli-',//婚庆喜筵,
  C011: 'iconsiji',//管家司机,
  C012: 'iconanmo',//保健按摩,
  C013: 'iconmudi',//殡葬墓地
  C014: 'iconV',//接送托护

  C801: 'iconchushi',//厨师
  C802: 'iconicon-test',//美发师
  C803: 'iconMassage',//按摩师
  C804: 'iconfuzhuang-',//服装设计师
  C805: 'iconshuinuanqigongcheng',//水暖工
  C806: 'iconjianzhudianqigongcheng',//电工
  C807: 'iconshuini',//泥瓦工
  C808: 'iconmugong01',//木工
  C809: 'iconyouqigong',//油漆工
  C810: 'iconjianzhu1',//钢筋架子工
  C811: 'icongongren',//车钳铸焊工
  C812: 'icondupinweixian',//高危毒化工
  C813: 'iconren',//厂矿技术
  C814: 'iconiconset0162',//小众技能
  C815: 'iconshuangrentaizhongwubiao-',//重体力活
  C816: 'iconjingcha',//门卫保安
  C817: 'icondianzanrenwu',//群众演员
  C818: 'icondoor',//打杂短工

  C901: 'iconkafei',//休闲娱乐
  C902: 'iconcantingrestaurant42',//餐饮美食
  C903: 'iconicon-test',//理发美容
  C904: 'iconMassage',//保健美体
  C905: 'iconxiche-',//汽车洗修
  C906: 'iconfengren',//裁缝编制
  C907: 'iconfengren',//眼镜锁具
  C908: 'iconyiyao',//药店门诊
  C909: 'iconwujin01',//五金杂货
  C910: 'iconyoupinwangtubiao-',//宠物家居
  C911: 'iconerrenzhuan',//老幼托护
  C912: 'iconguangchang',//广场空地
  C913: 'iconyinxiaojuchang32',//公园剧场
  C914: 'iconhuiyishi',//会议教室
  C915: 'iconkouqiang',//口腔整容
  C916: 'iconfeijiuwuzi-tongguoicon',//废旧处理

};
export {ItemIcons}
