<template>
  <div>
    <PopUpDown Title="活动信息创建" :ANs="2" Pos="btt" @FromPop5="SaveActDoc">
      <div v-if="$store.state.UserBools.indexOf('社交活动')===-1 && OkAuthorizationCode!=='9021'" class="MP4">
        <p>若要发布活动，需要先通过“首页→设置管理→权限申请…”取得活动信息发布资格。您当前尚无资格，但可以通过提供特别授权码，进行发布操作。</p>
        授权码：<input type="text" class="MP4 W26" @focus="OkAuthorizationCode=null" v-model="OkAuthorizationCode"/>
      </div>
      <div v-if="$store.state.UserBools.indexOf('社交活动') >= 0 || OkAuthorizationCode==='9021'" class="MP4">
        <div v-if="OP === '修改' && ActDoc.EndBool===1" class="XR YC TB" @click="SetActEndbool(2)">设为过期活动</div>
        <div v-if="OP === '修改' && ActDoc.EndBool===2" class="XR YC TB" @click="SetActEndbool(1)">设为当前活动</div>
        <div class="XL YC">活动范围：
          <FYArea @FromFYArea="SetActPCD()"></FYArea>
        </div>
        <div v-if="ActDoc.HoldStyle==='封闭式'" class="TF T3 XR">(指定参赛器时，须具体到城市)</div>
        <div class="XL YC">活动名称：<input type="text" class="W90" v-model="ActDoc.Title"/></div>
        <div class="XLR YC">
          <div>
            方式:
            <div :class="'BoxGray1 MP4 DispIB'+(ActDoc.HoldStyle==='开放式' ? ' Checked':' TF') "
                 @click="ActDoc.HoldStyle='开放式'">开放式
            </div>
            <div :class="'BoxGray1 MP4 DispIB'+(ActDoc.HoldStyle==='封闭式' ? ' Checked':' TF') "
                 @click="ActDoc.HoldStyle='封闭式'">封闭式
            </div>
          </div>
          <div>序号: <input type="number" class="W6" @focus="ActDoc.Index=null" v-model.number="ActDoc.Index"/></div>
        </div>
        <div v-if="ActDoc.HoldStyle==='开放式'">
          <div>
            活动类型:
            <div class="DispIB BDGreen MP4 TB T4" @click="Show=3">{{ActDoc.ActStyle===''?"—":ActDoc.ActStyle}}</div>
            <MySele1 v-if="Show===3" :Opts="ActStyles" :ColN="1" @FromMySele1="SetActStyle"></MySele1>
          </div>
          <div class="XLR YC">
            <div>
              参加人员:
              <div class="DispIB BDGreen MP4 TB T4" @click="Show=4">{{ActDoc.ActForm===''?"—":ActDoc.ActForm}}</div>
            </div>
            <div class="DispIB BDGreen MP4 TB T4" @click="Show=2">{{ActDoc.Scale===''?"—":ActDoc.Scale}}</div>
            <div></div>
          </div>
          <MySele1 v-if="Show===2" :Opts="Scales" :ColN="3" @FromMySele1="SetScale"></MySele1>
          <MySele1 v-if="Show===4" :Opts="['单打','双打','团体']" :ColN="3" @FromMySele1="SetActForm"></MySele1>
          <div v-if="ActDoc.ActStyle==='其它活动'" class="XL YC MT2">
            活动说明：
            <ShowUpdatePVT :PVTF="{P:ActDoc.Pic,V:ActDoc.Video,T:ActDoc.Text, TextTitle:'活动内容简要文字介绍'}"
                           @ShowUT="CallBack"></ShowUpdatePVT>
          </div>
          <div v-else class="XL YC">
            关联积分池:
            <div class="DispIB TB MP4 BoxGray1" @click="Show3=true,CurrJFPool=''">{{CurrJFPool}}</div>
          </div>
          <MySele1 v-if="Show3" :Opts="JFPools" :ColN="3" @FromMySele1="SetCurrJFPool"></MySele1>

          <div v-if="ActDoc.Scale==='额满为止'">
            <div class="XL YC">限定人数：<input type="number" class="MP4 W26" @focus="ActDoc.ScaleVal=null"
                                           v-model.number="ActDoc.ScaleVal"/>
            </div>
          </div>
        </div>
        <div v-if="ActDoc.HoldStyle==='封闭式'" class="XL YC">
          参赛器类型：
          <div class="Box41 BD_Radius1 MP4">
            <MySelectStr CallFrom="Act" :Msg="['单打','双打','团体']" :CurrN="0" @eventFromChild="SetActForm"></MySelectStr>
          </div>
        </div>
        <div v-if="ActDoc.Scale==='凭密参加' || ActDoc.HoldStyle==='封闭式'" class="XLR YC">
          <div>
            进入口令：
            <div class="TF T3 AlignC">（纯数字）</div>
          </div>
          <input type="number" class="MP4 W26" @focus="ActDoc.ScaleVal=null" v-model.number="ActDoc.ScaleVal"/>
          <div class="TF T2 LH120">若无须保密，<br>则不必输入。</div>&nbsp;
        </div>
        <div v-if="ActDoc.HoldStyle===''" class="TF T2">
          <div class="BottGray W26 AlignC">备注：</div>
          <p>开放式：任何用户均可自由参加；赛事比分由参加者自行输入；活动标题唯一。</p><!--；活动规则解释权属于本平台-->
          <p>封闭式：活动管理员指定参加人员（如：会员、报名者）；赛事比分由计分员输入；活动标题下须包含二级标题（即包含子活动）。</p><!--，活动规则解释权属于活动举办方-->
        </div>
      </div>
    </PopUpDown>
  </div>
</template>

<script>
  import {myMongoDBPost, AutoAlert, CloneObject} from '@/components/SharedVues/Shared0.js'
  import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import MyInput from "../SharedVues/MyInput";
  import MySele1 from "../SharedVues/MySele1";
  import SeleOneItem from "../FrontPage/SeleOneItem";
  import MySelectStr from "../SharedVues/MySelectStr";
  import PopUpDown from "../MyPopWin/PopUpDown";
  import FYArea from "../SharedVues/FYArea";

  export default {
    name: "CreateAct",
    components: {FYArea, PopUpDown, MySelectStr, SeleOneItem, MySele1, MyInput, PopTitle, ShowUpdatePVT},
    props: ['OP', 'Msg'],
    data() {
      return {
        CurrJFPool: '',
        JFPools: ['泛约'],
        Show3: false,

        Show: -1,
        ActStyles: ['单场地单循环排名赛', '跨场地单循环排名赛', '单场地多循环排名赛', '跨场地多循环排名赛', '其它活动'],
        Scales: ['不限人数', '额满为止', '凭密参加'],//不限人数和额满为止=泛约用户自由报名 凭密参加=由计分员负责输入参加者且凭口令进入
        ActDoc: {
          Index: 0,
          UserName: this.$store.state.UserName,
          Certified: this.$store.state.Certified,
          UserLogo: this.$store.state.UserLogo,

          Title: '',//活动名称或标题
          HoldStyle: '',
          ActForm: '单打',
          Scale: this.Msg.Scale === undefined ? '' : this.Msg.Scale,//活动规模= 不限人数 ;额满为止 ;特许参加
          ScaleVal: this.Msg.ScaleVal === undefined ? null : this.Msg.ScaleVal,// 活动参数:不限人数型=-1;额满为止型=限定人数;特许参加型=纯数字的特许口令
          ActStyle: '',
          ItemStr: this.Msg.ItemStr === undefined ? '' : this.Msg.ItemStr,
          JFPool: '泛约',
          BMList: [],

          Province: this.$store.state.FYArea.split(":")[1],
          City: this.$store.state.FYArea.split(":")[2],
          DC: this.$store.state.FYArea.split(":")[3],

          Pic: '',
          Video: '',
          Text: '',
          Time: 0,
          EndBool: 1,
          ScoringMethod: "按胜场次"
        },
        OkAuthorizationCode: ''
      }
    },
    mounted() {
      if (this.OP === '修改') this.ActDoc = this.Msg;
      let that = this;
      if (this.Msg.ItemStr !== undefined)
        myMongoDBPost("GetDocs/byset0", {
          UserName: that.$store.state.UserName,
          ItemStr: that.Msg.ItemStr
        }, function (Docs2) {
          if (Docs2.length > 0) {
            for (let i = 0; i < Docs2[0].MySites.length; i++) that.JFPools.push(Docs2[0].MySites[i].SiteShortName);
            that.CurrJFPool = (Docs2[0].CurrClub === '' ? '泛约' : Docs2[0].CurrClub);//应特别注意：这里的CurrJFPool和CurrClub
            that.ActDoc.JFPool = that.CurrJFPool;
          }
        });

    },
    computed: {
      CurrFYArea() {
        return this.$store.state.FYArea;
      }
    },
    watch: {
      CurrFYArea(Val) {
        this.SetActPCD(Val);
      }
    },
    methods: {
      SetActPCD(V) {
        let X = this.$store.state.FYArea.split(":");
        if (X[0].charAt(0) === 'P') this.ActDoc.Province = X[1];
        if (X[0].charAt(0) === 'C') this.ActDoc.Province = X[1], this.ActDoc.City = X[2];
        if (X[0].charAt(0) === 'D') this.ActDoc.Province = X[1], this.ActDoc.City = X[2], this.ActDoc.DC = X[3];
      },
      SetCurrJFPool(Val) {
        this.CurrJFPool = (Val === '' ? '泛约' : Val);
        this.ActDoc.JFPool = this.CurrJFPool;
        this.Show3 = false;
      },
      Quit(X, Y) {
        AutoAlert(X, Y);
        this.$emit("CreateAct", '');
      },
      CallBack(Val) {
        if (Val.P) this.ActDoc.Pic = Val.P;
        if (Val.V) this.ActDoc.Video = Val.V;
        if (Val.T) this.ActDoc.Text = Val.T;
      },
      SetScale(Val) {
        this.ActDoc.Scale = Val;
        this.Show = 0;
      },
      SetActForm(Val) {
        this.ActDoc.ActForm = Val;
        this.Show = 0;
      },
      SetActStyle(Val) {
        //AutoAlert("ActDoc.ItemStr="+this.ActDoc.ItemStr,"Msg.ItemStr="+this.Msg.ItemStr);
        if (this.ActDoc.ItemStr === '' && Val.indexOf('排名赛') >= 0)
          AutoAlert('赛事类活动必须要先指定一个竞技项目', '请先[取消],再点击顶部［全部项目］进行选择!');
        else
          this.ActDoc.ActStyle = Val;
        this.Show = 0;
      },
      SaveActDoc(Val) {
        let that = this;
        console.log(that.ActDoc);
        if (Val === -1) this.$emit("CreateAct", ''); else if (that.ActDoc.Title === '') this.Quit('活动名称尚未输入', '请确保所有选项都已完成！');
        else if (that.ActDoc.HoldStyle === '开放式' && that.ActDoc.ActStyle === '') this.Quit('活动类型必须确定', '请确保所有选项都已完成！');
        else if (that.ActDoc.HoldStyle === '开放式' && that.ActDoc.ActForm === '') this.Quit('参加人员必须确定', '请确保所有选项都已完成！'); else {
          if (this.OP === '新增') {

            myMongoDBPost("GetDocs/Acts", {Title: this.ActDoc.Title}, function (Docs) {
              if (Docs.length > 0) that.Quit('操作无效!', '同一活动名称不得重复发布!');
              else myMongoDBPost("PutDoc/Acts", that.ActDoc, function (data) {
                if (that.ActDoc.HoldStyle === '封闭式' && that.ActDoc.ActForm !== '' && that.ActDoc.City !== 'C') {
                  let aObj = {
                    CAUserName: that.$store.state.UserName,
                    ItemStr: that.ActDoc.ItemStr,
                    Province: that.ActDoc.Province,
                    City: that.ActDoc.City,
                    Title: that.ActDoc.Title,
                    BSType: that.ActDoc.ActForm,
                    Times: [],
                    Sites: [],
                    Teams: [],
                    ST0s: [],
                    Locked: false,
                    EndBool: false
                  };
                  myMongoDBPost("PutDoc/CSQs", aObj, function (data) {
                  })
                }
                that.$emit("CreateAct", data);
              });
            });
          } else myMongoDBPost("DocPutBack/Acts", that.ActDoc, function (data) {
            that.$emit("CreateAct", that.ActDoc);
          });
        }
      },
      SetActEndbool(NewEndbool) {
        let that = this;
        that.ActDoc.EndBool = NewEndbool;
        myMongoDBPost("DocPutBack/Acts", that.ActDoc, function (data) {
          let aObj = {UPTJ: {Title: that.ActDoc.Title}, UPObj: {EndBool: NewEndbool === 2}};
          myMongoDBPost('UpdateDoc/CSQs', aObj, function () {
            that.$emit("CreateAct", 'SetEndbool');
          });
        });
      }
    }
  }
</script>

<style scoped>
</style>
