
import {
  StampToDate,
  myMongoDBPost,
  myDataFileGet,
  SendWXMsg,
  GetItemStr3,
  Tofixed, KeepZX,
  GetItemType
} from '@/components/SharedVues/Shared0.js'
import MyToast from "@/components/SharedVues/MyToast";
import ShowPVT from "@/components/MyPVTs/ShowPVT";
import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";

export default {
  components: {ShowPVT, ShowUpdatePVT},
  props: ['Msg'],
  data() {
    return {
      CurrYY: this.Msg.CurrYY,
      Show1: false,
      PJed: false,//评价过标志
    }
  },
  methods: {
    Tofixed(aFloat, DecN) {
      return Tofixed(aFloat, DecN)
    },
    StampToDate(Stamp) {
      return StampToDate(Stamp)
    },
    CurrByEndProcess(that, ItemType, ItemCode, ItemName, CurrYYDoc, CurrBYObj, N) {
      let ZG = CurrBYObj.zg,aStr1='',aStr2='';
      if (CurrBYObj.xy === '暂拒' || CurrBYObj.xy === '拉黑') {//若被约有出场费,应退还给主约
        if (CurrBYObj.xy === '暂拒') {
          aStr1=GetItemStr3(ItemCode + ":" + ItemName) + '☞您因「' + CurrBYObj.zg +'」' + CurrBYObj.xy + '了' + CurrYYDoc.zyUserName+'。';
          //aStr2= GetItemStr3(ItemCode + ":" + ItemName) + '☞被约『' + CurrBYObj._id + '』因「' + CurrBYObj.zg +'」'+ CurrBYObj.xy+'了您。';
        }
        if (CurrBYObj.xy === '拉黑') {
          aStr1=GetItemStr3(ItemCode + ":" + ItemName) + '☞您拉黑了『' + CurrYYDoc.zyUserName+'』。';
          //aStr2= GetItemStr3(ItemCode + ":" + ItemName) + '☞被约『' + CurrBYObj._id + '』拉黑了您。';
        }
        SendWXMsg(CurrBYObj._id, aStr1);
        //SendWXMsg(CurrYYDoc.zyUserName,aStr2);
        if (CurrBYObj.AppearanceFee > 0) KeepZX(that, CurrYYDoc.zyUserName, '收', CurrBYObj.AppearanceFee, "被约" + CurrBYObj.xy + CurrYYDoc.zyUserName + "退还泛约币", function () {
        })
      } else if (CurrBYObj.z_bylyqk === '被约爽约' && CurrBYObj.AppearanceFee > 0)
        KeepZX(that, CurrYYDoc.zyUserName, '收', CurrBYObj.AppearanceFee, "被约爽约,退还主约泛约币", function () {
        });
      else {
        if (CurrBYObj.AppearanceFee > 0) {//打擂竞技频道，打擂获胜才能获取泛约币，其它频道只要履约就能获取泛约币
          if (ItemCode.charAt(0) === 'A') {
            if (ZG === '主约胜') {
              KeepZX(that, CurrYYDoc.zyUserName, '收', CurrBYObj.AppearanceFee * 2, "主约获胜,解冻并赢取泛约币", function () {
              });
              KeepZX(that, CurrBYObj._id, '支', CurrBYObj.AppearanceFee, "主约" + CurrYYDoc.zyUserName + "获胜,输掉泛约币", function () {
              });
              SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(ItemCode + ":" + ItemName) + '☞打擂成功,赢取被约『' + CurrBYObj._id + '』泛约币:' + CurrBYObj.AppearanceFee + "个");
              SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞主约『' + CurrYYDoc.zyUserName + '』获胜,输掉泛约币:' + CurrBYObj.AppearanceFee + "个");
            }
            if (ZG === '被约胜') {
              KeepZX(that, CurrBYObj._id, '收', CurrBYObj.AppearanceFee, "被约获胜,赢取泛约币", function () {
              });
              SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(ItemCode + ":" + ItemName) + '☞被约『' + CurrBYObj._id + '』获胜,输掉泛约币:' + CurrBYObj.AppearanceFee + "个");
              SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞守擂成功,赢取『' + CurrYYDoc.zyUserName + '』泛约币:' + CurrBYObj.AppearanceFee + "个");
            }
            if (ZG === '平局') {
              SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(ItemCode + ":" + ItemName) + '☞主被约打了个平局!');
              SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞主被约打了个平局!');
            }
          } else {
            KeepZX(that, CurrBYObj._id, '收', CurrBYObj.AppearanceFee, "被约履约,挣取泛约币", function () {
            });
            SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞您履约了，挣取泛约币:' + CurrBYObj.AppearanceFee + "个");
          }
        } else SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + (N === 1 ? '☞项目邀约活动已结束！' : '☞主约取消了本次邀约！'));
      }
    }
  }
}
