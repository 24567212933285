<template>
  <div>

    <div class="LH150 MLR4">
      <div>属地：{{CurrSite.Country}}{{CurrSite.Province}}{{CurrSite.City}}{{CurrSite.DC}}</div>
      <div class="XL YC">
        详址：{{CurrSite.Address===''?"―":CurrSite.Address}}
      </div>
      <div class="XLR YC">
        <div>
          <div class="XL MTB2 YC">
            电话：{{CurrSite.Telephone===''?"―":CurrSite.Telephone}}
          </div>
          <div class="XLR YC">
            <div class="XL YC T3">
              门脸：
              <ShowPFromThumb v-if="CurrSite.Pic.length>0" :Msg="{Pic:CurrSite.Pic,Update:false}"></ShowPFromThumb>
            </div>&nbsp;&nbsp;&nbsp;
            <div class="XL YC T3">
              步行路线：
              <ShowVFromThumb v-if="CurrSite.Video.length>0" :Msg="{Video:CurrSite.Video,Update:false}"></ShowVFromThumb>
            </div>
          </div>
        </div>
        <div class="AlignC" @click="ShowMap=true">
          <img src="../../../static/img/SY/Map1.png" class="WHcc BD_Radius1 BDGreen">
          <div class="TB">导航</div>
        </div>
      </div>
      <div class="MT2 XL">简介：{{CurrSite.Remark}}</div>
    </div>
    <FYMap v-if="ShowMap" OP="Look" :Msg="{Name:CurrSite.SiteName,Addr:CurrSite.Address,Lati:CurrSite.Lati,Longi:CurrSite.Longi}"
           @FromFYMap2="ShowMap=false"></FYMap>

    <div class="MP4">
      <MyDivider ShowStr="本场地可开展项目"></MyDivider>
    </div>
    <table  style="background-color:#fff;">
      <thead>
      <tr class="TF">
        <th>序号</th>
        <th>项目名称</th>
        <th>场地情况</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(Item,index) in CurrSite.Items" :key="index" class="H3">
        <td>{{index+1}}</td>
        <td>{{Item.ItemName}}</td>
        <td class="W50">
          <div class="MT2">
            <ShowPVT :PVT="{P:Item.Pic,V:Item.Video,T:Item.Remark,Update:false}"></ShowPVT>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <br>
    <FYQ Title="场地查询关联微信群"></FYQ>
  </div>
</template>

<script>
  import {Tofixed} from '@/components/SharedVues/Shared0.js'

  import FYMap from "@/components/SharedVues/FYMap";
  import ShowPVT from "../MyPVTs/ShowPVT";
  import ShowPFromThumb from "../MyPVTs/ShowPFromThumb";
  import ShowVFromThumb from "../MyPVTs/ShowVFromThumb";
  import SubEntrance from "../SharedVues/SubEntrance";
  import FYQ from "../IndependentVues/FYQ";
  import MyDivider from "../SharedVues/MyDivider";

  export default {
    name: "SiteLook",
    components: {MyDivider, FYQ, SubEntrance, ShowVFromThumb, ShowPFromThumb, ShowPVT, FYMap},
    props: ['Msg'],
    data() {
      return {
        CurrSite: this.Msg,
        ShowMap: false,
      }
    },
    mounted() {
      if (this.CurrSite.Items.length === 1) document.getElementById('TitleId').innerText = this.CurrSite.Items[0].ItemName + '项目场地推荐';
    },
    methods: {
      Tofixed(x, y) {
        return Tofixed(x, y)
      },
    }
  }
</script>

<style scoped>
</style>
