<template>
  <div class="XL YC">
    <div class="DispIB ML2">
      <span v-if="PVT.P.length===0">─</span>
      <ShowPFromThumb v-else :Msg="{Pic:PVT.P,Update:PVT.Update}"></ShowPFromThumb>
    </div>
    <div class="DispIB ML2">
      <span v-if="PVT.V.length===0">─</span>
      <ShowVFromThumb v-else :Msg="{Video:PVT.V,Update:PVT.Update}"></ShowVFromThumb>
    </div>
    <div class="DispIB ML2">
      <span v-if="PVT.T.length===0">─</span>
      <ShowTFromThumb v-else :Msg="{Text:PVT.T,Update:PVT.Update}"></ShowTFromThumb>
    </div>
  </div>
</template>

<script>

  import ShowPFromThumb from "./ShowPFromThumb";
  import ShowVFromThumb from "./ShowVFromThumb";
  import ShowTFromThumb from "./ShowTFromThumb";
  export default {
    name: "ShowPVT",
    components: {ShowTFromThumb, ShowVFromThumb, ShowPFromThumb},
    props: ['PVT']
  }
</script>

<style scoped>

</style>
