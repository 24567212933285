<template>
    <AppDL v-if="!ShowPlan"/>
</template>

<script>//
import {myMongoDBPost, MyRandomCode, myWXpost, UrlFrom, UrlParse} from '@/components/SharedVues/Shared0.js'
import AppDL from "./components/AppDL";
import MyTest from "./components/MyTest";

export default {
    name: "app",
    components: {MyTest, AppDL},
    data() {
        return {
            isReset: true,
            ShowPlan: false
        }
    },
    mounted() {
        let that = this;
        let LandTo = UrlParse().LandTo;//凡是来自微信的访问都会有LandTo，来自浏览器的访问如果没有特别输入则LandTo==undefined
        console.log(this.ShowPlan, LandTo);
        if (LandTo && LandTo.indexOf('Plan') >= 0) {
            this.ShowPlan = true;
            window.location.href = 'fy135Plan/index.html?LandTo=' + LandTo;//网页重定向:注意最前边没有反斜杠
        }//商业计划书
        if (LandTo && LandTo !== undefined) this.$store.commit('ChangeLandTo', LandTo);
        document.body.addEventListener('focusin', () => {
            that.isReset = false
        });// 软键盘弹出的事件处理
        document.body.addEventListener('focusout', () => {
            that.isReset = true;
            setTimeout(() => { // 当焦点在弹出层的输入框之间切换时先不归位
                if (that.isReset) {
                    window.scroll({top: 0, left: 0, behavior: 'smooth'}) // 失焦后强制让页面归位
                }
            }, 300)
        });// 软键盘收起的事件处理

        window.history.pushState(null, null, document.URL);
        window.addEventListener("popstate", function (e) {
            if (this.$store.state.LandTo.indexOf("MiniPro") >= 0) wx.miniProgram.navigateBack();
            if (e.state === null) history.pushState(null, null, document.URL);
            that.$store.commit('ChangeBackN', that.$store.state.CurrZindex);
        });

        let aUrl = window.location.href.split('#')[0];
        //微信接口注入权限验证配置
        if (UrlFrom() === '微信') myWXpost("GetConfigData", {
            nonceStr: MyRandomCode(20),
            timestamp: (new Date()).getTime(),
            url: aUrl
        }, function (aObj) {
            wx.config({
                debug: false, // true=开启调试模式,调用的所有api的返回值会在客户端alert出来
                appId: aObj.appId, // 必填，公众号的唯一标识
                timestamp: aObj.timestamp, // 必填，生成签名的时间戳
                nonceStr: aObj.nonceStr, // 必填，生成签名的随机串
                signature: aObj.signature,// 必填，签名，见附录1
                jsApiList: ["miniProgram", "closeWindow", "openLocation", "getLocation", "setStorageSync", "getStorageSync"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
            wx.ready(function () {
            });
            wx.error(function () {
            });
        });

    }
}
</script>
<style lang="less">
</style>
