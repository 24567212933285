<template>
    <div>
        <div class="XLR">
            <div class="OverLine" :style="'width:'+ X+'px'"></div>
            <div class="OverLine" :style="'width:'+Y+'px'"></div>
        </div>
        <div style="height: 1px;border:solid #003636;border-width: 1px 0 0 0"></div>

        <div class="XLR" style="line-height: 0.8rem;justify-content:center">
            <div class="AlignC" v-for="(NodeOBJ,index) in Defeats2" :key="index">
                <JFNode v-if="NodeOBJ.NodeName!==undefined" :Show="Show" :CurrNode="NodeOBJ"></JFNode>
                <div v-if="NodeOBJ.NodeBool === '有效分支节点'" class="AlignC">
                    <div v-if="NodeOBJ.OpenBool" class="AlignC">
                        <div @click="SetOpenBool(index,false)" class="DispIB" style="margin-left: -1px;">▲</div>
                    </div>
                    <div v-else class="AN5 AN51" @click="SetOpenBool(index,true)">展开</div>
                </div>

                <JFTree v-if="NodeOBJ.OpenBool && NodeOBJ.NodeBool === '有效分支节点'" :Show="Show"
                        :Defeats="NodeOBJ.Defeats2"></JFTree>
            </div>
        </div>
    </div>
</template>

<script>
    import {CloneObject} from '@/components/SharedVues/Shared0.js'
    import JFNode from "./JFNode";

    export default {
        name: "JFTree",
        components: {JFNode},
        props: ['Defeats', 'Show'],
        data() {
            return {
                Defeats2:{},
                X: 16,
                Y: 17
            }
        },
        mounted() {
            this.Defeats2 = CloneObject(this.Defeats);
            let A = this.Defeats2[0].LeavesN, B = this.Defeats2[this.Defeats2.length - 1].LeavesN;
            let Z = 30 + 2 + 2;//30= .NodeWH { width: 30px;   2=边框  2=间隙

            if (A > 1) if ((A / 2) === Math.trunc(A / 2)) this.X = Math.trunc(A / 2) * Z; else this.X = Math.trunc(A / 2) * Z + 16;
            if (B > 1) if ((B / 2) === Math.trunc(B / 2)) this.Y = Math.trunc(B / 2) * Z; else this.Y = Math.trunc(B / 2) * Z + 17;

        },
        methods: {
            SetOpenBool(index, Bool) {
                let OBJ = CloneObject(this.Defeats2[index]);
                OBJ.OpenBool = Bool;
                this.Defeats2.splice(index, 1, OBJ);
            }
        }
    }
    //
</script>

<style lang="less" scoped>
    .OverLine {
        position: relative;
        top: 2px;
        height: 4px;
        background-color: #efefef;
    }

    .LSide1 {
        margin-left: 8mm;
        border: solid #3a3b40;
        border-width: 0 0 0 1px
    }

    .LSide2 {
        margin-left: 8mm;
    }

    .LBox {
        width: 8mm;
        height: 10mm;
        border: solid #3a3b40;
    }

    .LBox2 {
        .LBox;
        border-width: 0 0 1px 0;
    }

    .LBox3 {
        .LBox;
        border-width: 0 0 1px 1px;
    }

    .HLine {
        height: 0;
        border: solid #3a3b40;
        border-width: 1px 0 0 0
    }

    .NoHLine {
        height: 1px;
        border: none
    }
</style>
