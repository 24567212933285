<template>
    <div class="XLR MT2">
      &nbsp;
      <div class="AlignC" v-for="(S,index) in Steps" :key="index">
        <div v-if="CurrStep===index" class="TG T2" title="橙色" style="margin-top: 1mm">
          {{S}}
          <div class="XLR">&nbsp;
            <div class="C1C">
              <div class="C0C">{{index+1}}</div>
            </div>&nbsp;
          </div>
        </div>
        <div v-else>
          <div v-if="StepEnable" @click="SetCurrStep(index)" title="紫色">
            <div class="AN ANBlue T3">{{S}}</div>
            <div class="XLR">&nbsp;
              <div class="C1A">
                <div class="C0A">{{index+1}}</div>
              </div>&nbsp;
            </div>
          </div>
          <div v-else class="TF" title="灰色">
            {{S}}
            <div class="XLR">&nbsp;
              <div class="C1B">
                <div class="C0B">{{index+1}}</div>
              </div>&nbsp;
            </div>
          </div>
        </div>
      </div>
      &nbsp;
    </div>
    <div class="BoxT" style="margin-top: -0.9rem"></div>
    <slot></slot>
</template>
<script>
  export default {
    name: "PopStep2",
    props: ['Steps', 'FromOpenUser'],
    data() {
      return {
        Timer: 0,
        CurrStep: 0
      }
    },
    computed: {
      CurrBackN() {
        return this.$store.state.BackN
      },
      StepEnable() {
        return this.$store.state.StepEnable
      },
    },
    watch: {
      CurrBackN(Val) {
        let X = this.CurrStep - 1;
        if (X === -1) X = this.Steps.length - 1;
        if (this.StepEnable) this.SetCurrStep(X);
      }
    },

    mounted() {
      if (this.FromOpenUser !== undefined) this.SetCurrStep(1);
    },
    beforeUnmount() {
      if (this.Timer) clearTimeout(this.Timer);
    },//如果定时器还在运行 或者直接关闭，不用判断
    methods: {
      SetCurrStep(I) {
        this.CurrStep = I;
        this.$emit('FromPopStep2', I);
      }
    }
  }
</script>

<style lang="less" scoped>
  .XYC { /*子元素居中*/
    display: flex;
    flex-direction: column;
    justify-content: center; /*子元素水平居中*/
    align-items: center; /*子元素垂直居中,前提是当前Box有height值*/
  }

  .Circle0 {
    margin: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100%;
    font-size: 1rem;
    color: white;
    border: 1px solid #ffffff;
  }

  .C0A {
    background-color: #450393;
    .XYC;
    .Circle0
  }

  .C0B {
    background-color: #949494;
    .XYC;
    .Circle0
  }

  .C0C {
    background-color: #82716b;
    .XYC;
    .Circle0
  }

  .Circle1 {
    margin: 0;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 100%;
  }

  .SA {
    font-size: 0.9rem;
    font-weight: normal;
    color: #450393;
    border: solid #450393;
    border-width: 0 0 1px 0;
    height: 1.2rem;
    position: relative;
    top: -1rem;
    display: inline-block
  }

  .SB {
    font-size: 0.9rem;
    font-weight: normal;
    color: #949494;
    border: solid #949494;
    border-width: 0 0 1px 0;
    height: 1.2rem;
    position: relative;
    top: -1rem;
    display: inline-block
  }

  .SC {
    font-size: 0.9rem;
    font-weight: normal;
    color: #3a3a3a;
    border: solid #3a3a3a;
    border-width: 0 0 1px 0;
    height: 1.2rem;
    position: relative;
    top: -1rem;
    display: inline-block
  }

  .C1A {
    border: 1px solid #450393;
    .XYC;
    .Circle1
  }

  .C1B {
    border: 1px solid #949494;
    .XYC;
    .Circle1
  }

  .C1C {
    border: 1px solid #82716b;
    .XYC;
    .Circle1
  }

</style>


