<template>
  <div :class="'AN WH77 XYC '+(T==='TF'? 'ANGray2':(T==='TB'?'ANBlue':'ANGreen'))" style="padding: 1mm">
    <div v-if="ItemCode===undefined">
      <div class="iconfont icon-tubiao" style="font-size:1.2rem;"></div>
    </div>
    <div v-else>
      <div v-if="ItemCode && ItemCode.split('.')[1]==='png'">
        <img :src="'UserUpload/static/MyIcons/'+ItemCode" style="width: 1.2rem;height: 1.2rem"/>
      </div>
      <div v-else :class="'iconfont '+ItemCode" style="font-size:1.2rem;"></div>
    </div>
  </div>
</template>

<script>

  import {ItemIcons} from './ItemIcons'

  export default {
    name: "ShowIcon",
    props: ['ItemStr','T'],
    data() {
      return {
        ItemIcons: ItemIcons,

      }
    },
    computed: {
      ItemCode() {
        return this.ItemIcons[this.ItemStr.split(':')[0]];
      }
    }
  }
</script>

<style scoped>
  .ISize {
    font-size: 1.2rem;
  }
</style>
