<template>

    <div class="XR">
      <slot></slot>
    </div>　
    <table  class="MP4" style="background-color:#fff;">
      <thead>
      <tr>
        <th>序号</th>
        <th>操作</th>
        <th><div class="AlignC LH120">UserName/Password<br>WXId/WXNickName</div></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(x,index) in FindUsers" :key="index">
        <td>{{index+1}}</td>
        <td>
          <div class="TE T8" @click="DeleteThisUser(index,x.UserName)">✗</div>
        </td>
        <td>
          <div class="T3 LH120">{{x.UserName}}/{{x.Password}}<br>{{x.WXId.substr(0,4)+'...'}}/{{x.WXNickName}}</div>
        </td>
      </tr>
      </tbody>
    </table>

</template>

<script>
  import {myMongoDBPost, LocaleCompare} from '@/components/SharedVues/Shared0.js'
  import PopTitle from "@/components/MyPopWin/PopTitle";

  export default {
    name: "HTUserRegMsg",
    components: {PopTitle},
    data() {
      return {
        FindUsers: [],　　
      }
    },
    computed: {},
    mounted() {
      let that = this;
      myMongoDBPost("GetDocs/fyUser0", {}, function (data) {
        that.FindUsers = data.sort(LocaleCompare('UserName'));
      });
    },
    methods: {　
      DeleteThisUser(index, U) {
        let that = this;
        myMongoDBPost('DeleteThisUser2', {fyUserName: U}, function (data) {
          that.FindUsers.splice(index, 1);
        });//删除一个用户时,应当把所有与该用户有关的文档全部删掉　
      }
    }
  }
</script>

<style scoped>
  .W20 {
    width: 20vw
  }

  .popup1 {
    width: 100%;
    height: 100%;
  }
</style>
