<template>
  <div class="BoxBR MLR2 PA2 MT2" style="background-color: #fff8d2">
    <div v-if="Loaded && CurrCard.CardType!=='现金付'">
      <div v-if="CurrCard.Bool==='待激活'">[{{CurrCard.CardType}}]尚未激活！</div>
      <div v-else class="XLR YC MLR2">
        <div class="XLR YC">
          <div v-if="CurrCard.CurrVal>0" class="XL YC">
            <div>待付：<br>&nbsp;{{CurrCard.CurrVal}}{{CurrCard.CardType==='计费卡' ? '元':(CurrCard.CardType==='泛约币付' ? '个泛约币':'次')}}</div>
            <div class="T3 MP4 Box42 LH120" @click="Pay">立即<br>支付</div>
          </div>
          <div v-else class="MP4 TF">当前<br>无欠账...</div>
          <div class="T3 PA2 Box41 LH120" @click="ShowLists=true">消费<br>记录</div>
        </div>
        <div class="TC XLR YC T2">
          <div>当前:[{{CurrCard.CardType}}]<br>
            <div>
              <div v-if="WithdrawBool" class="YC TG T5" @click="WithdrawBool=false">☑ <span class="T3">申请作废!</span>
              </div>
              <div v-else class="YC TF T5" @click="WithdrawBool=true">☐ <span class="T3">申请作废!</span></div>
            </div>
          </div>
          <div v-if="WithdrawBool" class="AN ANGreen T2 ML2" @click="DeleteMyCard()">作废</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="CurrCard.CardType==='现金付'" class="TF XLR YC">
        当前结账方式：现金付
        <div class="AN ANGreen T2" @click="DeleteMyCard()">取消</div>
      </div>
      <div v-else class="TF">
        当前结账方式：无
      </div>
    </div>
    <PopTitle v-if="ShowLists" :Title="'当前['+CurrCard.CardType+']消费记录'" @FromPopUp="ShowLists=false">
      <CardLists :CLs="CurrCard.Lists" :CType="CurrCard.CardType"></CardLists>
    </PopTitle>
  </div>
</template>

<script>
  import {myMongoDBPost, TransferZX} from '@/components/SharedVues/Shared0.js'
  import PopTitle from "../MyPopWin/PopTitle";

  export default {
    name: "ZY3CCard",
    components: {PopTitle},
    props: ['CurrYY', 'I2'],
    data() {
      return {
        CurrCard: null,
        TJObj: {},
        ShowLists: false,
        Loaded: false,
        WithdrawBool:false
      }
    },
    mounted() {
      this.ItemCode = this.CurrYY.ItemStr.split(':')[0];
      let that = this;
      this.TJObj = {ItemCode: this.ItemCode, BUserName: this.CurrYY.by[this.I2]._id, CUserName: this.$store.state.UserName};
      myMongoDBPost("GetDocs/Cards", this.TJObj, function (Docs) {
        if (Docs.length > 0) {
          that.CurrCard = Docs[0];
          that.Loaded = true;
        }
      });
    },
    methods: {
      Pay() {
        let that = this, ToUserName = that.CurrYY.by[that.I2]._id, Val = that.CurrCard.CurrVal;
        let aObj = {
          UPTJ: this.TJObj,
          UPObj: {
            CurrVal: 0,
            $inc: {CardVal: -1 * this.CurrCard.CurrVal},
            $push: {Lists: {Stamp: (new Date()).getTime(), Do: '消费', Val: this.CurrCard.CurrVal}}
          }
        };
        myMongoDBPost("UpdateDoc2/Cards", aObj, function (data2) {
          that.CurrCard.CardVal -= that.CurrCard.CurrVal;
          that.CurrCard.Lists.push({Stamp: (new Date()).getTime(), Do: '消费', Val: that.CurrCard.CurrVal});
          that.CurrCard.CurrVal = 0;
          if (that.CurrCard.CardType === '泛约币付') TransferZX(that,  that.$store.state.UserName, ToUserName, Val,function (asd) {
            if (asd === '泛约币转移成功!') alert( '向' + ToUserName + '转移了' + Val + '个泛约币！');
          })
        });
      },
      DeleteMyCard() {
        let that = this;
        this.TJObj = {ItemCode: this.ItemCode, BUserName: this.CurrYY.by[this.I2]._id, CUserName: this.$store.state.UserName};
        myMongoDBPost("RemoveDoc/Cards", this.TJObj, function (Docs) {
          that.Loaded = false;
          that.CurrCard.CardType='';
            alert( that.CurrCard.CardType + '已经取消!');
        });
      }
    }
  }
</script>

<style scoped>

</style>
