<template>
  <PopTop>
    <div class="XLR YC MP1">{{Title}}:
      <div class="ANGray BDCircle WHbb XYC LH120" @click="Return">确认</div>
    </div>
    
    <div class="AlignC">
        <textarea v-if="Type2==='textarea'" :ref="'InputA'" class="T7 W80" wrap="soft" title="textarea"
                  style="height:3cm" @blur.prevent="MyBlur()" v-model="Value2"></textarea>
    </div>
    <input v-if="Type2==='number'" :ref="'Input1'" type="number" v-model.number="Value1" title="number" @focus="Value1=null"
           @blur.prevent="MyBlur()" class="BoxGray1 MP4 T8 W80"/>
    <input v-if="Type2==='text' || Type2==='password'" :ref="'Input2'" :type="Type2" v-model="Value2" title="text" @focus="Value2=''"
           @blur.prevent="MyBlur()" class="BoxGray1 MP4 T8 W80"/>
    <div v-if="Type2==='ID'">
      <div>
        <input :ref="'Input3'" v-model='Value2' :is-type="CheckId" @blur.prevent="MyBlur()"
               class="BoxGray1 MP4 T8" required/>
      </div>
      <div v-if="ShowTip===2" class="TE MP4 T5">请输入您本人的有效身份证号!</div>
    </div>
    <div v-if="Type2==='china-name'">
      <div>
        <input :ref="'Input4'" v-model='Value2' is-type="china-name" @blur.prevent="MyBlur()"
               class="BoxGray1 MP4 T8" style="color: #000000" required/>
      </div>
    </div>
    <div v-if="Type2==='china-mobile'">
      <div>
        <input :ref="'Input6'" v-model="Value2" :max="13" is-type="china-mobile" @blur.prevent="MyBlur()"
               class="BoxGray1 MP4 T8" mask="999 9999 9999" style="color: #000000" required/>
      </div>
    </div>
    <div v-if="Type2==='P2'" class="ML4">
      <input :ref="'Input5'" title="登录密码:" type="password" @blur.prevent="MyBlur()" v-model="Value2"/><br>
      <input class="MT2" title="密码确认:" type="password" @blur.prevent="MyBlur()" v-model="Password2" :iconType="Value2"
             :is-type="CheckPassword"/>
      <div v-if="ShowTip===1" class="TE MP4 T5">请确保两次输入的密码一致且不空!</div>
    </div>
    <br>
  </PopTop>
</template>
<script>
  //注意数字型输入无内容时,应按如下判断:Val===""?0:Val;
  import {IdCheck} from '@/components/SharedVues/IdCheck.js'
  import {  Trim} from '@/components/SharedVues/Shared0.js'
  import PopTop from "../MyPopWin/PopTop";

  export default {
    name: "MyInput",
    props: ['Title', 'Type', 'Begin'],
    components: {PopTop},
    data() {
      return {
        Timer: 0,
        Value1: this.Begin,
        Value2: this.Begin,
        Type2: this.Type,
        Password2: this.Begin,
        ShowTip: 0,
        CheckPassword: function (value) {
          let Valid = (this.iconType === value);
          if (Valid && value !== '') return {
            valid: true
          }; else return {
            valid: false,
            msg: '两次输入的密码不一致!'
          }
        },//此处技巧:借用iconType属性传递Password1的值,至于Password2的值则就是value
        CheckId: function (value) {
          let Valid = true;

          let Value = IdCheck.getId18(value);//此处检查身份证号,如果是15位的,则统一转换为18位的
          if (Value === null) Valid = false; else Valid = IdCheck.check18IdCardNo(Value);

          if (Valid) {
            this.$store.commit('ChangeBool1', 'ValidId');
            return {
              valid: true
            };
          } else {
            this.$store.commit('ChangeBool1', 'InvalidId');
            return {
              valid: false,
              msg: '请输入有效身份证号:'
            }
          }
        }
      }
    },
    mounted() {
      let that = this;
      this.Timer = setTimeout(() => {
        if (that.Type2 === 'number') that.$refs.Input1.focus();
        if (that.Type2 === 'text' || that.Type2 === 'password') that.$refs.Input2.focus();
        if (that.Type2 === 'ID') that.$refs.Input3.focus();
        if (that.Type2 === 'china-name') that.$refs.Input4.focus();
        if (that.Type2 === 'china-mobile') that.$refs.Input6.focus();
        if (that.Type2 === 'P2') that.$refs.Input5.focus();
        if (that.Type2 === 'textarea') that.$refs.InputA.focus();
      }, 100);
    },
    unmounted() {
      if (this.Timer) clearTimeout(this.Timer);
    },
    methods: {
      Return() {
        let that = this;
        that.ShowTip = 0;
        if (that.Type2 === 'number')
          that.$emit("FromMyI", (that.Value1 === null || that.Value1 === '') ? 0 : that.Value1);
        else {
          if (that.Type2 === 'P2' && that.Value2 !== that.Password2 || that.Type2 === 'ID' && that.$store.state.Bool1 === 'InvalidId') {
              alert('输入无效! 请重新输入一次');
            that.$emit("FromMyI", '');
          } else {
            if (that.Value2 === '') {
                alert('无效输入! 烦请重新输入一次');
              that.$emit("FromMyI", '');
            } else that.$emit("FromMyI", Trim(that.Value2));
          }
        }
      },
      MyBlur() {
        window.scrollTo(0, 0)
      }
    }
  }
</script>

<style scoped>

</style>
