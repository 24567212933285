<template>
  <div class="Box5">
    <div v-if="Show7" class="AlignC"><br>
      <img src="../../../static/gif2/Loading.gif" class="W12"><br>
      正在载入地图...
    </div>
    <div ref="MapContainer2" style="width:100vw;height:1vh;background-color: #bdffe9"></div>
    <br>
    <MyToast v-if="Show8" :Tip="'不支持地图定位!'" @Tiped="Show8=false"></MyToast>
  </div>
</template>

<script>
  import {AutoAlert,  Tofixed, UrlFrom} from '@/components/SharedVues/Shared0.js'
  import MyToast from "./MyToast";

  export default {
    name: "FYMap",
    components: {MyToast},
    data() {
      return {
        Show7: false,
        Show8: false,
        MapLoadedBool: 0,
        Lati: 0,
        Longi: 0,
        Accuracy: 0,
        OpenRes: null
      }
    },
    props: ["Msg", "OP"],
    watch: {
      MapLoadedBool(Val) {
        if (Val > 0) {
          this.Show7 = false;
          if (this.accuracy > 30) AutoAlert("当前定位误差太大!", "请尝试使用本机自带浏览器或通过微信登陆泛约网！");
          this.$emit("FromFYMap1", {CanUse: !(this.accuracy > 30), Lati: this.Lati, Longi: this.Longi});
        }
      }
    },
    mounted() {
      let that = this;
      if (UrlFrom() === '微信') {
        wx.getLocation({
          type: 'gcj02', // 默认为wgs84的gps坐标(不准确)，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: function (res) {
            if (that.OP === 'Look') {
              that.Lati = parseFloat(that.Msg.Lati);
              that.Longi = parseFloat(that.Msg.Longi);
            } else {
              that.Lati = res.latitude;
              that.Longi = res.longitude;
            }
            wx.openLocation({
              latitude: that.Lati, // that.Lati纬度，浮点数，范围为90 ~ -90
              longitude: that.Longi, // that.Longi经度，浮点数，范围为180 ~ -180。
              name: that.Msg.Name, // 位置名
              address: that.Msg.Addr, // 地址详情说明
              scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
              infoUrl: 'https://zhidao.baidu.com/question/1900059921664686620.html', // 在查看位置界面底部显示的超链接,可点击跳转,这里只是个示例
              success: function (res) {
                that.OpenRes = res;
                //AutoAlert('',JSON.stringify(res));
                if (res.errMsg === "openLocation:ok") that.$emit("FromFYMap2", {CanUse: true, Lati: that.Lati, Longi: that.Longi}); else
                  AutoAlert('地图定位失败', '腾讯地图openLocation接口调用异常!');
              }
            });
          },
          fail: function (err) {
            //AutoAlert("接口调用失败err=" , JSON.stringify(err));
            console.log("接口调用失败err=" + err);
          }
        })
      } else {
        if (that.MapLoadedBool === 0) that.Show7 = true;
        let geolocation = new qq.maps.Geolocation("V66BZ-BUILD-32I4O-PPVML-TRFA6-FXF4H", "泛约在线地图");
        if (geolocation) {
          geolocation.getLocation(this.showPosition, this.showErr);
        } else that.Show8 = true;
      }
    },
    methods: {
      showPosition(position) {
        this.Lati = position.lat;
        this.Longi = position.lng;
        this.accuracy = position.accuracy;

        let myLatlng = new qq.maps.LatLng(this.Lati, this.Longi);
        let map = new qq.maps.Map(this.$refs.MapContainer2, {// document.getElementById("container"),{//{//载入并显示地图
          center: myLatlng,      // 地图的中心地理坐标。
          zoom: 14,
          mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
        });
        /*
        let marker1 = new qq.maps.Marker({
          position: center,
          map: map
        });
        let marker2 = new qq.maps.Label({
          position: myLatlng,
          map: map,
          content: this.Msg.Name//'文本标注'
        });
        */
        this.MapLoadedBool = map.zoom;
      },
      showErr() {
        //console.log("定位失败");
        this.getMyLocation();//定位失败再请求定位，测试使用
      },
      getMyLocation() {
        let geolocation = new qq.maps.Geolocation("V66BZ-BUILD-32I4O-PPVML-TRFA6-FXF4H", "泛约在线地图");//https://lbs.qq.com/console/myquota.html?key=V66BZ-BUILD-32I4O-PPVML-TRFA6-FXF4H
        geolocation.getLocation(this.showPosition, this.showErr);//注意不是geolocation.getIpLocation
      },
      Tofixed(x, y) {
        return Tofixed(x, y)
      }
    }
  }
</script>

<style scoped>
  .W12 {
    width: 12vw;
  }
</style>
