<template>
    <div class="XLR MP4 TB MT2 MLR4" @click="Show2 = 1">
        泛约共享卡
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <el-divider/>
    <div class="XLR MP4 TB MLR4" @click="Show2 = 2">
        列表选择式邀约
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <el-divider/>
    <div class="XLR MP4 TB MLR4" @click="Show2 = 3">
        设擂•守擂•打擂
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <el-divider/>
    <div class="XLR MP4 TB MLR4" @click="Show2 = 4">
        赛事•活动→管理器
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <el-divider/>
    <div class="XLR MP4 TB MLR4" @click="Show2 = 5">
        约课器=〉招生课程包 课表 课时统计
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <el-divider/>
    <PopMenu v-if="Show2 ===1" :Menus="Menus1" :BeginN="0" @FromPopMenu="PopMenuVal">
        <GXCard v-if="SelectedN===0" :ItemStr="ItemStr"></GXCard>
        <GXCard1 v-if="SelectedN===1" :ItemStr="ItemStr"></GXCard1>
        <GXCard2 v-if="SelectedN===2" :ItemStr="ItemStr"></GXCard2>
    </PopMenu>
    <PopMenu v-if="Show2 === 2" :Menus="Menus2" :BeginN="0" @FromPopMenu="PopMenuVal">
        <XMYY v-if="SelectedN===0" :ItemStr="ItemStr"></XMYY>
        <ZY v-if="SelectedN===1"></ZY>
        <BY v-if="SelectedN===2"></BY>
    </PopMenu>
    <PopTitle v-if="Show2 === 3 " :Title="ItemStr.split(':')[1]+'项目'" @FromPopUp="Show2=0">
        <DLQ :ItemStr="ItemStr"></DLQ>
    </PopTitle>
    <PopTitle v-if="Show2 === 4 " :Title="ItemStr.split(':')[1]+'项目'" @FromPopUp="Show2=0">
        <ActMain :ItemStr="ItemStr"></ActMain>
    </PopTitle>
    <PopTitle v-if="Show2 === 5 " :Title="ItemStr.split(':')[1]+'项目'" @FromPopUp="Show2=0">
        <YKQ :ItemStr="ItemStr"></YKQ>
    </PopTitle>

</template>

<script>
    import GXCard from "../M11_GXCards/GXCard";
    import YKQ from "../YKQ/YKQ";
    import DLQ from "../DLQ/DLQ";
    import PopTitle from "../MyPopWin/PopTitle";
    import ActMain from "../M3_Acts/ActMain";
    import PopMenu from "../MyPopWin/PopMenu";
    import ZY from "../ListYY/ZY";
    import BY from "../ListYY/BY";
    import XMYY from "../ListYY/XMYY";
    import GXCard1 from "../M11_GXCards/GXCard1";
    import GXCard2 from "../M11_GXCards/GXCard2";

    export default {
        name: "OpenItem",
        components: {GXCard2, GXCard1, XMYY, BY, ZY, PopMenu, ActMain, PopTitle, DLQ,  YKQ, GXCard},
        props: ['ItemStr'],
        data() {
            return {
                Show2: 0,
                Menus1: ["人次卡", "时限卡", "VIP卡"],
                Menus2: ["邀约发起", "主约信息", "被约信息"],
                SelectedN: 0
            }
        },
        methods: {
            PopMenuVal(Val) {
                if (Val === -1) this.Show2 = 0; else this.SelectedN = Val;
            },
        }
    }
</script>

<style scoped>

</style>