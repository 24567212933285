<template>
    <div v-if="$store.state.Certified==='成功'">
        <div class="XR MR4">
            <div v-if="Checked1" class="YC TG T5" @click="Checked1=false">☑ <span class="T4">同意协议书之全部条款!</span></div>
            <div v-else class="YC TG T5" @click="Checked1=true">☐ <span class="T4">同意协议书之全部条款!</span></div>
        </div>
        <div v-if="!Checked1" class="MP4">
            <div v-if="QX==='场地管理员'">
                <MyDivider ShowStr="泛约场地信息维护操作协议书"></MyDivider>
                <p>泛约场地是指乙方为方便广大用户线下活动，而通过甲方提供的本平台推荐的活动场所。双方本着互利互惠的原则，达成如下协议: </p>
                <p> 一、甲方的权利和义务</p>
                <p>1.甲方应对展现信息的可读性和美观性负责。</p>
                <p>2.甲方有权拒绝展示违法违规内容信息。</p>
                <p>二、乙方的权利和义务 </p>
                <p>1.乙方应对展现信息的真实性和可靠性负责。</p>
                <p>2.乙方同意按照下述泛约场地管理规则进行场地信息的展示。</p>
                <p>三、泛约场地信息管理规则</p>
                <p>1.系统平台中当前区域选择为城市时，显示详细的场地信息内容，场地信息的排列顺序按照星级高低进行，级别越高越靠前显示。</p>
                <p>2.系统平台中当前区域选择为城市以上的更大区域时，显示场地信息的统计信息。</p>
                <p>3.场地信息的星级由广大用户投票得出，甲乙双方均不得以任何形式进行干预。</p>
                <p>四、泛约场地信息发布规则</p>
                <p>1.在同一个场地名称下应该至少有一条项目场地信息</p>
                <p>
                    2.在同一个项目场地上可以进行多个项目时，对每一个项目要分别进行发布。</p>
            
            </div>
            <div v-else>
                <el-divider>{{QXTip}}发布协议书</el-divider>
                <div class="XR">
                    <div>
                        <p>甲方:天津泛约科技有限公司</p>
                        <p>乙方:{{$store.state.UserName}}</p>
                    </div>
                </div>
                <p>发布{{QXTip}}是甲方为广大用户提供的一种具体社交服务内容。双方本着互利互惠的原则，达成如下协议: </p>
                <p> 一、甲方的权利和义务</p>
                <p>1.甲方向乙方提供发布平台和环境服务。</p>
                <p>2.甲方有权撤销乙方发布的违规和不实信息。</p>
                <p>二、乙方的权利和义务 </p>
                <p>乙方应按照甲方要求，发布相关信息内容：</p>
                <p>1.确保发布信息的准确性和真实性。</p>
                <p>2.因信息内容不实而产生的一切争端和后果，由乙方负责。</p>
            </div>
            <p></p>
            <div class="XR">签约日期:{{CurrDate}}</div>
        </div>
        <div v-else class="MP4 XR">
            <div class="TB" @click="Submit()">提交申请</div>
        </div>
    </div>
    <div v-else class="MP4 AlignC TE">
        <p>只有通过实名认证的用户，才能获得授权！ 现在立即申请
            <el-button type="primary" @click="Show0=true" plain>实名认证</el-button>
        </p>
    </div>
    <PopBott v-if="Show0">
        <AskRealName @FromAskRealName="Show0=false"></AskRealName>
    </PopBott>
</template>

<script>
    import {myMongoDBPost, AutoAlert, CurrDateStr} from '@/components/SharedVues/Shared0.js'
    import PopBott from "../MyPopWin/PopBott";
    import AskRealName from "../M0_RegLogin/AskRealName";
    //v-if="QX==='寻人启事' || QX==='链接信息'"
    export default {
        name: "QXAsk",
        components: {AskRealName, PopBott},
        props: ['QX'],
        data() {
            return {
                Checked1: false,
                CurrDate: CurrDateStr(),
                QXTip: '',
                Show0: false
            }
        },
        mounted() {
            if (this.QX === '家谱管理员') this.QXTip = '家谱信息';
            if (this.QX === '社交活动') this.QXTip = '社交活动项目信息';
            if (this.QX === '社交工具') this.QXTip = '社交工具应用项目';
        },
        methods: {
            Submit() {
                let that = this;
                myMongoDBPost("PutDoc/USuggests", {
                    UserName: this.$store.state.UserName,
                    SClass: "权限申请",
                    Key1: this.QX,
                    Key2: "申请",
                    SDescribe: '',
                    STime: (new Date()).getTime()
                }, function (data) {
                    AutoAlert("申请已提交", "请留意您的权限变化！");
                    that.$emit('FromQXAsk');
                });
            }
        }
    }
</script>

<style scoped>
</style>
