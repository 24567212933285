<template>
    <div class="MP2">
        <p>游客用户您好!</p>
        <div class="MT2"></div>
        <p>欢迎使用本打擂器小程序。烦请注册登录后再回来使用，您将获得更好的体验！</p>
        <div v-if="$store.state.UserName==='游客'" class="MT2 XLR YC">&nbsp;
            <SubEntrance ShowMode="同时显示"></SubEntrance>&nbsp;
        </div>
    </div>
</template>

<script>
    import SubEntrance from "../SharedVues/SubEntrance";
    export default {
        name: "DLQSetup0",
        components: {SubEntrance},
    }
</script>