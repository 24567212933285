<template>
    <div class="XL YC BottGray MLR2">
        泛约昵称:
        <input type="text" class="MP4 W50" v-model="dlData.fyUserName"/>
    </div>
    <div class="XLR YC BottGray MLR2">
        <div class="XL YC">密码:
            <input type="password" maxlength="18" placeholder="6-18位数字&字母组合" class="MP4 W50" v-model="dlData.fyPassword"/>
        </div>
        <div class="TG" @click="Show1=true">找回<br>密码</div>
    </div>
    <div class="XLR YC MTB2">
        &nbsp;
        <div class="Item YC MP4"><input id="Input12" type="checkbox" class="aui-switch" v-model="dlData.AutoDl"/>&nbsp;
            <label for="Input12">下次自动登录!</label></div>
        　
    </div>
    <div class="BGBlue AlignC PA3" @click="Login2()">
        <div class="T7 TW MP4">登&nbsp;&nbsp; &nbsp;&nbsp;录</div>
    </div>
    <MyInput v-if="InputI===1" Title="泛约昵称" Type="text" :Begin="dlData.fyUserName" @FromMyI="MyInputVal"></MyInput>
    <MyInput v-if="InputI===2" Title="密码" Type="password" :Begin="dlData.fyPassword"
             @FromMyI="MyInputVal"></MyInput>
    <PopUpDown v-if="Show1" Title="请正确回答" Pos="btt" :ANs="1" @FromPop5="Show1=false">
        <div class="MT2">
            <div class="MP4 XL YC BottGray">
                用户名：<input type="text" class="W50" v-model="UserDoc.UserName">
            </div>
            <div class="MP4 XLR YC">
                性别
                <div class="XL YC">
                    <input id="Sex1" type="radio" class="AlignT" name="Sex" v-model="UserDoc.Sex" value="男">
                    <label for="Sex1">男</label>
                </div>
                <div class="XL YC">
                    <input id="Sex2" type="radio" class="AlignT" name="Sex" v-model="UserDoc.Sex" value="女">
                    <label for="Sex2">女</label></div>
                <div></div>

                <div></div>
            </div>
            <div>
                <div class="MP4 XLR YC">
                    出生日期


                    <el-date-picker v-model="BirthStr" format="YYYY-MM-DD" type="date" placeholder="Pick a day"
                                    @change="SetBirth"/>
                    <span class="T4 TB">{{UserDoc.BirthYear}}&nbsp;年&nbsp;{{UserDoc.BirthMonth}}&nbsp;月&nbsp;{{UserDoc.BirthDay}}&nbsp;日</span>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <br><br>
            <div class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="NewFound">提交</div>

        </div>

    </PopUpDown>
</template>

<script>
    import {AutoAlert, MyRandomCode, myMongoDBPost} from '@/components/SharedVues/Shared0.js'

    import {DL} from '@/components/M0_RegLogin/DL'
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MyInput from "@/components/SharedVues/MyInput";
    import PopUpDown from "../MyPopWin/PopUpDown";

    export default {
        name: "LoginFY",
        components: {
            PopUpDown,
            MyInput,
            PopTitle,
        },
        data() {
            return {
                BirthStr: '1960-11-01',
                InputI: -1,
                dlData: {
                    fyUserName: "",
                    fyPassword: "",
                    AutoDl: true,
                    ItemStr: '',
                },
                UserDoc: {
                    UserName: "",
                    BirthYear: 0,//出生年份
                    BirthMonth: 1,
                    BirthDay: 1,
                    Sex: "？"
                },
                Show1: false,
                Show2: false,
                Show3: false,
                NewId: '',
                TXKL: '',
                //ShowNewId1: '...',
                ShowNewId2: '',
                MyCheckCode0: '',
                MyCheckCode: '',

            }
        },

        mounted() {
            this.MyCheckCode0 = MyRandomCode(4);
            console.log("进入LoginFY");
        },
        unmounted() {
            console.log("退出LoginFY")
        },
        methods: {
            SetBirth(Val) {
                this.UserDoc.BirthYear = parseInt(this.BirthStr.split("-")[0]);
                this.UserDoc.BirthMonth = parseInt(this.BirthStr.split("-")[1]);
                this.UserDoc.BirthDay = parseInt(this.BirthStr.split("-")[2]);
            },
            NewFound() { //找回密码
                myMongoDBPost("GetDocs/fyuser0", this.UserDoc, function (Docs) {
                    if (Docs.length > 0)
                        AutoAlert("您的密码是：", Docs[0].Password);
                    else
                        AutoAlert('用户名、性别、出生年月全部回答正确', '才有可能找回密码!');
                })
            },
            Login2() {
                let that = this;
                DL(this, '按钮登录', function () {
                    if (that.$store.state.UserName === '游客')
                        AutoAlert('登录失败', '请检查用户名或密码是否正确!');
                    else that.$emit("FromLoginFY");
                });
            },
            MyInputVal(Val) {
                if (this.InputI === 1) this.dlData.fyUserName = Val;
                if (this.InputI === 2) this.dlData.fyPassword = Val;
                this.InputI = -1;
            }
        }
    }

</script>

<style lang="less" scoped>
    /*
    @switch-height: 25px; 样式变量
    @button-primary-bg-color: #ff8422;
    */
    .W12 {
        width: 12vw
    }

    .W55 {
        width: 55vw
    }

    .Css00 {
        font-size: 1.8rem;
        color: #0f44ff;
        font-style: normal;
    }

    .Css10 {
        font-size: 2.6rem;
        color: #ff1c69;
        font-style: italic;
    }

    .Css20 {
        font-size: 2.2rem;
        color: #000000;
        font-style: normal;
    }

    .Css30 {
        font-size: 2.8rem;
        color: #027455;
        font-style: italic;
    }

</style>
