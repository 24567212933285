<template>
    <div class="MT2">
        <el-tabs v-model="Sele" type="card" class="demo-tabs" @tab-change="SetShow">
            <el-tab-pane :label="inputP" name="0"></el-tab-pane>
            <el-tab-pane :label="inputC" name="1"></el-tab-pane>
            <el-tab-pane :label="inputDC" name="2"></el-tab-pane>
        </el-tabs>
    </div>

    <PopUpDown v-if="Show" :Title="'请选择'" Pos="btt" :ANs="1" @FromPop5="xSelected(Sele,'不限')">
        <div class="Popup0">
            <div v-if="Sele==='0'">
                <div class="Css2" v-for="(x,index) in  Ps" :key="index" @click="xSelected(Sele,x)">
                    {{x}}
                </div>
            </div>
            <div v-if="Sele==='1'">
                <div class="Css2" v-for="(x,index) in  Cs" :key="index" @click="xSelected(Sele,x)">
                    {{x}}
                </div>
            </div>
            <div v-if="Sele==='2'">
                <div class="Css2" v-for="(x,index) in  DCs" :key="index" @click="xSelected(Sele,x)">
                    {{x}}
                </div>
            </div>
            <div v-if="Sele==='1' && Cs.length===0" class="TE">请先选择省份!</div>
            <div v-if="Sele==='2' && DCs.length===0" class="TE">请先选择城市!</div>
        </div>
    </PopUpDown>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import PopUpDown from "../MyPopWin/PopUpDown";
    import PopBott from "../MyPopWin/PopBott";

    export default {
        name: "FyAddr",
        components: {PopBott, PopUpDown},
        data() {
            return {
                Sele: '-1',
                Show: false,
                inputP: '省份',
                inputc: '城市',
                inputDC: '区县',
                Ps: [],
                Cs: [],
                DCs: [],
            }
        },
        mounted() {
            let that = this;
            this.inputP=this.$store.state.UserP;
            this.inputC=this.$store.state.UserC;
            this.inputDC=this.$store.state.UserDC;
            myMongoDBPost("getAddrPs", {N: '中国'}, function (data) {
                that.Ps = data;
            });
        },
        methods: {
            SetShow() {
                let that = this;
                console.log(this.Sele, that.Ps, that.Cs, that.DCs);
                this.Show = false;
                if (this.inputP === "省份") {
                    this.Sele = '0';
                    that.Show = true;
                } else {
                    if (this.inputC === "城市") myMongoDBPost("getAddrCs", {P: this.inputP}, function (data) {
                        that.Cs = data;
                        that.Show = true;
                    }); else myMongoDBPost('getAddrDCs', {C: this.inputC}, function (data) {
                        that.DCs = data;
                        that.Show = true;
                    })
                }
            },
            xSelected: function (Sele, x) {
                this.Show = false;
                if (Sele === '0') this.inputP = x === '不限' ? "省份" : x;
                if (Sele === '1') this.inputC = x === '不限' ? "城市" : x;
                if (Sele === '2') this.inputDC = x === '不限' ? "区县" : x;
                console.log(Sele, x);
                this.$emit('FromFyAddr', {P: this.inputP, C: this.inputC, DC: this.inputDC});
            }
        }
    }
</script>

<style scoped>
    .demo-tabs > .el-tabs__content {
        padding: 32px;
        color: #6b778c;
        font-size: 32px;
        font-weight: 600;
    }

    .Css1 {
        margin: 3px;
        padding: 5px;
        border: 1px solid #68726d;
        color: #04597d;
        display: inline-block
    }

    .Css2 {
        display: inline-block;
        width: 28vw;
        text-align: left;
        color: #04597d;
        padding-top: 10px;
    }

    .Popup0 {
        min-height: 40vh;
        margin-left: 4vw;

    }

    .TypeSele-item {
        border: 1px solid #ececec;
        padding: 5px 15px;
        color: #685d04;
    }

    .TypeSele-item-selected {
        border: 1px solid #685d04;
        color: black;
    }
</style>
