<template>
    <div>
        <div class="MP2  T32">
            <h5 class="BottGray">&nbsp;&nbsp;更新常住地址&nbsp;&nbsp;</h5>
            <FyAddr @FromFyAddr="ZCBGChangeAddress"></FyAddr>
            <p>如果你想在自己常驻地以外的城市使用打擂器，可通过更新常住地址调出相应城市的打擂器面板数据，目前开通本打擂器小程序的城市只有天津市。</p>
        </div>
        <div class="MP2">
            <p>发布摆擂活动信息，需要具备泛约裁判员资格。现在立即
                <el-button type="primary" @click="QXAsk=true">申请</el-button>
            </p>
        </div>
        <PopTitle v-if="QXAsk" Title="泛约裁判员资格申请" @FromPopUp="QXAsk=false">
            <QXAsk QX="泛约裁判员" @FromQXAsk="Show=-1"></QXAsk>
        </PopTitle>
    </div>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import FyAddr from "../SharedVues/FyAddr";
    import PopTitle from "../MyPopWin/PopTitle";
    import QXAsk from "../SetupManage/QXAsk";

    export default {
        name: "DLQSetup3",
        components: {QXAsk, PopTitle, FyAddr},
        data (){
            return {
                QXAsk:false
            }
        },
        methods: {
            ZCBGChangeAddress(Val) {
                myMongoDBPost('ZCBGChangeAddress', {
                    UserName: this.$store.state.UserName,
                    FixedAddr: {N: '中国', P: Val.P, C: Val.C, DC: Val.DC}
                }, function (data) {
                })
            }
        }
    }
</script>

<style scoped>

</style>