<template>
  <div class="MT2"><br><br>
    <table>
      <thead>
      <tr class="TC LH200">
        <th>日期</th>
        <th>星期</th>
        <th>上午</th>
        <th>下午</th>
        <th>晚上</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(PD,index1) in AllPastDays" :key="index1">
        <td>{{PD.ThisDate}}</td>
        <td>{{PD.Week}}</td>
        <td v-for="(Noon,index2) in PD.Noons" :key="index2" class="LH120">
          <div v-if="Noon==='―'" class="PA3">―</div>
          <div v-else @click="OpenCell(index1,index2)" class="TB PA1">
            <div class="BottGray ">{{Noon.split("|")[0]}}</div>
            <div>{{Noon.split("|")[1]}}</div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>    　
    <br>
    <PopTitle v-if="I1>-1 && NoonIndex>-1" :Title="AllPastDays[I1].ThisDate+NoonIndexes[NoonIndex]+'影像记录'" @FromPopUp="RefreshAllPastDays()">
      <table class="MT2">
        <thead>
        <tr class="TC LH200">
          <th>场地</th>
          <th>照片</th>
          <th>视频</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(CCD,index1) in CurrCellDocs" :key="index1">
          <td>{{CCD.SiteShortName}}</td>
          <td>
            <div class="XLR YC LH120">&nbsp;
              <div v-for="(CP,index2) in CCD.Pics" :key="index2">
                <div v-if="CP==='Add'" @click="SetActive(1,index1)" class="MR4 TG "><img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77"></div>
                <div v-else>
                  <img :src="'/UserUpload/1/S'+CP" class="WH64" @click="PI1=index1,PI2=index2,ShowPic=true">
                  <div v-if="ActDoc.UserName===$store.state.UserName" class="TE T5" @click="DeleteVP(1,index1,index2)">ㄨ</div>
                </div>
              </div>&nbsp;
            </div>
          </td>
          <td>
            <div class="XLR YC LH120">&nbsp;
              <div v-for="(CV,index2) in CCD.Videos" :key="index2">
                <div v-if="CV==='Add' && QX" @click="SetActive(2,index1)" class="MR4 TG"><img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77"></div>
                <div v-if="CV!=='Add'">
                  <img :src="'/UserUpload/2/S'+CV.split('.')[0] + '.jpg'" class="WH64" @click="VI1=0,VI2=index2,ShowVideo=true">
                  <div v-if="ActDoc.UserName===$store.state.UserName" class="TE T5" @click="DeleteVP(2,index1,index2)">ㄨ</div>
                </div>
              </div>&nbsp;
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <p class="TF T3">提示：若要上传视频，需要点击"设置管理→权限申请→视频信息..."，以取得视频发布资格。</p>
    </PopTitle>
    <PopUpDown v-if="ShowPic" Title="照片" Pos="btt" :ANs="1" @FromPop5="ShowPic=false">
      <img class="WHa" :src="'/UserUpload/1/'+CurrCellDocs[PI1].Pics[PI2]">
    </PopUpDown>

    <PopUpDown v-if="ShowVideo" Title="视频" Pos="btt" :ANs="1" @FromPop5="ShowVideo=false">
      <video :src="'/UserUpload/2/'+CurrCellDocs[VI1].Videos[VI2]" webkit-playsinline="" playsinline="" x5-playsinline=""
             width="100%" height="auto" controls></video>
    </PopUpDown>

    <UpFiles1 v-if="PI1>-1 && Active" VP="1" @FromU1="SetPics($event,PI1)"></UpFiles1>
    <UpFiles1 v-if="VI1>-1 && Active" VP="2" @FromU1="SetVideos($event,VI1)"></UpFiles1>
  </div>
</template>

<script>
  import {myMongoDBPost, myDataFileGet, AutoAlert, CloneObject, StampToDate} from '@/components/SharedVues/Shared0.js'


  import PopTitle from "../MyPopWin/PopTitle";
  import MySele2 from "../SharedVues/MySele2";
  import UpFiles1 from "../MyPVTs/UpFiles1";
  import PopUpDown from "../MyPopWin/PopUpDown";

  export default {
    name: "Act0PVs",
    components: {
      PopUpDown,
      UpFiles1,
      MySele2,
      PopTitle,


    },
    props: ['ActDoc'],
    data() {
      return {
        AllPastDays: [],
        PI1: -1,
        PI2: -1,
        VI1: -1,
        VI2: -1,
        Active: false,
        ShowPic: false,
        ShowVideo: false,

        MatchSites: [],
        Weeks: ['日', '一', '二', '三', '四', '五', '六'],
        I1: -1,
        NoonIndex: -1,
        NoonIndexes: ['上午', '下午', '晚上'],
        CurrTimeStrs: [],
        CurrCellDocs: [],
        QX: this.$store.state.UserBools.indexOf('视频信息') >= 0 || this.ActDoc.UserName === this.$store.state.UserName
      }
    },
    mounted() {
      let Today = (new Date()).getTime();
      for (let i = 1; i < 11; i++) {//程序调试通过以后要把5 改成1
        let asd = StampToDate(Today + i * 24 * 3600 * 1000) + ":" + this.Weeks[(new Date(Today + i * 24 * 3600 * 1000)).getDay()];
        this.CurrTimeStrs.push(asd + ":2", asd + ":3", asd + ":4");
      }
      this.RefreshAllPastDays();
    },
    methods: {
      RefreshAllPastDays() {
        let that = this;
        myMongoDBPost("Act0PVsRefreshAllPastDays", {ActId: that.ActDoc._id, UserName: that.$store.state.UserName, CurrTimeStrs: this.CurrTimeStrs}, function (Docs) {
          that.AllPastDays = Docs;
          that.I1 = that.NoonIndex = -1;
        });
      },
      OpenCell(I1, NoonIndex) {
        let that = this, TimeStr = this.AllPastDays[I1].ThisDate + ":" + this.AllPastDays[I1].Week + ":" + NoonIndex;
        myMongoDBPost("GetDocs/act0", {ActId: this.ActDoc._id, TimeStr: TimeStr}, function (Docs) {
          that.CurrCellDocs = Docs;
          for (let i = 0; i < Docs.length; i++) {
            that.CurrCellDocs[i].Pics.push('Add');
            that.CurrCellDocs[i].Videos.push('Add');
          }

          that.I1 = I1;
          that.NoonIndex = NoonIndex;
        })
      },
      SetActive(Bool, index1) {
        if (this.$store.state.UserName !== '游客') {
          if (Bool === 1) this.PI1 = index1;
          if (Bool === 2) this.VI1 = index1;
          this.Active = true;
        } else AutoAlert('无效操作', '只有注册用户才能上传!');
      },
      SetPics(Val, index1) {
        if (Val !== '未选择') {
          this.CurrCellDocs[index1].Pics.splice(0, 0, Val);
          let asd = CloneObject(this.CurrCellDocs[index1].Pics);
          asd.splice(this.CurrCellDocs[index1].Pics.length - 1, 1);//该行删除掉之前的Pics.push('Add');
          myMongoDBPost("UpdateDoc/act0", {UPTJ: {_id: this.CurrCellDocs[index1]._id}, UPObj: {Pics: asd}}, function (data2) {
          });
        }
        this.PI1 = -1;
        this.Active = false;
      },
      SetVideos(Val, index1) {
        if (Val !== '未选择') {
          this.CurrCellDocs[index1].Videos.splice(0, 0, Val);
          let asd = CloneObject(this.CurrCellDocs[index1].Videos);
          asd.splice(this.CurrCellDocs[index1].Videos.length - 1, 1);//该行删除掉之前的Videos.push('Add');
          myMongoDBPost("UpdateDoc/act0", {UPTJ: {_id: this.CurrCellDocs[index1]._id}, UPObj: {Videos: asd}}, function (data2) {
          });
        }
        this.VI1 = -1;
        this.Active = false;
      },
      DeleteVP(Bool, index1, index2) {
        let that = this;
        if (Bool === 1)  myDataFileGet("DeleteVPFile/UserUpload/?VP=1&VPFileName=" + that.CurrCellDocs[index1].Pics[index2], function (data) {
          that.CurrCellDocs[index1].Pics.splice(index2, 1);
          myMongoDBPost("UpdateDoc/act0", {UPTJ: {_id: that.CurrCellDocs[index1]._id}, UPObj: {Pics: that.CurrCellDocs[index1].Pics}}, function (data2) {
          });
        });
        if (Bool === 2) myDataFileGet("DeleteVPFile/UserUpload/?VP=2&VPFileName=" + that.CurrCellDocs[index1].Videos[index2], function (data) {
          that.CurrCellDocs[index1].Videos.splice(index2, 1);
          myMongoDBPost("UpdateDoc/act0", {UPTJ: {_id: that.CurrCellDocs[index1]._id}, UPObj: {Videos: that.CurrCellDocs[index1].Videos}}, function (data2) {
          });
        });
      }
    }
  }
</script>

<style scoped>

</style>
