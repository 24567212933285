<template>
  <div>
    <PopTitle :Title="Msg.SiteName" @FromPopUp="SaveCurrSiteDoc">

      <div class="XLR YC MT2 MR4">
        <div class="BottGray">&nbsp;&nbsp;&nbsp;场地基本信息：&nbsp;&nbsp;&nbsp;</div>
        <div class="AN5 AN52" @click="SaveCurrSiteDoc">保存</div>
      </div>
      <div class="LH150 MLR2">
        <div class="XL YC">
          简称：
          <input type="text" class="MP4 W26" v-model="CurrSite.SiteShortName"/><span class="TF">（最长５个汉字）</span>
        </div>
        <div MT2>属地：<span class="TF">{{CurrSite.Country}}{{CurrSite.Province}}{{CurrSite.City}}{{CurrSite.DC}}</span>
        </div>
        <div class="XL YC">
          详址：
          <input type="text" class="MP4 W60" v-model="CurrSite.Address"/>
        </div>
        <div class="XLR YC MT2">
          <div class="XLR YC" @click="ShowMap=1">地图定位：<img src="../../../static/img/SY/Map1.png"
                                                           class="WHcc BD_Radius1 BDGreen">
          </div>
          <div class="AN ANBlue" @click="ShowMap=2">重新<br>定位</div>&nbsp;
        </div>

        <table class="MT2">
          <tr>
            <th rowspan="2">场地管理员</th>
            <th colspan="2">约课器管理</th>
          </tr>
          <tr>
            <th>开通</th>
            <th>删除</th>
          </tr>
          <tr v-for="(CS,index) in CurrSite.SiteAdmins" :key="index">
            <td><input type="text" v-model="CurrSite.SiteAdmins[index]" @blur="UpdateSiteAdmin(CS,index)" class="W26"/></td>

            <td class="W26 AlignC">
              <div class="AN ANGreen" @click="AddSiteUsers(CS)">开通</div>
            </td>
            <td class="W26">
              <div class="TE MP4" @click="DelSiteUsers(CS)">删除</div>
            </td>
          </tr>
        </table>

        <div class="MT2">
          实时滚动信息：
          <input class="W90 MT2" type="text" v-model="CurrSite.ScrollTexts[0]"
                 @focus="CurrSite.ScrollTexts[0]=null"/><br>
          <input class="W90 MT2" type="text" v-model="CurrSite.ScrollTexts[1]"
                 @focus="CurrSite.ScrollTexts[1]=null"/><br>
          <input class="W90 MT2" type="text" v-model="CurrSite.ScrollTexts[2]" @focus="CurrSite.ScrollTexts[2]=null"/>
        </div>
        <!--div-- class="XL MT2 YC">          电话：          <input type="text" class="MP4 W40" v-model="CurrSite.Telephone"/>        </div-->
        <div class="XL MTB2 YC">
          会员积分算法：
          <div class="TG BDGreen">
            <MySelectStr CallFrom="SiteEdit" :Msg="['泛约(默认)','ChinaTT']" :CurrN="CurrN"
                         @eventFromChild="SetJFMethod"></MySelectStr>
          </div>
        </div>
        <table :cell-bordered="true" :content-bordered="true" style="background-color:#fff;">
          <thead>
          <tr class="TF H3">
            <th>
              <div class="T3 NoWrap">门脸照片&nbsp;步行路线&nbsp;场地概况</div>
            </th>
            <th>电话</th>
          </tr>
          </thead>
          <tbody>
          <tr class="H3">
            <td>
              <div class="MLR4 MT2">
                <ShowUpdatePVT
                  :PVTF="{P:CurrSite.Pic,V:CurrSite.Video,T:CurrSite.Remark, TextTitle:'场地情况简要介绍'}"
                  @ShowUT="CallBack0"></ShowUpdatePVT>
              </div>
            </td>
            <td>
              <input type="text" class="W32" v-model="CurrSite.Telephone"/>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

      <MyInput v-if="InputI===1" Title="场地地址" Type="text" :Begin="CurrSite.Address" @FromMyI="MyInputVal"></MyInput>
      <MyInput v-if="InputI===2" Title="电话" Type="text" :Begin="CurrSite.Telephone" @FromMyI="MyInputVal"></MyInput>


      <FYMap v-if="ShowMap===1" OP="Look"
             :Msg="{Name:CurrSite.SiteName,Addr:CurrSite.Address,Lati:CurrSite.Lati,Longi:CurrSite.Longi}"
             @FromFYMap2="ShowMap=-1"></FYMap>
      <FYMap v-if="ShowMap===2" OP="Edit" :Msg="{Name:CurrSite.SiteName,Addr:CurrSite.Address}"
             @FromFYMap2="SaveCurrPosi"></FYMap>


      <MyDivider ShowStr="本场地可开展项目"></MyDivider>
      <table v-if="CurrSite.Items.length>0" :cell-bordered="true" :content-bordered="true" style="background-color:#fff;">
        <thead>
        <tr class="TF">
          <th rowspan="2">序号</th>
          <th rowspan="2">项目名称</th>
          <th rowspan="2">场地情况</th>
          <th rowspan="2">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(Item,index) in CurrSite.Items" :key="index" style="height: 3.5rem">
          <td class="MT2">{{index+1}}</td>
          <td class="MT2">{{Item.ItemName}}</td>
          <td @click="SeleI =index">
            <div class="MLR4 MT2">
              <ShowUpdatePVT :PVTF="{P:Item.Pic,V:Item.Video,T:Item.Remark, TextTitle:'场地情况简要介绍'}"
                             @ShowUT="CallBack"></ShowUpdatePVT>
            </div>
          </td>
          <td class="MT2">
            <div class="TE" @click="DeleItem(index)">删除</div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="XR PA2">
        <div class="AN ANBlue" @click="Show1=true">添加</div>
      </div>
      <SeleOneItem v-if="Show1" Title="项目名称选择" @FromSeleOneItem="AppendItem"></SeleOneItem>
      <p v-if="CurrSite.Pic.length==='' || CurrSite.Remark===''">备注：场地概况照片与文字必须要有！</p>
      <WXLink v-else :WLandTo="'TJSite_' + Msg._id" :Pic="'UserUpload/1/S'+CurrSite.Pic"
              :WTitle="'乒乓球场地推荐：'+Msg.SiteName" :WText="CurrSite.Remark"></WXLink>

    </PopTitle>
  </div>
</template>

<script>

  import {AutoAlert, CloneObject, GetItemType, myMongoDBPost, Tofixed} from '@/components/SharedVues/Shared0.js'

  import PopTitle from "@/components/MyPopWin/PopTitle";
  import SeleOneItem from "../FrontPage/SeleOneItem";
  import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
  import MyToast from "@/components/SharedVues/MyToast";
  import FYMap from "@/components/SharedVues/FYMap";
  import MyInput from "@/components/SharedVues/MyInput";
  import PopUpDown from "../MyPopWin/PopUpDown";
  import WXLink from "../IndependentVues/WXLink";
  import MySelectStr from "../SharedVues/MySelectStr";
  import MyDivider from "../SharedVues/MyDivider";

  export default {
    name: "SiteEdit",

    components: {
      MyDivider,
      MySelectStr,
      WXLink,
      PopUpDown,
      MyInput,
      FYMap,
      MyToast,
      ShowUpdatePVT,
      SeleOneItem,

      PopTitle
    },
    props: ['Msg'],
    data() {
      return {
        CurrSite: this.Msg,
        CurrN: this.Msg.JFMethod === 'ChinaTT' ? 1 : 0,
        aItemObj: {
          ItemType: '',
          ItemCode: '',
          ItemName: '项目名称',
          Pic: '',
          Video: '',
          Remark: ''
        },
        SeleI: -1,
        Show1: false,

        ShowMap: -1,
        InputI: -1,
        InputI1: -1,
        InputI2: -1,
      }
    },
    methods: {
      UpdateSiteAdmin(CAdminUser, index) {
        this.CurrSite.SiteAdmins.splice(index, 1, CAdminUser)
      },
      AddSiteUsers(CAdminUser) {
        let that = this;
        myMongoDBPost("GetDocs/SiteUsers", {
          CAdminUser: CAdminUser,
          DocBool: '课程管理员'
        }, function (Docs) {
          let aObj = {
            SiteId: that.CurrSite._id,
            Province: that.CurrSite.Province,
            City: that.CurrSite.City,
            DC: that.CurrSite.DC,
            SiteName: that.CurrSite.SiteName,//对应场地的名称
            SiteShortName: that.CurrSite.SiteShortName,
            CAdminUser: CAdminUser,
            MyCurrBool: true,
            DocBool: '课程管理员'
          };
          if (Docs.length === 0) myMongoDBPost("PutDoc/SiteUsers", aObj, function (Docs) {
            AutoAlert('提示', '已开通“约课器”')
          }); else AutoAlert('每个课程管理员只允许开通1套课表', '若想管理多套课表,请再注册1个用户名!')
        });
      },
      DelSiteUsers(CAdminUser) {
        let that = this;//遗留问题:照片和视频的删除问题
        myMongoDBPost("RemoveDoc/SiteUsers", {CAdminUser: CAdminUser}, function (Docs1) {
          myMongoDBPost("RemoveDoc/Schedules", {CAdminUser: CAdminUser}, function (Docs2) {
            AutoAlert('提示', '删除完毕!')
          });
        });
      },
      SetJFMethod(Val) {
        this.CurrSite.JFMethod = (Val.indexOf("泛约") >= 0 ? '泛约' : Val);
      },
      MyInputVal(Val) {
        if (this.InputI === 1) this.CurrSite.Address = Val;
        else if (this.InputI === 2) this.CurrSite.Telephone = Val;
        this.InputI = -1;
      },
      SaveCurrPosi(aObj) {
        if (aObj.CanUse) {
          this.CurrSite.Longi = aObj.Longi;
          this.CurrSite.Lati = aObj.Lati;
          this.CurrSite.SiteShortName = this.CurrSite.SiteShortName.slice(0, 5);//留头不留尾，也就是截取的子串包含indexStart，不包含indexEnd
          myMongoDBPost("SaveCurrSiteDoc", this.CurrSite, function (data) {
          });
          this.ShowMap = -1;
        }
      },
      Tofixed(x, y) {
        return Tofixed(x, y)
      },
      DeleItem(index) {
        this.CurrSite.Items.splice(index, 1);
      },
      AppendItem(Val) {
        console.log("Val=" + Val);
        this.Show1 = false;
        this.aItemObj.ItemType = GetItemType(Val.split(":")[0]);//此时:Val=this.$store.state.CurrItemStr
        this.aItemObj.ItemCode = Val.split(":")[0];
        this.aItemObj.ItemName = Val.split(":")[1];

        let asd = CloneObject(this.aItemObj), Finded = false;
        if (this.aItemObj.ItemName === '项目名称') alert("未选择项目名称"); else {
          for (let i = 0, len = this.CurrSite.Items.length; i < len; i++)
            if (this.CurrSite.Items[i].ItemName === asd.ItemName) Finded = true;
          if (Finded) alert("不允许项目重复"); else this.CurrSite.Items.push(asd);
        }
      },
      SaveCurrSiteDoc() {
        let that = this;
        myMongoDBPost("GetDocs/fysites", {
          City: that.CurrSite.City,
          DC: that.CurrSite.DC,
          SiteName: that.CurrSite.SiteName
        }, function (Docs) {
          if (Docs.length === 0) AutoAlert("提示", "当前场地信息已被别人删除,现已重建!");
          myMongoDBPost("SaveCurrSiteDoc", that.CurrSite, function (data) {
            that.$emit("FromSiteEdit");
          })
        });
      },
      Quit() {
        this.$emit("FromSiteEdit");
      },
      CallBack0(Val) {
        if (Val.P) this.CurrSite.Pic = Val.P;
        if (Val.V) this.CurrSite.Video = Val.V;
        if (Val.T) this.CurrSite.Remark = Val.T;
      },
      CallBack(Val) {
        if (Val.P) this.CurrSite.Items[this.SeleI].Pic = Val.P;
        if (Val.V) this.CurrSite.Items[this.SeleI].Video = Val.V;
        if (Val.T) this.CurrSite.Items[this.SeleI].Remark = Val.T;
      }
    }
  }
</script>

<style scoped>
  .W22 {
    width: 22vw;
  }

  .W25 {
    width: 25vw;
  }

  .W12 {
    width: 12vw;
  }
</style>
