<template>
  <div>
    <PopUpDown v-if="ShowX" :Title="Title" Pos="btt" :ANs="1" @FromPop5="Return('无')">
      <div v-for="(Obj, index1) in Objs" :key="index1" class="BottO">
        <div class="DispIB BottGray PA3">{{Obj.Type}}：</div>
        <div class="XL YC ">
          <div v-for="(Opt, index2) in Obj.Opts" :key="index2" @click="Return(Opt)" class="TB MP4 BoxGray1">
            {{Opt}}
          </div>
        </div>
      </div>
    </PopUpDown>
  </div>
</template>

<script>
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import PopUpDown from "../MyPopWin/PopUpDown";

  export default {
    name: "MySele2",
    components: {PopUpDown, PopTitle},
    props: ['Title','Objs'],//格式必须为:[{Type:'分类1',Opts:['备选1','备选2',...]},{},{}...]
    data() {
      return {
        ShowX:true
        //Objs2: [{Type: '分类1', Opts: ['备选11', '备选12']}, {Class: '分类2', Opts: ['备选21', '备选22']}]
      }
    },
    methods: {
      Return(Str) {
        this.ShowX=false;
        this.$emit("MySele2", Str)
      }
    }
  }
</script>

<style scoped>

  .MBott2 {
    margin-bottom: 2mm;
  }
</style>
