<template>
  <div class="XLR">
    <div v-for="(M,index) in Menus" :key="index">
      <div v-if="MSele===index" class="SelM  XYC">{{M}}</div>
      <div v-else class="UnselM XYC" @click="SetMSele(index)">{{M}}</div>
    </div>
  </div>
  <slot></slot>
</template>

<script>
  export default {
    name: "TopMenu2",
    props: ['Menus','BVal'],//BVal=菜单选择初值
    data() {
      return {
        MSele: this.BVal,
        VW:80/this.Menus.length
      }
    },
    methods:{
      SetMSele(index){
        this.MSele=index;
        this.$emit("TopMenu2", index)
      }
    }
  }
</script>

<style  lang="less" scoped>
  .M {
    height: 2rem;
    border: solid #d1d1d1;
    border-width: 1px 1px 0 1px;
  }
  .SelM {
    color: #d1d1d1;
    padding: 0 2mm;
    background-color: white;
    .M
  }

  .UnselM {
    color: #04597d;
    padding: 0 2mm;
    background-color: #f3f3f3;
    .M
  }
</style>
