import {AutoAlert, GetC_I, GetMyYYs, myMongoDBPost, myWXget, UrlParse} from '@/components/SharedVues/Shared0.js'


function DL(that, Bool, CallBack) {//三件事情：1.修改全局变量 2.修改本地存储  3.ChangeCurrScr
    let WXNickName = '';
    let UrlParameters = UrlParse(), LandTo = UrlParameters.LandTo;//凡是来自微信或小程序的访问都会有LandTo，来自浏览器的访问则LandTo==undefined

    function GetVisitWXId(that, Bool, Callback) {
        let VisitWXId = UrlParameters.VisitWXId;


        //AutoAlert("1.LandTo="+LandTo+"----2.VisitWXId=",VisitWXId+"   Bool="+Bool);
        if (that.$store.state.WXId !== '') Callback(VisitWXId);//选择'按钮登录'时,如果本GetVisitWXId,已被调用过,立即再次调用后台会出现40001号错误
        else if (LandTo === undefined) Callback('from浏览器'); else {
            if (Bool === '按钮登录' || VisitWXId === undefined) {//从自定义菜单或'WXLink' 或LandTo === 'ZY2'等 过来的访问;
                myWXget("GetOpenidFromCode/?Code=" + UrlParameters.code, function (aObj) { //alert(JSON.stringify(aObj));
                    VisitWXId = (aObj.subscribe === 0 ? '未关注' : aObj.openid);
                    WXNickName = aObj.nickname;
                    if (VisitWXId === null || VisitWXId === undefined)
                        AutoAlert('网络异常导致的程序错误', 'VisitWXId =' + VisitWXId);
                    else
                        that.$store.commit('ChangeWXId', VisitWXId);//登录前赋值也是为了防止后台出现40001号错误
                    Callback(VisitWXId);
                })
            } else Callback(VisitWXId);
        }
    }

    GetVisitWXId(that, Bool, function (VisitWXId) {//设置与绑定WXId
        function SetCommit(data) {
            that.$store.commit('ChangeUserName', data.UserName);//登录成功,把游客改为泛约昵称
            that.$store.commit('ChangeUserLogo', data.UserLogo);
            that.$store.commit('ChangeCertified', data.Certified);
            that.$store.commit('ChangeZX', data.ZX);
            that.$store.commit('ChangeRealName', data.RealName);
            that.$store.commit('ChangeMobile', data.Mobile);
            that.$store.commit('ChangeDefaultItem', data.DefaultItem);
            that.$store.commit('ChangeSex', data.Sex);
            that.$store.commit('ChangeAge', data.Age);
            that.$store.commit('ChangeUserBools', data.UserBools);
            that.$store.commit('ChangeUserP', data.UserP);
            that.$store.commit('ChangeUserC', data.UserC);
            that.$store.commit('ChangeUserDC', data.UserDC);
        }

         if (Bool === '直接返回') CallBack(); else
            myMongoDBPost("DL", that.dlData, function (data) { //此处,服务器端也应该去解析dlData
                if (data === "未找到!" || data === '网络出错啦!') CallBack(); else { //登录成功,需要完成三件事情:
                    SetCommit(data);//1.
                    that.$store.commit('ChangeWXId', VisitWXId);
                    if (window.localStorage) {
                        window.localStorage.M1 = that.dlData.fyUserName + "-=_+" + that.dlData.fyPassword + "-=_+" + that.dlData.AutoDl;
                        let FYArea = 'N:P:C:D';
                        if (window.localStorage.M2) FYArea = JSON.parse(window.localStorage.M2).FYArea;//如果已经存在
                        window.localStorage.M2 = JSON.stringify({
                            fyUserName: that.dlData.fyUserName,
                            ZX: data.ZX,
                            Certified: data.Certified,
                            FYArea: FYArea
                        }); //可以将json对象转换成json对符串
                    } //2.如果浏览器支持localStorage,则写入localStorage

                    GetMyYYs(that, function () {
                        if (UrlParse().CurrYYId) that.$store.commit('ChangeBool3', UrlParse().CurrYYId);//2.借助Bool3保存CurrYYId,本行放到下面回调函数内部,则UrlParse()为空
                        if (VisitWXId === 'from浏览器') CallBack();
                        else if (that.$store.state.UserName === '游客') CallBack();
                        else if (VisitWXId || WXNickName) {//重新绑定
                            let oOBJ = {};
                            if (WXNickName !== '' && WXNickName !== undefined && WXNickName !== null) oOBJ.WXNickName = WXNickName;
                            if (VisitWXId) oOBJ.WXId = VisitWXId;
                            //AutoAlert(WXNickName,JSON.stringify(oOBJ));
                            if (JSON.stringify(oOBJ) !== "{}") myMongoDBPost("UpdateDoc/fyuser0", {
                                UPTJ: {UserName: that.$store.state.UserName},
                                UPObj: oOBJ
                            }, function (data2) {
                                CallBack();
                            });
                        } else CallBack();
                    }); //3.准备我的邀约数据

                }
            });
    });
}

export {DL}
