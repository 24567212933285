<template>
  <div>　
    <PopTitle v-if="Loaded" :Title="Act1Doc.Title" @FromPopUp="Quit()">
      <div class="XR PA3">计分：{{Act1Doc.UserName}}</div>
      <div class="PA2 BGLightGreen Box5" v-for="(i,index) in Games" :key="index">
        第{{i+1}}场☞
        <div class="XLR YC MP4 ">
          <div>{{Rows[i].Vs}}</div>
          =
          <div @click="I1=i">{{Rows[i].BF}}</div>
        </div>
      </div>
      <br>
      <MySele2 v-if="I1>=0" :Title="Rows[I1].Vs" :Objs="BFs" @MySele2="SetBF($event,I1)"></MySele2>
      　附:
      <table class="MT2">
        <thead>
        <tr>
          <th>场次</th>
          <th>照片</th>
          <th>视频</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(PV,index) in PABs" :key="index">
          <td>{{index+1}}</td>
          <td>
            <div class="MT2">
              <ShowUpdateP v-if="$store.state.UserName !=='游客'" :Pic="PV.Pic" @ShowUP="CallBack1($event,index)"></ShowUpdateP>
              <ShowPFromThumb v-else :Msg="{Pic:PV.Pic,Update:false}"></ShowPFromThumb>
            </div>
          </td>
          <td>
            <div class="MT2">
              <ShowUpdateV v-if="$store.state.UserName !=='游客'" :Video="PV.Video" @ShowUP="CallBack2($event,index)"></ShowUpdateV>
              <ShowVFromThumb v-else :Msg="{Video:PV.Video,Update:false}"></ShowVFromThumb>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </PopTitle>
  </div>
</template>

<script>
  import {myMongoDBPost, myDataFileGet, AutoAlert, DeletePV, CloneObject, Compare} from '@/components/SharedVues/Shared0.js'

  import PopTitle from "../MyPopWin/PopTitle";
  import MySele2 from "../SharedVues/MySele2";
  import Act1PVABs from "./Act1PVABs";
  import ShowUpdateP from "../MyPVTs/ShowUpdateP";
  import ShowUpdateV from "../MyPVTs/ShowUpdateV";
  import ShowVFromThumb from "../MyPVTs/ShowVFromThumb";
  import ShowPFromThumb from "../MyPVTs/ShowPFromThumb";

  export default {
    name: "Act1JFQ3",
    components: {
      ShowPFromThumb,
      ShowVFromThumb,
      ShowUpdateV,
      ShowUpdateP,
      Act1PVABs,
      MySele2,
      PopTitle,

    },
    props: ['ActDoc', 'Act1Doc'],
    data() {
      return {
        CurrJFMethod: '泛约',
        Loaded: false,
        Rows: this.Act1Doc.TableCols,//注意：这是一个二维叔组
        PABs: this.Act1Doc.PABs,
        I1: -1,
        BFs: [
          {Type: '3局2胜制', Opts: ['0:2', '1:2', '2:0', '2:1']},
          {Type: '5局3胜制', Opts: ['0:3', '1:3', '2:3', '3:2', '3:1', '3:0']},
          {Type: '7局4胜制', Opts: ['0:4', '1:4', '2:4', '3:4', '4:0', '4:1', '4:2', '4:3']}],
        Games: [0, 1, 2, 3, 4]
      }
    },
    mounted() {
      if (this.Rows.length === 6) this.Loaded = true; else {
        AutoAlert('该活动创建有误', '请发布者进一步完善活动相关信息!');
        this.Loaded = false;
      }
      if (this.Act1Doc.Rule === '3局2胜') this.Games = [0, 1, 2];
      let that = this;
      if (this.ActDoc.JFPool !== '泛约')
        myMongoDBPost("GetDocs/fysites", {SiteShortName: that.ActDoc.JFPool}, function (Docs) {
          if (Docs.length > 0) that.CurrJFMethod = Docs[0].JFMethod
        });
    },
    methods: {
      Quit() {
        let that = this, Victor = '', Second = '', V1n = 0, V2n = 0;
        for (let i = 0; i < 5; i++) {
          if (this.Rows[i].BF === '―' || this.Rows[i].BF === '无') break;
          let D = this.Rows[i].BF.split(":");
          if (parseInt(D[0]) > parseInt(D[1])) V1n += 1; else V2n += 1;
        }
        if (V1n > V2n)
          Victor = this.Rows[5].Vs.split(":")[0], Second = this.Rows[5].Vs.split(":")[1];
        else
          Victor = this.Rows[5].Vs.split(":")[1], Second = this.Rows[5].Vs.split(":")[0];

        myMongoDBPost("UpdateDoc/act1", {UPTJ: {_id: this.Act1Doc._id}, UPObj: {Victor: Victor, Second: Second, PABs: this.PABs}}, function (data2) {
          that.$emit('FromJFQ3');
        });
      },
      CallBack1(Val, index) {
        DeletePV(this.PABs[index].Pic, '');//更新前,先把旧的删了
        this.PABs[index].Pic = Val.P;
      },
      CallBack2(Val, index) {
        DeletePV('', this.PABs[index].Video);//更新前,先把旧的删了
        this.PABs[index].Video = Val.V;
      },
      SetBF(Val, index1) {
        if (this.$store.state.UserName === this.Act1Doc.UserName || this.$store.state.UserName === this.$store.state.HTM0) {
          let ASD = CloneObject(this.Rows[index1]);
          ASD.BF = Val;
          this.Rows.splice(index1, 1, ASD);

          let aOBJ = {Act1DocId: this.Act1Doc._id, Winner: this.Rows[index1].Vs.split(":")[0], Loser: this.Rows[index1].Vs.split(":")[1], BF: Val};

          aOBJ.ItemStr = this.ActDoc.ItemStr;
          aOBJ.JFPool = this.ActDoc.JFPool;
          if (aOBJ.ItemStr.charAt(0).toLowerCase() === 'a') {
            console.log("aOBJ.Winner,aOBJ.Loser,aOBJ.BF=", aOBJ.Winner, aOBJ.Loser, aOBJ.BF);
            if (this.CurrJFMethod === '泛约') myMongoDBPost("Act1FillToFymc", aOBJ, function (asd) {
            });
            if (this.CurrJFMethod === 'ChinaTT') myMongoDBPost("ChinaTTToByset0", aOBJ, function (asd) {
            })
          }

          myMongoDBPost("UpdateDoc/Act1Main", {UPTJ: {_id: this.Act1Doc._id}, UPObj: {TableCols: this.Rows}}, function (data2) {
          });
        } else AutoAlert('无效操作', '只有计分员才能录入比分!');
        this.I1 = -1;

      },//数组元素更新
    }
  }
</script>

<style scoped>
</style>



