<template>
    <div class="ANGreen MP1 LH150 BD_Radius1">
        <div class="XL">
            <div class=" TF  DispIB">已选被约：</div>
            <div v-if="BYSelected">
                <div class="MR4" v-for="(x,index) in CurrYYDoc.by" :key="index">{{x._id}}</div>
            </div>
            <div v-else class="TE T3">尚未选择邀约对象</div>
        </div><!--请注意此处的W26来自本组件,若是用全局的则不起作用-->
        <div v-if="CurrYYDoc.meetTimeStr!==''"><span class="TF">时间：</span>{{CurrYYDoc.meetTimeStr}}</div>
        <div v-if="CurrYYDoc.meetAddrStr!==''"><span class="TF">地点：</span><span class="T3"> {{CurrYYDoc.meetAddrStr}}</span>
        </div>
        <div v-if="CurrYYDoc.fySitefee>0"><span class="TF">场地器械费用：</span>{{CurrYYDoc.fySitefee}}元/人</div>
        <div v-if="CurrYYDoc.paymentWay!=='无费用'"><span class="TF">付费方式：</span>{{CurrYYDoc.paymentWay}}</div>
        <div v-if="CurrYYDoc.jdfy!==''"><span class="TF">简短附言：</span>{{CurrYYDoc.jdfy}}</div>
        <div v-if="YYStepSele===3" class="MT2">
            <div v-if="YYSiteChecked" class="XLR YC MLR4">
                <div class="AN ANYellow" @click="SendYY1()">保存为经常性邀约</div>
                <div class="AN ANGray " @click="SendYY()">发&nbsp;送&nbsp;邀&nbsp;约</div>
            </div>
            <div v-else class="TE MP4">碰头地点输入有误!</div>
        </div>
    </div>
    <div class="PA2 BottO DispIB">
        步骤{{YYStepSele+1}}：
        <span v-if="YYStepSele===0" class="TF T3">(请至少选择一个被约)</span>
        <span v-if="YYStepSele===1" class="TF T3">(请确定项目活动时间和地点)</span>
        <span v-if="YYStepSele===2" class="TF T3">(该步骤都是选填信息，可不填写)</span>
        <span v-if="YYStepSele===3" class="TF T3">(请确认邀约信息准确无误后，再发送)</span>
    </div>
    <PopStep2 :Steps="Steps" :FromOpenUser="FromOpenUser" @FromPopStep2="FromPopStep">
        <br>
        <div class="BGY">
            <div v-show="YYStepSele===0" class="MT2">
                <div class="BottGray XLR YC PA2 MT2">
                    <div class="XL YC">
                        范围：
                        <FYArea></FYArea>
                    </div>
                    <div v-if="CurrTM===0" class="XL YC">
                        <!--img src="../../../static/gif2/ArrowR.gif" style="width: 1rem;"-->积分池：
                        <div class="DispIB TB PA2 BoxGray1" @click="Show3=true,CurrClub=''">{{CurrClub}}</div>
                    </div>
                    <div v-else></div>
                </div>
                <MySele1 v-if="Show3" :Opts="Clubs" :ColN="3" @FromMySele1="SetCurrClub"></MySele1>
                <TM0bySele v-if="CurrTM===0 && CurrClub!==''" :CurrClub="CurrClub" :ZyJF="CurrYYDoc.JF"
                           @eventFromTM0="SetBy"></TM0bySele>
                <TM1bySele v-if="CurrTM===1" @eventFromTM="SetBy"></TM1bySele>
                <TM2bySele v-if="CurrTM===2" @eventFromTM="SetBy"></TM2bySele>
                <TM3bySele v-if="CurrTM===3" @eventFromTM="SetBy"></TM3bySele>
                <div v-if="NoBY" class="TE AlignC MP4">找不到可选被约！请扩大选择范围或退出!</div>
                <div v-if="FromOpenUser !== undefined" class="MP4">{{FromOpenUser}}</div>
            </div>
            <div v-if="YYStepSele===1">

                时间：
                <el-date-picker v-model="CurrYYDoc.meetTimeStr" format="YYYY-MM-DD HH:mm" type="datetime"/>
                <div class="MP4 TC XL YC">
                    碰头地点：
                    <FyAddr @FromFyAddr="SetFyAddr"></FyAddr>
                </div>
                <div class="XLR YC PA3 TC T3">
                    具体地点:
                    <div class="BoxGray1 W60 H PA3 TF" @click="SetInputI2">
                        <span v-if="AddrDA===''">(请精确到50米范围内!)</span>
                        <span v-else>{{AddrDA}}{{SiteName}}</span>
                    </div>
                </div>
                <div v-if="Show4">
                    <MyDivider ShowStr="推荐场地(自动生成碰头地点)"></MyDivider>
                    <SitesShow :CurrYYDoc="CurrYYDoc" :ItemStr="ItemStr"
                               @eventFromSitesShow="ResetAddr"></SitesShow>
                    <SitesShow></SitesShow>
                </div>
                <MyInput v-if="InputI===2" Title="具体地点" Type="text" :Begin="AddrDA" @FromMyI="MyInputVal"></MyInput>
            </div>
            <div v-if="YYStepSele===2">
                <div>
                    <!--XInput title="场地器械费用:" v-model="CurrYYDoc.fySitefee"><span slot="right">元/人</span></XInput-->
                    <div class="XL YC PA3 TF">
                        场地器械费用：<input type="number" class="W12" @focus="CurrYYDoc.fySitefee=null"
                                      v-model.number="CurrYYDoc.fySitefee"/> 元/人
                    </div>
                    付费方式:
                    <div class="flex flex-wrap gap-4 items-center">
                        <el-select v-model="CurrYYDoc.paymentWay">
                            <el-option v-for="(item,index) in PayWays" :key="index"/>
                        </el-select>
                    </div>
                </div>
                <MyInput v-if="InputI===3" Title="场地器械费用" Type="number" :Begin="CurrYYDoc.fySitefee"
                         @FromMyI="MyInputVal"></MyInput>
                <div class="TF MP4">简短附言:</div>
                <div class="XLR">&nbsp;
                    <textarea v-model="CurrYYDoc.jdfy" class="Mtextarea2 Box5 MR4"></textarea>
                    &nbsp;
                </div>
                <div class="MP4 TF">
                    <p>
                        <span class="TE">特别说明：</span>上述场地器械费用，特指由主约提供的线下活动场地收取的费用，须由主被约双方按照上述付费方式现场支付给场地一方，与本平台无关！
                    </p>
                </div>
                <MyInput v-if="InputI===1" Title="简短附言" Type="textarea" :Begin="CurrYYDoc.jdfy"
                         @FromMyI="MyInputVal"></MyInput>
            </div>
            <div v-if="zyPic!=='' && $store.state.UserName === $store.state.HTM0" class="MT2">
                <WXLink :WTitle="'【'+CurrYYDoc.ItemName+'】项目新玩法：赢取泛约币。'" WText="谁赢了泛约币归谁。"
                        :WLandTo="'XMYY'+CurrYYDoc.ItemCode+'XMYY'+CurrYYDoc.zyUserName"
                        :Pic="'UserUpload/1/S'+zyPic"></WXLink>
                <!--'擂主：'+CurrYYDoc.zyUserName+'。赢取泛约币：'+CurrYYDoc.AppearanceFee+'个。'-->
            </div>
        </div>
    </PopStep2>
</template>

<script>
    import {
        GetC_I,
        GetItemStr3,
        GetMyYYs,
        GetRelativeTime,
        KeepZX,
        myDataFileGet,
        myMongoDBPost,
        MyUrl,
        SendWXMsg,
        Tofixed
    } from '@/components/SharedVues/Shared0.js' ;
    import TM0bySele from "./TM0bySele";
    import TM1bySele from "./TM1bySele";
    import TM2bySele from "./TM2bySele";
    import TM3bySele from "./TM3bySele";
    import TimeWindowSele from "@/components/SharedVues/TimeWindowSele";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MyToast from "@/components/SharedVues/MyToast";
    import MyInput from "@/components/SharedVues/MyInput";
    import WXLink from "../IndependentVues/WXLink";
    import MySele1 from "../SharedVues/MySele1";
    import PopStep2 from "../MyPopWin/PopStep2";
    import FYArea from "../SharedVues/FYArea";
    import MyDivider from "../SharedVues/MyDivider";
    import SitesShow from "../M4_Sites/SitesShow";
    import FyAddr from "../SharedVues/FyAddr";

    export default {
        name: "XMYY",
        components: {
            MyDivider,
            FYArea,
            PopStep2,
            MySele1,
            WXLink,
            MyInput,
            MyToast,
            PopTitle,
            TM0bySele,
            TM3bySele,
            TM2bySele,
            TM1bySele,
            SitesShow,
            TimeWindowSele,
            FyAddr
        },
        props: ['ItemStr', 'FromOpenUser'],
        data() {
            return {
                CurrClub: '泛约',
                Clubs: ['泛约'],
                NoBY: false,
                SendedMsg: '',
                Show3: false,
                Show4: false,
                YYStepSele: 0,
                //minuteListValue: '2017-06-12 09:00',
                Steps: ['选择被约', '时间地点', '费用等', '确认'],
                PayWays: ['无费用', 'AA制', '主约付费', '被约付费'],
                AddrDA: '',
                SiteName: '',
                CurrYYDoc: {
                    ItemCode: this.ItemStr.split(":")[0],
                    ItemName: this.ItemStr.split(":")[1],
                    ItemStr: this.ItemStr.split(":")[0] + ":" + this.ItemStr.split(":")[1],
                    zyUserGrade: this.$store.state.Certified,
                    zyUserName: this.$store.state.UserName,
                    AppearanceFee: 0,
                    JF: 0,
                    meetTimeStr: '',
                    meetAddrStr: "",
                    Longi: 116.38,
                    Lati: 39.9,
                    TimeWinStr: '',//HS:1,2;或 Y:3; 或 M:2; 或 W:2,3 或 WE:2,3 或 H:1
                    speicialServer: [false, false, false],
                    paymentWay: "无费用",
                    fySitefee: 0.0,
                    by: [],
                    jdfy: "",
                    YYSiteChecked: false
                },
                // Timer: 0,
                InputI: -1,
                zyPic: '',
                PCDC: {P: "省份", C: "城市", DC: "区县"}
            }
        },
        computed: {
            CurrTM() {
                return GetC_I(this.ItemStr);
            },
            BYSelected() {
                return this.CurrYYDoc.by.length > 0;
            },
        },
        watch: {
            BYSelected(Val) {
                this.$store.commit('ChangeStepEnable', Val);
            },
        },
        mounted() {
            let that = this;
            myMongoDBPost("GetDocs/byset0", {
                UserName: that.$store.state.UserName,
                ItemStr: that.CurrYYDoc.ItemStr
            }, function (Docs2) {
                console.log(Docs2)
                if (Docs2.length > 0) {
                    if (Docs2[0].Pic && Docs2[0].Pic.length > 0) that.zyPic = Docs2[0].Pic; else that.zyPic = that.$store.state.UserLogo;
                    for (let i = 0; i < Docs2[0].MySites.length; i++) that.Clubs.push(Docs2[0].MySites[i].SiteShortName);
                    that.CurrClub = (Docs2[0].CurrClub === '' ? '泛约' : Docs2[0].CurrClub);
                    that.CurrYYDoc.AppearanceFee = Docs2[0].AppearanceFee;
                    myMongoDBPost("GetDocs/fymc-" + that.CurrYYDoc.ItemStr.split(":")[0], {_id: that.$store.state.UserName}, function (Docs) {
                        if (Docs.length > 0) that.CurrYYDoc.JF = Docs[0].JF;
                    });
                }
            });
        },
        // beforeUnmount() {      if (this.Timer) clearTimeout(this.Timer);    },//如果定时器还在运行 或者直接关闭，不用判断
        methods: {
            SetFyAddr(Val) {
                this.PCDC = {P: Val.P, C: Val.C, DC: Val.DC};
            },
            SetCurrClub(Val) {
                let that = this;
                this.CurrClub = (Val === '' ? '泛约' : Val);
                myMongoDBPost("UpdateDoc/bySet0", {
                    UPTJ: {UserName: this.$store.state.UserName, ItemStr: this.CurrYYDoc.ItemStr},
                    UPObj: {CurrClub: Val}
                }, function (data2) {
                    that.Show3 = false;
                });
            },
            SetInputI2() {
                if (this.PCDC.P === '省份') alert( "省份必须选择！");
                else if (this.PCDC.C === '城市') alert(  "城市必须选择！");
                else if (this.PCDC.DC === '区县') alert(  "区县必须选择！");
                else this.InputI = 2;
            },
            MyInputVal(Val) {
                if (this.InputI === 1) this.CurrYYDoc.jdfy = Val;
                else if (this.InputI === 2) {
                    this.AddrDA = Val;
                    this.AddrChanged();
                }
                else if (this.InputI === 3) this.CurrYYDoc.fySitefee = Val;
                this.InputI = -1;
            },
            Tofixed(x, y) {
                return Tofixed(x, y)
            },
            AddrChanged() {
                this.CurrYYDoc.meetAddrStr = this.PCDC.P + this.PCDC.C + this.PCDC.DC + this.AddrDA + this.SiteName;
                let X = this.PCDC.P !== '省份', Y = this.PCDC.C !== '城市', Z = this.PCDC.DC !== '区县';
                if ((X && Y && Z && this.AddrDA !== '')) {
                    this.$store.commit('ChangeStepEnable', true);
                    this.YYSiteChecked = true;
                }
            },
            eventFromStep(data) {
                this.YYStepSele = data;
                if (data === 1 && !this.YYSiteChecked) this.$store.commit('ChangeStepEnable', false);
                this.$store.commit('ChangeBool2', '' + Math.random());
                this.$store.commit('ChangeBool3', 'ResetAddr of XMYY');
            },
            FromPopStep(Val) {
                this.YYStepSele = Val;
                if (Val === 1) this.CurrYYDoc.meetTimeStr = GetRelativeTime('h', 1, "yyyy-MM-ddd hh:mm");//当前时间推后1小时
                if (Val === 1 && !this.YYSiteChecked) {
                    this.Show4 = true;
                    this.$store.commit('ChangeStepEnable', false);
                }
                this.$store.commit('ChangeBool2', '' + Math.random());
                this.$store.commit('ChangeBool3', 'ResetAddr of XMYY');
            },
            SetBy(Val) {
                //console.log("this.FromOpenUser=" + this.FromOpenUser + "Val=" + Val)
                //console.log(Val)
                let that = this;
                if (this.FromOpenUser !== undefined) {
                    myMongoDBPost("GetDocs/byset0", {
                        UserName: that.FromOpenUser,
                        ItemStr: that.CurrYYDoc.ItemStr
                    }, function (Docs2) {
                        that.CurrYYDoc.by = [{
                            "_id": that.FromOpenUser,
                            "xy": "未响应",
                            "z_zg": "初值",
                            "z_bylyqk": "初值",
                            "b_zg": "初值",
                            "b_zylyqk": "初值",
                            "zg": "初值",
                            "z_ts": "初值",
                            "b_ts": "初值",
                            "Pic": '',//线下活动情况
                            "Video": '',
                            "Text": '',
                            "AppearanceFee": Docs2.length > 0 ? Docs2[0].AppearanceFee : 0
                        }];
                        that.NoBY = false;
                    })
                } else {
                    if (Val === 'NoBY') this.NoBY = true; else {
                        this.CurrYYDoc.by = Val;
                        this.NoBY = false;
                    }
                }

            },//看看从不同的子组件中反馈的事件是否通用?

            SendYY() {
                if (this.YYStepSele === 3) {
                    this.YYStepSele = 0;//为防止快速点击两次发送时的重复发送,设置本变量;
                    if (this.$store.state.UserName === '游客') return alert(  '为了最大限度的堵塞滥约,游客不能作为主约发送邀约!');
                    let C_S = this.CurrYYDoc.ItemCode.charAt(0);
                    let that = this, AppearanceFees = 0, byN = this.CurrYYDoc.by.length;
                    for (let i = 0, len = byN; i < len; i++) AppearanceFees += that.CurrYYDoc.by[i].AppearanceFee;

                    let Describe = C_S + ">" + this.CurrYYDoc.ItemCode + ">" + this.CurrYYDoc.ItemName + ",邀请被约" + byN + "个";
                    let ThisMoney = AppearanceFees;
                    GetMyYYs(that, function () {

                        if (ThisMoney > 0) {
                            if (that.$store.state.ZX < ThisMoney)
                                alert("邀约无效：您的泛约币存量不足! 本次需冻结：" + ThisMoney + "个泛约币");
                            else
                                KeepZX(that, that.$store.state.UserName, '支', ThisMoney, Describe, function (data) {
                                    alert("当前邀约已发送... ，需冻结" + ThisMoney + "个泛约币");
                                    that.SendYY3();
                                });
                        } else {
                            //if (C_S === 'C') AutoAlert('当前邀约已发送...', "请按被约确定的结账方式支付！"); else AutoAlert('当前邀约已发送...', "无需耗费泛约币");
                            alert('当前邀约已发送...，无需耗费泛约币');
                            that.SendYY3();
                        }
                    });
                }
            },
            SendYY1() {
                //this.$emit("eventFromXMYY", false);
                let that = this;
                if (this.$store.state.UserName === '游客') return alert('为了最大限度的堵塞滥约,游客不能作为主约发送邀约!'); else {
                    myMongoDBPost("SaveAsYYTemplet", this.CurrYYDoc, function (data) {
                        alert("当前邀约信息已保存为经常性邀约!");
                    })
                }
            },//存为模板
            SendYY3() {
                let that = this;
                //this.Timer = setTimeout(function () {          that.$emit("eventFromXMYY", false);        }, 1000)//确保动画完成
                myMongoDBPost("GetDocs/YYMBs", {
                    zyUserName: that.$store.state.UserName,
                    ItemStr: that.CurrYYDoc.ItemStr
                }, function (Docs) {
                    if (Docs.length === 0) myMongoDBPost("SaveAsYYTemplet", that.CurrYYDoc, function (data) {
                    })
                });
                myMongoDBPost("PutDoc/yy-" + that.CurrYYDoc.ItemCode, that.CurrYYDoc, function (aDoc) {//邀约发送成功后进行关联用户处理
                    let i, len;//向每一个被选中的被约发送消息
                    for (i = 0, len = that.CurrYYDoc.by.length; i < len; i++)
                        (function (i) {
                            // let Url=MyUrl('LandTo=BY2!ItemCode=' + that.CurrYYDoc.ItemCode + '!ItemName=' + that.CurrYYDoc.ItemName + '!CurrYYId=' + aDoc._id + '___');
                            //let Msg = GetItemStr3(that.CurrYYDoc.ItemStr) + "☞“" + that.$store.state.UserName + '”向您发起邀约。\n[ <a href="' + Url + '">立即查看/回复</a> ]';

                            let MyUrlPara = 'LandTo=BY2QWERItemCode=' + that.CurrYYDoc.ItemCode + 'QWERItemName=' + that.CurrYYDoc.ItemName + 'QWERCurrYYId=' + aDoc._id + '___';
                            let MyUrlShow = '立即查看/回复';
                            let Msg = GetItemStr3(that.CurrYYDoc.ItemStr) + "☞『" + that.$store.state.UserName + '』向您发起邀约。' + '[ MyUrlHere ]MyUrlHereMyUrl=' + MyUrlPara + 'MyUrl=' + MyUrlShow;
                            SendWXMsg(that.CurrYYDoc.by[i]._id, Msg);

                        })(i)  //  循环内有回调 : )(i)

                    for (i = 0, len = that.CurrYYDoc.by.length; i < len; i++)
                        (function (i) {
                            let Obj1 = {
                                UPTJ: {UserName: that.$store.state.UserName, RType: '我约过的'},
                                UPObj: {$addToSet: {RUsers: that.CurrYYDoc.by[i]._id}}
                            }, Obj2 = {
                                UPTJ: {UserName: that.CurrYYDoc.by[i]._id, RType: '约过我的'},
                                UPObj: {$addToSet: {RUsers: that.$store.state.UserName}}
                            };
                            myMongoDBPost("UpdateArrayAttr/RUsers", Obj1, function (data) {
                            });//追加一个关联用户
                            myMongoDBPost("UpdateArrayAttr/RUsers", Obj2, function (data) {
                            });//追加一个关联用户:往指定文档的数组属性中增加一个数组元素
                        })(i)//采用匿名函数闭包的方法解决循环内有回调的问题
                    //that.$emit("eventFromXMYY", false);
                });
            },//取消
            ResetAddr(aObj) {
                this.$store.commit('ChangeCurrInputP', aObj.P);
                this.$store.commit('ChangeCurrInputC', aObj.C);
                this.$store.commit('ChangeCurrInputDC', aObj.DC);
                this.$store.commit('ChangeBool2', '' + Math.random());
                this.$store.commit('ChangeBool3', 'ResetAddr of XMYY');
                this.AddrDA = aObj.DA;
                this.SiteName = aObj.SiteName;
                this.CurrYYDoc.meetAddrStr = aObj.P + aObj.C + aObj.DC + aObj.DA + aObj.SiteName;
                this.CurrYYDoc.Longi = aObj.Longi;
                this.CurrYYDoc.Lati = aObj.Lati;

                //console.log("2.this.CurrYYDoc.meetAddrStr=" + this.CurrYYDoc.meetAddrStr)
                this.Show4 = false;
                this.AddrChanged();
                //this.$store.commit('ChangeCurrYYDoc', {meetAddrStr:aObj.P+aObj.C+aObj.DC+aObj.DA});
            }
        }
    }
</script>

<style scoped>

</style>
