<template>
    <PopUpDown Title="请选择限定区域" Pos="btt" :ANs="1" @FromPop5="$emit('FromSeleArea', '全国:ALL:')">
      <div v-if="SeleI1===-1" class="AlignC">
          <div v-for="(P, index1) in Areas" :key="index1">
            <div class="SubBox">
              <div v-if="P.P==='不限'" class="TG" @click="SetP('不限')">不限</div>
              <div v-else class="XLR">
                <div class="TB" @click="SetP(P.P)">{{P.P}}</div>
                <div @click="SeleI1=index1">
                  <el-icon><ArrowDown /></el-icon>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div v-else class="AlignC">
        <div v-if="SeleI2===-1"  >
          <div v-for="(C, index2) in Areas[SeleI1].Cs" :key="index2">
            <div class="SubBox">
              <div v-if="C.C==='不限'" class="TG" @click="SetC('不限')">不限</div>
              <div v-else class="XLR">
                <div class="TB" @click="SetC(C.C)">{{C.C}}</div>
                <div @click="SeleI2=index2">
                  <el-icon><ArrowDown /></el-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else >
          <div v-for="(DC, index3) in Areas[SeleI1].Cs[SeleI2].DCs" :key="index3">
            <div class="SubBox">
              <div v-if="DC==='不限'" class="TG" @click="SetDC('不限')">不限</div>
              <div v-else class="TB" @click="SetDC(DC)">{{DC}}</div>
            </div>
          </div>
        </div>
      </div>
    </PopUpDown>
</template>

<script>
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import PopUpDown from "../MyPopWin/PopUpDown";

  export default {
    name: "SeleArea",
    components: {PopUpDown, PopTitle},
    props: ['Areas'],
    data() {
      return {
        Area: '全部',
        SeleI1: -1,
        SeleI2: -1
      }
    },
    mounted() {
      //console.log(this.Areas)
    },
    methods: {
      SetP(P) {
        if (P === '不限') this.$emit("FromSeleArea", "全国:ALL:");else this.$emit("FromSeleArea", "中国:P:" + P)
      },
      SetC(C) {
        if (C === '不限') this.SeleI1 = -1;else this.$emit("FromSeleArea", this.Areas[this.SeleI1].P+":C:" + C)
      },
      SetDC(DC) {
        if (DC === '不限') this.SeleI2 = -1;else this.$emit("FromSeleArea", this.Areas[this.SeleI1].Cs[this.SeleI2].C+":DC:" + DC)
      },
    }
  }
</script>

<style scoped>
  .W24 {
    width: 24mm
  }

  .SubBox {
    padding: 2mm;
    margin: 1mm;
    width: 24vw;
    border: 1px solid #8b8d94;
  }
</style>
