<template>
  <div>
    <div v-for="(x,index) in Msg" :key="index">

        <div v-if="Show===index" class="PA2 TG NoWrap" @click="SetShow()">
          {{x}}
        </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "MySelectStr",

    props: ['Msg', 'CallFrom', 'CurrN'],
    data() {
      return {
        Show: 0
      }
    },
    mounted() {
      this.Show = this.CurrN;
    },
    methods: {
      SetShow() {
        this.Show++;
        if (this.Show === this.Msg.length) this.Show = 0;
        this.$emit("eventFromChild", this.Msg[this.Show])
      }

    }
  }
</script>

<style scoped>
  /* 可以设置不同的进入和离开动画 */
  /* 设置持续时间和动画函数 */

</style>
