<template>
    <div class="MT2">
        <div class="XLR YC BottGray PA3">
            <div>
                <div class="T2">活动范围：</div>
                <FYArea @FromFYArea="SetActArea"></FYArea>
            </div>
            <div></div>
            <div class="LH120 TG" @click="SetCurrEndBool()">{{EndBools[Curr.EndBool]}}<br>活动</div>
            <div></div>
            <img :src="'UserUpload/static/After2021/Help.png'" class="WH77" @click="Show=6"/>
            <div @click="SetShow1" class="AlignC">
                <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">
                <div class="T3">创建活动</div>
            </div>
        </div>
        <div class="MT2 ">
            <div v-for="(Act,index) in Acts" :key="index" class="MP4 BottGray">
                <div v-if="$store.state.UserName===$store.state.HTM0 || $store.state.UserName===Act.UserName"
                     class="XLR YC">
                    <div class="TE BDCircle BoxBR WH77 XYC" @click="Delete(Act._id,index,Act.Title)">删</div>
                    <div class="TE BDCircle BoxBR WH77 XYC" @click="SeleI = index,Show=2" style="margin: 0 1.5mm">改</div>
                    <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SeleI=index,Show=3">活动<br>规则</div>
                    &nbsp;
                    <div class="W75 TG" @click="SetSeleI(index)">
                        {{Act.Title}}<!--sup class="T1 TE">{{Act.LookedTimes}}</sup-->
                        <div v-if="Act.HoldStyle==='封闭式'" class="TF T2">内含多个子活动</div>
                        <div v-else class="TF T2">类型:{{Act.ActStyle}}</div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="Act.Scale==='凭密参加' || Act.HoldStyle==='封闭式' && Act.ScaleVal!==null" class="XLR YC">
                        <div class="XLR YC">
                            <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SeleI=index,Show=3">活动<br>规则</div>&nbsp;
                            <div :class="Act.HoldStyle==='封闭式' ? 'TB' :'TG'">
                                {{Act.Title}}<!--sup class="T1 TE">{{Act.LookedTimes}}</sup-->
                                <div v-if="Act.HoldStyle==='封闭式'" class="TF T2">内含多个子活动</div>
                                <div v-else class="TF T2">类型:{{Act.ActStyle}}</div>
                            </div>
                            <div></div>
                        </div>
                        <div class="XLR YC TG" @click="SeleI = index,InputI=1">
                            <div></div>
                            <div class="W12" style="white-space: nowrap;">口令</div>
                            <div class="W2">
                                <el-icon>
                                    <ArrowRight/>
                                </el-icon>
                            </div>
                        </div>
                    </div>
                    <div v-else class="XLR YC TG">
                        <div class="XLR YC">
                            <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SeleI=index,Show=3">活动<br>规则</div>&nbsp;
                            <div :class="Act.HoldStyle==='封闭式' ? 'TB' :'TG'" @click="SetSeleI(index)">
                                {{Act.Title}}<!--sup class="T1 TE">{{Act.LookedTimes}}</sup-->
                                <div v-if="Act.HoldStyle==='封闭式'" class="TF T2">内含多个子活动</div>
                                <div v-else class="TF T2">类型:{{Act.ActStyle}}</div>
                            </div>
                            <div></div>
                        </div>
                        <!--div>
                          <div v-if="Scale==='不限人数'">不限人数</div>
                          <div v-if="Scale==='额满为止'" class="TF">限:{{Act.Scale}}人</div>
                          <div class="DispIB CRW" style="position: relative;left: -0.3rem;top:-0.5rem">{{Act.Joinn}}☺</div>
                        </div-->
                        <div class="W2" @click="SetSeleI(index)">
                            <el-icon>
                                <ArrowRight/>
                            </el-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CreateAct v-if="Show===1" OP="新增" :Msg="Curr" @CreateAct="Refresh1"></CreateAct>
        <CreateAct v-if="Show===2" OP="修改" :Msg="Acts[SeleI]" @CreateAct="Refresh1"></CreateAct>

        <PopOneAN v-if="SeleI>-1 && Show4.indexOf('循环排名赛')>=0" @FromPopUp="Refresh2">
            <Act0Main :ActDoc="Acts[SeleI]" :ItemStr="ItemStr"></Act0Main>
        </PopOneAN>
        <PopTitle v-if="OpenAct1 " :Title="CurrActDoc.Title" @FromPopUp="Refresh2">
            <Act1Main :ActDoc="CurrActDoc" :ItemStr="ItemStr"></Act1Main>
        </PopTitle>
        <OpenOneAct v-if="SeleI>-1 && Show4==='其它活动'" :Msg="Acts[SeleI]" @OpenOneAct="Refresh2"></OpenOneAct>

        <PopTitle v-if="Show===3" Title="泛约服务平台活动规则" @FromPopUp="Show=-1"><br>
            <div v-if="Acts[SeleI].UserName===$store.state.UserName">
                <MsgDetails CallFrom='ActMain' :MsgId="Acts[SeleI]._id" OP="编辑" @MsgDetails="Show=-1"></MsgDetails>
                <WXLink :WLandTo="'Act_'+ItemStr+'Act_' + Acts[SeleI]._id+'Act_Rule'"
                        Pic="UserUpload/static/Img/Award.png"
                        :WTitle="Acts[SeleI].Title" WText="当前已有两个特混舰队，27人预报名。"></WXLink>
            </div>
            <MsgDetails v-else CallFrom='ActMain' :MsgId="Acts[SeleI]._id" OP="查询" @MsgDetails="Show=-1"></MsgDetails>
        </PopTitle>

        <MyInput v-if="InputI===1" Title="授权口令（纯数字）" Type="number" :Begin="null" @FromMyI="MyInputVal"></MyInput>
        <PopTitle v-if="Show===6" Title="操作说明" @FromPopUp="Show=-1">
            <div class="MP4 LH200">
                <p>1.凡是参加泛约活动，一般需要预先设置喜好场地：</p>
                <div class="AlignC">
                    <img :src="'UserUpload/CZSM/SetMySite.jpg'" class="W90">
                </div>
                <p>2.泛约活动分为两大类：</p>
                <p>开放式：任何用户均可自由参加；赛事比分由参加者自行输入；活动标题唯一。</p>
                <p>封闭式：活动管理员指定参加人员（如：会员、报名者）；赛事比分由计分员输入；活动标题下须包含二级标题（即包含子活动）。</p>
                <p>3.泛约赛事活动</p>
                <p>当前主要支持乒乓球类项目比赛。开放式活动面向单打，不仅提供赛事运算服务，还可以用来日常约球；封闭式活动主要面向团体，须通过二级标题管理各轮次小赛比分和排名。</p>
                <p>4.乒乓球项目积分管理办法</p>
                <p>
                    本平台积分管理分为全局和局部两种类型：全局积分采用“泛约积分树”方法排名；局部积分采用“ChinaTT计分法”排名；一切泛约用户均自动参与全局排名；局部积分则按积分池管理排名，原则上一个场地或俱乐部拥有一个积分池。</p>
                <p>此外，每次乒乓球赛事还会有小积分出现，如：采用单循环赛制时，每次比赛的小积分计算方法为“胜1场得2分，负1场得1分，弃权者得0分”。</p>
                <p>5.积分查询方法：</p>
                <div class="AlignC">
                    <img :src="'UserUpload/CZSM/LookJF.jpg'" class="W90">
                </div>
                <p>6.开放式赛事活动操作方法：</p>
                <div class="AlignC">
                    <img :src="'UserUpload/CZSM/SignUp.jpg'" class="W90">
                </div>
            </div>
        </PopTitle>
        <WXLink :WLandTo="'Act_'+ItemStr" Pic="UserUpload/static/SY/Advise.png" WTitle="泛约网赛事活动总入口"
                WText="内含：活动规则、操作说明与活动目录信息"></WXLink>
    </div>
</template>

<script>
    import {myMongoDBPost, Compare, AutoAlert, GetItemType} from '@/components/SharedVues/Shared0.js'


    import PopTitle from "../MyPopWin/PopTitle";
    import MyInput from "../SharedVues/MyInput";
    import OpenOneAct from "./OpenOneAct";
    import CreateAct from "./CreateAct";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import Act0Main from "./Act0Main";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import Act1Main from "./Act1Main";
    import LoginFY from "../M0_RegLogin/LoginFY";
    import FYReg from "../M0_RegLogin/FYReg";
    import SubEntrance from "../SharedVues/SubEntrance";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import WXLink from "../IndependentVues/WXLink";
    import FYArea from "../SharedVues/FYArea";

    export default {
        name: "ActMain",
        components: {
            FYArea,
            WXLink,
            PopUpDown,
            SubEntrance,
            FYReg,
            LoginFY,
            Act1Main,
            PopOneAN,
            Act0Main,
            SeleOneItem,
            CreateAct,
            OpenOneAct,
            MyInput,
            PopTitle
        },
        props: ['ItemStr'],
        data() {
            return {
                Show: -1,
                Show4: '',
                SeleI: -1,
                InputI: -1,
                Password: -1,
                Acts: [],
                Scale: '',
                Curr: {ItemStr: this.ItemStr, EndBool: 1},
                Title: '最新活动信息',
                // Title1: '',
                EndBools: ['全部', '当前', '过期', '碰撞'],
                Title3: '',
                OpenAct1: false,
                CurrActDoc: {}
            }
        },
        mounted() {
            let that = this;
            this.SetActArea();
            if (that.$store.state.LandTo.split('Act_').length > 2) this.ActsRefresh(function () {
                let aId = that.$store.state.LandTo.split("Act_")[2];
                if (aId !== "Menu") {
                    for (let i = 0; i < that.Acts.length; i++) if (that.Acts[i]._id === aId) that.SeleI = i;
                    if (that.SeleI === -1) AutoAlert('提示', '该项链接已失效!');
                    else {
                        that.SetSeleI(that.SeleI);
                        if (that.$store.state.LandTo.indexOf('Act_Rule') >= 0) that.Show = 3;
                    }
                }
                that.$store.commit('ChangeLandTo', '');
            });


        },
        methods: {
            SetActArea(V) {
                let Val = this.$store.state.FYArea;
                this.Curr.Province = Val.split(":")[1];
                this.Curr.City = Val.split(":")[2];
                this.Curr.DC = Val.split(":")[3];
                if (Val.split(":")[0] === 'N') {
                    delete this.Curr.Province;
                    delete this.Curr.City;
                    delete this.Curr.DC;
                } else if (Val.split(":")[0] === 'P') {
                    delete this.Curr.City;
                    delete this.Curr.DC;
                } else if (Val.split(":")[0] === 'C') delete this.Curr.DC;

                this.ActsRefresh(function () {

                });
            },

            SetCurrEndBool() {
                if (this.Curr.EndBool === 0) this.Curr.EndBool = 1;
                else if (this.Curr.EndBool === 1) this.Curr.EndBool = 2;
                else if (this.Curr.EndBool === 2) this.Curr.EndBool = 3;
                else if (this.Curr.EndBool === 3) this.Curr.EndBool = 0;

                this.ActsRefresh(function () {
                });
            },
            ActsRefresh(CallBack) {
                let that = this;
                that.Acts = [];
                myMongoDBPost("ActsRefresh", {Curr: this.Curr}, function (Docs) {
                    that.Acts = Docs.sort(Compare('Index'));
                    CallBack();
                });
            },

            SetSeleI(index) {
                this.SeleI = index;
                this.Show4 = this.Acts[this.SeleI].ActStyle;
                if (this.Acts[index].HoldStyle === '封闭式') {
                    this.OpenAct1 = true;
                    this.CurrActDoc = this.Acts[this.SeleI];
                    this.Show4 = '';
                }
            },
            MyInputVal(Val) {//凭密进入
                if (this.$store.state.UserName === '游客') AutoAlert('游客操作无效', '请先登录!');
                else if (this.InputI === 1) {
                    this.Password = Val;
                    if (this.Password === this.Acts[this.SeleI].ScaleVal) {
                        this.Show4 = this.Acts[this.SeleI].ActStyle;
                        if (this.Acts[this.SeleI].HoldStyle === '封闭式') this.SetSeleI(this.SeleI);
                    } else AutoAlert('口令不对', '您无权介入本活动！');
                }
                this.InputI = -1;
            },
            SetShow1() {//此时强制只显示自己创建的活动
                this.Curr.UserName = this.$store.state.UserName;
                this.Show = 1;
            },
            Refresh1(Val) {
                let that = this;
                if (Val !== '') {
                    that.CurrActDoc = Val;
                    if (Val.HoldStyle === '封闭式' && Val !== 'SetEndbool') that.OpenAct1 = true;
                    this.ActsRefresh(function () {
                        that.Show = -1;
                    });
                } else that.Show = -1;
            },
            Refresh2() {
                this.SeleI = -1;
                this.Show4 = '';
                this.OpenAct1 = false;
                this.ActsRefresh(function () {

                })
            },
            Delete(Id, index, Title) {
                let that = this;
                let YN = confirm(Title + "===》确认要删除吗？");
                if (YN && Id !== undefined) myMongoDBPost("RemoveDoc/Acts", {_id: Id}, function (data) {//待优化,本活动下相关视频都应删除
                    myMongoDBPost("RemoveDoc/Joins", {ActId: Id}, function (data) {
                        myMongoDBPost("RemoveDoc/Act0Main", {ActId: Id}, function (data) {
                            myMongoDBPost("RemoveDoc/Act1Main", {ActId: Id}, function (data) {
                                myMongoDBPost("RemoveDoc/MsgDetails", {MsgId: Id}, function (data) {
                                    that.Acts.splice(index, 1);
                                })
                            })
                        })
                    })
                })
            }
        }
    }
</script>

<style scoped>
</style>
