<template>
    <div v-if="Role==='平台管理员' || Role==='场地管理员'" title="新场地注册">
        <div class="XLR MP4 TB" @click="Show = 1">
            新场地注册(管理员)
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <SiteCreate v-if="Show === 1" :Recommend="false" @FromSiteCreate="Show=0"></SiteCreate>
        <el-divider/>
    </div>
    <div v-if="Role==='平台管理员'" title="推荐场地信息提取+项目场地列表维护">
        <div class="XLR MP4 TB" @click="Show = 2">
            用户推荐场地信息提取(管理员)
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <PopTitle v-if="Show===2" Title="用户推荐场地信息提取(管理员))" @FromPopUp="Show=0">
            <SitesGetTJ></SitesGetTJ>
        </PopTitle>
        <el-divider/>

        <div class="XLR MP4 TB" @click="Show=3">
            项目场地列表维护
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <PopTitle v-if="Show===3" Title="泛约场地列表" @FromPopUp="Show=0">
            <SitesEdit></SitesEdit>
        </PopTitle>
        <el-divider/>
    </div>
    <div v-if="Role===''" title="项目场地列表查看+喜好场地设置+场地推荐+申请场地管理员资格">
        <div class="XLR MP4 TB" @click="Show=4">
            全部项目场地列表查看
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <PopTitle v-if="Show===4" Title="泛约场地列表查看" @FromPopUp="Show=0">
            <SitesLook></SitesLook>
        </PopTitle>
        <el-divider/>

        <div class="XLR MP4 TB" @click="Show=4">
            我的喜好项目场地
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <PopTitle v-if="Show===4" Title="我的喜好项目场地" @FromPopUp="Show=0">
            <MySites></MySites>
        </PopTitle>
        <el-divider/>

        <div class="XLR MP4 TB" @click="Show=5">
            向平台推荐场地
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <SiteCreate v-if="Show===5" :Recommend="true" @FromSiteCreate="Show=0"></SiteCreate>

        <el-divider/>

        <div class="XLR MP4 TB" @click="Show=6">
            申请场地管理员资格
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
        <PopTitle v-if="Show===6" Title="申请场地管理员资格" @FromPopUp="Show=0">
            <QXAsk QX="场地管理员" @FromQXAsk="Show=0"></QXAsk>
        </PopTitle>
        <el-divider/>


    </div>


</template>

<script>
    import SitesLook from "../M4_Sites/SitesLook";
    import SetMySites from "../M4_Sites/SetMySites";
    import MySites from "../M4_Sites/MySites";
    import SiteCreate from "../M4_Sites/SiteCreate";
    import SitesGetTJ from "../M4_Sites/SitesGetTJ";
    import PopTitle from "../MyPopWin/PopTitle";
    import SitesEdit from "../M4_Sites/SitesEdit";
    import QXAsk from "./QXAsk";

    export default {
        name: "SiteManage",
        components: {QXAsk, SitesEdit, PopTitle, SitesGetTJ, SiteCreate, MySites, SetMySites, SitesLook},
        data() {
            return {
                Role: '',
                Show: 0
            }
        },
        mounted() {
            if (this.$store.state.UserName === this.$store.state.HTM0) this.Role = '平台管理员';
            else if (this.$store.state.UserBools.indexOf('场地管理员') >= 0) this.Role = '场地管理员';
        }
    }
</script>

<style scoped>

</style>