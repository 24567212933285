<template>
    <div>
        <img v-if="Pic2===''" src='../../../static/img/Media/PicColor.png' class="WH99" @click="Active=true">
        <div v-else>
            <ShowPFromThumb v-if="FreshBool"  :Msg="{Pic:Pic2,Update:true}" @FromChildP="Active=true,FreshBool=false"></ShowPFromThumb>
        </div>
        <UpFiles1 v-if="Active" VP="1"  @FromU1="UpEnd"></UpFiles1>
    </div>
</template>

<script>
    import UpFiles1 from "./UpFiles1";
    import ShowPFromThumb from "./ShowPFromThumb";

    export default {
        name: "ShowUpdateP",
        components: {ShowPFromThumb, UpFiles1},
        props: ['Pic'],
        data() {
            return {
                Pic2: '',
                Active: false,
                FreshBool: true
            }
        },
        mounted() {
        this.Pic2=this.Pic;
        },
        methods: {
            UpEnd(VAL) {
                this.Active = false;
                if (VAL === '未选择') this.$emit("ShowUP", {P: this.Pic2}); else {
                    this.Pic2 = VAL;
                    this.FreshBool = true;
                    this.$emit("ShowUP", {P: VAL});
                }
            }
        }
    }
</script>

<style scoped>
</style>

