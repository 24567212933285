<template>
    <div style="background-color: #FFF;padding-top: 2mm">
        <div class="XLR">
            <div class="Sider">
                <div v-for="(Menu, index1) in Menus" :key="index1">
                    <div :class="Menu.length===2 ? 'SideH2':(Menu.length===3 ? 'SideH3':'SideH4')"
                         @click="SetSideSele2(index1)">{{Menu}}
                    </div>
                </div>
            </div>
            <div style="width: 75vw;">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SideMenu2",//区别:SideMenu在驻菜单中应用;SideMenu2在popup弹出窗口中应用和
        props: ['Menus'],
        methods: {
            SetSideSele2(Val) {
                console.log(Val)
                this.$emit("eventFromSideMenu2", Val)
            }
        }
    }
</script>

<style lang="less" scoped>
    .Sider {
        width: 24vw;
    }

    .SideH2 {
        height: 8vh;
    }

    .SideH3 {
        height: 12vh;

    }

    .SideH4 {
        height: 16vh;
    }

    .XYC { /*子元素居中*/
        display: flex;
        flex-direction: column;
        justify-content: center; /*子元素水平居中*/
        align-items: center; /*子元素垂直居中,前提是当前Box有height值*/
    }

    .Side {
        display: block;
        width: 100%;

        padding: 2mm 2mm 0;
        .XYC
    }

    .SideItem {
        border: 1px solid #cccccc;

        /*border-radius: 0.4rem;*/
        /*border-top-right-radius: 0.2em;
        border-bottom-right-radius: 0.2em;*/
        background-color: #ffffff;
        color: #04597d;
        background-clip: padding-box;
        .Side
    }

    .SideSelected {
        background-color: #eeeeee;
        border-width: 1px 0 1px 1px;
        text-align: center;
        color: #68726d;
        .Side;
    }

</style>
