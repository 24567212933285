<template>
    <div class="DispIB">
      <img v-if="TW==='开放'" src="../../../static/img/XMYY/TimeWindowOpen.png" class="WH64">
      <img v-if="TW==='关闭' || TW==='初值'" src="../../../static/img/XMYY/TimeWindowClose.png" class="WH64">
    </div>
</template>

<script>
    export default {
        name: "ShowTimeWin",
      props:['TW']
    }
</script>

<style scoped>

</style>
