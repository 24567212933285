
export default {
  methods: {
    bySelect(CheckedBool, byUserName,AppearanceFee,TimeWinState) {
      let findBool = false;
      //if (CheckedBool && TimeWinState!=='开放') AutoAlert('喜好时间未设定','该被约空闲时间窗可能不匹配!');
      for (let i = 0, byCount = this.SelectedBYs.length; i < byCount; i++) {
        if (this.SelectedBYs[i]._id === byUserName && CheckedBool === false) { //如果一个当前未选中的被约出现在了被约数组中则将其删除掉
          this.SelectedBYs.splice(i, 1);
          return
        }
        if (this.SelectedBYs[i]._id === byUserName) findBool = true;
      }
      if (findBool === false && CheckedBool === true) this.SelectedBYs.push({
        "_id": byUserName,
        "xy": "未响应",
        "z_zg": "初值",
        "z_bylyqk": "初值",
        "b_zg": "初值",
        "b_zylyqk": "初值",
        "zg": "初值",
        "z_ts": "初值",
        "b_ts": "初值",
        "Pic": '',//线下活动情况
        "Video": '',
        "Text": '',
        "AppearanceFee":AppearanceFee
      }); //如果当前选中的被约没有出现在被约数组中则将其追加进去
      this.$emit("eventFromTM", this.SelectedBYs)
    }
  }
}
