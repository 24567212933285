<template>
    <div class="BGY">
        <br>
        <MyDivider :ShowStr="Title"></MyDivider>
        <div v-for="(FYQ,index) in FYQs" :key="index" class="PA2 BottGray">
            <div v-if="$store.state.UserName===$store.state.HTM0 || $store.state.UserName===FYQ.GroupLeader"
                 class="XLR YC">
                <img :src="'UserUpload/static/1/'+FYQ.Pic" class="W16Ha">
                <div class="W80 TC">
                    <div class="ML2" @click="SetNewQ(index)">{{index+1}}.{{FYQ.QTitle}}</div>
                    <div class="XLR YC TF T2">
                        <div class="TE BDCircle BoxBR WH77 XYC MT2" @click="Delete(FYQ._id,index,FYQ.QTitle)">删</div>
                        <div></div>
                        关联项目：{{FYQ.ItemName}}
                        <div></div>
                        群主:{{FYQ.GroupLeader}}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="XLR YC">
                    <div class="W80 TC">
                        {{index+1}}.{{FYQ.QTitle}}
                        <div class="XLR YC TF T2 MT2">
                            关联项目:{{FYQ.ItemName}}&nbsp;&nbsp;群主:{{FYQ.GroupLeader}}
                        </div>
                    </div>
                    <img :src="'UserUpload/1/'+FYQ.Pic" class="W16Ha">
                </div>
            </div>
        </div>
        <div class="PA2 TF">
            <p>提醒：1.各群主应每隔7日更新1次群二维码；2.长按二维码可加入以上群。</p>
        </div>
        <div v-if="$store.state.UserName===$store.state.HTM0" class="XR YC PA3 TG  MT2" @click="Show=1">
            <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;新增
        </div>
        <PopUpDown v-if="Show>0" Title="泛约群信息" :ANs="2" Pos="btt" @FromPop5="NewFYQ">
            <div class="LH200 PA2">
                <div class="XLR YC">
                    群名称：
                    <div class="XL YC">序号：<input type="number" class="W6" v-model.number="NewQ.Index"
                                                 @focus="NewQ.Index=null"/></div>
                    &nbsp;
                </div>
                <input type="text" class="W90" v-model="NewQ.QTitle" @focus="NewQ.QTitle=null"/>
                <div class="XLR YC MTB2">
                    <div>
                        关联项目：
                        <div class="DispIB TG" @click="Show2=1">{{NewQ.ItemName}}▼</div>
                    </div>


                    <div class="XL YC">
                        群二维码：
                        <ShowUpdateP :Pic="NewQ.Pic" @ShowUP="CallBack1"></ShowUpdateP>
                    </div>
                    &nbsp;
                </div>
                群主泛约用户名：<input type="text" class="W26" v-model="NewQ.GroupLeader" @focus="NewQ.GroupLeader=null"/>
                <SeleOneItem v-if="Show2===1" Title="项目名称选择" @FromSeleOneItem="SeleItem"></SeleOneItem>
            </div>
        </PopUpDown>
    </div>
</template>

<script>
    import {AutoAlert, Compare,  myMongoDBPost} from '@/components/SharedVues/Shared0.js'

    import PopUpDown from "../MyPopWin/PopUpDown";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import ShowUpdateP from "../MyPVTs/ShowUpdateP";
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "FYQ",
        components: {MyDivider, ShowUpdateP, SeleOneItem, PopUpDown},
        props: ['Title'],
        data() {
            return {
                Show: -1,
                Show2: -1,
                NewQ: {
                    Index: 0,//排序用
                    QTitle: '',//群标题
                    ItemName: '乒乓球',//群关联项目名称
                    Pic: '',//群二维码,须7天更新1次
                    GroupLeader: this.$store.state.UserName,//群主用户名
                },
                FYQs: [],
                SeleI: -1
            }
        },
        mounted() {
            this.RefreshFYQs();
        },
        methods: {
            Delete(Id, index, Title) {
                let that = this;
                let YN = confirm(Title + "===》确认要删除吗？");
                if (YN && Id !== undefined) myMongoDBPost("RemoveDoc/FYQs", {_id: Id}, function (data) {
                    that.FYQs.splice(index, 1);
                })
            },
            CallBack1(Val) {
                this.NewQ.Pic = Val.P;
            },
            SeleItem(Val) {
                this.NewQ.ItemName = Val.split(":")[1];
                this.Show2 = -1;
            },
            Quit(X, Y) {
                AutoAlert(X, Y);
                this.Show = -1;
            },
            RefreshFYQs() {
                let that = this;
                myMongoDBPost("GetDocs/FYQs", {}, function (Docs) {
                    that.FYQs = Docs.sort(Compare('Index'));
                });
            },
            SetNewQ(index) {
                this.NewQ = this.FYQs[index];
                this.Show = 2;
            },
            NewFYQ(Val) {
                let that = this;
                if (Val === -1) that.Show = -1;
                else if (that.NewQ.QTitle === '') this.Quit('群标题名称尚未输入', '请确保所有选项都已完成！');
                else if (that.NewQ.ItemName === '') this.Quit('群关联项目尚未选择', '请确保所有选项都已完成！');
                else if (that.NewQ.Pic === '') this.Quit('群二维码图片尚未上传', '请确保所有选项都已完成！');
                else if (this.Show === 1) {//'新增'
                    myMongoDBPost("GetDocs/FYQs", {QTitle: that.NewQ.QTitle}, function (Docs) {
                        if (Docs.length > 0) that.Quit('操作无效!', '同一群标题名不得重复创建!');
                        else myMongoDBPost("PutDoc/FYQs", that.NewQ, function (data) {
                            that.Show = -1;
                            that.RefreshFYQs();
                        });
                    });
                } else myMongoDBPost("DocPutBack/FYQs", that.NewQ, function (data) {
                    that.Show = -1;
                    that.RefreshFYQs();
                });
            }
        }
    }


</script>

<style scoped>

</style>
