<template>
    <div class="XR MT2 H3">提示：按拼音顺序排列。</div>
    <div class="MT2 MP4" v-for="(AS,index1) in AllSites" :key="index1">
        <div class="BottGray W26 PA3">{{AS.ItemStr}}：</div>
        <div class="XLR PA2">
            <div class="BoxDot">
                全部场地：
                <div class="DispIB MP1" v-for="(AIS,index2) in AS.ItemInSites" :key="index2">
                    <div @click="SetMySite(AS.ItemStr,AIS)">{{AIS.SiteShortName}}</div>
                </div>
            </div>
            <div class="BoxDot">
                我的场地：
                <div class="DispIB MP1" v-for="(MyS,index3) in AS.MySites" :key="index3">
                    {{MyS.SiteShortName}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {myMongoDBPost, LocaleCompare, CloneObject} from '@/components/SharedVues/Shared0.js'
    import SiteCreate from "./SiteCreate";

    export default {
        name: "MySites",
        components: {SiteCreate},
        data() {
            return {
                AllSites: [],
            }
        },
        mounted() {
            this.Refresh();
        },
        methods: {
            Refresh() {
                let that = this;
                myMongoDBPost("GetAllSites", {UserName: this.$store.state.UserName}, function (AllSites) {
                    that.AllSites = AllSites;
                })
            },
            SetMySite(ItemStr, Site) {
                let that=this ;
                let aObj = {
                    UserName: this.$store.state.UserName,
                    ItemStr: ItemStr,
                    Bool: true,
                    SiteId: Site._id,
                    City: Site.City,
                    SiteShortName: Site.SiteShortName,
                    SiteName: Site.SiteName,
                    Address: Site.Address,
                    Lati: Site.Lati,
                    Longi: Site.Longi,
                    JF: 1600
                };
                myMongoDBPost("UpdateMySite", aObj, function (Docs) {
                    that.Refresh();
                })
            }
        }
    }
</script>

<style scoped>

</style>
