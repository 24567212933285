<template>
    <div class="MaskCss" :style="'z-index:'+MZindex"></div>
    <transition>
        <div v-if="Show7" class="PopWin" :style="'z-index:'+(Zindex+1)+';top:'+NewTop+'px'">
            <div class="BottGray Header" :style="'height: 3rem;z-index:'+ (Zindex+2)">
                <div class="XLR YC MLR4 MT2">
                    <div>
                        <img v-if="Zindex<100" src="../../../static/img/ButtonPic/SY2.png" @click="Return" class="WH77 MT2">
                        <img v-else src="../../../static/img/ButtonPic/CB3.png" @click="Return" class="WH99">
                    </div>
                    {{Title2}}
                    <div></div>
                </div>
            </div>
            <div class="H4"></div>
            <slot></slot>
        </div>
    </transition>

</template>
<script>
    import SubEntrance from "../SharedVues/SubEntrance";

    export default {
        name: "PopTitle",
        components: {SubEntrance},
        props: ['Title'],
        data() {
            return {
                Show7: false,
                Timer: 0,
                MZindex: 0,
                Zindex: 0,
                MoveTo: '',
                windowhistory: null,
                Title2: '',
                NewTop: 0
            }
        },
        computed: {
            CurrBackN() {
                return this.$store.state.BackN
            }
        },
        watch: {
            CurrBackN(Val) {
                if (this.Title.indexOf('步骤') === -1) this.Return();//这里很重要,当Title中有"步骤"二字时,退回按钮交给Step组件的调用者处理
            }
        },
        mounted() {
            this.NewTop = window.scrollTop || document.documentElement.scrollTop;
            console.log(this.NewTop)
            document.body.classList.add('body-locked');

            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex + 50);
            this.Zindex = this.$store.state.CurrZindex;
            this.MZindex = this.$store.state.CurrZindex - 2;
            this.Title2 = (this.Title === '' || this.Title === undefined) ? '' : this.Title.split("(")[0];
            this.Title2 = this.Title2.length > 20 ? this.Title2.substr(0, 20) + '...' : this.Title2;
            this.Show7 = true;
        },
        beforeUnmount() {
            document.body.classList.remove('body-locked');
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex - 50);//切记10000和2000改小了,还不行
            if (this.Timer) clearTimeout(this.Timer);
        },//如果定时器还在运行 或者直接关闭，不用判断
        methods: {
            Return() {
                if (this.$store.state.LandTo.indexOf("MiniPro") >= 0) wx.miniProgram.navigateBack({delta: 0});
                this.Show7 = false;
                let that = this;
                this.Timer = setTimeout(function () {
                    that.$emit("FromPopUp")
                }, 1000)//确保动画完成
            }
        }
    }
</script>

<style>

</style>
