<template>
  <div>
    <PopMenu :Menus="Menus" :BeginN="BeginN" @FromPopMenu="PopMenuVal">
      <div v-if="$store.state.UserName==='游客'" class="MT2 XLR YC">&nbsp;
        <SubEntrance ShowMode="同时显示"></SubEntrance>&nbsp;
      </div>
      <HDZL v-if="MenuN2===0"></HDZL>
      <KJZR v-if="MenuN2===1"></KJZR>
      <div v-if="MenuN2===2">
        <div class="BMP BGGradient">
          <div class="TA MP4 XLR YC MT2 BottGray"> 泛约帮办：
            <div class="AN ANGreen" @click="FYBBShow=true">打开...</div>
          </div>
          <FYBBing></FYBBing>
        </div>
        <br><br><br><br><br>
        <PopTitle v-if="FYBBShow" Title="泛约帮办服务中心" @FromPopUp="FYBBShow=false">
          <FYBB></FYBB>
        </PopTitle>
      </div>
    </PopMenu>
  </div>
</template>
<script>

  import PopMenu from "../src/components/MyPopWin/PopMenu";
  import SubEntrance from "../src/components/SharedVues/SubEntrance";
  import HDZL from "../src/components/QYs/HDZL";
  import PopTitle from "../src/components/MyPopWin/PopTitle";
  import FYBBing from "../src/components/QYs/FYBBing";
  import KJZR from "../src/components/QYs/KJZR";
  import FYBB from "../src/components/QYs/FYBB";

  export default {
    name: "YYFW",
    components: {FYBBing, PopTitle, HDZL, KJZR, FYBB, SubEntrance, PopMenu},
    data() {
      return {
        Menus: ["助手小程序", "快捷找人", "泛约帮办"],
        BeginN: 1,
        MenuN2: 0,
        FYBBShow: false
      }
    },
    methods: {
      PopMenuVal(Val) {
        if (Val === -1) this.$emit('FromYYFW'); else this.MenuN2 = Val;
        this.$nextTick(() => {
        });
      },
    }
  }
</script>

<style scoped>

</style>
