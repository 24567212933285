<template>
  <div class="DispIB T2 TG">
    <div style="position: relative;top:-0.5rem">
      <div v-if="Certified==='成功'">
        <img src="../../../static/img/IDed.png" class="Uwh">
        <span v-if="ShowHZ==='显示汉字'">已认证</span>
      </div>
      <div v-else>
        <div v-if="Certified==='失败'" class="DispIB">
          <img src="../../../static/img/ID2.png" class="Uwh">
          <span v-if="ShowHZ==='显示汉字'">认证失败</span>
        </div>
        <div v-else>
          <img src="../../../static/img/ID3.png" class="Uwh">
          <span v-if="ShowHZ==='显示汉字'">待认证</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserFace1",
    props: ["Certified", "ShowHZ"]
  }
</script>

<style scoped>
</style>
