<template>
    <div>
        <div v-for="(O,index) in Objs" :key="index" class="BottGray" style="margin: 4mm 2mm">
            <div class="PA2 XLR YC">
                {{index+1}}.{{O.Title}}
                <div></div>
                <div v-if="OkBool" class="TE BDCircle BoxBR WH77 XYC" @click="Delete(O._id,index)">删</div>
                <div v-if="OkBool" class="TE BDCircle BoxBR WH77 XYC" @click="SetActive(0,index)" style="margin: 0 1.5mm">&nbsp;改&nbsp;</div>
                &nbsp;
            </div>
            <div class="AN ANYellow MT2">
                <div class="XL YC">
                    <div class="AlignC PA2" v-for="(OP,index2) in O.Pics" :key="index2">
                        <img v-if="OP===''" src="../../../static/img/Media/PicColor.png" class="WH99" @click="SetActive2(3,index,index2)">
                        <div v-else class="DispIB">
                            <ShowPFromThumb :Msg="{Pic:OP,Update:false}"></ShowPFromThumb>
                            <!--img :src="'UserUpload/1/'+OP" @click="ShowPic=OP" class="WHab"/-->&nbsp;&nbsp;
                            <div v-if="OkBool" class="TE BDCircle BoxBR WH77 XYC" @click="Delete1(O._id,index,index2,OP)">删</div>
                        </div>
                        <div class="XL YC">
                            {{index2}}:
                            <input v-if="OkBool" type="text" class="W12" v-model="O.PicNames[index2]" @focus="O.PicNames[index2]=''"
                                   @blur="ChangeNames(1,index,index2,O.PicNames[index2])"/>
                            <span v-else>{{O.PicNames[index2]}}</span>
                        </div>
                    </div>
                    <div v-if="$store.state.UserName!=='游客'" class="PA2 XLR YC" @click="SetActive(1,index)">
                        <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77 ML2">&nbsp;照片
                    </div>
                </div>
            </div>
            <div class="AN ANGreen MT2">
                <div class="XL YC">
                    <div class="AlignC PA2" v-for="(OV,index2) in O.Videos" :key="index2">
                        <img v-if="OV===''" src="../../../static/img/Media/VideoColor.png" class="WH99" @click="SetActive2(4,index,index2)">
                        <div v-else class="DispIB">

                            <ShowVFromThumb :Msg="{Video:OV,Update:false}"></ShowVFromThumb>

                            <!--img :src="'UserUpload/2/S'+OV.split('.')[0] + '.jpg'" @click="ShowVideo=OV" class="WHab"/-->&nbsp;&nbsp;              　
                            <div v-if="OkBool" class="TE BDCircle BoxBR WH77 XYC" @click="Delete2(O._id,index,index2,OV)">删</div>
                        </div>
                        <div class="XL YC">
                            {{index2}}:
                            <input v-if="OkBool" type="text" class="W12" v-model="O.VideoNames[index2]" @focus="O.VideoNames[index2]=''"
                                   @blur="ChangeNames(2,index,index2,O.VideoNames[index2])"/>
                            <span v-else>{{O.VideoNames[index2]}}</span>
                        </div>
                    </div>
                    <div v-if="$store.state.UserName!=='游客'" class="PA2 XLR YC" @click="SetActive(2,index)">
                        <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77 ML2">&nbsp;视频
                    </div>
                </div>
            </div>
        </div>
        <div v-if="1===2" class="PA3 TF">
            <p>备注：0p0,0p1...和0v0,0v1...分别表示相应图片或视频的“引用码”。//O.QuoteCode+'V'</p>
        </div>
        <div class="XR YC">
            <div v-if="OkBool" class="PA3 YC BottO TG" @click="SetActive(0,-1)">
                <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;分类标题
            </div>
        </div>
        <FYQ Title="共享相册关联微信群"></FYQ>
        <br><br>
        <PopUpDown v-if="ShowPic!==''" :ANs='1' Title="照片" Pos="btt" @FromPop5="ShowPic=''">
            <div class="PA2">
                <img :src="'UserUpload/1/'+ShowPic" width="100%" height="auto">
            </div>
        </PopUpDown>
        <PopUpDown v-if="ShowVideo!==''" :ANs='1' Title="视频" Pos="btt" @FromPop5="ShowVideo=''">
            <div class="PA2">
                <video :src="'/UserUpload/2/'+ShowVideo" webkit-playsinline="" playsinline="" x5-playsinline="" width="100%" height="auto" controls></video>
            </div>
        </PopUpDown>
        <PopUpDown v-if="Active===0" :ANs='2' Title="创建分类标题" Pos="btt" @FromPop5="Save">
            <div class="PA3">
                标题名称：<br><input type="text" class="W80 PA3" v-model="CurrObj.Title" @focus="CurrObj.Title=''"/>
            </div>
        </PopUpDown>
        <UpFiles1 v-if="Active===1" VP="1"  @FromU1="SetPV($event,1,SeleI)"></UpFiles1>
        <UpFiles1 v-if="Active===2" VP="2"  @FromU1="SetPV($event,2,SeleI)"></UpFiles1>
        <UpFiles1 v-if="Active===3" VP="1"  @FromU1="SetPV2($event,1,SeleI,SeleI2)"></UpFiles1>
        <UpFiles1 v-if="Active===4" VP="2"  @FromU1="SetPV2($event,2,SeleI,SeleI2)"></UpFiles1>

    </div>
</template>

<script>
    import {
        Compare,
        CurrDateStr,
        StampToDate,
        CloneObject,
        myDataFileGet,
        myMongoDBPost,
        Tofixed
    } from '../SharedVues/Shared0.js'
    import MyInput from "../SharedVues/MyInput";
    import PopTitle from "../MyPopWin/PopTitle";
    import ShowVFromThumb from "../MyPVTs/ShowVFromThumb";
    import ShowUpdatePV from "../MyPVTs/ShowUpdatePV";
    import UploadPV from "../MyPVTs/UploadPV";

    import PopUpDown from "../MyPopWin/PopUpDown";
    import UpFiles1 from "../MyPVTs/UpFiles1";
    import WXLink from "../IndependentVues/WXLink";
    import FYQ from "../IndependentVues/FYQ";
    import ShowPFromThumb from "../MyPVTs/ShowPFromThumb";

    export default {
        name: "GXXCContent",
        components: {
            ShowPFromThumb,
            FYQ,
            WXLink,
            UpFiles1,
            PopUpDown,
            UploadPV,
            ShowUpdatePV,
            ShowVFromThumb,
            PopTitle,
            MyInput
        },
        props: ['ToolDoc'],
        data() {
            return {
                OkBool: this.ToolDoc.UserName === this.$store.state.UserName,
                CurrObj: {
                    ToolsId: this.ToolDoc._id,
                    ToolM: this.ToolDoc.ToolM,
                    Title: '',
                    QuoteCode: 0,
                    Pics: [],
                    Videos: [],
                    PicNames: [],//PicNames和Pics一定要相对应
                    VideoNames: [],//
                },
                Objs: [],
                SeleI: -1,//=-1 新增 >-1 修改
                SeleI2: -1,//Pics或Videos中的序号

                Active: -1,//初值:激活谁
                ShowPic: '',
                ShowVideo: '',
            }
        },
        mounted() {
            this.Refresh();
        },
        methods: {
            SetActive(Active, index) {
                this.Active = Active;
                this.SeleI = index;
                if (Active === 0 && index > -1) this.CurrObj = this.Objs[index];
            },
            SetActive2(Active, index, index2) {
                this.Active = Active;
                this.SeleI = index;
                this.SeleI2 = index2
            },
            Save(Val) {
                let that = this;
                if (Val === 1) {
                    if (this.CurrObj.Title === '') alert('分类标题尚未输入，请输入！'); else {
                        if (this.SeleI === -1) {
                            if (that.CurrObj._id) delete that.CurrObj._id;
                            myMongoDBPost("PutGXXCObjs", that.CurrObj, function (data) {
                                that.Refresh();
                            });
                        } else {
                            myMongoDBPost("UpdateDoc/Objs", {UPTJ: {_id: this.Objs[this.SeleI]._id}, UPObj: {Title: this.CurrObj.Title}}, function (data2) {
                                that.Refresh();
                            });
                        }
                    }
                } else this.Active = -1;

            },
            Delete(Id, index) {
                let that = this;
                if (Id !== undefined) myMongoDBPost("RemoveDoc/Objs", {_id: Id}, function (data) {
                    that.Objs.splice(index, 1);
                })
            },
            Delete1(Id, index, index2, OP) {
                let that = this;
                myDataFileGet("DeleteVPFile/UserUpload/?VP=1&VPFileName=" + OP, function (data) {
                    let NewObj = that.Objs[index];
                    if (index2 === NewObj.Pics.length - 1) {
                        NewObj.Pics.splice(index2, 1);
                        NewObj.PicNames.splice(index2, 1);
                    } else {
                        NewObj.Pics[index2] = '';
                        NewObj.PicNames[index2] = '';
                    }//如果是最后一个，可以删除，中间的就不要删除了
                    that.Objs.splice(index, 1, NewObj);
                    myMongoDBPost("UpdateDoc/Objs", {UPTJ: {_id: Id}, UPObj: NewObj}, function (data2) {
                    });
                });
            },//删除图片后还要占位
            Delete2(Id, index, index2, OV) {
                let that = this;
                myDataFileGet("DeleteVPFile/UserUpload/?VP=2&VPFileName=" + OV, function (data) {
                    let NewObj = that.Objs[index];
                    if (index2 === NewObj.Videos.length - 1) {
                        NewObj.Videos.splice(index2, 1);
                        NewObj.VideoNames.splice(index2, 1);
                    } else {
                        NewObj.Videos[index2] = '';
                        NewObj.VideoNames[index2] = '';
                    }//如果是最后一个，可以删除，中间的就不要删除了
                    that.Objs.splice(index, 1, NewObj);

                    myMongoDBPost("UpdateDoc/Objs", {UPTJ: {_id: Id}, UPObj: NewObj}, function (data2) {
                    });

                });
            },//删除视频后还要占位
            Refresh() {
                let that = this;
                that.Objs = [];
                myMongoDBPost("GetDocs/Objs", {ToolsId: this.ToolDoc._id}, function (Docs) {
                    that.Objs = Docs;
                    that.SeleI = -1;
                    that.Active = -1;
                })
            },
            SetPV(Val, PV, index) {
                let UPObj = null, Name = this.OkBool ? '―' : '待审核';
                if (Val !== '未选择') {
                    if (PV === 1) {
                        this.Objs[index].Pics.push(Val);
                        this.Objs[index].PicNames.push(Name);
                        UPObj = {Pics: this.Objs[index].Pics, PicNames: this.Objs[index].PicNames}
                    } else {
                        this.Objs[index].Videos.push(Val);
                        this.Objs[index].VideoNames.push(Name);
                        UPObj = {Videos: this.Objs[index].Videos, VideoNames: this.Objs[index].VideoNames}
                    }
                    myMongoDBPost("UpdateDoc/Objs", {UPTJ: {_id: this.Objs[index]._id}, UPObj: UPObj}, function (data2) {
                    });
                }
                this.SeleI = -2;
                this.Active = -1;
            },//添加照片和视频
            SetPV2(Val, PV, index, index2) {
                let UPObj = null;
                if (Val !== '未选择') {
                    if (PV === 1) {
                        this.Objs[index].Pics[index2] = Val;
                        UPObj = {Pics: this.Objs[index].Pics}
                    } else {
                        this.Objs[index].Videos[index2] = Val;
                        UPObj = {Videos: this.Objs[index].Videos}
                    }
                    myMongoDBPost("UpdateDoc/Objs", {UPTJ: {_id: this.Objs[index]._id}, UPObj: UPObj}, function (data2) {
                    });
                }
                this.SeleI = -2;
                this.Active = -1;
            },//更新照片和视频
            ChangeNames(PV, index, index2, Name) {
                let UPObj = null;
                if (PV === 1) {
                    this.Objs[index].PicNames[index2] = Name;
                    UPObj = {PicNames: this.Objs[index].PicNames}
                } else {
                    this.Objs[index].VideoNames[index2] = Name;
                    UPObj = {VideoNames: this.Objs[index].VideoNames}
                }
                myMongoDBPost("UpdateDoc/Objs", {UPTJ: {_id: this.Objs[index]._id}, UPObj: UPObj}, function (data2) {
                });
            }
        }
    }
</script>

<style scoped>

</style>
