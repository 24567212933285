<template>
  <div class="DispIB">
    <img v-if="Certified==='成功'" src="../../../static/img/IDed.png" width="16">
    <div v-else >
      <img v-if="Certified==='失败'" src="../../../static/img/ID2.png" width="16">
      <img v-else src="../../../static/img/ID3.png" width="16">
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserFace3",
    props: ["Certified"]
  }
</script>

<style scoped>

</style>
