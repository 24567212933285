<template>
    <div>
        <div v-if="$store.state.UserName !== $store.state.HTM0" class="MT2" style="font-weight: bolder;">
            <MyDivider ShowStr="泛约币充值"></MyDivider>
            <div class="XLR YC MP4">
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(20)"> 20元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(60)"> 60元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(100)">100元</div>
            </div>
            <div class="XLR YC MP4">
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(200)">200元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(600)">600元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(980)">980元</div>
            </div>
        </div>
        <div v-if="$store.state.UserName === $store.state.HTM0" class="BottGray">
            <div class="BGLightGray BD_Radius1 PA2">
                <MyDivider ShowStr="充值用户列表"></MyDivider>
                <table>
                    <thead class="LH200">
                    <tr class="TF">
                        <th>
                            <div class=" LH120 MTB2">泛约用户名<br>微信昵称</div>
                        </th>
                        <th>
                            <div class="LH120 MTB2">留言信息<br>泛约币数量</div>
                        </th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(CZ,index) in CZs" :key="index">
                        <td>
                            <div class=" LH120">{{CZ.UserName}}<br>{{CZ.Title}}</div>
                        </td>
                        <td class="LH120">
                            <div class="T3">{{CZ.Text}}</div>
                            <input type="number" class="W12" v-model.number="CZ.ZXn" @focus="CZ.ZXn=null"/>个
                        </td>
                        <td class=" LH150">
                            <div v-if="CZ.EndBool===1" class="XLR YC">
                                <div></div>
                                <div class="PA2 BD_Radius1 BGGray TW T3 LH120" @click="Delete1(index)">取消</div>
                                <div class="PA2  BD_Radius1 BGBlue TW T3 LH120" @click="SetEndBool1(index)">充值</div>
                                <div></div>
                            </div>
                            <div v-else class="TF MTB2">已充</div>
                            <div class="T2">{{StampToTimeStr(CZ.Time)}}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="BGLightGray PA3 MT2">
                <div class="XLR YC MTB2">
                    泛约币转让☞
                    <div class="AN ANBlue" @click="MoveZX(aUserName,ZXn,1)">确认</div>
                </div>
                <div class="XLR YC MTB2 TF">
                    <div>向：<input type="text" class="W26 MT2" v-model="aUserName" placeholder="泛约用户名"/></div>
                    <div>转让泛约币：<input type="number" class="W12 MT2" v-model.number="ZXn" @focus="ZXn=null"/>个</div>
                </div>
            </div>
        </div>
        <div v-else class="TC BGO PA2 XLR YC">
            <div></div>
            1元=10个泛约币
            <div class="TC LH120" style="font-weight: bolder"> {{'现有：'+$store.state.ZX+'个'}}</div>
            <div></div>
        </div>
        <div v-if="$store.state.UserName === $store.state.HTM0" class="BGLightGray BD_Radius1 PA2">
            <MyDivider ShowStr="兑现用户列表"></MyDivider>
            <br>
            <table>
                <thead class="LH200">
                <tr class="TF">
                    <th>序号</th>
                    <th>
                        <div class="LH120 MTB2">用户名<br>泛约币</div>
                    </th>
                    <th>收款码</th>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody class="LH200">
                <tr v-for="(ZX,index) in ZXs" :key="index">
                    <td>{{index+1}}</td>
                    <td>
                        <div class="LH150 MTB2">{{ZX.UserName}}<br>{{ZX.ZXn}}个</div>
                    </td>
                    <td><img :src="'UserUpload/1/'+ZX.Pic" style="width: 16mm;height: auto"></td>
                    <td>
                        <div v-if="ZX.EndBool===1" class="XLR YC">
                            <div class="PA2 BD_Radius1 BGGray TW T4 LH120" @click="Delete2(index)">取<br>消</div>
                            <div class="PA2  BD_Radius1 BGBlue TW T4 LH120" @click="SetEndBool2(index)">已<br>兑</div>
                        </div>
                        <div v-else class="TF MTB2">已兑</div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="BottGray XLR YC H3 MTB2 BGWhite" @click="OpenMyZXAccount=!OpenMyZXAccount">&nbsp;
                <div class="T4 TG ML2">我的泛约币收支明细</div>
                <div></div>
                <div v-if="OpenMyZXAccount">
                    <el-icon><ArrowUp/></el-icon>
                </div>
                <div v-else>
                    <el-icon><ArrowDown/></el-icon>
                </div>
            </div>
        </div>
        
        <div v-if="OpenMyZXAccount">
            <MyDivider ShowStr="收支明细"></MyDivider>
            <ZXAccounts></ZXAccounts>
        </div>
        <div v-if="$store.state.UserName !== $store.state.HTM0" class="BGLightGray BD_Radius1 PA3 MT2">
            <MyDivider ShowStr="我要退币"></MyDivider>
            <div class="XLR YC MTB2">
                退回泛约币换成人民币☞
                <div class="TF">
                    个数：<input type="number" class="W12" v-model.number="CurrQYDoc.ZXn" @focus="CurrQYDoc.ZXn=null"
                              style="height: 2rem"/>
                </div>
            </div>
            <!--div class="XLR YC MTB2">
              <div class="XL YC TF ">
                您的绿色二维收款码：
                <ShowUpdateP :Pic="CurrQYDoc.Pic" @ShowUP="CallBack1"></ShowUpdateP>
              </div>
              <div class="PA2 BD_Radius1 BGBlue TW" @click="Submit()">提交</div>
            </div-->
            <div class="XLR YC MTB2">
                别着急退嘛！😂😃😄‌😅‌😅☺️
                <div >给你发个<el-button type="danger" @click="Submit()">红包</el-button></div>
            </div>
        </div>
        <br>
        
        <div v-if="1===2" class="BottGray XLR YC H3 MTB2" @click="OpenZXrules=true">&nbsp;
            <div class="T4 TG ML2">“平台内应用”泛约币流通规则</div>
            <div></div>
            <el-icon><ArrowRight/></el-icon>
        </div>
        <div v-if="$store.state.UserName === $store.state.HTM0">
            <el-divider> • 平台内流通泛约币 •</el-divider>
            <div class="XLR YC PA3">
                <div>注册用户数：{{SumUsers}}</div>
                总量：{{SumZX}}
            </div>
            <table>
                <thead>
                <tr class="TF ">
                    <th>序号</th>
                    <th>
                        <div class="LH200">泛约用户名</div>
                    </th>
                    <th>微信昵称</th>
                    <th>泛约币1</th>
                    <!--th>操作</th-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="(UZX,index) in UserZXs" :key="index">
                    <td>{{index+1}}</td>
                    <td class="LH150">{{UZX.UserName}}</td>
                    <td>{{UZX.WXNickName}}</td>
                    <td>{{UZX.ZX}}</td>
                    <!--input type="number" class="MP4 W12" @focus="UZX.ZX=null" v-model.number="UZX.ZX"/-->
                    
                    <!--td>
                      <div class="AN ANBlue" @click="ChangeZX(UZX.Id,index,UZX.ZX)">确认</div>
                    </td-->
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="1===2" class="MTB2">
            <div class="MLR4 MT2" style="background: 0 0 / 100% 100% url('../../../static/img/MyFrame.png') no-repeat;">
                <br><br><br>
                <div class="XLR YC">&nbsp;
                    <div class="AlignC W26">
                        <div class="BottGray T3">&nbsp;注册用户&nbsp;</div>
                        <div class="TE T8 MT2">{{SumUsers}}</div>
                    </div>&nbsp;
                    <div class="AlignC W26">
                        <div class="BottGray T3">&nbsp;泛约币总量&nbsp;</div>
                        <div class="TE T8 MT2">{{SumZX}}</div>
                    </div>&nbsp;
                    <div class="AlignC W26" @click="OpenMyZXAccount=true">
                        <div class="BottGray T3">&nbsp;我的泛约币&nbsp;</div>
                        <div class="TE T8 MT2">{{$store.state.ZX}}</div>
                    </div>&nbsp;
                </div>
                <br><br>
            </div>
        </div>
        
        <PopTitle v-if="OpenZXrules" Title="“平台内应用”泛约币流通规则" @FromPopUp="OpenZXrules=false">
            <ZXrules></ZXrules>
        </PopTitle>
    </div>
</template>

<script>
    import {
        myMongoDBPost, myWXget, weixnVersion, WXKeepA,
        ReGetZX,
        StampToTimeStr,
        MyUrl,
        SendWXMsg,
        SendWXPicMsg,
        KeepZX,
        TransferZX,
        Compare, getUniqueCode, MyRandomCode
    } from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "../MyPVTs/ShowUpdatePVT";

    import ZXAccounts from "./ZXAccounts";
    import PopTitle from "../MyPopWin/PopTitle";
    import ZXrules from "./ZXrules";
    import ShowUpdateP from "../MyPVTs/ShowUpdateP";

    import MyDivider from "../SharedVues/MyDivider";

    export default {//泛约币管理
        name: "ZXGL",
        components: {MyDivider, ShowUpdateP, ZXrules, PopTitle, ZXAccounts, ShowUpdatePVT},
        data() {
            return {
                SumUsers: 0,
                SumZX: 0,
                CurrQYDoc: {
                    UserName: this.$store.state.UserName,
                    Certified: this.$store.state.Certified,
                    UserLogo: this.$store.state.UserLogo,

                    Channel: '泛约币兑现',
                    Type: '',
                    Key3: null,
                    Title: '',

                    Province: this.$store.state.FYArea.split(":")[1],
                    City: this.$store.state.FYArea.split(":")[2],
                    DC: this.$store.state.FYArea.split(":")[3],

                    ZXn: null,
                    Pic: '',
                    Video: '',
                    Text: '',
                    Time: (new Date()).getTime(),
                    Strength: '',
                    EndBool: 1//1=未完成　2=完成
                },
                CZs: [],
                ZXs: [],
                OpenMyZXAccount: this.$store.state.UserName !== this.$store.state.HTM0,
                OpenZXrules: false,
                aUserName: '',
                ZXn: 0,
                UserZXs: []
            }
        },
        mounted() {
            this.GetUserZXs();
            this.Refresh();
            document.getElementById('TitleId').innerText = '泛约币管理中心';
        },
        beforeUnmount() {
            let that = this;
            myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs1) {
                that.$store.commit('ChangeZX', Docs1[0].ZX);
            })
        },
        methods: {
            WXCZ(WXCZ0) {
                let that = this;
                that.OpenMyZXAccount = false;
                if (weixnVersion() === -1) alert("无效操作：微信支付必须通过微信公众号进行操作!"); else {
                    if (parseInt(weixnVersion().split(".")[0]) < 5) return alert("5.0以下微信版本不支持微信支付功能!");
                    if (that.$store.state.UserName === "游客") return alert("游客用户不能进行微信支付!");
                    if (WXCZ0 < 0.01) return alert("本支付金额不被支持!");
                    myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs) {
                        let openid = Docs[0].WXId;
                        if (openid === 'Error!') return alert("获取用户微信id号失败!");
                        let WXOut_Trade_No = 'WX' + getUniqueCode();//微信商户订单号
                        myWXget("GetWeixinJSBridgeData/" + MyRandomCode(30) + "/" + (parseInt(WXCZ0 * 100)) + "/" + WXOut_Trade_No + "/" + openid, function (aObj) {//1.统一下单
                            //在微信浏览器里面打开H5网页中执行JS调起支付。接口输入输出数据格式为JSON。 注意：WeixinJSBridge内置对象在其他浏览器中无效。
                            function onBridgeReady() {
                                WeixinJSBridge.invoke('getBrandWCPayRequest', aObj, function (res) {
                                        WXKeepA(that, that.$store.state.UserName, '收', WXCZ0, '泛约币充值', res.err_msg, WXOut_Trade_No, MyRandomCode(30), function (data) {
                                            that.OpenMyZXAccount = true;
                                        })
                                    }
                                );
                            }

                            if (typeof WeixinJSBridge === "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                }
                            } else {
                                onBridgeReady();
                            }
                        });
                    });
                }

            },
            WXCZ_old(WXCZ0) {
                let that = this;
                that.OpenMyZXAccount = false;
                if (this.$store.state.UserName === "游客") return alert("游客用户不能进行微信支付!");
                if (WXCZ0 < 0.01) return alert("本支付金额不被支持!");
                console.log("this.$store.state.WXId=" + this.$store.state.WXId, WXCZ0);
                let WXOut_Trade_No = 'WX' + getUniqueCode();//微信商户订单号
                myWXget("GetWeixinJSBridgeData/" + MyRandomCode(30) + "/" + (WXCZ0 * 100) + "/" + WXOut_Trade_No + "/" + this.$store.state.WXId, function (aObj) {//1.统一下单
                    //在微信浏览器里面打开H5网页中执行JS调起支付。接口输入输出数据格式为JSON。 注意：WeixinJSBridge内置对象在其他浏览器中无效。
                    function onBridgeReady() {
                        WeixinJSBridge.invoke('getBrandWCPayRequest', aObj, function (res) {

                                //alert("res.err_msg="+res.err_msg);
                                if (res.err_msg === "get_brand_wcpay_request:ok") {
                                    KeepZX(that, that.$store.state.UserName, '收', WXCZ0 * 10, "购买泛约币", function () {
                                        that.OpenMyZXAccount = true;
                                    });
                                }     // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。

                            }
                        );
                    }

                    if (typeof WeixinJSBridge === "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                    } else {
                        onBridgeReady();
                    }
                });
            },
            GetUserZXs() {
                let that = this;
                that.UserZXs = [];
                myMongoDBPost("GetDocs/fyuser0", {}, function (Docs1) {
                    that.UsersN = Docs1.length;
                    myMongoDBPost("GetAggregate/fyuser0", {
                        $group: {
                            _id: "",
                            SumUsers: {$sum: 1},
                            SumZX: {$sum: "$ZX"},
                        }
                    }, function (Docs2) {
                        that.SumUsers = Docs2[0].SumUsers;
                        that.SumZX = Docs2[0].SumZX;
                    });

                    for (let i = 0; i < Docs1.length; i++)
                        if (Docs1[i].ZX > 0) that.UserZXs.push({
                            Id: Docs1[i]._id,
                            UserName: Docs1[i].UserName,
                            WXNickName: Docs1[i].WXNickName,
                            ZX: Docs1[i].ZX
                        });

                    /*
                              let i = 0;
          
                              function r() {
                                if (i < Docs1.length) {
                                  myMongoDBPost("GetZXs", {fyUserName: Docs1[i].UserName}, function (data) {
                                    if (data.length > 0) {
                                      let ZX2s = data.sort(Compare('_id')).reverse();
                                      that.UserZXs.push({
                                        Id: Docs1[i]._id,
                                        UserName: Docs1[i].UserName,
                                        WXNickName: Docs1[i].WXNickName,
                                        ZX: Docs1[i].ZX,
                                        ZX2: ZX2s[0].Balance1
                                      });
                                      myMongoDBPost("UpdateDoc/fyuser0", {
                                        UPTJ: {UserName: Docs1[i].UserName},
                                        UPObj: {ZX: ZX2s[0].Balance1}
                                      }, function (data2) {
                                        i++;
                                        r()
                                      })
                                    } else {
                                      i++;
                                      r()
                                    }
          
                                  })
                                }
                              }
          
                              r()
                    */
                });
            },
            SendWXPicMsg() {
                SendWXPicMsg(this.$store.state.UserName, '收款码');
            },
            CallBack1(Val) {
                this.CurrQYDoc.Pic = Val.P;
            },
            StampToTimeStr(Stamp) {
                return StampToTimeStr(Stamp)
            },
            MoveZX(aUserName, ZXn, Bool) {
                let that = this;
                if (ZXn > 0) myMongoDBPost("GetDocs/fyuser0", {UserName: aUserName}, function (Docs1) {
                    if (Docs1.length === 0) alert('转让失败：泛约用户名[' + aUserName + ']不存在!'); else {
                        let Time = StampToTimeStr((new Date()).getTime()).split(" ");
                        let Url = MyUrl('LandTo=ZXGL');
                        let fyM0Url = '[ ' + '<a href="' + Url + '">查看一下</a>' + ' ]';
                        if (Bool === 1) {
                            let aTip = '成功补充' + ZXn + '个泛约币!';
                            KeepZX(that, aUserName, '收', ZXn, aTip, function (StrZX) {
                                if (StrZX !== "无效") myMongoDBPost("UpdateDoc/fyuser0", {
                                    UPTJ: {UserName: aUserName},
                                    UPObj: {XYZ: {Tip: aTip, Time1: Time[0], Time2: Time[1]}}
                                }, function (data2) {
                                    alert('泛约币补充成功：已通知“' + aUserName + '”');
                                    SendWXMsg(aUserName, aTip + fyM0Url);
                                })
                            });
                        }
                        if (Bool === 2)
                            myMongoDBPost("UpdateDoc2/fyuser0", {
                                UPTJ: {UserName: aUserName},
                                UPObj: {$inc: {ZX: ZXn}}
                            }, function (data2) {
                                TransferZX(that, aUserName, that.$store.state.UserName, ZXn, function (StrZX) {
                                    let aTip = ZXn + '个泛约币被回收';
                                    if (StrZX === "泛约币转移成功!") myMongoDBPost("UpdateDoc/fyuser0", {
                                        UPTJ: {UserName: aUserName},
                                        UPObj: {XYZ: {Tip: aTip, Time1: Time[0], Time2: Time[1]}}
                                    }, function (data2) {
                                        alert('泛约币兑现操作完毕：已通知对方!');
                                        SendWXMsg(aUserName, aTip + fyM0Url);
                                    })
                                });
                            });
                        that.aUserName = '';
                        that.ZXn = 0;
                    }
                }); else alert('失败：请输入泛约币数量!');
            },
            Refresh() {
                let that = this;
                myMongoDBPost("GetDocs/QYs", {Channel: '泛约币兑现', EndBool: 1}, function (Docs1) {
                    if (Docs1.length > 0) that.ZXs = Docs1.sort(Compare('Time')).reverse();//点操作符　
                });
                myMongoDBPost("GetDocs/QYs", {Channel: '泛约币充值', EndBool: 1}, function (Docs2) {
                    if (Docs2.length > 0) that.CZs = Docs2.sort(Compare('Time')).reverse();//点操作符
                });
                ReGetZX(that, function (ZX) {
                })
            },
            Submit() {
                let that = this;
                if (this.$store.state.UserName === '游客') alert("游客操作无效");
                else if (this.CurrQYDoc.ZXn === null || this.CurrQYDoc.ZXn < 1) alert("请输入泛约币数量");
                else if (that.$store.state.ZX < this.CurrQYDoc.ZXn) alert('您的泛约币数量不足：无法兑现!');
                else myMongoDBPost("GetDocs/Cards", {CUserName: this.$store.state.UserName, Bool: '待刷卡'}, function (Docs1) {
                        if (Docs1.length > 0) {
                            alert('您在“' + Docs1[0].SiteShortName + '”有个“待刷卡”, 须先取消或刷卡后，才能进行兑现操作！')
                        } else {
                            that.CurrQYDoc.Channel = '泛约币兑现';
                            myMongoDBPost("PutDoc/QYs", that.CurrQYDoc, function (data) {
                                myMongoDBPost("UpdateDoc2/fyuser0", {
                                    UPTJ: {UserName: that.$store.state.UserName},
                                    UPObj: {$inc: {ZX: -1 * that.CurrQYDoc.ZXn}}//先减去就是冻结
                                }, function (data2) {
                                    that.$store.commit('ChangeZX', that.$store.state.ZX - that.CurrQYDoc.ZXn);
                                    alert('泛约币兑现申请已提交。数量:' + that.CurrQYDoc.ZXn + '个，当前：10泛约币=1元');
                                })
                            });
                        }
                    });
            },
            Delete1(index) {
                let Id = this.CZs[index]._id;
                this.CZs.splice(index, 1);
                myMongoDBPost("RemoveDoc/QYs", {_id: Id}, function (data2) {
                })
            },
            Delete2(index) {
                let that = this, Id = this.ZXs[index]._id;
                myMongoDBPost("UpdateDoc2/fyuser0", {
                    UPTJ: {UserName: that.ZXs[index].UserName},
                    UPObj: {$inc: {ZX: that.ZXs[index].ZXn}}
                }, function (data2) {
                    myMongoDBPost("RemoveDoc/QYs", {_id: Id}, function (data2) {
                        that.ZXs.splice(index, 1);
                    })
                })
            },
            SetEndBool1(index) {
                let aOBJ = this.CZs[index];
                aOBJ.EndBool = 2;
                this.CZs.splice(index, 1, aOBJ);
                this.MoveZX(aOBJ.UserName, aOBJ.ZXn, 1);
                myMongoDBPost("UpdateDoc/QYs", {UPTJ: {_id: aOBJ._id}, UPObj: {EndBool: 2}}, function (data2) {
                })
            },//充值
            SetEndBool2(index) {
                let aOBJ = this.ZXs[index];
                aOBJ.EndBool = 2;
                this.ZXs.splice(index, 1, aOBJ);
                this.MoveZX(aOBJ.UserName, aOBJ.ZXn, 2);
                myMongoDBPost("UpdateDoc/QYs", {UPTJ: {_id: aOBJ._id}, UPObj: {EndBool: 2}}, function (data2) {
                })
            }//兑现
        },
    }
</script>

<style scoped>

</style>
