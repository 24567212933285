<template>
  <div>
    <PopUpDown v-if="ShowPopup" Title="子活动信息创建" Pos="btt" :ANs="2" @FromPop5="SaveAct1Doc">
      <div class="MP4">
        <div class="XLR YC">
          <div>类型:
            <div class="DispIB BDGreen MP4 TB T4" @click="Show=1">{{Act1Doc.SubActType===''?"—":Act1Doc.SubActType}}
            </div>
          </div>
          <MySelectStr v-if="Act1Doc.SubActType==='团体淘汰赛'" CallFrom="Act1Create" :Msg="['5局3胜','3局2胜']" :CurrN="CurrN"
                       @eventFromChild="SetRule"></MySelectStr>
          <div v-else></div>
        </div>
        <MySele1 v-if="Show===1" :Opts="SubActTypes" :ColN="2" @FromMySele1="SetSubActType"></MySele1>
        <div v-if="Act1Doc.SubActType.indexOf('团体')===-1" class="MT2">标题：<input type="text" class="W86"
                                                                                v-model="Act1Doc.Title"/></div>
        <div class="MT2">计分：<input type="text" class="W50" v-model="Act1Doc.UserName" @focus="Act1Doc.UserName=''"
                                   @blur="Check1(Act1Doc.UserName)"/></div>
        <div v-if="Act1Doc.SubActType.indexOf('循环排名赛')>=0">
          <div class="XLR YC MR4 MT2">
            <div class="PA3 TF BottO">参赛队员：</div>
            <div></div>
            <div @click="InputI=4" class="TG XL YC">
              <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;添加
            </div>
            <div @click="InputI=6" class="TG XL YC">
              <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;选择
            </div>
          </div>
          <div v-for="(A,i) in Act1Doc.As" :key="i" class="DispIB TC">
            {{A}}
            <div class="T5 TE DispIB" @click="AsBsDelete('A',i)">ㄨ</div>
            ；&nbsp;
          </div>
        </div>
        <div v-if="Act1Doc.SubActType.indexOf('团体')>=0">
          <div class="XLR YC LH12">
            <div class="MT2 XLR YC">
              <div class="TG" @click="Show=2">主队<br>简称</div>
              ：<input type="text" class="PA3 W20" v-model="Act1Doc.A" @focus="Act1Doc.A=''"
                      @blur="ColTitle=Act1Doc.A+':'+Act1Doc.B"/></div>
            <div class="MT2 XLR YC">
              <div class="TG" @click="Show=3">客队<br>简称</div>
              ：<input type="text" class="PA3 W20" v-model="Act1Doc.B" @focus="Act1Doc.B=''"
                      @blur="ColTitle=Act1Doc.A+':'+Act1Doc.B"/></div>
          </div>
          <MySele1 v-if="Show===2 || Show===3" :Opts="BMList1" :ColN="2" @FromMySele1="SetMembers"></MySele1>
          <div class="MT2">标题：<input type="text" class="PA2 W86" v-model="Act1Doc.Title"/></div>
          <div class="XLR YC MR4 MT2">
            <div class="PA3 TF BottO">主队参赛队员：</div>
            <div></div>
            <div @click="InputI=4" class="TG XL YC">
              <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;输入
            </div>
            <div @click="InputI=6" class="TG XL YC">
              <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;选择
            </div>
          </div>
          <div v-for="(A,i) in Act1Doc.As" :key="i" class="DispIB TC">
            {{i+1}}.{{A}}
            <div class="T5 TE DispIB" @click="AsBsDelete('A',i)">ㄨ</div>
            ；&nbsp;
          </div>
          <br>
          <div class="XLR YC MR4">
            <div class="PA3 TF BottO">客队参赛队员：</div>&nbsp;
            <div @click="InputI=5" class="TG XL YC">
              <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;输入
            </div>
            <div @click="InputI=7" class="TG XL YC">
              <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;选择
            </div>
          </div>
          <div v-for="(B,i) in Act1Doc.Bs" :key="i" class="DispIB TC">
            {{i+1}}.{{B}}
            <div class="T5 TE DispIB" @click="AsBsDelete('B',i)">ㄨ</div>
            ；&nbsp;
          </div>
        </div>
      </div>

      <MyInput v-if="InputI===4 || InputI===5" Title="新队员名称" Type="text" :Begin="''" @FromMyI="MyInputVal"></MyInput>
      <MySele3 v-if="InputI===6 || InputI===7" :Opts="InputI===6 ? BMAsUsers:BMBsUsers"
               @FromMySele3="MyInputVal"></MySele3>
    </PopUpDown>

  </div>
</template>

<script>
  import {myMongoDBPost, AutoAlert, CloneObject} from '@/components/SharedVues/Shared0.js'
  import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import MyInput from "../SharedVues/MyInput";
  import MySele1 from "../SharedVues/MySele1";
  import SeleOneItem from "../FrontPage/SeleOneItem";
  import PopUpDown from "../MyPopWin/PopUpDown";
  import MySelectStr from "../SharedVues/MySelectStr";
  import MySele3 from "../SharedVues/MySele3";

  export default {
    name: "Act1Create",
    components: {MySele3, MySelectStr, PopUpDown, SeleOneItem, MySele1, MyInput, PopTitle, ShowUpdatePVT},
    props: ['OP', 'Msg'],
    data() {
      return {
        Show: -1,
        ShowPopup: false,
        SubActTypes: ['单循环排名赛', '双循环排名赛', '团体对抗赛', '团体淘汰赛'],
        CurrN: 0,
        Act1Doc: {
          ActId: this.Msg.ActId,
          UserName: this.$store.state.UserName,
          Title: this.OP === '新增' ? '' : this.Msg.Title,
          SubActType: this.OP === '新增' ? '' : this.Msg.SubActType,
          Rule: this.OP === '新增' ? '3局2胜' : this.Msg.Rule,
          TableCols: this.OP === '新增' ? [] : this.Msg.TableCols,
          As: this.OP === '新增' ? [] : this.Msg.As,
          Bs: this.OP === '新增' ? [] : this.Msg.Bs,
          A: this.OP === '新增' ? '' : this.Msg.A,
          B: this.OP === '新增' ? '' : this.Msg.B,
          PABs: this.OP === '新增' ? [] : this.Msg.PABs,
          VABs: this.OP === '新增' ? [] : this.Msg.VABs,
          BMAs: this.OP === '新增' ? [] : this.Msg.BMAs,//当前子活动As的预报名名单库:[{UserName:?,UserLogo:?,Sex:?,Age:?}]
          BMBs: this.OP === '新增' ? [] : this.Msg.BMBs,//当前子活动Bs的预报名名单库:[{UserName:?,UserLogo:?,Sex:?,Age:?}]
        },
        InputI: -1,
        ColTitle: '',

        BMList: [],//来自ActDoc
        BMList1: [],//只有队名

        BMAsUsers: [],
        BMBsUsers: [],
      }
    },
    mounted() {
      console.log(this.Act1Doc)
      this.$nextTick(() => {
        if (this.OP === '修改') this.Act1Doc = this.Msg;
        for (let i = 0; i < this.Act1Doc.BMAs.length; i++) this.BMAsUsers.push(this.Act1Doc.BMAs[i].UserName);
        for (let i = 0; i < this.Act1Doc.BMBs.length; i++) this.BMBsUsers.push(this.Act1Doc.BMBs[i].UserName);
        console.log(this.Act1Doc.BMAs)
        console.log(this.BMAsUsers)
        this.ShowPopup = true;
        if (this.Act1Doc.Rule === '3局2胜') this.CurrN = 1;
      });
      let that = this;
      myMongoDBPost("GetDocs/Acts", {_id: this.Msg.ActId}, function (Docs) {
        that.BMList = Docs[0].BMList;
        for (let i = 0; i < Docs[0].BMList.length; i++) that.BMList1.push(Docs[0].BMList[i].TeamName);

      })
    },
    methods: {
      SetMembers(Val) {
        for (let i = 0; i < this.BMList.length; i++) if (this.BMList[i].TeamName === Val) {//找到那个队
          if (this.Show === 2) {
            this.Act1Doc.A = Val;
            for (let j = 0; j < this.BMList[i].Members.length; j++)
              if (this.BMAsUsers.indexOf(this.BMList[i].Members[j]) === -1) this.BMAsUsers.push(this.BMList[i].Members[j]);
            for (let k = 0; k < this.BMAsUsers.length; k++)
              this.Act1Doc.BMAs.push({UserName: this.BMAsUsers[k], UserLogo: '?', Sex: '?', Age: '?'});
          }
          if (this.Show === 3) {
            this.Act1Doc.B = Val;
            for (let j = 0; j < this.BMList[i].Members.length; j++)
              if (this.BMBsUsers.indexOf(this.BMList[i].Members[j]) === -1) this.BMBsUsers.push(this.BMList[i].Members[j]);
            for (let k = 0; k < this.BMBsUsers.length; k++)
              this.Act1Doc.BMBs.push({UserName: this.BMBsUsers[k], UserLogo: '?', Sex: '?', Age: '?'});
          }
        }
        this.ColTitle = this.Act1Doc.A + ':' + this.Act1Doc.B;
        this.Act1Doc.Title = this.Act1Doc.A + 'vs' + this.Act1Doc.B;
        this.Show = -1;
      },
      SetRule(Val) {
        this.Act1Doc.Rule = Val;
      },
      SaveAct1Doc(Val) {
          function JFQ3TableCols() {
              AD.TableCols = [];
              if (AD.As.length >= 3 && AD.Bs.length >= 3) {//用于记分器JFQ3时直接保存二维数组数据到TableCols中
                  AD.TableCols.push({Vs: AD.As[0] + ":" + AD.Bs[0], BF: '―'});//第一场
                  AD.TableCols.push({Vs: AD.As[1] + ":" + AD.Bs[1], BF: '―'});//第二场
                  AD.TableCols.push({Vs: AD.As[2] + ":" + AD.Bs[2], BF: '―'});//第三场
                  if (AD.Rule === '3局2胜') {
                      AD.TableCols.push({Vs: '―', BF: '―'});//第四场
                      AD.TableCols.push({Vs: '―', BF: '―'});//第五场
                  } else {
                      AD.TableCols.push({Vs: AD.As[0] + ":" + AD.Bs[1], BF: '―'});//第四场
                      AD.TableCols.push({Vs: AD.As[1] + ":" + AD.Bs[0], BF: '―'});//第五场
                  }
                  AD.TableCols.push({Vs: that.Act1Doc.A + ":" + that.Act1Doc.B, BF: '―'});//记录主客队名称及总比分
              } else {
                  AutoAlert('每个队的人数不能少于3人!', '请注意补齐。');
                  //that.$emit("Act1Create");
              }
              //以下用于生成照片视频数组容器
              AD.PABs = [{Pic: '', Video: ''}, {Pic: '', Video: ''}, {Pic: '', Video: ''}, {
                  Pic: '',
                  Video: ''
              }, {Pic: '', Video: ''}];//借用PABs
          }
        if (Val === -1) {
          this.ShowPopup = false;
          this.$emit("Act1Create");
        } else {
          let that = this, AD = that.Act1Doc;
          myMongoDBPost("GetDocs/fyuser0", {UserName: AD.UserName}, function (Docs) {
            if (Docs.length === 0) {
              AutoAlert('计分用户名不存在!', '本角色需要注册登录泛约网平台。');
              that.$emit("Act1Create");
            } else {
              if (AD.Title === '') {
                AutoAlert('子活动名称尚未输入', '请确保所有选项都已完成！');
                that.$emit("Act1Create");
              } else {
                let JFQ1 = AD.SubActType.indexOf('循环排名赛') >= 0, JFQ2 = AD.SubActType === '团体对抗赛',
                  JFQ3 = AD.SubActType === '团体淘汰赛';
                if (that.OP === '新增') {
                  if (JFQ1 || JFQ2) {//用于记分器时直接保存二维数组数据到TableCols中
                    AD.TableCols = [];
                    if (JFQ1) AD.Bs = AD.As;
                    let aRow = [JFQ1 ? '' : that.ColTitle];//1.先构造表头行
                    for (let i = 0, len = AD.Bs.length; i < len; i++) aRow.push(AD.Bs[i]);
                    if (JFQ2) aRow.push('积分');
                    AD.TableCols.push(aRow);

                    if (JFQ1) AD.TableCols[0][0] = AD.SubActType.substr(0, 3) + '赛';

                    console.log(AD.As);
                    for (let j = 0, len = AD.As.length; j < len; j++) {//2.构造表体
                      aRow = [AD.As[j]];
                      for (let i = 0, len = AD.Bs.length; i < len; i++) aRow.push("―");
                      if (JFQ2) aRow.push(0);
                      AD.TableCols.push(aRow);
                    }

                    if (JFQ2) {
                      aRow = ['积分'];//构造最后一行
                      for (let i = 0, len = AD.Bs.length; i < len; i++) aRow.push(0);
                      aRow.push('?:?');
                      AD.TableCols.push(aRow);
                    }

                    console.log(AD.TableCols);

                    //以下用于生成照片视频数组容器
                    AD.PABs = CloneObject(AD.TableCols);
                    AD.PABs[0][0] = '合影';
                    if (JFQ2) {
                      AD.PABs.splice(AD.PABs.length - 1, 1);//去掉最后＇积分＇那一行
                      for (let i = 0, len = AD.PABs.length; i < len; i++) AD.PABs[i].splice(AD.PABs[i].length - 1, 1);//去掉最后＇积分＇那一列
                    }
                    console.log(AD.PABs);

                    AD.VABs = CloneObject(AD.PABs);
                    AD.VABs[0][0] = '合录';
                  }
                  if (JFQ3) JFQ3TableCols();
                  myMongoDBPost("GetDocs/Act1Main", {Title: AD.Title}, function (Docs) {
                    if (Docs.length > 0) {
                      AutoAlert('操作无效!', '同一活动名称不得重复发布!');
                      that.$emit("Act1Create");
                    }
                    else myMongoDBPost("PutDoc/Act1Main", AD, function (data) {
                      that.ShowPopup = false;
                      that.$emit("Act1Create");
                    });
                  });
                } else {
                  if (JFQ3) JFQ3TableCols();
                  myMongoDBPost("DocPutBack/Act1Main", AD, function (data) {
                    that.ShowPopup = false;
                    that.$emit("Act1Create");
                  });
                }
              }
            }
          })
        }
      },
      Check1(Val) {
        let that = this;
        myMongoDBPost("GetDocs/fyuser0", {UserName: Val}, function (Docs) {
          if (Docs.length === 0) {
            AutoAlert('用户名不存在!', '本角色需要注册登录泛约网平台。');
            that.Act1Doc.UserName = '';
          }
        })
      },
      MyInputVal(Val) {
        console.log("Val=" + Val)

        let AD = this.Act1Doc;
        let JFQ1 = AD.SubActType.indexOf('循环排名赛') >= 0, JFQ2 = AD.SubActType === '团体对抗赛',
          JFQ3 = AD.SubActType === '团体淘汰赛';
        let T = CloneObject(AD.TableCols), P = CloneObject(AD.PABs), V = CloneObject(AD.VABs);

        if (Val !== '无' && Val !== '') {
          if (this.InputI === 4 || this.InputI === 6) {
            AD.As.push(Val);

            //let Finded = false;
            //for (let i = 0; i < AD.BMAs.length; i++) if (AD.BMAs[i].UserName === Val) Finded = true;
            //if (!Finded) AD.BMAs.push({UserName: Val, UserLogo: '?', Sex: '?', Age: '?'});


            if (this.OP === '修改') {
              if (JFQ1) {
                AD.Bs = AD.As;
                T[0].push(Val);//第0行
                P[0].push(Val);//第0行
                V[0].push(Val);//第0行
                for (let i = 1, len = T.length; i < len; i++) {
                  T[i].splice(T[i].length, 0, '―');
                  P[i].splice(P[i].length, 0, '―');
                  V[i].splice(V[i].length, 0, '―');
                }//再填最后1列

                let Cs = [Val];//最后1行
                for (let m = 0, len = AD.As.length; m < len; m++) Cs.push('―');//再填最后1行
                T.splice(T.length, 0, Cs);
                P.splice(P.length, 0, Cs);
                V.splice(V.length, 0, Cs);
              }
              if (JFQ2) {
                let Cs = [Val];//倒数第2行
                for (let i = 1, len = T[0].length - 1; i < len; i++) Cs.push('―');//再填最后1行
                Cs.push(0);
                T.splice(T.length - 1, 0, Cs);

                let C2s = CloneObject(Cs);
                C2s.pop();
                P.splice(P.length - 1, 0, C2s);
                V.splice(V.length - 1, 0, C2s);

              }
            }
          }
          if (this.InputI === 5 || this.InputI === 7) {
            AD.Bs.push(Val);

            //let Finded = false;
            //for (let i = 0; i < AD.BMBs.length; i++) if (AD.BMBs[i].UserName === Val) Finded = true;
            //if (!Finded) AD.BMBs.push({UserName: Val, UserLogo: '?', Sex: '?', Age: '?'});

            if (this.OP === '修改' && JFQ2) {
              T[0].splice(T[0].length - 1, 0, Val);
              for (let i = 1, len = T.length - 1; i < len; i++) T[i].splice(T[i].length - 1, 0, '―');//填最后1列
              T[T.length - 1].splice(T[T.length - 1].length - 1, 0, 0);//填最后1列

              P[0].splice(P[0].length - 1, 0, Val);
              for (let i = 1, len = P.length; i < len; i++) P[i].splice(P[i].length - 1, 0, '―');//填最后1列

              V[0].splice(V[0].length - 1, 0, Val);
              for (let i = 1, len = V.length; i < len; i++) V[i].splice(V[i].length - 1, 0, '―');//填最后1列
            }

          }
        }
        AD.TableCols = CloneObject(T);
        AD.PABs = CloneObject(P);
        AD.VABs = CloneObject(V);

        console.log(AD.TableCols)
        console.log(AD.PABs)
        console.log(AD.VABs)
        this.InputI = -1;
      },
      SetSubActType(Val) {
        this.Act1Doc.SubActType = Val;
        this.Show = 0;
      },
      AsBsDelete(Bool, index) {
        let AD = this.Act1Doc;
        let JFQ1 = AD.SubActType.indexOf('循环排名赛') >= 0, JFQ2 = AD.SubActType === '团体对抗赛',
          JFQ3 = AD.SubActType === '团体淘汰赛';
        if (Bool === 'A') {
          AD.As.splice(index, 1);
          if (JFQ1) {
            AD.Bs = AD.As;
            AD.TableCols[0].splice(index + 1, 1);
            for (let i = 1, len = AD.TableCols.length; i < len; i++) AD.TableCols[i].splice(index + 1, 1);
            AD.TableCols.splice(index + 1, 1);

            AD.PABs[0].splice(index + 1, 1);
            for (let i = 1, len = AD.PABs.length; i < len; i++) AD.PABs[i].splice(index + 1, 1);
            AD.PABs.splice(index + 1, 1);

            AD.VABs[0].splice(index + 1, 1);
            for (let i = 1, len = AD.VABs.length; i < len; i++) AD.VABs[i].splice(index + 1, 1);
            AD.VABs.splice(index + 1, 1);

          }
          if (JFQ2) {
            for (let i = 0, len = AD.TableCols.length; i < len; i++) AD.TableCols[i].splice(index + 1, 1);
            for (let i = 0, len = AD.PABs.length; i < len; i++) AD.PABs[i].splice(index + 1, 1);
            for (let i = 0, len = AD.VABs.length; i < len; i++) AD.VABs[i].splice(index + 1, 1);
          }

        }
        if (Bool === 'B') {
          this.Act1Doc.Bs.splice(index, 1);
          if (JFQ2) {
            AD.TableCols.splice(index + 1, 1);
            AD.PABs.splice(index + 1, 1);
            AD.VABs.splice(index + 1, 1);
          }

        }
      }
    }
  }
</script>

<style scoped>
</style>
