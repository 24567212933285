<template>
  <div>
    <div v-if="$store.state.UserName==='游客'" class="MP4 TC  BGWhite">
      <p>能用<span class="TE">泛约币</span>解决的问题，都不是问题。</p>
      <p>只要注册为泛约用户，相信任何困难都会有人站出来帮助您解决和面对，您将永远告别孤单无助！</p>
    </div>
    <div v-else>
      <div v-if="Show===1">
        <br>
        <div class="XLR YC MLR4">
          标题：
          <div class="XL YC TC">
            悬赏<input type="number" class="W12" v-model.number="CurrQYDoc.ZXn" @focus="CurrQYDoc.ZXn=null" style="height: 2rem"/>个泛约币
          </div>
          <div class="AN5 AN52" @click="SaveBBs(CurrQYDoc.Title)"> 确认</div>
        </div>
      </div>
      <div :class="(Show===1 ? 'BGWhite':'BGBlack')+' AlignC'"><br>
        <input type="text" class="W90 BD_Radius1" v-model="CurrQYDoc.Title" placeholder="" @focus="Show=1,CurrQYDoc.Title=null"/>
      </div>
      <div v-if="Show===1" class="XLR YC PA3 BGWhite">
        <div class="XL YC">附加信息：
          <ShowUpdatePVT :PVTF="{P:CurrQYDoc.Pic,V:CurrQYDoc.Video,T:CurrQYDoc.Text, TextTitle:'文字内容表述'}"
                         @ShowUT="CallBack"></ShowUpdatePVT>
        </div>&nbsp;
      </div>
      <div v-else class="AlignC BGBlack">
        <img src="../../../static/gif2/Gif1.gif" style="width: 95vw;height: auto"><br><br>
      </div>
    </div>
    <br>
    <FYBB_AllBBs v-if="Show===-1"></FYBB_AllBBs>
    <WXLink v-if="$store.state.UserName!=='游客'" WText="只要注册为泛约用户， 许多困难都会有人站出来帮助您解决和面对，您将永远告别孤单无助！"
            WTitle="能用泛约币解决的问题，都不是问题。" WLandTo="FYBB" Pic="UserUpload/static/WXLink/FYBB.jpg"></WXLink>
  </div>
</template>

<script>
  import {myMongoDBPost, AutoAlert} from '@/components/SharedVues/Shared0.js'
  import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import FYBB_AllBBs from "./FYBB_AllBBs";
  import WXLink from "../IndependentVues/WXLink";

  //一方有难 八方支援 让天下人都没有过不去的坎儿!
  //要 帮 忙<br>尽 管 说 //一呼百应
  export default {
    name: "FYBB",
    components: {
      WXLink,
      FYBB_AllBBs,
      PopTitle,
      ShowUpdatePVT
    },
    data() {
      return {
        Loaded: false,
        HaveBBs: false,
        CurrQYDoc: {
          UserName: this.$store.state.UserName,
          Certified: this.$store.state.Certified,
          UserLogo: this.$store.state.UserLogo,

          Channel: '泛约帮办',
          Type: '综合',//泛约调度填写：行业划分，用于查找让谁解决问题
          Key3: '综合',//泛约调度填写：问题进一步划分
          Title: '✍任务、求助、需求等一句话标题',

          Province: this.$store.state.FYArea.split(":")[1],
          City: this.$store.state.FYArea.split(":")[2],

          ZXn: 1,
          Pic: '',
          Video: '',
          Text: '',
          Time: (new Date()).getTime(),
          Strength: '随缘',
          EndBool: 1
        },

        OpenShow: false,
        InputI: -1,
        Show: -1
      }
    },
    mounted() {
      let that = this
      myMongoDBPost("GetDocs/QYs", {Channel: '泛约帮办', UserName: this.$store.state.UserName, EndBool: 1}, function (Docs) {
        if (Docs.length > 0) that.HaveBBs = true;
        if (that.$store.state.LandTo && that.$store.state.LandTo === 'WNFB') that.InputI = 1;
        if (that.$store.state.LandTo && that.$store.state.LandTo === 'WRMFW') that.OpenBBs();
      })//如果当前用户已有未了结的任务，则显示FYBBing
      document.getElementById('TitleId').innerText = '泛约活动服务平台';
    },
    methods: {
      CallBack(Val) {
        if (Val.P) this.CurrQYDoc.Pic = Val.P;
        if (Val.V) this.CurrQYDoc.Video = Val.V;
        if (Val.T) this.CurrQYDoc.Text = Val.T;
      },
      SaveBBs(Title) {
        let that = this;
        this.CurrQYDoc.Title = Title;
        if (Title.length < 3) AutoAlert('操作提示', '请您说得再详细些!'); else myMongoDBPost("PutDoc/QYs", this.CurrQYDoc, function (data) {
          that.Show = -1;
        });
      },
    }
  }
</script>

<style scoped>

</style>
