<template>
    <div class="MP2">
        <h5 class="BottGray">&nbsp;&nbsp;预设/重设我的裁判场地&nbsp;&nbsp;</h5>
        <div class="MP2 XLR YC BottGray" v-for="(ZGSite,index) in AdminZGs" :key="index">
            <div class="TC">{{index+1}}.{{ZGSite.SiteShortName}}</div>
            <div class="TB" @click="SeleZGSite(index)">
                <el-icon v-if="Bools[index]"><Check /></el-icon>
                选中
            </div>
        </div>
        <div class="MP2">
            <div v-if="Changed" class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="SaveToDLQsZGs">提 交</div>
            <div v-else class="BD_Radius1 BGLightGray T5 TW XYC H3 MT2">提 交</div>
        </div>
    </div>
</template>
<script>
    import {myMongoDBPost} from '../SharedVues/Shared0.js'
    export default {
        name: "DLQSetup2",
        props:['AdministratorId0','AdminZGs0'],
        data() {
            return {
                AdministratorId: '',
                AdminZGs: [],
                Bools:[],
                Changed: false,

            }
        },
        mounted() {
            this.AdministratorId=this.AdministratorId0;
            this.AdminZGs=this.AdminZGs0;
            this.Bools=new Array(this.AdminZGs0.length);
        },
        methods: {
            SeleZGSite(index) {
                let that = this,  asd = this.AdminZGs[index];
                let ASDs = asd.Judges.map(function (x) { return (x.Judge === that.$store.state.UserName) });
                if (ASDs.indexOf(true) >= 0) {
                    this.Bools[index]=false;
                    asd.Judges.splice(ASDs.indexOf(true), 1);
                } else {
                    this.Bools[index]=true;
                    this.AdminZGs[index].Judges.splice(0, 0, { Judge: that.$store.state.UserName, PreBMs: [] });
                }
                this.AdminZGs.splice(index, 1, asd);
                this.Changed=true;
            },
            SaveToDLQsZGs() {
                let that = this;
                myMongoDBPost('UpdateDoc/DLQs',{ UPTJ: { _id: that.AdministratorId }, UPObj: { ZGs: that.AdminZGs } },function (Docs) {
                        that.$emit("FromSetup2")
                })
            }
        }
    }
</script>

<style scoped>
</style>