<template>
    <div>　
        <PopTitle :Title="Act1Doc.Title" @FromPopUp="Quit()">
            <div class="XR PA3">计分：{{Act1Doc.UserName}}</div>
            <table>
                <thead>
                <tr class="TC LH120">
                    <th v-for="(R0,index1) in Rows[0]" :key="index1" style="padding: 2mm 0">
                        <div v-if="index1===0" class="ST TG" style="min-width: 4.5rem">{{R0}}</div>
                        <div v-else>
                            <div v-if="index1===Rows[0].length-1" class="ST TE" style="min-width:3rem">{{R0}}</div>
                            <div v-else>{{R0}}</div>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody v-for="(Cols,index1) in Rows" :key="index1">
                <tr v-if="index1>0 && index1<Rows.length-1">
                    <td v-for="(ZD,index2) in Cols" :key="index2" class="LH120">
                        <div v-if="index2===0" class="TC">{{ZD}}</div>
                        <div v-if="index2>0 && index2<Cols.length-1" @click="I1=index1,I2=index2" class="TB"
                             style="padding: 2mm 0">{{ZD}}
                        </div>
                        <div v-if="index2===Cols.length-1" class="TE">{{ZD}}</div>
                    </td>
                </tr>
                </tbody>
                <tbody v-for="(Cols,index1) in Rows" :key="index1">
                <tr v-if="index1===Rows.length-1">
                    <td v-for="(ZD,index2) in Cols" :key="index2" class="TE">
                        <div v-if="index2===Cols.length-1" class="TG" style="min-width: 2rem">{{ZD}}</div>
                        <div v-else>{{ZD}}</div>
                    </td>
                </tr>
                </tbody>
            </table>
            <br>
            <MySele2 v-if="I2!==-1" :Title="Rows[I1][0]+' ： '+Rows[0][I2]" :Objs="BFs"
                     @MySele2="SetZD($event,I1,I2)"></MySele2>
            <Act1PVABs :Act1Doc="Act1Doc" CallFrom="JFQ2"></Act1PVABs>
        </PopTitle>
    </div>
</template>

<script>
    import {
        myMongoDBPost,
        myDataFileGet,
        AutoAlert,
        DeletePV,
        CloneObject,
        Compare
    } from '@/components/SharedVues/Shared0.js'

    import PopTitle from "../MyPopWin/PopTitle";
    import MySele2 from "../SharedVues/MySele2";
    import Act1PVABs from "./Act1PVABs";

    export default {
        name: "Act1JFQ2",
        components: {
            Act1PVABs,
            MySele2,
            PopTitle,

        },
        props: ['ActDoc', 'Act1Doc'],
        data() {
            return {
                CurrJFMethod: '泛约',
                Rows: this.Act1Doc.TableCols,//注意：这是一个二维叔组
                I1: -1,
                I2: -1,
                BFs: [
                    {Type: '3局2胜制', Opts: ['0:2', '1:2', '2:0', '2:1']},
                    {Type: '5局3胜制', Opts: ['0:3', '1:3', '2:3', '3:2', '3:1', '3:0']},
                    {Type: '7局4胜制', Opts: ['0:4', '1:4', '2:4', '3:4', '4:0', '4:1', '4:2', '4:3']}]
            }
        },
        mounted() {
            this.Loaded = (this.Rows.length > 0 && this.Rows[0].length > 0)
            let that = this;
            if (this.ActDoc.JFPool !== '泛约')
                myMongoDBPost("GetDocs/fysites", {SiteShortName: that.ActDoc.JFPool}, function (Docs) {
                    if (Docs.length > 0) that.CurrJFMethod = Docs[0].JFMethod
                });
        },
        methods: {
            Quit() {
                if (this.Rows.length > 1) {
                    let that = this, Victor = '', Second = '', A = this.Act1Doc.A, B = this.Act1Doc.B;
                    let i = this.Rows.length - 1, j = this.Rows[i].length - 1, aBF = this.Rows[i][j].split(":");
                    if (parseInt(aBF[0]) === parseInt(aBF[1])) Victor = '平局', Second = A + ":" + B;
                    else if (parseInt(aBF[0]) > parseInt(aBF[1])) Victor = A, Second = B; else Victor = B, Second = A;
                    if (this.Rows[i][j] === '?:?') Victor = '未定', Second = A + ":" + B;

                    myMongoDBPost("UpdateDoc/act1", {
                        UPTJ: {_id: this.Act1Doc._id},
                        UPObj: {Victor: Victor, Second: Second}
                    }, function (data2) {
                        that.$emit('FromJFQ2');
                    });
                } else this.$emit('FromJFQ2');
            },
            SetZD(Val, index1, index2) {
                if (this.$store.state.UserName === this.Act1Doc.UserName || this.$store.state.UserName === this.$store.state.HTM0) {
                    let Cols = CloneObject(this.Rows[index1]), RJF = 0, CJF = 0, RJFSum = 0, CJFSum = 0;//RJF=行积分  CJF=列积分
                    Cols.splice(index2, 1, Val);//1.先把当前数据写入

                    for (let i = 1; i < Cols.length - 1; i++) {//2.计算并写入当前行积分

                        if (parseInt(Cols[i].split(':')[0]) > parseInt(Cols[i].split(':')[1])) RJF += 1;
                    }
                    Cols.splice(Cols.length - 1, 1, RJF);
                    this.Rows.splice(index1, 1, Cols);

                    for (let j = 1; j < this.Rows.length - 1; j++) {//3.计算并写入各列积分以及行总积分
                        let C = this.Rows[j][index2].split(":");

                        if (parseInt(C[1]) > parseInt(C[0])) CJF += 1;
                        RJFSum += parseInt(this.Rows[j][Cols.length - 1]);
                    }

                    Cols = CloneObject(this.Rows[this.Rows.length - 1]);//4.最后一行
                    Cols.splice(index2, 1, CJF);
                    for (let i = 1; i < Cols.length - 1; i++) CJFSum += Cols[i];

                    Cols.splice(Cols.length - 1, 1, '' + RJFSum + " : " + CJFSum);
                    this.Rows.splice(this.Rows.length - 1, 1, Cols);


                    let aOBJ = {
                        Act1DocId: this.Act1Doc._id,
                        Winner: this.Rows[index1][0],
                        Loser: this.Rows[0][index2],
                        BF: Val
                    };

                    aOBJ.ItemStr = this.ActDoc.ItemStr;
                    aOBJ.JFPool = this.ActDoc.JFPool;
                    if (aOBJ.ItemStr.charAt(0).toLowerCase() === 'a') {
                        console.log("aOBJ.Winner,aOBJ.Loser,aOBJ.BF=", aOBJ.Winner, aOBJ.Loser, aOBJ.BF);
                        if (this.CurrJFMethod === '泛约') myMongoDBPost("Act1FillToFymc", aOBJ, function (asd) {
                        });
                        if (this.CurrJFMethod === 'ChinaTT') myMongoDBPost("ChinaTTToByset0", aOBJ, function (asd) {
                        })
                    }


                    myMongoDBPost("UpdateDoc/Act1Main", {
                        UPTJ: {_id: this.Act1Doc._id},
                        UPObj: {TableCols: this.Rows}
                    }, function (data2) {
                    });
                } else AutoAlert('无效操作', '只有计分员才能录入比分!');
                this.I2 = -1;

            }//数组元素更新
        }
    }
</script>

<style scoped>
</style>

