<template>
    <div>
        <div v-if="CurrThrumb===''" class="DispIB MVideoFrame TC T2" @click="Show1=true;">更新中<br>...</div>
        <div v-else class="DispIB MVideoFrame TW T2" @click="Show1=true;"
             :style="'overflow:hidden;background:0 0 / 100% 100% url('+CurrThrumb+') no-repeat'"><br>&nbsp;&nbsp;&nbsp;{{Duration}}
        </div>
        <PopUpDown v-if="Show1" Title="视频浏览" Pos="btt" :ANs="1" @FromPop5="Show1=false">
            <video :src="CurrVideo" webkit-playsinline="" playsinline="" x5-playsinline="" width="100%" height="90%"
                   controls></video>
            <div v-if="Msg.Update" class="XR MR4">
                <div class="TB Cursor0" @click="UpdateVideo()">更新视频</div>
            </div>
            <br>
        </PopUpDown>
    </div>
</template>

<script>
    import PopTitle from "../MyPopWin/PopTitle";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import {myVodpost} from '@/components/SharedVues/Shared0.js';

    export default {
        name: "ShowVFromThumb",
        props: ['Msg'],
        components: {PopUpDown, PopTitle},
        data() {
            return {
                Show1: false,
                Show2: false,
                Show3: false,
                This: '',
                ASD: '',
                Timer: 0,
                CurrThrumb: '',
                CurrVideo: '',
                Duration: 0
            }
        },
        mounted() {
            let that = this;
            myVodpost("getMediaInfos", {FileId: that.Msg.Video}, function (Obj) {
                let asd1 = Math.round(Obj.Duration / 60) - 1, asd2 = Math.round(Obj.Duration % 60);
                asd1 = asd1 < 0 ? 0 : asd1;
                that.Duration = "" + (asd1 < 10 ? "0" + asd1 : asd1) + ":" + (asd2 < 10 ? "0" + asd2 : asd2);
                that.CurrThrumb = Obj.CoverUrl;
                that.CurrVideo = Obj.MediaUrl;
            })
        },
        methods: {
            UpdateVideo() {
                let that = this;
                myVodpost("DeleteMedia", {FileId: this.Msg.Video}, function (aObj) {
                    that.Show1 = false;
                    that.$emit("FromChildV");
                })
            }
        }
    }
</script>

<style scoped>
</style>
