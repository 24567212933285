<template>
    <div class="DispIB"><img src="../../../static/img/Media/TextRect.jpg" @click="Show1=true" class="WH99"></div>
    <PopUpDown v-if="Show1" Title="文字信息查看" Pos="btt" :ANs="1" @FromPop5="Show1=false">
        <div class="MP4 TC" style="position: relative;height: 70%">
            <div v-if="Msg.Text.split('\n').length>1">
                <p v-for="(W,index) in Msg.Text.split('\n')" :key="index">{{W}}</p>
            </div>
            <p v-else class="LH150">{{Msg.Text}}</p>
        </div>
        <div v-if="Msg.Update" class="XR MR4">
            <div class="TB Cursor0" @click="UpdateText()" style="position: relative;top:-2rem">更新</div>
        </div>
        <br>
    </PopUpDown>
</template>

<script>
    import PopTitle from "../MyPopWin/PopTitle";
    import PopUpDown from "../MyPopWin/PopUpDown";

    export default {
        name: "ShowTFromThumb",
        components: {PopUpDown, PopTitle},
        data() {
            return {
                Show1: false
            }
        },
        props: ['Msg'],
        methods: {

            UpdateText() {
                this.Show1 = false;
                this.$emit("UpdateTextFromChild");
            }//自定义事件
        }
    }
</script>

<style scoped>

</style>
