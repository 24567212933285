<template>
  <div>
    <div v-if="IsSiteMannager" class="MLR2">
      <fieldset v-for="(CurrSite,index) in Sites" :key="index">
        <legend>{{index+1}}.{{CurrSite.SiteName}}：</legend>
        <div>
          <div>{{CurrSite.Country}}{{CurrSite.Province}}{{CurrSite.City}}{{CurrSite.DC}}{{CurrSite.Address}}</div>
          　
          <div class="XLR MT2">
            <div>电话：{{CurrSite.Telephone}}</div>
            <div>场地管理员：{{CurrSite.SiteAdmins}}</div>
          </div>
          <div class="XLR YC MT2">场地概况：
            <ShowPVT :PVT="{P:CurrSite.Pic,V:CurrSite.Video,T:CurrSite.Remark,Update:false}"></ShowPVT>&nbsp;
          </div>
        </div>
        <table  style="background-color:#fff;">
          <thead>
          <tr class="TF">
            <th>项目名称</th>
            <th>收费标准</th>
            <th>场地情况</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(Item,index) in CurrSite.Items" :key="index" style="height: 3.5rem">
            <td class="MT2">{{Item.ItemName}}</td>
            <td class="MT2">
              {{Item.FeeScale}}/{{Item.fyVIPFeeScale}}
            </td>
            <td>
              <ShowPVT :PVT="{P:Item.Pic,V:Item.Video,T:Item.Remark,Update:false}"></ShowPVT>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="XR">
          <div class="TE MP4" @click="DeleteCurrSiteMsg(CurrSite._id)">作废删除</div>
          <div class="TG MP4" @click="GetCurrSiteMsg(CurrSite._id)">转换提取</div>
        </div>
      </fieldset>
    </div>
    <div v-else class="MP4 AlignC TE">
      请您先取得场地管理员资格!
    </div>
  </div>
</template>

<script>
  import {myMongoDBPost, Tofixed} from '@/components/SharedVues/Shared0.js'
  import ShowPVT from "@/components/MyPVTs/ShowPVT";


  export default {
    name: "SitesGetTJ",
    components: {      ShowPVT    },
    data() {
      return {
        IsSiteMannager: false,
        Sites: []
      }
    },
    mounted() {
      this.IsSiteM();
    },
    methods: {
      IsSiteM() {
        let that = this;
        myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName, UserBools: '场地管理员'}, function (Docs) {
          if (Docs.length > 0) {
            that.IsSiteMannager = true;
            myMongoDBPost("GetDocs/fySites", {XYedUserN: -2}, function (Docs2) {
              that.Sites = Docs2;
              console.log(that.Sites)
            });
          }
        })
      },
      Tofixed(x, y) {
        return Tofixed(x, y)
      },
      DeleteCurrSiteMsg(Id) {
        let that = this;
        myMongoDBPost("RemoveDoc/fySites", {_id:Id}, function (data2) {
          myMongoDBPost("GetDocs/fySites", {XYedUserN: -2}, function (Docs) {
            that.Sites = Docs;
          });
        })
      },
      GetCurrSiteMsg(Id) {
        let that = this;
        myMongoDBPost("UpdateDoc/fySites", {
          UPTJ: {_id:Id},
          UPObj: {XYedUserN: 0, SiteAdmins: [this.$store.state.UserName]}
        }, function (data2) {
          myMongoDBPost("GetDocs/fySites", {XYedUserN: -2}, function (Docs) {
            that.Sites = Docs;
          });
        })
      },

    }
  }
</script>

<style scoped>

</style>

