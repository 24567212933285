<template>
    <PopMenu :Menus="['招生信息','教练','学生','排课预设']" :BeginN="0" @FromPopMenu="Selected">
        <div v-if="SeleI===0" style="max-width: 100vw">
            <div class="XLR YC MP2">
                <h5>{{SiteName}}</h5>
                <div class="XR T3">简称：{{SiteShortName}}</div>
            </div>
            <table>
                <tr>
                    <th>海报照片</th>
                    <th>宣传片</th>
                    <th>群二维码</th>
                </tr>
                <tr>
                    <th>
                        <ShowUpdateP :Pic="CurrSrc1" @ShowUP="AddVP($event,'1')"></ShowUpdateP>
                    </th>
                    <th>
                        <ShowUpdateV :Video="CurrSrc2" @ShowUP="AddVP($event,'2')"></ShowUpdateV>
                    </th>
                    <th>
                        <ShowUpdateP :Pic="CurrSrc3" @ShowUP="AddVP($event,'3')"></ShowUpdateP>
                    </th>
                </tr>
            </table>
            <div class="Div2"></div>
            <div style="max-width: 100vw">
                文字信息：<br>
                <textarea class="Mtextarea4" v-model="Text" @blur="TextChanged"></textarea>
            </div>$store.state.DefaultItem={{$store.state.DefaultItem}}
            <div v-if="['A000','A001'].indexOf($store.state.DefaultItem.split(':')[0])>=0">
                <WXLink :WTitle="SiteName+'正在推出免费体验课程包'" WText="点击本链接，可查看详情。"
                        :WLandTo="'YKQFromWXLink'+CurrSiteUser._id" :Pic="'UserUpload/static/WXLink/'+$store.state.DefaultItem.split(':')[0]+'.jpg'"></WXLink>
            </div>
            <div class="Div2"></div>
        </div>
        <div v-if="SeleI===1">
            <div v-if="Coaches.length===0" class="H25vw XYC TF AlignC BGLightGray">( 当前为空 )</div>
            <table v-else>
                <thead>
                <tr>
                    <th>删除</th>
                    <th>用户名<br>实名</th>
                    <th>形象照</th>
                    <th>授课视频</th>
                    <th>文字<br>介绍</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(CC,index) in Coaches" :key="index">
                    <td>
                        <div class="DispIB TE BDCircle BoxBR WH77 XYC" @click="DeleteOneCC(index)">删</div>
                    </td>
                    <td>{{CC.CoacheUser}}
                        <div class="T3 MT2">
                            <input class="W25" type="text" v-model="CC.Pic2" @blur="SavePic2(CC.Pic2,index)" placeholder="实名"/>
                        </div>
                    </td>
                    <td>
                        <ShowUpdateP :Pic="CC.Src1" @ShowUP="AddVP2($event,'1',index)"></ShowUpdateP>
                    </td>
                    <td>
                        <ShowUpdateV :Video="CC.Src2" @ShowUP="AddVP2($event,'2',index)"></ShowUpdateV>
                    </td>
                    <td>
                        <div class="W20 AlignC" @click="AddText(index)">
                            <a class="T3"> {{CC.Text==='' ? '添加':'修改'}} </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <p class="MP4">特别说明：追加教练应由教练本人注册登录后自行完成。</p>
            <PopBott v-if="EditText">
                <div class="XLR MP2">
                    {{Coaches[CurrIndex].CoacheUser}}教练情况简介：
                    <div @click="EditText=false">
                        <el-icon>
                            <CircleClose/>
                        </el-icon>
                    </div>
                </div>
                <div class=".BDLightGray BD_Radius1 ML2">
                    <textarea class="Mtextarea4" v-model="Text1" @blur="SaveText"></textarea>
                </div>
                <div class="MT2"></div>
            </PopBott>
        </div>
        <div v-if="SeleI===2">
            <div v-if="Students.length===0" class="H25vw XYC TF AlignC BGLightGray">( 当前为空 )</div>
            <div v-else>
                <div class="BGLightGray MT2" v-for="(S,index) in Students" :key="index">
                    <div class="XLR YC PA1">
                        <ShowPFromThumb :Msg="{Pic:S.Src1,Update:false}"></ShowPFromThumb>
                        <div>
                            <div class="XLR YC">{{S.StudentUser}}
                                <div class="XR YC">
                                    <div class="DispIB TF T3">实名:</div>
                                    <input class="DispIB .BDLightGray BD_Radius1 W20" type="text" v-model="S.RealName"
                                           @blur="SaveStudentsX(index,'RealName',S.RealName)"/>
                                </div>
                            </div>
                            <div class="XL YC">
                                <div class="TF T3">电话：</div>
                                <input class=".BDLightGray BD_Radius1 W40" type="tel" v-model="S.Mobile" @blur="SaveStudentsX(index,'Mobile',S.Mobile)"/>
                            </div>
                        </div>
                        <div class="BGGreen1 PA2">
                            <div class="TF T3">{{S.CoacheUser}}</div>
                            <div class="W20 XL YC">1对
                                <input class=".BDLightGray BD_Radius1 W6 MT2" type="number" v-model="S.YKTypeN"
                                       @focus="S.YKTypeN=null" @blur="SaveStudentsX(index,'YKType',S.YKTypeN)"/>
                            </div>
                        </div>
                    </div>
                    <div class="XLR YC PA3">
                        <div class="W40">
                            <div class="XL YC BottGray">
                                总学时：<input class="DispIB .BDLightGray BD_Radius1 W12" type="number" v-model="S.HourN"
                                           @focus="S.HourN=null" @blur="SaveStudentsX(index,'HourN',S.HourN)" placeholder="总学时数"/>
                            </div>
                            <div class="XLR YC MT2">
                                <div class="T3 TF">已用：{{S.HouredN}}</div>
                                <div class="T3 TF">剩余：{{S.HourN-S.HouredN}}</div>
                            </div>
                        </div>
                        <div>
                            <div class="T3 TF">有效期至：
                                <el-date-picker v-model="S.EndDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD" type="date"
                                                @change="SetEndStamp(S.EndDate,index)" size="small" style="width: 7vw"/>
                            </div>
                            {{S.EndDate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="SeleI===3">
            <div class="ML2 XL YC">
                同时容纳教练最大数量：<input class="BD_Radius1 W6" type="number" v-model="YKMaxN" @blur="Changed=true" placeholder="0"/> 个
            </div>
            <h5 class="ML2">1日内课时单元预设</h5>
            <table>
                <thead>
                <tr class="TF">
                    <th>操作</th>
                    <th>节次</th>
                    <th>时间范围</th>
                    <th>学时</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(CC,index) in ClassTWs" :key="index">
                    <td class="AlignC">
                        <div class="TE BDCircle BoxBR WH77 XYC" @click="SetClassTWs(index,'DeleteOneTW',null)">删</div>
                    </td>
                    <td>{{index+1}}</td>
                    <td>
                        <input class="W20" type="text" v-model="CC.TW1" @focus="CC.TW1=null" @blur="SetClassTWs(index,'SetTW1',CC.TW1)"/>
                        &nbsp;-&nbsp;
                        <input class="W20" type="text" v-model="CC.TW2" @focus="CC.TW2=null" @blur="SetClassTWs(index,'SetTW2',CC.TW2)"/>
                    </td>
                    <td><input class="W12" type="number" v-model.number="CC.Hn" @focus="CC.Hn=null" @blur="SetClassTWs(index,'SetHn',CC.Hn)"
                               placeholder="0"/></td>
                </tr>
                <tr>
                    <td class="AlignC"><img src="../../../UserUpload/static/Img/Plus_3x.png" class="WH99" @click="SetClassTWs(-1, 'AddOneTW', null)"/>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            
            <div class="XLR">
                <div class="T5 TW XYC H4 W50 BGBlue" @click="KBInit">课表框架初始化</div>
                <div class="T5 TW XYC H4 W50 BGBlue" @click="$emit('FromYKQSetup1','KBStart')">启动/重启课表</div>
            </div>
            <p class="PA1">特别提醒：1.课时单元预设信息，一旦课表启用，再修改就无效了；2.课表框架初始化后，必须等教练和学生完成课表预设后，才能启动课表。</p>
        </div>
    </PopMenu>
</template>

<script>
    import ShowUpdateP from "../MyPVTs/ShowUpdateP";
    import ShowUpdateV from "../MyPVTs/ShowUpdateV";
    import PopBott from "../MyPopWin/PopBott";
    import {myMongoDBPost, StampToDate, AutoHideAlert, CloneObject} from '@/components/SharedVues/Shared0.js'
    import PopMenu from "../MyPopWin/PopMenu";
    import ShowPFromThumb from "../MyPVTs/ShowPFromThumb";
    import WXLink from "../IndependentVues/WXLink";

    export default {
        name: "YKQSetup1",
        components: {WXLink, ShowPFromThumb, PopMenu, PopBott, ShowUpdateV, ShowUpdateP},
        props: ['CurrSiteUser'],
        data() {
            return {
                SeleI: 0,
                SiteName: '',
                SiteShortName: '',
                UploadProgress: 0,
                //CurrSiteUser: {},
                //以下是菜单0中用到的数据
                CurrSrc1: '',
                CurrSrc2: '',
                CurrSrc2V: '',
                CurrSrc3: '',
                Text: '',


                //以下是菜单3中用到的数据
                YKMaxN: null,
                ClassTWs: [],

                //以下是菜单1中用到的数据
                Coaches: [],
                CurrIndex: -1,
                EditText: false,
                Text1: '',

                //以下是菜单2中用到的数据
                Students: []
            }
        },
        mounted() {
            console.log(this.CurrSiteUser)
            this.SiteName = this.CurrSiteUser.SiteName;
            this.SiteShortName = this.CurrSiteUser.SiteShortName;
            this.Menu03Init(this.CurrSiteUser);
            document.getElementById('TitleId').innerText = this.CurrSiteUser.SiteShortName + '☞' + this.$store.state.UserName + '☞排课预设';
        },
        methods: {
            Selected(index) {
                this.SeleI = index;
                if (index === -1) this.$emit('FromYKQSetup1', '返回');
                else if (index === 0 || index === 3) this.Menu03Init();
                else if (index === 1) this.Menu1Init();
                else if (index === 2) this.Menu2Init();
            },
            Menu03Init() {
                if (JSON.stringify(this.CurrSiteUser) !== '{}') {
                    this.SiteName = this.CurrSiteUser.SiteName;
                    this.SiteShortName = this.CurrSiteUser.SiteShortName;
                    this.CurrSrc1 = this.CurrSiteUser.Pic;
                    this.CurrSrc2 = this.CurrSiteUser.Video;
                    this.CurrSrc3 = this.CurrSiteUser.Pic2;
                    this.Text = this.CurrSiteUser.Text;
                    this.YKMaxN = this.CurrSiteUser.YKMaxN;
                    this.ClassTWs = this.CurrSiteUser.ClassTWs;
                }
            },
            //...........................招生信息相关.................................................

            AddVP(Val, VP) {
                let that = this;
                that.CurrSrc2 = '';
                let Obj1 = {CAdminUser: this.$store.state.UserName, DocBool: '课程管理员'}, Obj2 = {};
                if (VP === '1') Obj2.Pic = Val.P;
                if (VP === '2') Obj2.Video = Val.V;
                if (VP === '3') Obj2.Pic2 = Val.P;
                myMongoDBPost("UpdateDoc/SiteUsers", {UPTJ: Obj1, UPObj: Obj2}, function (Docs) {
                    if (VP === '1') that.CurrSrc1 = Val.P;
                    if (VP === '2') that.CurrSrc2 = Val.V;
                    if (VP === '3') that.CurrSrc3 = Val.P;
                })
            },//点击添加按钮选择VP
            TextChanged() {
                let that = this;
                let Obj1 = {CAdminUser: this.$store.state.UserName, DocBool: '课程管理员'};
                let Obj2 = {Text: this.Text};
                myMongoDBPost("UpdateDoc/SiteUsers", {UPTJ: Obj1, UPObj: Obj2}, function (Docs) {
                })
            },
            //--------------教练相关-----------------
            Menu1Init() {
                let that = this, aOBJ = {CAdminUser: this.$store.state.UserName, DocBool: '教练'};
                myMongoDBPost("GetDocs/SiteUsers", aOBJ, function (Docs) {
                    let asd = [];
                    for (let i = 0; i < Docs.length; i++) asd.push({
                        CoacheUser: Docs[i].CoacheUser,
                        Pic2: Docs[i].Pic2,
                        Src1: Docs[i].Pic,
                        Src2: Docs[i].Video,
                        Text: Docs[i].Text,
                        StudentUser: Docs[i].StudentUser
                    })
                    that.Coaches = CloneObject(asd);
                })
            },
            SavePic2(CoachRealName, index) {
                let that = this;
                let Obj1 = {CAdminUser: this.$store.state.UserName, CoacheUser: this.Coaches[index].CoacheUser, DocBool: '教练'};
                let Obj2 = {Pic2: CoachRealName};
                myMongoDBPost("UpdateDoc/SiteUsers", {UPTJ: Obj1, UPObj: Obj2}, function (Docs) {
                    that.Coaches[index].Pic2 = CoachRealName;
                })
            },//注意这里实则保存的是教练的实名
            AddVP2(Val, VP, index) {
                let that = this;
                this.CurrIndex = index;
                let Obj1 = {CAdminUser: this.$store.state.UserName, CoacheUser: that.Coaches[index].CoacheUser, DocBool: '教练'}, Obj2 = {};
                if (VP === '1') Obj2.Pic = Val.P;
                if (VP === '2') Obj2.Video = Val.V;
                myMongoDBPost("UpdateDoc/SiteUsers", {UPTJ: Obj1, UPObj: Obj2}, function (Docs) {
                    if (VP === '1') that.Coaches[index].Src1 = Val.P;
                    if (VP === '2') that.Coaches[index].Src2 = Val.V;
                    that.Coaches.splice(index, 1, that.Coaches[index]);
                })
            },
            DeleteOneCC(index) {
                let that = this;
                let aObj = {CAdminUser: this.$store.state.UserName, CoacheUser: that.Coaches[index].CoacheUser, DocBool: '教练'};
                myMongoDBPost("RemoveDoc/SiteUsers", aObj, function (Docs) {
                    that.Coaches.splice(index, 1)
                })
            }
            ,//删除一条教练记录
            AddText(index) {
                this.CurrIndex = index;
                this.Text1 = this.Coaches[index].Text;
                this.EditText = true;
            },
            SaveText() {
                let that = this, index = this.CurrIndex;
                let Obj1 = {CAdminUser: this.$store.state.UserName, CoacheUser: that.Coaches[index].CoacheUser, DocBool: '教练'};
                let Obj2 = {Text: that.Text1};
                myMongoDBPost("UpdateDoc/SiteUsers", {UPTJ: Obj1, UPObj: Obj2}, function (Docs) {
                    that.Coaches[index].Text = that.Text1;
                    that.CurrIndex = -1;
                    that.Text1 = '';
                    that.EditText = false;
                })
            },


            //=================================学生相关============================================
            Menu2Init() {
                let that = this;
                myMongoDBPost("GetDocs/SiteUsers", {CAdminUser: this.$store.state.UserName, DocBool: '学生'}, function (Docs) {
                    let asd = CloneObject(Docs);
                    for (let i = 0; i < asd.length; i++) {
                        asd[i].YKTypeN = parseInt(asd[i].YKType.split("对")[1]);
                        asd[i].Src1 = asd[i].Pic;
                        asd[i].Show1 = false;
                        asd[i].EndDate = StampToDate(asd[i].EndStamp);
                    }
                    that.Students = CloneObject(asd);
                    console.log(that.Students)
                });
            },
            SetEndStamp(Val, index) {
                let asd1 = Val.split("-")[0] + "年" + Val.split("-")[1] + "月" + Val.split("-")[2] + "日";
                let asd2 = Date.parse(Val.split("-")[0] + "/" + Val.split("-")[1] + "/" + Val.split("-")[2])
                this.SaveStudentsX(index, 'EndStamp', {Date: asd1, Stamp: asd2});
            }
            ,
            SaveStudentsX(index, X, Val) {
                if (this.Students.length > 0) {
                    let that = this, TJ = {_id: this.Students[index]._id}, Obj = {}, asd = that.Students[index];
                    if (X === 'HourN') {
                        Obj = {HourN: parseInt(Val)};
                        asd.HourN = parseInt(Val);
                    }
                    if (X === 'YKType') {
                        Obj = {YKType: '1对' + parseInt(Val)};
                        asd.YKTypeN = parseInt(Val);
                    }
                    if (X === 'RealName') {
                        Obj = {RealName: Val};
                        asd.RealName = Val;
                    }
                    if (X === 'Mobile') {
                        Obj = {Mobile: Val};
                        asd.Mobile = Val;
                    }
                    if (X === 'EndStamp') {
                        asd.EndDate = Val.Date;
                        Obj = {EndStamp: Val.Stamp};
                    }
                    myMongoDBPost("UpdateDoc/SiteUsers", {UPTJ: TJ, UPObj: Obj}, function (Docs) {
                        that.Students.splice(index, 1, asd);
                    })
                }
            },

            //------------------------------------排课预设-----------------------------------------------------------

            SetClassTWs(index, OPBool, Val) {
                let asd = this.ClassTWs, that = this;
                console.log(this.ClassTWs)
                if (OPBool === 'AddOneTW') asd.push({TW1: "??:??", TW2: "??:??", Hn: null, Show1: false, Show2: false});
                if (OPBool === 'DeleteOneTW') asd.splice(index, 1);
                if (OPBool === 'SetTW1') {
                    asd[index].Show1 = false;
                    asd[index].TW1 = Val;
                }
                if (OPBool === 'SetTW2') {
                    asd[index].Show2 = false;
                    asd[index].TW2 = Val;
                }
                if (OPBool === 'SetHn') asd[index].Hn = Val;
                this.ClassTWs = asd;

                if (this.ClassTWs.length === 0) alert('遇见鬼了！请向18622582303反馈。');
                else myMongoDBPost("UpdateDoc/SiteUsers", {
                    UPTJ: {CAdminUser: this.$store.state.UserName, DocBool: '课程管理员'}, UPObj: {YKMaxN: this.YKMaxN, ClassTWs: this.ClassTWs}
                }, function (Docs) {
                    that.CurrSiteUser.YKMaxN = that.YKMaxN;
                    that.CurrSiteUser.ClassTWs = that.ClassTWs;
                })
            },
            KBInit() {
                let that = this,
                    Obj = {UPTJ: {CAdminUser: that.$store.state.UserName}, UPObj: {KBBeginStamp: (new Date()).getTime(), KBStatus: '已初始化'}};
                myMongoDBPost("UpdateDoc/SiteUsers", Obj, function (Docs) {
                    myMongoDBPost("RemoveDoc/Schedules", {SiteId: that.CurrSiteUser.SiteId, CAdminUser: that.$store.state.UserName}, function (data) {
                        that.$emit('FromYKQSetup1', 'KBInit');
                    })
                })
            }//课表框架初始化须每年进行一次，一以便生成新的KBBeginStamp
        }
    }
</script>

<style scoped>

</style>
