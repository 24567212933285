<template>
  <div>
    <div v-if="OP==='查看'">
      <div v-for="(O,index) in BMList" :key="index" class="BottGray" style="margin: 4mm 2mm">
        {{index+1}}.{{O.TeamName}}
        <div class="PA2 BGLightGreen XL">
          <div class="PA2 ML2 MT2 BottWhite" v-for="(OM,index2) in O.Members" :key="index2">
            {{index2+1}}.{{OM}}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="BMList.length===0" class="MLR4 MT2">
        请先插入一个二级标题：
        <div class="XLR YC MT2">
          <input type="text" v-model="NewTeam" @focus="NewTeam=null"/>
          <div class="AN3" @click="SetNewTeam(0,NewTeam)">确认</div>
        </div>
      </div>
      <div v-else class="XLR YC MT2 T2 TF MLR4">
        <div>图例：<img :src="'UserUpload/static/After2021/Insert.png'" class="WH77"/>=添加成员</div>
        <div class="AN ANGray" @click="FromCSQs()">约球器导入</div>
      </div>
      <div v-for="(O,index) in BMList" :key="index" class="BottGray" style="margin: 4mm 2mm">
        <div class="PA2 XLR YC">
          <div class="XL YC">
            <input type="number" class="W12" v-model.number="O.Index" @focus="O.Index=null"/>
            <div class="DispIB TE T8" @click="SetNewIndex(index,O.Index>0 ? O.Index-1:0)"
                 style="position: relative;top:-1rem">✔
            </div>
            .{{O.TeamName}}
          </div>
          <div class="TE T5 MLR4 PA3" @click="Delete1(index)">✗</div>
          <!--div-- v-if="SeleI3===index || SeleI3===-1" class="XL YC">
            新序号：<input type="number" class="W12" v-model.number="NewIndex" @focus="NewIndex=null,SeleI3=index"/>&nbsp;
            <div class="AN5 AN51" @click="SetNewIndex(index,NewIndex>0 ? NewIndex-1:0)">确认</div>
          </div-->
          &nbsp;
        </div>
        <div class="PA2 XL YC BGLightGray">
          <img v-if="O.Members.length===0" :src="'UserUpload/static/After2021/Insert.png'"
               @click="SeleI1=index,SeleI2=-1" class="WH77"/>&nbsp;
          <div v-else class="XL NoWrap" v-for="(OM,index2) in O.Members" :key="index2">
            <div class="PA2 Box5 W26 NoWrap MT2">
              {{index2+1}}.{{OM}}
              <div class="TE T5 DispIB" @click="Delete2(index,index2)">✗</div>
            </div>&nbsp;
            <img :src="'UserUpload/static/After2021/Insert.png'" @click="SeleI1=index,SeleI2=index2" class="WH77"/>&nbsp;
          </div>
          <div v-if="SeleI1===index && SeleI2>=-1" class="XLR YC MT2">
            <div>添加成员：<input type="text" class="W26" v-model="NewMem" @focus="NewMem=null"/></div>&nbsp;
            <div class="AN ANYellow" @click="SetNewMem(SeleI1,SeleI2+1,NewMem)">确认</div>
          </div>
        </div>
        <div v-if="SeleI4===index || SeleI4===-1" class="XLR YC MTB2">
          插入标题<img src="../../../static/gif2/ArrowR.gif" style="width: 1rem;">
          <input type="text" class="W40" v-model="NewTeam" @focus="NewTeam=null,SeleI4=index"/>
          <div></div>
          <div class="AN5 AN52" @click="SetNewTeam(index+1,NewTeam)">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {CloneObject, myMongoDBPost} from '@/components/SharedVues/Shared0.js'

  export default {
    name: "Act1BMList",
    props: ['ActId', 'OP'],
    data() {
      return {
        BMList: [],
        NewTeam: '',
        //NewIndex: 0,
        NewMem: '',
        SeleI1: -1,
        SeleI2: -1,
        // SeleI3: -1,
        SeleI4: -1,
        // Loaded: false
        ActTitle: ''
      }
    },
    mounted() {
      let that = this;
      myMongoDBPost("GetDocs/Acts", {_id: this.ActId}, function (Docs) {
        if (Docs.length > 0) {
          that.BMList = Docs[0].BMList;
          that.ActTitle = Docs[0].Title;
        }
        if (that.BMList.length > 0) {
          for (let i = 0; i < that.BMList.length; i++) that.BMList[i].Index = i + 1;
        }
        //that.Loaded = true
      })
    },
    methods: {
      FromCSQs() {
        let that = this;
        myMongoDBPost("GetDocs/CSQs", {Title: this.ActTitle}, function (Docs) {
          for (let i = 0; i < Docs[0].Teams.length; i++) that.BMList.splice(i, 0, {
            Index: i + 1,
            TeamName: Docs[0].Teams[i].TeamName,
            Members: Docs[0].Teams[i].Members
          });
          myMongoDBPost("UpdateDoc/Acts", {UPTJ: {_id: that.ActId}, UPObj: {BMList: that.BMList}}, function (Docs) {
          })
        })
      },
      SetNewTeam(index, NewTeam) {
        let that = this;
        that.SeleI4 = -1;
        that.BMList.splice(index, 0, {Index: index, TeamName: NewTeam, Members: []});
        myMongoDBPost("UpdateDoc/Acts", {UPTJ: {_id: this.ActId}, UPObj: {BMList: that.BMList}}, function (Docs) {
          that.NewTeam = '';
          for (let i = 0; i < that.BMList.length; i++) that.BMList[i].Index = i + 1;
        })
      },
      SetNewMem(index, index2, NewMem) {
        let that = this;
        that.BMList[index].Members.splice(index2, 0, NewMem);
        that.SeleI1 = -1;
        that.SeleI2 = -2;
        myMongoDBPost("UpdateDoc/Acts", {UPTJ: {_id: this.ActId}, UPObj: {BMList: that.BMList}}, function (Docs) {
        })
      },
      Delete1(index) {
        let that = this;
        that.BMList.splice(index, 1);
        myMongoDBPost("UpdateDoc/Acts", {UPTJ: {_id: this.ActId}, UPObj: {BMList: that.BMList}}, function (Docs) {
          for (let i = 0; i < that.BMList.length; i++) that.BMList[i].Index = i + 1;
        })
      },
      Delete2(index, index2) {
        let that = this;
        that.BMList[index].Members.splice(index2, 1);
        myMongoDBPost("UpdateDoc/Acts", {UPTJ: {_id: this.ActId}, UPObj: {BMList: that.BMList}}, function (Docs) {
        })
      },
      SetNewIndex(index, NewIndex) {
        let that = this, Obj = CloneObject(this.BMList[index]);
        Obj.Index = NewIndex;
        that.BMList.splice(index, 1);
        that.BMList.splice(NewIndex, 0, Obj);
        for (let i = 0; i < that.BMList.length; i++) that.BMList[i].Index = i + 1;
        //that.SeleI3 = -1;
        myMongoDBPost("UpdateDoc/Acts", {UPTJ: {_id: this.ActId}, UPObj: {BMList: that.BMList}}, function (Docs) {
          //that.NewIndex = null;
        })
      }
    }
  }
</script>

<style scoped>

</style>
