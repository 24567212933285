<template>
    <div>
        <div class="T2" @click="OpenPopup()">喜好<br>时间</div>
        <PopTitle v-if="Show3" :Title="Msg.ItemStr.split(':')[1]+'项目喜好时间窗设置'" @FromPopUp="SaveTimeWinStrs">
            <TopMenu2 :Menus="Menus" :BVal="0" @TopMenu2="TopMenu2">
                <div class="MP4">请根据当前项目特点选择(可以多选)：</div>
                <div v-if="TypeSele===0">
                    <table style="background-color:#fff;">
                        <thead>
                        <tr class="TF">
                            <th>时段</th>
                            <th v-for="(w,index) in ['一','二','三','四','五','六','日']" :key="index">
                                {{w}}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(HS,index1) in HourSegs" :key="index1">
                            <td>&nbsp;{{HS}}&nbsp;</td>
                            <td v-for="(Week,index2) in Weeks" :key="index2">

                                <img v-if="HSs[(index1*7)+index2]" @click="ReSetTimeWinStrs(0,index1,index2,false)"
                                     src="../../../static/img/XMYY/TimeWindowOpen.png" class="WH64">

                                <img v-else @click="ReSetTimeWinStrs(0,index1,index2,true)"
                                     src="../../../static/img/XMYY/TimeWindowClose.png" class="WH64">

                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="T2 XLR MP4">
                        备注：
                        <div>
                            <div v-for="(H,index) in HourSeg1" :key="index">
                                {{index+1}}. {{H}}
                            </div>
                        </div>
                        <div>
                            <div v-for="(H,index) in HourSeg2" :key="index">
                                {{index+5}}. {{H}}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="TypeSele===1">
                    <table style="background-color:#fff;">
                        <thead>
                        <tr class="TF">
                            <th>年份</th>
                            <th v-for="(M,index) in ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']" :key="index">
                                {{M}}
                            </th>
                            <th class="T3">十一</th>
                            <th class="T3">十二</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(Y,index1) in Years" :key="index1">
                            <td>{{Y}}</td>
                            <td v-for="(M,index2) in Months" :key="index2">
                                <img v-if="YMs[(index1*12)+index2]" @click="ReSetTimeWinStrs(1,index1,index2,false)"
                                     src="../../../static/img/XMYY/TimeWindowOpen.png" class="WH55">
                                <img v-else @click="ReSetTimeWinStrs(1,index1,index2,true)"
                                     src="../../../static/img/XMYY/TimeWindowClose.png" class="WH55">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="TypeSele===2" class="XLR MP4 BoxGray1">&nbsp;
                    <div>
                        <div class="AlignC TF BottGray">&nbsp;&nbsp;全体公民假&nbsp;&nbsp;</div>
                        <div class="TG" v-for="(x,index) in Hs1" :key="index">
                            <div v-if="Holidays2[index]" @click="ReSetTimeWinStrs(2,index,0,false)" class="MT2 BoxBR TE">
                                {{x}}
                                <el-icon>
                                    <Check/>
                                </el-icon>
                            </div>
                            <div v-else @click="ReSetTimeWinStrs(2,index,0,true)" class="MT2 ML2">{{x}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="AlignC TF BottGray">&nbsp;&nbsp;部分公民假&nbsp;&nbsp;</div>
                        <div class="TG" v-for="(x,index) in Hs2" :key="index">
                            <div v-if="Holidays2[index+Hs1.length]" @click="ReSetTimeWinStrs(2,index+Hs1.length,0,false)"
                                 class="MT2 BoxBR TE">
                                {{x}}
                                <el-icon>
                                    <Check/>
                                </el-icon>
                            </div>
                            <div v-else @click="ReSetTimeWinStrs(2,index+Hs1.length,0,true)" class="MT2 ML2">{{x}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="AlignC TF BottGray">&nbsp;&nbsp;周末&nbsp;&nbsp;</div>
                        <div class="TG" v-for="(x,index) in Hs3" :key="index">
                            <div v-if="Holidays2[index+Hs1.length+Hs2.length]"
                                 @click="ReSetTimeWinStrs(2,index+Hs1.length+Hs2.length,0,false)" class="MT2 BoxBR TE">
                                {{x}}
                                <el-icon>
                                    <Check/>
                                </el-icon>
                            </div>
                            <div v-else @click="ReSetTimeWinStrs(2,index+Hs1.length+Hs2.length,0,true)" class="MT2 ML2">{{x}}
                            </div>
                        </div>
                    </div>&nbsp;
                </div>
            </TopMenu2>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show4 = !Show4">
                已设时间窗列表
                <div v-if="Show4">
                    <el-icon>
                        <ArrowDown/>
                    </el-icon>
                </div>
                <div v-else>
                    <el-icon>
                        <ArrowUp/>
                    </el-icon>
                </div>
            </div>
            <div v-if="Show4" class="T3 TF XLR">
                &nbsp;
                <div>
                    <div v-for="(TS,index) in TWSs1" :key="index">
                        {{index+1}}. {{TimeWinStrsToShow(TS)}}
                    </div>
                </div>
                <div>
                    <div v-for="(TS,index) in TWSs2" :key="index">
                        {{index+1+TWSs1.length}}. {{TimeWinStrsToShow(TS)}}
                    </div>
                </div>
                &nbsp;
            </div>

        </PopTitle>
    </div>
</template>

<script>
    import SideMenu2 from "./SideMenu2";
    import {myMongoDBPost, Weeks, HourSegs, Years, Months, Holidays} from '@/components/SharedVues/Shared0.js';
    import PopTitle from "../MyPopWin/PopTitle";
    import TopMenu2 from "@/components/SharedVues/TopMenu2";

    export default {
        name: "TimeWindowSet",

        props: ['Msg'],
        components: {
            TopMenu2,
            PopTitle,
            SideMenu2
        },
        data() {
            return {
                Menus: ['某日时段', '某年某月', '节假日'],
                Weeks: Weeks,
                HourSegs: HourSegs,
                Years: Years,
                Months: Months,
                Holidays: Holidays,

                Show3: false,
                Show4: false,
                TypeSele: 0,

                HourSeg1: ["晨练=5:00～7:00", "早点=7:30～8:30", "上午=8:30～11:30", "午餐=11:30～13:30"],
                HourSeg2: ["下午=15:00～16:30", "课外=16:30～18:30", "晚餐=18:30～20:00", "晚上=20:00～23:00"],
                HSs: [],
                YMs: [],
                Holidays2: [],
                Hs1: ['元旦', '春节', '清明节', '五一劳动节', '端午节', '中秋节', '十一国庆节'],
                Hs2: ['妇女节', '青年节', '儿童节', '建军节'],
                Hs3: ['星期六', '星期日'],
                TimeWinStrs: '',
                TWSs1: [],
                TWSs2: [],
            }
        },
        computed: {
            CurrItemType() {
                return this.Menus[this.TypeSele];
            }
        },
        mounted() {//解析当前时间窗字符串

            for (let i = 0; i < 7 * 8; i++) this.HSs.push(false);
            for (let i = 0; i < 12 * Years.length; i++) this.YMs.push(false);//这样是不行的==>this.Years2=(new Array(Years.length));
            for (let i = 0, len = Holidays.length; i < len; i++) this.Holidays2.push(false);
            //console.log("1.this.TimeWinStrs=" + this.TimeWinStrs)
            for (let i = 0, len = this.Msg.bySets.length; i < len; i++)
                if (this.Msg.bySets[i].ItemStr === this.Msg.ItemStr) {
                    if (this.Msg.bySets[i].TimeWinStrs) this.TimeWinStrs = this.Msg.bySets[i].TimeWinStrs;
                }
            //console.log("2.this.TimeWinStrs=" + this.TimeWinStrs)
            let that = this;
            for (let HS = 0; HS < 8; HS++) //1.先解析时段
                for (let Week = 0; Week < 7; Week++)
                    if (that.TimeWinStrs.split(";").indexOf("HS:" + Week + "," + HS) >= 0) that.HSs[HS * 7 + Week] = true;

            for (let Yn = 0; Yn < Years.length; Yn++) //2.年月
                for (let Mn = 0; Mn < 12; Mn++)
                    if (that.TimeWinStrs.split(";").indexOf("YM:" + Mn + "," + Yn) >= 0) {
                        //console.log("that.TimeWinStrs=" + that.TimeWinStrs + "    ．．．．=" + "YM:" + Mn + "," + Yn);
                        //console.log("Yn=" + Yn + "    Mn=" + Mn);
                        that.YMs[Yn * 12 + Mn] = true;
                    }
            //console.log(that.YMs)
            that.Holidays2.forEach(function (x, index) {//3.节假日
                that.Holidays2[index] = (that.TimeWinStrs.indexOf("H:" + index + ";") >= 0);
            });
        },
        methods: {
            FillTWSs12() {
                let TWSs = this.TimeWinStrs.split(';');
                TWSs.splice(TWSs.length - 1, 1);
                this.TWSs1 = [];
                this.TWSs2 = [];
                let L1 = Math.ceil(TWSs.length / 2);
                for (let i = 0; i < L1; i++) this.TWSs1.push(TWSs[i]);
                for (let i = 0; i < TWSs.length - L1; i++) this.TWSs2.push(TWSs[i + L1]);
            },
            //eventFromSideMenu2(data) {this.TypeSele = data;},
            TopMenu2(Val) {
                this.TypeSele = Val;
                //console.log("TopMenu2(Val)=" + (Val + 3));
            },
            bySetSave(TimeWinStrs) {
                let bySets = this.Msg.bySets;
                for (let i = 0, len = bySets.length; i < len; i++)
                    if (bySets[i].ItemStr === this.Msg.ItemStr) {
                        bySets[i].TimeWinStrs = TimeWinStrs;
                        break;
                    }
                if (this.$store.state.UserName !== '游客')
                    myMongoDBPost("UpdateDoc/bySet0", {
                        UPTJ: {UserName: this.$store.state.UserName, ItemStr: this.Msg.ItemStr},
                        UPObj: {TimeWinStrs: TimeWinStrs}
                    }, function (data2) {
                    })
            },

            ReSetTimeWinStrs(N, index1, index2, Val) {
                let that = this;
                //console.log(N, index1, index2, Val);
                if (N === 0) that.HSs.splice((index1 * 7) + index2, 1, Val);
                if (N === 1) that.YMs.splice((index1 * 12) + index2, 1, Val);
                if (N === 2) that.Holidays2.splice(index1, 1, Val);

                this.TimeWinStrs = '';//时间窗字符串清空
                for (let HS = 0; HS < 8; HS++) //1.先重设时段
                    for (let Week = 0; Week < 7; Week++)
                        if (that.HSs[HS * 7 + Week]) that.TimeWinStrs += "HS:" + Week + "," + HS + ";";

                for (let Yn = 0; Yn < Years.length; Yn++) //2.重设年月
                    for (let Mn = 0; Mn < 12; Mn++)
                        if (that.YMs[Yn * 12 + Mn]) that.TimeWinStrs += "YM:" + Mn + "," + Yn + ";";


                that.Holidays2.forEach(function (x, index) {//6节假日
                    if (x) that.TimeWinStrs += "H:" + index + ";";
                });
                that.FillTWSs12();
                //console.log("that.TimeWinStrs=" + that.TimeWinStrs)
            },
            SaveTimeWinStrs() {
                let that = this;
                that.ReSetTimeWinStrs();
                that.bySetSave(that.TimeWinStrs);
                that.Show3 = false;
                that.$emit("eventFromTWS", that.TimeWinStrs.split(";").length - 1)
            },
            TimeWinStrsToShow(aTimeWinStr) {
                let Weeks = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
                let Hours = ['晨练', '早点', '上午', '午餐', '下午', '课外', '晚餐', '晚上'];
                let ts = '';
                if (aTimeWinStr.length > 0) {
                    let ASD = aTimeWinStr.split(":")[1], tt = aTimeWinStr.split(":")[0];
                    let tw = parseInt(ASD.split(",")[0]),
                        tp = (tt === 'Y' || tt === 'M' || tt === 'H') ? 0 : parseInt(ASD.split(",")[1]);
                    //console.log("tt,tw,tp=", tt, tw, tp);
                    if (tt === 'HS') ts = Weeks[tw] + Hours[tp];
                    if (tt === 'YM') ts = this.Years[tp] + "年" + this.Months[tw] + "月";
                    if (tt === 'H') ts = Holidays[tw];
                }
                return ts
            },
            OpenPopup() {
                this.Show3 = true;
                this.TypeSele = 0;
            }
        }
    }
</script>

<style scoped>
    .WH55 {
        width: 5mm;
        height: 5mm;
    }
</style>
