<template>
    <div>
        <PopUpDown :Title="(OP==='新增'?'开放':'VIP')+'共享卡建卡信息'" :ANs="2" Pos="btt" @FromPop5="Save">
            <div v-if="Show>-2" class="PA3 TF">
                <div class="XL YC">
                    场馆：
                    <div class="XLR YC BoxGray1 TG" @click="Show=3">
                        <div class="PA2">{{CurrTool.SiteShortName}}</div>
                        <el-icon>
                            <ArrowDown/>
                        </el-icon>
                    </div>
                </div>
                <div>本卡可刷： <input type="number" class="W12 MT2" v-model.number="CurrTool.CurrTimes"
                                  @focus="CurrTool.CurrTimes=null"/> 人次
                </div>
                <div>每人次＝ <input type="number" class="W12 MT2" v-model.number="CurrTool.OneFYB"
                                 @focus="CurrTool.OneFYB=null"/> 个泛约币
                </div>
                <div v-if="CardType==='VIP' || OP==='修改'">　
                    <div class="XLR YC MR4 MT2">
                        <div class="PA3 TF BottO">允许刷卡人员：</div>
                        　
                        <div @click="InputI=4" class="TG XL YC">
                            <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;添加
                        </div>
                        　
                    </div>
                    <div v-for="(A,i) in CurrTool.MyVips" :key="i" class="DispIB TC">
                        {{A}}
                        <div class="T5 TE DispIB" @click="Delete('A',i)">ㄨ</div>
                        ；&nbsp;
                    </div>
                </div>
                <div>
                    有效时段：<input type="text" class="W50 MT2" v-model="CurrTool.Text" @focus="CurrTool.Text=null"/>
                </div>
                <div class="MT2">
                    场地管理员：{{CurrTool.SiteAdmins[0]}};{{CurrTool.SiteAdmins[1]}};{{CurrTool.SiteAdmins[2]}}
                </div>
                <MySele1 v-if="Show===3" :Opts="MySiteShortNames" :ColN="3" AddAN="更多..."
                         @FromMySele1="SetSite"></MySele1>
            </div>
            <PopOneAN v-if="Show===-2" @FromPopUp="SetValidMySites()">
                <SetMySites :ItemStr="CurrTool.ItemStr" @FromSetMySites="SetValidMySites()"></SetMySites>
            </PopOneAN>
            <MyInput v-if="InputI===4" Title="允许刷卡泛约用户名" Type="text" :Begin="''" @FromMyI="MyInputVal"></MyInput>
        </PopUpDown>
    </div>
</template>

<script>
    import {AutoAlert, myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MyInput from "../SharedVues/MyInput";

    import STable from "../SharedVues/STable";

    import MySele1 from "../SharedVues/MySele1";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import ShowUpdateP from "../MyPVTs/ShowUpdateP";
    import MySelectStr from "../SharedVues/MySelectStr";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import SetMySites from "../M4_Sites/SetMySites";
    import FYArea from "../SharedVues/FYArea";

    export default {
        name: "GXCardPub",
        props: ['OP', 'ItemStr', 'CardType'],
        components: {
            FYArea,
            SetMySites,
            PopOneAN,
            MySelectStr,
            ShowUpdateP,
            SeleOneItem,
            PopUpDown,
            MySele1,
            STable,
            MyInput,
            PopTitle,
            ShowUpdatePVT
        },
        data() {
            return {
                CurrTool: {
                    ItemStr: this.ItemStr,
                    CardType: this.OP === '新增' ? this.CardType : '',
                    UserName: this.$store.state.UserName,//普通卡时为卡主
                    Certified: this.$store.state.Certified,//卡主用户级别
                    UserLogo: this.$store.state.UserLogo,//卡主用户Logo

                    MyVips: [],

                    Title: '',//项目名称☛地点提示及场馆名称卡名称

                    SiteAdmins: [],//共享卡时场馆方刷卡员的用户名=最多允许3个
                    Cipher: '',//Cipher='建卡申请' 或 场馆反馈的建卡口令或卡号
                    Text: '',
                    OneFYB: 0,//每次刷卡时的应刷金额
                    CurrTimes: 0,//当前卡剩余金额

                    Province: '',//场馆Province
                    City: '',//场馆City
                    DC: '',

                    Address: '',
                    Longi: 116.38,//经度
                    Lati: 39.9,//纬度
                    SiteName: '',
                    SiteShortName: '',//SiteShortName
                },
                InputI: -1,
                Show: -1,
                MySites: [],
                MySiteShortNames: []
            }
        },
        mounted() {
            this.CurrTool.SiteShortName = '?　?　?';
            if (this.OP === '新增')
                AutoAlert('建新卡须与场地管理员核实信息', '最好面对面创建！');
            else
                this.CurrTool = this.CardType;
            this.SetValidMySites();
        },
        methods: {
            MyInputVal(Val) {
                let that = this;
                if (Val !== '无' && Val !== '') {
                    myMongoDBPost("GetDocs/fyuser0", {UserName: Val}, function (Docs) {
                        if (Docs.length === 0) AutoAlert('添加失败', '不是有效的泛约用户名!'); else that.CurrTool.MyVips.push(Val);
                    })
                }
                this.InputI = -1;
            },
            Delete(aUserName, index) {
                this.CurrTool.MyVips.splice(index, 1);
            },
            SetValidMySites() {
                let that = this;
                this.Show = -1;
                that.MySites = that.MySiteShortNames = [];
                myMongoDBPost("GetValidMySites", {
                    UserName: this.$store.state.UserName,
                    ItemStr: that.CurrTool.ItemStr
                }, function (Docs1) {
                    that.MySites = Docs1[0].MySites;
                    for (let i = 0, len = Docs1[0].MySites.length; i < len; i++) that.MySiteShortNames.push(Docs1[0].MySites[i].SiteShortName);
                    //that.MySiteShortNames.push('更多...');
                })
            },
            SetSite(Val) {
                let that = this;
                if (Val === '') this.Show = -1;
                else if (Val === '更多...' || Val === '无') {
                    AutoAlert('共享卡必须与卡主的喜好场地相关联', '请先勾选配置一下!');
                    this.Show = -2;
                } else {
                    myMongoDBPost("GetDocs/fySites", {SiteShortName: Val}, function (Docs) {
                        if (Docs.length === 0) AutoAlert('场地原始数据已遭破坏', '请联系该场地管理员重建');
                        else if (Docs[0].SiteAdmins.length === 0) AutoAlert('该场地没有管理员', '无法创建共享卡'); else {
                            that.CurrTool.Province = Docs[0].Province;
                            that.CurrTool.City = Docs[0].City;
                            that.CurrTool.DC = Docs[0].DC;
                            that.CurrTool.Address = Docs[0].Address;
                            that.CurrTool.SiteName = Docs[0].SiteName;
                            that.CurrTool.SiteShortName = Val;
                            that.CurrTool.SiteAdmins = Docs[0].SiteAdmins;
                            that.CurrTool.Longi = Docs[0].Longi;
                            that.CurrTool.Lati = Docs[0].Lati;
                            that.CurrTool.ScrollTexts = Docs[0].ScrollTexts;
                        }
                    });
                    this.Show = -1;
                }
            },
            Save(Val) {
                let that = this;
                this.CurrTool.Title = this.CurrTool.SiteShortName + " ☞ 卡主：" + this.CurrTool.UserName;
                if (Val === -1) this.$emit("GXCardPub", that.CurrTool.SiteShortName);
                else if (that.CurrTool.Title === '' || that.CurrTool.CardType === '普通' && that.CurrTool.OneFYB <= 0 || that.CurrTool.CurrTimes <= 0 || that.CurrTool.SiteShortName === '') {
                    AutoAlert('建卡信息有误', '请检查标题或金额是否正确！');
                    that.$emit("GXCardPub", that.CurrTool.SiteShortName);
                } else {
                    if (that.CurrTool.Cipher === '') that.CurrTool.Cipher = '建卡申请';
                    myMongoDBPost("GetDocs/Acts", {Title: that.CurrTool.SiteShortName + '刷卡会员排名争夺赛'}, function (Docs) {
                        if (Docs.length === 0) myMongoDBPost("PutDoc/Acts", {
                            Index: 0,
                            UserName: that.$store.state.UserName,
                            Certified: that.$store.state.Certified,
                            UserLogo: that.$store.state.UserLogo,

                            Title: that.CurrTool.SiteShortName + '刷卡会员排名争夺赛',//活动名称或标题
                            HoldStyle: '开放式',
                            Scale: '不限人数',
                            ScaleVal: -1,
                            ActStyle: '单场地多循环排名赛',
                            ItemStr: that.ItemStr,
                            JFPool: that.CurrTool.SiteShortName,
                            BMList: [],


                            Province: that.$store.state.FYArea.split(":")[1],
                            City: that.$store.state.FYArea.split(":")[2],
                            DC: that.$store.state.FYArea.split(":")[3],

                            Pic: '',
                            Video: '',
                            Text: that.CurrTool.SiteName,
                            Time: 0,
                            EndBool: 3,
                            ScoringMethod: "按胜场次"
                        }, function (data) {
                        });
                    })

                    myMongoDBPost("PutDoc/SharedCards", that.CurrTool, function (data) {
                        that.$emit("GXCardPub", that.CurrTool.SiteShortName);
                    });
                }
            }
        }
    }
</script>

<style scoped>
</style>

