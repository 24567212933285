<template>
    <div v-if="ShowZG">
        <div v-if="Cell.DocBool===7" class="XLR YC LH300">
            <h5>&nbsp;&nbsp;战果：&nbsp;&nbsp;</h5>
            <div class="MR4 LH120">
                <div class="T3 BottGray">裁判员：{{Cell.Judge}}</div>
                <div v-if="Cell.ZXn>0">
                    <div v-if="Cell.DLStyle==='单打挑战'">
                        泛约币总量：{{(Cell.L2s.length-Cell.L1s.length)*Cell.ZXn*2}}
                    </div>
                    <div v-if="Cell.DLStyle==='双打挑战'">
                        泛约币总量>={{Cell.L2s.length*Cell.ZXn}}
                    </div>
                    <div v-if="Cell.DLStyle==='团体挑战' || Cell.DLStyle==='团体对抗'">
                        泛约币总量：{{(Cell.L1s.length+Cell.L2s.length)*Cell.ZXn}}
                    </div>
                    <div v-if="Cell.DLStyle==='名次争夺' || Cell.DLStyle==='擂台争霸'">
                        泛约币总量：{{Cell.L2s.length*Cell.ZXn}}
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="XLR YC LH300">
            <h5>&nbsp;&nbsp;比分登录：&nbsp;&nbsp;</h5>
            <div v-if="Role === '泛约裁判员' && Cell.ZXn>0" class="XLR YC">
                <div class="AlignC LH120" @click="RefundCancel">
                    <el-icon><Service/></el-icon>
                    <div class="T4 TB">退币取消</div>
                </div>
                <img src="../../../UserUpload/static/Img/Award.png" class="WHaa MLR4" @click="Award"/>
            </div>
            <div v-if="Role === '泛约裁判员' && Cell.ZXn===0" class="PA3 BGBlue TW" @click="SetDocBool7">宣布结束</div>
        </div>
        <div v-if="Cell.DocBool===7" class="MP2">{{Cell.ZG}}</div>
        
        <div v-if="Cell.DLStyle==='单打挑战' || Cell.DLStyle==='双打挑战'">
            <div class="XLR YC BD_Top2_Bott1">
                <div class="W30 TE">擂主</div>
                <div class="W30 TB">攻擂者</div>
                <div class="W16 TG">比分</div>
                <div class="W24 TC_Yellow2">战果</div>
            </div>
            <div v-for="(L1,index1) in Cell.L1s" :key="index1">
                <div v-for="(G,index2) in L1.Guests" :key="index2">
                    <div class="XLR YC BottGray">
                        <div v-if="Cell.DLStyle==='单打挑战'" class="XLR YC ">
                            <div class="W30 TE">{{L1.Host}}</div>
                            <div class="W30 TB">{{G}}</div>
                        </div>
                        <div v-if="Cell.DLStyle==='双打挑战' && G.length>1" class="XLR YC ">
                            <div class="W30 T3 TE">
                                <div>{{L1.Host1}}</div>
                                <div>{{L1.Host2}}</div>
                            </div>
                            <div class="W30 T3 TB">
                                <div>{{G[0]}}</div>
                                <div>{{G[1]}}</div>
                            </div>
                        </div>
                        <div v-if="!(Cell.DLStyle==='双打挑战' && G.length<2)" class="XLR YC">
                            <div class="MP2 TW BD_Radius1 BGGreen" @click="SetShowBF1(index1,index2)">{{L1.BFs[index2]}}</div>
                            <div v-if="WhoWin(L1.BFs[index2])==='擂主胜'" class="W24 TE">擂主胜</div>
                            <div v-if="WhoWin(L1.BFs[index2])==='攻方胜'" class="W24 TB">攻方胜</div>
                            <div v-if="WhoWin(L1.BFs[index2])==='未决'" class="W24 TF">未决</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="Cell.DLStyle==='团体挑战'">
            <div v-if="Cell.ZGs.length>0">
                <div class="XLR YC BD_Top2_Bott1">
                    <div class="W12 TC T3 AlignC">场序</div>
                    <div class="W24 TE">主 队</div>
                    <div class="W24 TB">客 队</div>
                    <div class="W20 TG AlignC">比分</div>
                    <div class="W20 TC_Yellow2 AlignC">战果</div>
                </div>
                <div v-for="(ZG,index1) in Cell.ZGs" :key="index1">
                    <div class="XLR YC BottGray">
                        <div class="W12 TC AlignC">{{index1+1}}</div>
                        <div v-if="Cell.L2s.length===4 && index1===2" class="W24 TE">
                            {{ZG.A1}}
                            <div>{{ZG.A2}}</div>
                        </div>
                        <div v-else class="W24 TE MTB2">{{ZG.A}}</div>
                        
                        <div v-if="Cell.L2s.length===4 && index1===2" class="W24 TB">
                            {{ZG.X1}}
                            <div>{{ZG.X2}}</div>
                        </div>
                        <div v-else class="W24 TB">{{ZG.X}}</div>
                        
                        <div class="PA3 AlignC TW BD_Radius1 BGGreen" @click="SetShowTTBF(inde1)">{{ZG.BF}}</div>
                        <div v-if="WhoWin(ZG.BF)==='擂主胜'" class="W20 TE AlignC">擂主胜</div>
                        <div v-if="WhoWin(ZG.BF)==='攻方胜'" class="W20 TB AlignC">攻方胜</div>
                        <div v-if="WhoWin(ZG.BF)==='未决'" class="W20 TF AlignC">未决</div>
                    </div>
                </div>
            </div>
            <div v-else class="MP2 AlignC">报名人数不足</div>
        </div>
        <div v-if="Cell.DLStyle==='团体对抗'">
            <div v-if="Cell.ZGs.length>0">
                <div class="XLR">
                    <div class="W25">
                        <div class="W25 H86 XYC BDLightGray TF"> 主\客</div>
                        <div class="W25 H86 XYC BDLightGray TC" v-for="(L1,index) in Cell.L1s" :key="index">{{L1}}</div>
                    </div>
                    <DLCell2 :CurrI="CurrI" :SwiperH="SwiperH" :LoopAs="LoopAs" :Cell="Cell" @FromDLCell2="SetShowTDBF"></DLCell2>
                </div>
                <div class="AlignC MP2">当前总比分☞{{TDZBF}}</div>
            </div>
            <div v-else class="MP2 AlignC">报名人数不足</div>
            <div class="WH55"></div>
        </div>
        <div v-if="(Cell.DLStyle==='名次争夺' || Cell.DLStyle==='擂台争霸') && Cell.ZGs.length>0">
            <div class="XLR">
                <div class="W25">
                    <div class="W25 H86 XYC BDLightGray TF">单循环</div>
                    <div class="W25 H86 XYC BDLightGray TC" v-for="(L2,index) in Cell.L2s" :key="index">{{L2}}</div>
                </div>
                <DLCell2 :CurrI="CurrI" :SwiperH="SwiperH" :LoopAs="LoopAs" :Cell="Cell" @FromDLCell2="SetShowTDBF"></DLCell2>
            </div>
        </div>
        <div v-if="Cell.DLStyle==='名次争夺' && PHBs.length>0">
            <h5>&nbsp;&nbsp;实时排名情况：&nbsp;&nbsp;</h5>
            <div class="XLR YC MT2">
                <div class="W17 AlignC BDLightGray TC">
                    <div>当前</div>
                    排名
                </div>
                <div class="W32 AlignC TC NoWrap">用户名</div>
                <div class="W17 AlignC   BDLightGray TC">
                    <div>争夺</div>
                    名次
                </div>
                <div class="W17 AlignC  BDLightGray TC">
                    <div>获胜</div>
                    局数
                </div>
                <div class="W17 AlignC  BDLightGray TC">
                    <div>小分</div>
                    合计
                </div>
            </div>
            <div class="XLR YC" v-for="(PHB,index) in PHBs" :key="index"
                 style="'background-color:'+(PHB.MC===PHB.FightFor && PHB.MC<(PHBs.length / 3) ?' #fadada': '#fff')">
                <div class="W17 H86 XYC BDLightGray">{{PHB.MC}}
                    <div v-if="PHB.MC===PHB.FightFor && PHB.MC<(PHBs.length / 3)" class="TE">✓</div>
                </div>
                <div class="W32 H86 XYC BDLightGray TC">{{PHB.UserName}}</div>
                <div class="W17 H86 XYC BDLightGray TC">{{PHB.FightFor}}
                    <div v-if="PHB.MC===PHB.FightFor && PHB.MC<(PHBs.length / 3)" class="TE">✓</div>
                </div>
                <div class="W17 H86 XYC BDLightGray TC">{{PHB.Vn}}</div>
                <div class="W17 H86 XYC BDLightGray TC">{{PHB.XF}}</div>
            </div>
        </div>
        <div v-if="Cell.DLStyle==='擂台争霸' && PHBs.length>0">
            <h5>&nbsp;&nbsp;实时排名情况：&nbsp;&nbsp;</h5>
            <div class="XLR YC MT2">
                <div class="W25 H86 XYC BDLightGray TC">当前排名</div>
                <div class="W25 H86 XYC BDLightGray TC">用户名</div>
                <div class="W25 H86 XYC BDLightGray TC">获胜局数</div>
                <div class="W25 H86 XYC BDLightGray TC">小分合计</div>
            </div>
            <div class="XLR YC" v-for="(PHB,index) in PHBs" :key="index" :style="'background-color:'+(PHB.MC===1 ?' #fadada': '#fff')">
                <div class="W25 H86 XYC BDLightGray">{{PHB.MC}}</div>
                <div class="W25 H86 XYC BDLightGray TC">{{PHB.UserName}}</div>
                <div class="W25 H86 XYC BDLightGray TC">{{PHB.Vn}}</div>
                <div class="W25 H86 XYC BDLightGray TC">{{PHB.XF}}</div>
            </div>
        </div>
        
        
        <PopBott v-if="ShowBF">
            <div class="MP2" v-for="(BF,index1) in BFs" :key="index1">
                <div class="BottGray">{{BF.Type}}：</div>
                <div class="ML2">
                    <div class="MP2 DispIB TB" v-for="(Opt,index2) in BF.Opts" :key="index2" @click="FillBF(Opt)">
                        {{Opt}}
                    </div>
                </div>
            </div>
            <div class="XLR_Center">☝</div>
        </PopBott>
        <PopBott v-if="Show===2">
            <div class="W85 MP2 BD_Radius1 BGWhite AlignC">
                <div class="MP2" v-for="(U,index2) in Cell.L1s[CurrIndex1].UserNames" :key="index2">
                    <el-icon>
                        <Check/>
                    </el-icon>
                    <div class="DispIB ML2" @click="SetWinner(index2)">{{U}}</div>
                </div>
            </div>
        </PopBott>
        <div class="BGLightBlue ">
            <div class="PA2"></div>
            <h5 class="BottGray">&nbsp;&nbsp;报名情况&nbsp;&nbsp;</h5>
            <div class="PA2">
                <div v-if="Cell.DLStyle==='擂台争霸' || Cell.DLStyle==='名次争夺'">
                    <div class="MP2 DispIB" v-for="(L2,index2) in Cell.L2s" :key="index2">{{L2}}</div>
                </div>
                <div v-else>
                    <div class="PA2">
                        <h5 class="BottGray">&nbsp;&nbsp;擂主&nbsp;&nbsp;</h5>
                        <div v-for="(L1,index1) in Cell.L1s" :key="index1" class="MP2 DispIB BGDeepGray TW PA3 NoWrap">
                            <div v-if="Cell.DLStyle==='单打挑战'">{{L1.Host}}</div>
                            <div v-if="Cell.DLStyle==='双打挑战'" class="DispIB">
                                {{L1.Host1}}/
                                <div v-if="L1.Host2!==''">{{L1.Host2}}</div>
                            </div>
                            <div v-if="Cell.DLStyle==='团体挑战' || Cell.DLStyle==='团体对抗'">{{L1}}</div>
                        </div>
                    </div>
                    <div class="PA2">
                        <h5 class="BottGray">&nbsp;&nbsp;攻擂&nbsp;&nbsp;</h5>
                        <div v-for="(L1,index1) in Cell.L1s" :key="index1">
                            <div v-if="Cell.DLStyle==='单打挑战'" class="BGDeepGray TW MP2">
                                <div class="BottGray"> {{L1.Host}}：</div>
                                <div v-if="L1.Guests.length===0" class="AlignC MP2">( 空无一人 )</div>
                                <div v-else>
                                    <div class="PA3 DispIB" v-for="(L1G,index2) in L1.Guests" :key="index2">{{L1G}}</div>
                                </div>
                            </div>
                            <div v-if="Cell.DLStyle==='双打挑战' && L1.Host2!==''" class="MP2 BGDeepGray TW ">
                                <div class="BottGray"> {{L1.Host1}}/{{L1.Host2}}</div>
                                <div v-if="L1.Guests.length===0" class="AlignC MP2">( 空无一人 )</div>
                                <div v-else>
                                    <div class="PA3 DispIB" v-for="(L1Gs,index2) in L1.Guests" :key="index2">
                                        {{L1Gs[0]}}/
                                        <div v-if="L1Gs.length===2">{{L1Gs[1]}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="Cell.DLStyle==='团体挑战' || Cell.DLStyle==='团体对抗'">
                            <div v-for="(L2,index2) in Cell.L2s" :key="index2">
                                <div class="MP2 DispIB TF">{{L2}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="XLR_Center">☝</div>
                </div>
            </div>
        </div>
    </div>
    <div v-else style="box-sizing:border-box ;max-width: 100vw">
        <div v-if="Role==='泛约裁判员' && CanPub" class="MP2">
            <div class="XLR YC">
                <h5 class="BottGray">&nbsp;&nbsp;发布打擂活动信息&nbsp;&nbsp;</h5>
                <div class="BD_Radius1 BGDeepGray TW PA2" @click="CancellCell">取消</div>
            </div>
            <div class="XLR YC">
                打擂方式：
                <div class="DispIB BDLightGray BD_Radius1 MP2 TG" @click="Show=1">{{Cell.DLStyle}}</div>
                <div></div>
                <div></div>
                <div @click="ShowArticleFind=Cell.RuleCode">
                    <el-icon><Comment/></el-icon>
                </div>
            </div>
            <div class="XL YC">
                每人预收：
                <input class="W12 BDLightGray" type="number" v-model="Cell.ZXn" @focus="Cell.ZXn = null" @blur="ZXnInputed"/>个泛约币
            </div>
            
            <div class="MT2">
                本次打擂活动补充说明：
                <div class="XR MT2">
                    <textarea class="WH85vw30vh" v-model="Cell.Annotate" @blur="Changed=true"/>
                </div>
            </div>
            <div v-if="Changed" class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="SaveOneCell">提 交</div>
            <div v-else class="BD_Radius1 BGLightGray T5 TW XYC H3 MT2">提 交</div>
            <PopBott v-if="Show===1">
                <div class="MP2" v-for="(DLQ,index) in DLQs" :key="index">
                    {{index+1}}.
                    <div class="DispIB ML2" @click="SetDLStyle(index,DLQ)">{{DLQ}}</div>
                </div>
            </PopBott>
        </div>
        <div v-else class="LH200 MP2">
            <div v-if="Cell.DLStyle!=='??????'">
                <div class="XLR YC">打擂方式：
                    <div class="TE">{{Cell.DLStyle}}</div>&nbsp;
                    <div @click="ShowArticleFind=Cell.RuleCode">
                        <el-icon><Comment/></el-icon>
                        规则
                    </div>
                </div>
                <div class="XLR">
                    <div>报名费用：<span class="TE">{{Cell.ZXn}}</span>个泛约币</div>
                    <el-button type="warning" @click="GBBool=true">购币</el-button>
                </div>
                
                
                <div class="XLR YC">
                    补充说明：
                    <div class="T3 BottGray">裁判员：{{Cell.Judge}}</div>
                </div>
                <div class="WH85vw30vh BDLightGray BD_Radius1 MT2">
                    <p>{{Cell.Annotate}}</p>
                </div>
                
                <div v-if="Cell.DLStyle==='擂台争霸' || Cell.DLStyle==='名次争夺'">
                    <div class="XLR YC">
                        <h5 class="BottGray">&nbsp;&nbsp;报名参加&nbsp;&nbsp;</h5>
                        <div class="DispIB TF T3">(报名后不可撤销!)</div>
                    </div>
                    <div class="MP2 DispIB" v-for="(L2,index2) in Cell.L2s" :key="index2">
                        <div v-if="Cell.DLStyle==='擂台争霸' && L2!==$store.state.UserName">不公开</div>
                        <div v-else>{{L2}}</div>
                    </div>
                    <div v-if="Cell.L2s.indexOf($store.state.UserName)<0" class="MP2 DispIB BGBlue TW PA3 BD_Radius1 NoWrap"
                         @click="AddL2(index)">
                        报名
                    </div>
                    <div v-if="Cell.DLStyle==='名次争夺' && Cell.L2s.indexOf($store.state.UserName)>=0" class="XLR YC MP2 BGBlue TW BD_Radius1">
                        争夺名次：
                        <input class="W12 BDLightGray" type="number" v-model="Cell.L1s[Cell.L2s.indexOf($store.state.UserName)]"
                               @blur="ToNChanged(Cell.L2s.indexOf($store.state.UserName),Cell.L1s[Cell.L2s.indexOf($store.state.UserName)])"/>
                        <div></div>
                        <div class="PA3 NoWrap" @click="ToNChanged2">确认</div>
                    </div>
                </div>
                <div v-else>
                    <!-----------------------------擂主报名---------------------------------->
                    <div class="XLR YC">
                        <h5 class="BottGray">&nbsp;&nbsp;擂主报名&nbsp;&nbsp;</h5>
                        <div class="DispIB TF T3">(报名后不可撤销!)</div>
                    </div>
                    
                    <div v-for="(L1,index1) in Cell.L1s" :key="index1" class="MP2 DispIB BGDeepGray TW PA3 NoWrap">
                        <div v-if="Cell.DLStyle==='单打挑战'">{{L1.Host}}</div>
                        <div v-if="Cell.DLStyle==='双打挑战'" class="DispIB">
                            {{L1.Host1}}/
                            <div v-if="L1.Host2!==''">{{L1.Host2}}</div>
                            <el-button v-if="L1.Host2==='' && Cell.L2s.indexOf($store.state.UserName)<0" type="primary" @click="AddHost2(index1)">报名
                            </el-button>
                        </div>
                        <div v-if="Cell.DLStyle==='团体挑战' || Cell.DLStyle==='团体对抗'">{{L1}}</div>
                    </div>
                    
                    <div v-if="Cell.DLStyle==='团体挑战' && Cell.L1s.indexOf($store.state.UserName)<0 && Cell.L2s.indexOf($store.state.UserName)<0 && Cell.L1s.length<5"
                         class="DispIB MP2  BGBlue TW BD_Radius1 NoWrap" @click="AddTL1">报名
                    </div>
                    <div v-if="Cell.DLStyle==='团体挑战' && Cell.L1s.length===5" class="XR TF PA2">团体挑战赛5人上限，名额已满。</div>
                    <div v-if="Cell.DLStyle==='团体对抗' && Cell.L1s.indexOf($store.state.UserName)<0 && Cell.L2s.indexOf($store.state.UserName)<0">
                        <div class="MP2 DispIB BGBlue TW BD_Radius1 NoWrap" @click="AddTL1">报名</div>
                    </div>
                    
                    <div v-if="Cell.DLStyle==='单打挑战' && Cell.L2s.indexOf($store.state.UserName)<0"
                         class=" MP2 DispIB BGBlue TW PA3 BD_Radius1 NoWrap" @click="Add1Host(index)">报名
                    </div>
                    <div v-if="Cell.DLStyle==='双打挑战' && Cell.L2s.indexOf($store.state.UserName)<0"
                         class="MP2 DispIB BGBlue TW BD_Radius1 NoWrap"
                         @click="Add2Host1">报名
                    </div>
                    
                    <!-----------------------------攻擂报名---------------------------------->
                    <div class="XLR YC MT2">
                        <h5 class="BottGray">&nbsp;&nbsp;攻擂报名&nbsp;&nbsp;</h5>
                        <div class="DispIB TF T3">(报名后不可撤销!)</div>
                    </div>
                    <div v-for="(L1,index1) in Cell.L1s" :key="index1">
                        <div v-if="Cell.DLStyle==='单打挑战'" class="BGDeepGray TW MP2">
                            <div class="BottGray"> {{L1.Host}}：</div>
                            <div class="PA3 DispIB" v-for="(L1G,index2) in L1.Guests" :key="index2">{{L1G}}</div>
                            <div v-if="Cell.L2s.indexOf($store.state.UserName)<0" class=" MP2 DispIB BGBlue TW PA3 BD_Radius1 NoWrap"
                                 @click="Add1Guests(index1)">报名
                            </div>
                        </div>
                        <div v-if="Cell.DLStyle==='双打挑战' && L1.Host2!==''" class="MP2 BGDeepGray TW ">
                            <div class="BottGray"> {{L1.Host1}}/{{L1.Host2}}</div>
                            <div class="PA3 DispIB" v-for="(L1Gs,index2) in L1.Guests" :key="index2">
                                {{L1Gs[0]}}/
                                <div v-if="L1Gs.length===2">{{L1Gs[1]}}</div>
                                <div v-if="L1Gs.length===1 && Cell.L2s.indexOf($store.state.UserName)<0"
                                     class="DispIB BGBlue TW PA3 BD_Radius1 NoWrap" @click="Add2Guests2(index1,index2)">报名
                                </div>
                            </div>
                            <div v-if="Cell.L2s.indexOf($store.state.UserName)<0" class="MP2 DispIB BGBlue TW PA3 BD_Radius1 NoWrap"
                                 @click="Add2Guests1(index1)">报名
                            </div>
                        </div>
                    </div>
                    <div v-if="Cell.DLStyle==='团体挑战' || Cell.DLStyle==='团体对抗'">
                        <div v-for="(L2,index2) in Cell.L2s" :key="index2" class="DispIB MP2">
                            {{L2}}
                        </div>
                    </div>
                    <div v-if="Cell.DLStyle==='团体挑战' && Cell.L2s.length===5" class="XR TF MP2">团体挑战赛5人上限，名额已满。</div>
                    <div v-if="Cell.DLStyle==='团体挑战' && Cell.L2s.length<5 && Cell.L1s.indexOf($store.state.UserName)<0 && Cell.L2s.indexOf($store.state.UserName)<0"
                         class="MP2 DispIB BGBlue TW BD_Radius1 NoWrap" @click="AddL2">报名
                    </div>
                    <div v-if="Cell.DLStyle==='团体对抗' && Cell.L1s.indexOf($store.state.UserName)<0 && Cell.L2s.indexOf($store.state.UserName)<0"
                         class="MP2 DispIB BGBlue TW BD_Radius1 NoWrap" @click="AddL2">报名
                    </div>
                    <div class="XLR_Center">☝</div>
                </div>
            </div>
            <div v-else class="MT4B2 AlignC LH300">当前时间地点没有打擂活动！</div>
        </div>
    </div>
    
    <PopBlank v-if="ShowArticleFind!==''">
        <ShowArtical :FindWhat="ShowArticleFind" @FromShowArticle="ShowArticleFind=''"></ShowArtical>
    </PopBlank>
    <PopTitle v-if="GBBool" :title="$store.state.UserName+'的泛约币'"  @FromPopUp="GBBool=false">
        <ZXGL></ZXGL>
    </PopTitle>
</template>

<script>
    import {myMongoDBPost, myDataFileGet, Compare, CloneObject} from '@/components/SharedVues/Shared0.js'
    import PopBott from "../MyPopWin/PopBott";
    import DLCell2 from "./DLCell2";
    import ShowArtical from "../MyPVTs/ShowArtical";
    import PopBlank from "../MyPopWin/PopBlank";
    import PopTitle from "../MyPopWin/PopTitle";
    import ZXGL from "../IndependentVues/ZXGL";

    export default {
        name: "DLCell",
        components: {ZXGL, PopTitle, PopBlank, ShowArtical, DLCell2, PopBott},
        props: ['Role0', 'Cell0', 'ColIndex0', 'CurrCellJudge0'],
        data() {
            return {
                Role: '',
                Cell: '',
                ColIndex: '',
                CurrCellJudge: '',
                CanPub: true,
                DLQs: ['单打挑战', '双打挑战', '团体挑战', '团体对抗', '名次争夺', '擂台争霸'],
                Changed: false,
                Show: -1,
                Articles: [],

                ShowZG: false,

                ShowBF: false,
                CurrIndex1: -1,
                CurrIndex2: -1,
                BFs: [
                    {Type: '3局2胜制', Opts: ['0:2', '1:2', '2:0', '2:1']},
                    {Type: '5局3胜制', Opts: ['0:3', '1:3', '2:3', '3:2', '3:1', '3:0']},
                    {Type: '7局4胜制', Opts: ['0:4', '1:4', '2:4', '3:4', '4:0', '4:1', '4:2', '4:3']}],
                CurrFill: '',
                PHBs: [],
                TDZBF: '',//团体对抗时的实时总比分情况

                SrcPath1: 'http://www.fy135.vip/UserUpload/static/Img/',//业务域名是大写,就必须用大写
                CurrI: 0,
                SwiperH: 400,
                LoopAs: [],
                ShowArticleFind: '',
                GBBool:false
            }
        },
        mounted() {
            this.Role = this.Role0;
            this.Cell = this.Cell0;
            this.ColIndex = this.ColIndex0;
            this.CurrCellJudge = this.CurrCellJudge0;
            console.log(this.Role, this.ColIndex, this.CurrCellJudge)

            if (this.ColIndex < 3) {//此判断在frontpage.js中还有1个
                if (this.Cell.DLStyle === '团体挑战' && this.Cell.ZGs.length === 0) {
                    if (this.Cell.L1s.length === 3 && this.Cell.L2s.length === 3) this.Cell.ZGs.push(
                        {A: this.Cell.L1s[0], X: this.Cell.L2s[0], BF: "？:？"},
                        {A: this.Cell.L1s[1], X: this.Cell.L2s[1], BF: "？:？"},
                        {A: this.Cell.L1s[2], X: this.Cell.L2s[2], BF: "？:？"},
                        {A: this.Cell.L1s[0], X: this.Cell.L2s[1], BF: "？:？"},
                        {A: this.Cell.L1s[1], X: this.Cell.L2s[0], BF: "？:？"}
                    );
                    if (this.Cell.L1s.length === 4 && this.Cell.L2s.length === 4) this.Cell.ZGs.push(
                        {A: this.Cell.L1s[0], X: this.Cell.L2s[0], BF: "？:？"},
                        {A: this.Cell.L1s[1], X: this.Cell.L2s[1], BF: "？:？"},
                        {A1: this.Cell.L1s[2], A2: this.Cell.L1s[3], X1: this.Cell.L2s[2], X2: this.Cell.L2s[3], BF: "？:？"},
                        {A: this.Cell.L1s[0], X: this.Cell.L2s[1], BF: "？:？"},
                        {A: this.Cell.L1s[1], X: this.Cell.L2s[0], BF: "？:？"}
                    );
                    if (this.Cell.L1s.length === 5 && this.Cell.L2s.length === 5) this.Cell.ZGs.push(
                        {A: this.Cell.L1s[0], X: this.Cell.L2s[0], BF: "？:？"},
                        {A: this.Cell.L1s[1], X: this.Cell.L2s[1], BF: "？:？"},
                        {A: this.Cell.L1s[2], X: this.Cell.L2s[2], BF: "？:？"},
                        {A: this.Cell.L1s[3], X: this.Cell.L2s[3], BF: "？:？"},
                        {A: this.Cell.L1s[4], X: this.Cell.L2s[4], BF: "？:？"}
                    );
                }
                if (this.Cell.DLStyle === '团体对抗' && this.Cell.ZGs.length === 0 && this.Cell.L1s.length > 1 && this.Cell.L2s.length > 1) {
                    for (let i = 0; i < this.Cell.L1s.length; i++) {
                        this.Cell.ZGs.push([])
                        for (let j = 0; j < this.Cell.L2s.length; j++)
                            this.Cell.ZGs[i].push("？:？")
                    }
                }
                if (this.Cell.DLStyle === '名次争夺' || this.Cell.DLStyle === '擂台争霸') {
                    if (this.Cell.ZGs.length === 0 && this.Cell.L2s.length > 1)
                        for (let i = 0; i < this.Cell.L2s.length; i++) {
                            this.Cell.ZGs.push([])
                            for (let j = 0; j < this.Cell.L2s.length; j++)
                                if (i > j) this.Cell.ZGs[i].push("？:？"); else this.Cell.ZGs[i].push("—")
                        }
                    else this.FillPHBs();
                }
                if (this.Cell.DLStyle !== '??????') this.ShowZG = true;
                if (['团体对抗', '名次争夺', '擂台争霸'].indexOf(this.Cell.DLStyle) >= 0)
                    this.LoopAs = new Array(Math.ceil(this.Cell.L2s.length / 3)), this.SwiperH = (this.Cell.L2s.length + 1) * 90;
                if (this.Cell.DLStyle === '团体对抗') this.SwiperH = (this.Cell.L1s.length + 1) * 90;
                this.SetTDZBF();
            }

            let TJ1 = this.Cell.Judge === '??' || this.Cell.DocBool === null;//一个空Cell
            if (this.Role === '泛约裁判员') {
                if (!this.CurrCellJudge) {//裁判员在本Cell没有权限，则本发布被禁止,但可以参加别人的活动
                    this.CanPub = false;
                    alert('提示:您具备泛约裁判员资格,但您未设定在本场地发布打擂活动!');
                } else if (!TJ1 && this.Cell.Judge !== this.$store.state.UserName) {
                    //裁判员遇到了另外的裁判员开展的活动，则本发布被禁止,但可以参加别人的活动
                    this.CanPub = false;
                    alert('当前时间、当前地点已经有人在组织活动了。请您换个时间或场地发布打擂活动，或参加当前活动!');
                }
            } else if (TJ1) this.$emit('FromDLCell') //其它用户点击了无效的打擂活动,则直接返回
        },
        methods: {
            WhoWin(aBF) {
                if (aBF === null) return '无效!'
                let ASD = aBF.split(":");
                if (parseInt(ASD[0]) > parseInt(ASD[1])) return '擂主胜';
                else if (parseInt(ASD[1]) > parseInt(ASD[0])) return '攻方胜'; else return '未决'
            },
            FillPHBs() {
                let that = this;
                that.PHBs = [];
                for (let i = 0; i < that.Cell.L2s.length; i++) {
                    if (this.Cell.DLStyle === '名次争夺')
                        that.PHBs.push({MC: 1, UserName: that.Cell.L2s[i], FightFor: that.Cell.L1s[i], Vn: 0, XF: 0, VX: 0});
                    else
                        that.PHBs.push({MC: 1, UserName: that.Cell.L2s[i], Vn: 0, XF: 0, VX: 0});
                }
                for (let i = 0; i < that.Cell.L2s.length; i++)
                    for (let j = 0; j < that.Cell.L2s.length; j++) {
                        let aBF = that.Cell.ZGs[i][j].split(":");
                        if (aBF && parseInt(aBF[0]) > 0 && parseInt(aBF[1]) > 0) {
                            that.PHBs[i].XF += parseInt(aBF[0]);
                            that.PHBs[j].XF += parseInt(aBF[1]);
                        }
                        if (aBF && parseInt(aBF[0]) > parseInt(aBF[1])) that.PHBs[i].Vn += 1;
                        if (aBF && parseInt(aBF[0]) < parseInt(aBF[1])) that.PHBs[j].Vn += 1;
                    }
                for (let i = 0; i < that.PHBs.length; i++) that.PHBs[i].VX = that.PHBs[i].Vn * 1000 + that.PHBs[i].XF;
                console.log(that.PHBs)
                that.PHBs.sort(Compare('VX')).reverse();

                let LastMC = 1;
                for (let i = 1; i < that.PHBs.length; i++) {
                    if (that.PHBs[i].VX !== that.PHBs[i - 1].VX) LastMC += 1;
                    that.PHBs[i].MC = LastMC;
                }
            }
            ,
            //-----------------------------发布打擂活动-------------------------------------------------------

            SetDLStyle(index, DLStyle) {
                let RuleCodes = ['DLQ1', 'DLQ2', 'DLQ3', 'DLQ4', 'DLQ5', 'DLQ6'];
                this.Cell.Judge = this.$store.state.UserName;
                this.Cell.DLStyle = DLStyle;
                this.Cell.RuleCode = RuleCodes[index];
                this.Show = -1;
            }
            ,
            ZXnInputed() {
                console.log(this.Cell)
                if (this.Cell.ZXn === 0 || this.Cell.ZXn >= 10) this.Changed = true; else alert('泛约币数量必须为0或者>=10：0表示“无币打擂活动”，其它值表示押存数量。');
            }
            ,
            CancellCell() {
                let that = this, x = that.Cell;
                that.Cell.DocBool = (x.TW === '上' ? 0 : (x.TW === '下' ? 1 : 2));
                let TJ = {ItemStr: this.$store.state.DefaultItem, SiteId: x.SiteId, Y: x.Y, M: x.M, D: x.D, TW: x.TW};//限定一个Cell的条件},
                myMongoDBPost('RemoveDoc/DLQs', TJ, function (Docs) {
                    that.$emit('FromDLCell');
                })
            }
            ,
            //================================打擂活动报名=======================================================
            Add1Host() {
                let that = this;
                this.RecycleZX(this.Cell.ZXn * 10, function (Bool) {
                    if (Bool) {
                        that.Cell.L2s.push(this.$store.state.UserName);//同一个Cell下报过名的就不能再报了
                        that.Cell.L1s.push({Host: this.$store.state.UserName, Guests: [], BFs: []});
                        that.SaveOneCell2('不退出');
                    }
                });
            }
            ,//单打挑战擂主报名时,每次挑战最多允许10人,因此先冻结10个人次的泛约币,如果挑战人数不足10人,则退回
            Add1Guests(index1) {
                let that = this;
                this.RecycleZX(this.Cell.ZXn, function (Bool) {
                    if (Bool) {
                        that.Cell.L2s.push(this.$store.state.UserName);//同一个Cell下报过名的就不能再报了
                        that.Cell.L1s[index1].Guests.push(this.$store.state.UserName);
                        that.Cell.L1s[index1].BFs.push("？:？");
                        that.SaveOneCell2('不退出');
                    }
                });
            }
            ,//单打挑战攻擂报名

            Add2Host1() {
                let that = this;
                this.RecycleZX(this.Cell.ZXn * 6, function (Bool) {
                    if (Bool) {
                        that.Cell.L2s.push(this.$store.state.UserName);//同一个Cell下报过名的就不能再报了
                        that.Cell.L1s.push({Host1: this.$store.state.UserName, Host2: '', Guests: [], BFs: []});
                        that.SaveOneCell2('不退出');
                    }
                })
            }
            ,//双打挑战 擂主队员1报名,双打挑战每次打擂者最多允许6对组合,因此擂主报名时先冻结每个擂主先冻结6人次的泛约币,如果挑战者不足6对,则自动退回
            AddHost2(index1) {
                let that = this;
                this.RecycleZX(this.Cell.ZXn * 6, function (Bool) {
                    if (Bool) {
                        that.Cell.L2s.push(this.$store.state.UserName);//同一个Cell下报过名的就不能再报了
                        that.Cell.L1s[index1].Host2 = this.$store.state.UserName;
                        that.SaveOneCell2('不退出');
                    }
                })
            }
            ,//双打挑战 擂主队员2报名,双打挑战每次打擂者最多允许6对组合,因此擂主报名时先冻结每个擂主先冻结6人次的泛约币,如果挑战者不足6对,则自动退回

            Add2Guests2(index1, index2) {
                let that = this;
                this.RecycleZX(this.Cell.ZXn, function (Bool) {
                    if (Bool) {
                        that.Cell.L2s.push(this.$store.state.UserName);//同一个Cell下报过名的就不能再报了
                        that.Cell.L1s[index1].Guests[index2].push(this.$store.state.UserName);
                        that.Cell.L1s[index1].BFs[index2] = "？:？";
                        that.SaveOneCell2('不退出');
                    }
                })
            }
            ,//双打挑战 攻擂队员2报名
            Add2Guests1(index1) {
                let that = this;
                this.RecycleZX(this.Cell.ZXn, function (Bool) {
                    if (Bool) {
                        that.Cell.L2s.push(this.$store.state.UserName);//同一个Cell下报过名的就不能再报了
                        that.Cell.L1s[index1].Guests.push([this.$store.state.UserName]);
                        that.SaveOneCell2('不退出');
                    }
                })
            }
            ,//双打挑战 攻擂队员1报名

            AddTL1() {
                let that = this;
                this.RecycleZX(this.Cell.ZXn, function (Bool) {
                    if (Bool) {
                        that.Cell.L1s.push(this.$store.state.UserName);//同一个Cell下报过名的就不能再报了
                        that.SaveOneCell2('不退出');
                    }
                })
            }
            ,//团体挑战擂主报名

            AddL2() {
                let that = this;
                this.RecycleZX(this.Cell.ZXn, function (Bool) {
                    if (Bool) {
                        that.Cell.L2s.push(this.$store.state.UserName);
                        if (that.Cell.DLStyle === '名次争夺') that.Cell.L1s.push(1);
                        that.SaveOneCell2('不退出');
                    }
                })
            }
            ,
            SaveOneCell() {
                this.SaveOneCell2('退出');
            }
            ,
            SaveOneCell2(Bool) {
                let that = this, x = that.Cell;
                this.Cell.DocBool = (x.DLStyle.indexOf('争') >= 0 ? 6 : 5);
                let TJ = {ItemStr: this.$store.state.DefaultItem, SiteId: x.SiteId, Y: x.Y, M: x.M, D: x.D, TW: x.TW};//限定一个Cell的条件
                myMongoDBPost('UpdateDoc/DLQs', {UPTJ: TJ, UPObj: that.Cell}, function (Docs) {
                    if (Bool === '退出') that.$emit('FromDLCell')

                })
            }
            ,

            KeepZX(UserName, IOType, ZXn, Describe, Callback) {
                let Url0 = 'http://www.fy135.vip/DataFile/GetStrFromServer';
                let Url1 = 'http://www.fy135.vip/myMongoose/KeepZXs';
                myDataFileGet('DataFile/GetStrFromServer', function (Docs) {
                    console.log(Docs)
                    let aOBJ = {fyUserName: UserName, IOType: IOType, ZXn: ZXn, Describe: Describe + Docs + ZXn};
                    myMongoDBPost('KeepZXs', aOBJ, function (Docs) {
                        Callback();
                    })

                })
            }
            ,
            RecycleZX(NeedZXn, CallBack) {
                let that = this;
                myMongoDBPost('GetDocs/fyuser0', {UserName: this.$store.state.UserName}, function (Docs) {
                    let OldZX = Docs[0].ZX;
                    if (Docs[0].ZX < NeedZXn) {
                        alert('报名失败!，您现有泛约币:' + OldZX + "个;本次需要:" + NeedZXn + "个");
                        CallBack(false)
                    } else that.KeepZX(that.$store.state.UserName, '支', NeedZXn, '打擂报名成功', function () {
                        alert('报名成功!抵押泛约币:' + NeedZXn + "个");
                        CallBack(true);
                    })
                })
            }
            ,//回收泛约币尊享 如果回收成功, 则返回true


            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~记分与奖励~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            SetShowBF1(index1, index2) {
                if (this.Cell.DocBool === 7) return alert('比分已被冻结!');
                if (this.Cell.Judge === this.$store.state.UserName) {
                    this.CurrFill = '单双';
                    this.ShowBF = true;
                    this.CurrIndex1 = '单双';
                    this.CurrIndex2 = index2;
                } else alert('提示：只有当前裁判员才能填写比分');
            }
            ,
            SetShowTTBF(index1) {
                if (this.Cell.DocBool === 7) return alert('比分已被冻结!');
                if (this.Cell.Judge === this.$store.state.UserName) {
                    this.CurrFill = '团体挑战';
                    this.ShowBF = true
                    this.CurrIndex1 = index1;
                } else alert('提示：只有当前裁判员才能填写比分!');
            }
            ,
            SetShowTDBF(Val) {
                if (this.Cell.DocBool === 7) return alert('比分已被冻结!');

                if (this.Cell.Judge === this.$store.state.UserName) {
                    this.CurrFill = '团体对抗';
                    this.ShowBF = true;
                    this.CurrIndex1 = Val.X;
                    this.CurrIndex2 = Val.Y;
                } else alert('提示：只有当前裁判员才能填写比分!');
            }
            ,
            CloseBF() {
                this.ShowBF = false;
            }
            ,
            FillBF(BF) {
                let Winner = '', Loser = '';
                if (this.CurrFill === '单双') {
                    let index1 = this.CurrIndex1, index2 = this.CurrIndex2;
                    let asd = this.Cell.L1s[index1];
                    asd.BFs[index2] = BF;
                    this.Cell.L1s.splice(index1, 1, asd);
                    if (this.Cell.DLStyle === '单打挑战') {
                        Winner = this.Cell.L1s[index1].Host;
                        Loser = this.Cell.L1s[index1].Guests[index2];
                    }
                }
                if (this.CurrFill === '团体挑战') {
                    let index1 = this.CurrIndex1, index2 = this.CurrIndex2;
                    let asd = this.Cell.ZGs[index1];
                    asd.BF = BF;
                    this.Cell.ZGs.splice(index1, 1, asd);
                }
                if (this.CurrFill === '团体对抗') {
                    let index1 = this.CurrIndex1, index2 = this.CurrIndex2;
                    let asd = this.Cell.ZGs[index1];
                    asd[index2] = BF;
                    this.Cell.ZGs.splice(index1, 1, asd);
                    Winner = this.Cell.L1s[index1];
                    Loser = this.Cell.L2s[index1];
                    this.SetTDZBF();
                }
                if (this.Cell.DLStyle === '名次争夺' || this.Cell.DLStyle === '擂台争霸') this.FillPHBs();
                this.ShowBF = false;
                this.SaveOneCell2('不退出');

                if (Winner !== '' && Loser !== '') {//只有单打挑战和团体对抗与泛约积分挂钩
                    let aObj = {Winner: Winner, Loser: Loser, BF: BF, ItemStr: this.$store.state.DefaultItem}
                    myMongoDBPost('Act0FillToFymc', aObj, function (Docs) {
                    })
                }
            },
            SetDocBool7() {
                if (this.Cell.L2s.length === 0) return this.CancellCell();
                let that = this, x = that.Cell;
                let TJ = {ItemStr: this.$store.state.DefaultItem, SiteId: x.SiteId, Y: x.Y, M: x.M, D: x.D, TW: x.TW};//限定一个Cell的条件
                that.Cell.DocBool = 7;
                myMongoDBPost('UpdateDoc/DLQs', {UPTJ: TJ, UPObj: {DocBool: 7}}, function (Docs) {
                    alert('活动结束,比分已被冻结!');
                    that.$emit('FromDLCell')
                })
            }
            ,
            DLQDistributeZX(ZXs) {
                let that = this, x = that.Cell;
                let TJ = {SiteId: x.SiteId, Y: x.Y, M: x.M, D: x.D, TW: x.TW};//限定一个Cell的条件
                if (ZXs.length === 0) alert('比赛尚未结束，操作无效!'); else myMongoDBPost('DLQDistributeZX', ZXs, function (Docs) {
                    myMongoDBPost('UpdateDoc/DLQs', {UPTJ: TJ, UPObj: {DocBool: 7}}, function (Docs) {
                        that.Cell.DocBool = 7;
                        alert('获胜者泛约币奖励完毕!请各自进入泛约网查看验证!');
                        that.$emit('FromDLCell')

                    })

                })
            }
            ,
            DLQRefundZX(ZXs) {
                let that = this;
                myMongoDBPost('DLQRefundZX', ZXs, function (Docs) {
                    that.Cell.DocBool = 7;
                    that.SaveCell({ZG: '该打擂活动泛约币已退币清算！', DocBool: 7});
                    that.$emit('FromDLCell')
                })
            }
            ,
            RefundCancel() {
                let RefundZXs = [];
                if (this.Cell.ZXn > 0) {
                    if (this.Cell.DLStyle === '单打挑战')
                        for (let i = 0; i < this.Cell.L1s.length; i++) {
                            let Host = this.Cell.L1s[i].Host, LGs = this.Cell.L1s[i].Guests;
                            RefundZXs.push({UserName: Host, ZXn: (10 - LGs.length) * this.Cell.ZXn});
                        }
                    if (this.Cell.DLStyle === '双打挑战') //双打挑战时,和单打挑战的情况不一样,它是按有效比分数量,来计算实际攻擂人数的
                        for (let i = 0; i < this.Cell.L1s.length; i++) {
                            let Host1 = this.Cell.L1s[i].Host1, Host2 = this.Cell.L1s[i].Host2;
                            let ZXn = this.Cell.ZXn;
                            RefundZXs.push({UserName: Host1, ZXn: ZXn * 6});
                            if (Host2 !== "") RefundZXs.push({UserName: Host2, ZXn: ZXn * 6});
                            for (let j = 0; j < this.Cell.L1s[i].Guests.length; j++) {
                                if (this.Cell.L1s[i].Guests[j][0]) RefundZXs.push({UserName: this.Cell.L1s[i].Guests[j][0], ZXn: ZXn});
                                if (this.Cell.L1s[i].Guests[j][1]) RefundZXs.push({UserName: this.Cell.L1s[i].Guests[j][1], ZXn: ZXn});
                            }
                        }
                    if (this.Cell.DLStyle === '团体挑战' || this.Cell.DLStyle === '团体对抗') {
                        for (let i = 0; i < this.Cell.L1s.length; i++)
                            RefundZXs.push({UserName: this.Cell.L1s[i], ZXn: this.Cell.ZXn});
                        for (let i = 0; i < this.Cell.L2s.length; i++)
                            RefundZXs.push({UserName: this.Cell.L2s[i], ZXn: this.Cell.ZXn});
                    }
                    if (this.Cell.DLStyle === '名次争夺' || this.Cell.DLStyle === '擂台争霸')
                        for (let i = 0; i < this.Cell.L2s.length; i++)
                            RefundZXs.push({UserName: this.Cell.L2s[i], ZXn: this.Cell.ZXn});
                    this.DLQRefundZX(RefundZXs)
                } else alert('没有泛约币可退!');
            }
            ,
            SetTDZBF() {
                let HVn = 0, GVn = 0;//分别记录双方获胜的局数,最后获胜局数多的为胜方
                for (let i = 0; i < this.Cell.L1s.length; i++)
                    for (let j = 0; j < this.Cell.L2s.length; j++) {
                        let aBF = this.Cell.ZGs[i][j].split(":");
                        if (aBF && parseInt(aBF[0]) > parseInt(aBF[1])) HVn += 1;
                        if (aBF && parseInt(aBF[0]) < parseInt(aBF[1])) GVn += 1;
                    }
                this.TDZBF = HVn + ":" + GVn;
            }
            ,
            Award() {
                let ZXs = [];
                if (this.Cell.DLStyle === '单打挑战') {
                    let RefundZXs = [];
                    for (let i = 0; i < this.Cell.L1s.length; i++) {
                        let Host = this.Cell.L1s[i].Host, LGs = this.Cell.L1s[i].Guests;
                        if (LGs.length < 10) RefundZXs.push({UserName: Host, ZXn: (10 - LGs.length) * this.Cell.ZXn});
                        for (let j = 0; j < this.Cell.L1s[i].Guests.length; j++) {
                            let Guest = this.Cell.L1s[i].Guests[j], aBF = this.Cell.L1s[i].BFs[j];
                            let ZX2 = parseInt(this.Cell.ZXn * 2 * 0.2), ZX7 = parseInt(this.Cell.ZXn * 2 * 0.7);
                            if (aBF !== null) aBF = aBF.split(":");
                            console.log(Host, Guest, aBF, ZX2, ZX7)
                            if (aBF && parseInt(aBF[0]) > parseInt(aBF[1])) ZXs.push({
                                Judge: this.Cell.Judge,
                                JudgeZXn: ZX2,
                                Winner: Host,
                                WinnerZXn: ZX7
                            })
                            if (aBF && parseInt(aBF[0]) < parseInt(aBF[1])) ZXs.push({
                                Judge: this.Cell.Judge,
                                JudgeZXn: ZX2,
                                Winner: Guest,
                                WinnerZXn: ZX7
                            })
                            //如果未决,不退泛约币
                        }
                    }
                    this.DLQRefundZX(RefundZXs)
                }
                if (this.Cell.DLStyle === '双打挑战') {
                    let RefundZXs = [];//双打挑战时,和单打挑战的情况不一样,它是按有效比分数量,来计算实际攻擂人数的
                    for (let i = 0; i < this.Cell.L1s.length; i++) {
                        let Host1 = this.Cell.L1s[i].Host1, Host2 = this.Cell.L1s[i].Host2;
                        if (this.Cell.L1s[i].BFs.length > 0) {
                            let ZXn = (6 - this.Cell.L1s[i].BFs.length) * this.Cell.ZXn / 2;
                            if (this.Cell.L1s[i].BFs.length < 6) {
                                RefundZXs.push({UserName: Host1, ZXn: ZXn});
                                RefundZXs.push({UserName: Host2, ZXn: ZXn});
                            }//一人退一半

                            for (let j = 0; j < this.Cell.L1s[i].Guests.length; j++) {
                                let Guest1 = this.Cell.L1s[i].Guests[j][0];
                                if (this.Cell.L1s[i].Guests[j].length === 1)
                                    RefundZXs.push({UserName: Guest1, ZXn: this.Cell.ZXn});
                                else {
                                    let Guest2 = this.Cell.L1s[i].Guests[j][1];
                                    let aBF = this.Cell.L1s[i].BFs[j];
                                    let ZX2 = parseInt(this.Cell.ZXn * 2 * 0.2), ZX7 = parseInt(this.Cell.ZXn * 2 * 0.7);
                                    if (aBF !== null && aBF !== undefined) {
                                        aBF = aBF.split(":");
                                        if (aBF && parseInt(aBF[0]) > parseInt(aBF[1])) {
                                            ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: Host1, WinnerZXn: ZX7});
                                            ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: Host2, WinnerZXn: ZX7});
                                        }
                                        if (aBF && parseInt(aBF[0]) < parseInt(aBF[1])) {
                                            ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: Guest1, WinnerZXn: ZX7});
                                            ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: Guest2, WinnerZXn: ZX7});
                                        }
                                        //如果未决,不退泛约币
                                    }
                                }
                            }
                        } else if (Host2 === '') RefundZXs.push({UserName: Host1, ZXn: this.Cell.ZXn * 6});
                    }
                    this.DLQRefundZX(RefundZXs);
                }
                if (this.Cell.DLStyle === '团体挑战') {
                    let HVn = 0, GVn = 0;//主客双方有1方获胜局数>2,则为胜方，否则，比赛算作尚未结束
                    let SumZX = (this.Cell.L1s.length + this.Cell.L2s.length) * this.Cell.ZXn;//报名泛约币总数
                    for (let i = 0; i < this.Cell.ZGs.length; i++) {
                        let aBF = this.Cell.ZGs[i].BF.split(":");
                        if (aBF && parseInt(aBF[0]) > parseInt(aBF[1])) HVn += 1;
                        if (aBF && parseInt(aBF[0]) < parseInt(aBF[1])) GVn += 1;
                    }
                    if (HVn > 2 || GVn > 2) {
                        if (HVn > 2) {//擂主方获胜:SumZX分为this.Cell.L1s.length份
                            let ZX1 = SumZX / this.Cell.L1s.length;
                            let ZX2 = parseInt(ZX1 * 0.2), ZX7 = parseInt(ZX1 * 0.7);
                            for (let i = 0; i < this.Cell.L1s.length; i++)
                                ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: this.Cell.L1s[i], WinnerZXn: ZX7})
                            this.SaveCell({ZG: '擂主(守擂方)获胜,每人赢币:' + ZX7 + '个'});
                        }
                        if (GVn > 2) {//攻擂方获胜:SumZX分为this.Cell.L2s.length份
                            let ZX1 = SumZX / this.Cell.L2s.length;
                            let ZX2 = parseInt(ZX1 * 0.2), ZX7 = parseInt(ZX1 * 0.7);
                            for (let i = 0; i < this.Cell.L2s.length; i++)
                                ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: this.Cell.L2s[i], WinnerZXn: ZX7})
                            this.SaveCell({ZG: '攻擂方获胜,每人赢泛约币' + ZX7 + '个'});
                        }
                    }
                }
                if (this.Cell.DLStyle === '团体对抗') {
                    let SumZX = (this.Cell.L1s.length + this.Cell.L2s.length) * this.Cell.ZXn;//报名泛约币总数

                    let HVn = 0, GVn = 0;//分别记录双方获胜的局数,最后获胜局数多的为胜方
                    for (let i = 0; i < this.Cell.L1s.length; i++)
                        for (let j = 0; j < this.Cell.L2s.length; j++) {
                            let aBF = this.Cell.ZGs[i][j].split(":");
                            if (aBF && parseInt(aBF[0]) > parseInt(aBF[1])) HVn += 1;
                            if (aBF && parseInt(aBF[0]) < parseInt(aBF[1])) GVn += 1;
                        }
                    if (HVn > GVn || HVn < GVn) {//必须要决出胜负才有效
                        if (HVn > GVn) {//擂主方获胜:SumZX分为this.Cell.L1s.length份
                            let ZX1 = SumZX / this.Cell.L1s.length;
                            let ZX2 = parseInt(ZX1 * 0.2), ZX7 = parseInt(ZX1 * 0.7);
                            for (let i = 0; i < this.Cell.L1s.length; i++)
                                ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: this.Cell.L1s[i], WinnerZXn: ZX7})
                            this.SaveCell({ZG: HVn + ":" + GVn + '☞擂主(守擂方)获胜,每人赢币:' + ZX7 + '个'});
                        }
                        if (HVn < GVn) {//攻擂方获胜:SumZX分为this.Cell.L2s.length份
                            let ZX1 = SumZX / this.Cell.L2s.length;
                            let ZX2 = parseInt(ZX1 * 0.2), ZX7 = parseInt(ZX1 * 0.7);
                            for (let i = 0; i < this.Cell.L2s.length; i++)
                                ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: this.Cell.L2s[i], WinnerZXn: ZX7})
                            this.SaveCell({ZG: HVn + ":" + GVn + '☞攻擂方获胜,每人赢币:' + ZX7 + '个'});
                        }
                    }
                }
                if (this.Cell.DLStyle === '擂台争霸') {
                    let SumZX = this.Cell.L2s.length * this.Cell.ZXn;//报名泛约币总数
                    this.FillPHBs();
                    let VnN = 0;
                    for (let i = 0; i < this.PHBs.length; i++) if (this.PHBs[i].MC === this.PHBs[0].MC) VnN += 1;

                    if (VnN === 1) {
                        let ZX2 = parseInt(SumZX * 0.2), ZX7 = parseInt(SumZX * 0.7);
                        ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: this.PHBs[0].UserName, WinnerZXn: ZX7})
                        this.SaveCell({ZG: '擂台霸主☞' + this.PHBs[0].UserName + '，赢泛约币' + ZX7 + '个'});
                    } else alert('霸主只能有1人! 请启用加时赛!');
                }
                if (this.Cell.DLStyle === '名次争夺') {//有可能需要裁判人工介入，借用ZG作为操作状态记录标志
                    let AwardN = 0, SumZX = this.Cell.L2s.length * this.Cell.ZXn;//报名泛约币总数，裁判员和获奖者均分
                    this.FillPHBs();
                    for (let i = 0; i < (this.PHBs.length / 3); i++) if (this.PHBs[i].MC === this.PHBs[i].FightFor) AwardN += 1;
                    if (AwardN > 0) {
                        let ZX1 = SumZX / AwardN;//计算每1份是多少
                        let ZX2 = parseInt(ZX1 * 0.2), ZX7 = parseInt(ZX1 * 0.7);
                        for (let i = 0; i < (this.PHBs.length / 3); i++) //前1/3有效
                            if (this.PHBs[i].MC === this.PHBs[i].FightFor)
                                ZXs.push({Judge: this.Cell.Judge, JudgeZXn: ZX2, Winner: this.PHBs[i].UserName, WinnerZXn: ZX7});
                        this.SaveCell({ZG: '共有' + AwardN + "人押对有效名次。每人奖励" + ZX7 + "个泛约币！"});
                    } else {
                        ZXs.push({Judge: this.Cell.Judge, JudgeZXn: parseInt(SumZX * 0.7), Winner: '决不放弃', WinnerZXn: parseInt(SumZX * 0.3)});
                        if (this.Cell.L2s.length === 0) this.SaveCell({ZG: '很遗憾，本次活动没人报名！'}); else this.SaveCell({ZG: '很遗憾，没有人押对自己的名次！'});
                    }
                }
                this.DLQDistributeZX(ZXs);
            }
            ,//分发泛约币尊享
            ToNChanged(index, value) {
                this.Cell.L1s[index] = parseInt(value);
                this.Changed = true;
                this.SaveCell({L1s: this.Cell.L1s});
            }
            ,
            ToNChanged2() {
                this.Changed = true;
                this.SaveCell({L1s: this.Cell.L1s});
                alert('争夺名次已上报!');
                this.$emit('FromDLCell')
            }
            ,
            SaveCell(UPObj) {
                let that = this, x = that.Cell;
                if (UPObj.ZG) that.Cell.ZG = UPObj.ZG;
                let TJ = {SiteId: x.SiteId, Y: x.Y, M: x.M, D: x.D, TW: x.TW};//限定一个Cell的条件
                myMongoDBPost('UpdateDoc/DLQs', {UPTJ: TJ, UPObj: UPObj}, function (Docs) {
                })
            }
            ,
            SetShow2(index1) {
                this.CurrIndex1 = index1;
                this.Show = 2;
            }
            ,
            SetWinner(index2) {
                this.Cell.L1s[this.CurrIndex1].Winner = this.Cell.L1s[this.CurrIndex1].UserNames[index2];
                this.SaveCell({L1s: this.Cell.L1s});
            }
        }
    }
</script>

<style scoped>
    .W8 {
        width: 8vw;
        text-align: center;
        box-sizing: border-box;
        }
    
    .W16 {
        width: 16vw;
        text-align: center;
        box-sizing: border-box;
        }
    
    .W17 {
        width: 17vw;
        text-align: center;
        box-sizing: border-box;
        }
    
    .W24 {
        width: 24vw;
        text-align: center;
        box-sizing: border-box;
        }
    
    .W30 {
        width: 30vw;
        text-align: center;
        box-sizing: border-box;
        }
    
    .H86 {
        height: 86px;
        }
    
    .TC_Yellow2 {
        color: #4e3705d7;
        }
    
    .WH80 {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        }
    
    .T24 {
        font-size: 24px;
        }
</style>