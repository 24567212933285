<template>
    <div v-if="IsLoading">IsLoading...</div>
    <div v-else>
      <el-divider>用户权限资格审核</el-divider>
      <table  style="background-color:#fff;">
        <thead>
        <tr class="TF">
          <th>序号</th>
          <th>申请用户</th>
          <th>申请权限</th>
          <th>申请时间</th>
          <th>审核操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(K,index) in UserAskQXs" :key="index">
          <td>{{index+1}}</td>
          <td>{{K.UserName}}</td>
          <td>{{K.Key1}}</td>
          <td>{{StampToDate(K.STime)}}</td>
          <td>
            <div class="MP4 TB" @click="SetUserAskQXs(K._id,K.UserName,K.Key1)">同意</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
  import {myMongoDBPost, StampToDate} from '@/components/SharedVues/Shared0.js'

  export default {
    name: "QXCheck",
    data() {
      return {
        IsLoading: true,
        UserAskQXs: [],
        CurrUserBools: []
      }
    },
    mounted() {
      this.GetUserAskQXs();
    },
    methods: {
      StampToDate(Stamp) {
        return StampToDate(Stamp)
      },
      GetUserAskQXs() {
        let that = this;
        myMongoDBPost("GetDocs/USuggests", {SClass: "权限申请", Key2: "申请"}, function (Docs) {
          that.UserAskQXs = Docs;
          that.IsLoading = false
        });
      },
      SetUserAskQXs(Id, UserName, Key1) {
        let that = this, aObj1 = {UPTJ: {_id: Id}, UPObj: {Key2: "同意"}}, aObj2 = {UPTJ: {UserName: UserName}, UPObj: {$addToSet: {UserBools: Key1}}};
        that.IsLoading = false;
        myMongoDBPost("UpdateDoc/USuggests", aObj1, function (data2) {
          that.IsLoading = true;
          that.GetUserAskQXs();
          myMongoDBPost("UpdateDoc2/fyuser0", aObj2, function (data2) {
          })
        })
      }
    }
  }
</script>

<style scoped>
</style>
