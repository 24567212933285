<template>
    <div ref="ZY">
        <div v-if="$store.state.LandTo === 'ZY2'">
            <ZY3 v-if="CurrYY3" :Msg="{CurrYY:CurrYY3}" @FromZY3="$emit('FromZY')"></ZY3>
            <MyToast v-else :Tip="'已失效!'" @Tiped="$emit('FromZY')"></MyToast>
        </div>
        <div v-else>
            <br>
            <div class="XLR YC">
                <div class="W12"></div>
                <div class="TF">主约信息：{{ZYs.length}}条</div>
                <el-button-group>
                    <el-button type="primary" @click="ToCurr">当前</el-button>
                    <el-button type="primary" @click="ToHistory">历史</el-button>
                </el-button-group>
                <div></div>
            </div>
            <div class="BottGray">&nbsp;</div>
            <div v-if="Show">
                <div v-for="(x,I1) in ZYs" :key="I1">
                    <div class="XLR YC TG T4 MP4 BottGray" @click="SeleI=I1">
                        {{I1+1}}.{{x.meetTimeStr.substr(5,20)+'➱'+x.ItemStr.split(':')[2]+':'+x.ItemStr.split(':')[1]}}
                        <el-icon>
                            <ArrowRight/>
                        </el-icon>
                    </div>
                </div>
                <PopTitle v-if="SeleI !==-1"
                         :Title="ZYs[SeleI].ItemStr.split(':')[2]+'：'+ZYs[SeleI].ItemStr.split(':')[1]"
                         @FromPopUp="ToCurr">
                    <ZY3 :Msg="{CurrYY:ZYs[SeleI]}" @FromZY3="ToCurr"></ZY3>
                </PopTitle>
            </div>
            <div v-else>
                <div v-for="(x,I1) in ZYs" :key="I1">
                    <div class="XLR YC T4 TB MP4 BottGray" @click="SeleI=I1">
                        {{I1+1}}.{{x.meetTimeStr.substr(5,20)+'➱'+x.ItemStr.split(':')[2]+'：'+x.ItemStr.split(':')[1]}}

                        <el-icon>
                            <ArrowRight/>
                        </el-icon>
                    </div>
                </div>

                <PopTitle v-if="SeleI !==-1" :Title="ZYs[SeleI].ItemStr.split(':')[2]+':'+ZYs[SeleI].ItemStr.split(':')[1]"  @FromPopUp="ToHistory">
                    <div class="MP4 LH150 TF">
                        时间:{{ZYs[SeleI].meetTimeStamp}}<br>
                        地点：{{ZYs[SeleI].meetAddrStr}}
                        <div v-if="ZYs[SeleI].fySitefee>0" class="XLR">
                            <div>场地费：{{ZYs[SeleI].fySitefee}}</div>
                            <div>付费方式：{{ZYs[SeleI].paymentWay}}</div>&nbsp;
                        </div>
                        <div v-if="ZYs[SeleI].jdfy.length>0">简短附言：{{ZYs[SeleI].jdfy}}</div>
                        <div class="XR MR4 YC MT2">
                            <div class="TC">线下活动情况：&nbsp;</div>
                            <ShowPVT
                                    :PVT="{P:ZYs[SeleI].Pic,V:ZYs[SeleI].Video,T:ZYs[SeleI].Text,Update:false}"></ShowPVT>
                        </div>
                    </div>
                    <table style="background-color:#fff;">
                        <thead>
                        <tr class="TF">
                            <th>被约</th>
                            <th>情况</th>
                            <th>战果</th>
                            <th>履约情况</th>
                            <th>评价</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(y,I2) in ZYs[SeleI].by" :key="I2" style="line-height:1.3rem;">
                            <td style="text-align: left">{{I2+1}}.{{y._id}}</td>
                            <td>
                                <div v-if="y.xy==='同意'">
                                    <ShowPVT :PVT="{P:y.Pic,V:y.Video,T:y.Text,Update:false}"></ShowPVT>
                                </div>
                                <div v-else>被约:{{y.xy}}</div>
                            </td>
                            <td class="W26 LH150">{{y.zg}}</td>
                            <td>{{y.b_zylyqk}}</td>
                            <td>{{y.b_ts}}</td>
                        </tr>
                        </tbody>
                    </table>
                </PopTitle>
            </div>
        </div>
    </div>
</template>

<script>
    import {Compare, myMongoDBPost, UrlParse} from '@/components/SharedVues/Shared0.js'
    import ZY3 from "./ZY3";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import ShowPVT from "@/components/MyPVTs/ShowPVT";

    export default {
        name: "ZY",
        components: {ShowPVT, PopTitle, ZY3},
        data() {
            return {
                Show: true,
                ZYs: this.$store.state.ZYMsgs,//初值
                CurrYY3: null,
                SeleI: -1
            }
        },

        mounted() {
            let CurrYYId = this.$store.state.Bool3;//借助Bool3保存CurrYYId，在DL中赋值
            if (this.$store.state.LandTo === 'ZY2') {
                for (let i = 0, len = this.ZYs.length; i < len; i++) {
                    if (this.ZYs[i]._id === CurrYYId) {
                        this.CurrYY3 = this.ZYs[i];
                        break;
                    }
                }
            } else this.ToCurr();
        },
        methods: {

            SetSeleI(Val) {
                this.SeleI = Val;
            },
            ToCurr() {
                let that = this;
                this.SeleI = -1;
                myMongoDBPost("GetMyYYs", {
                    fyUserName: that.$store.state.UserName,
                    EndBool: false
                }, function (data) {//读取邀约信息数据
                    for (let i1 = 0, len1 = data.zys.length; i1 < len1; i1++)//为主约的每一个被约增加一个更新标志
                        for (let i2 = 0, len2 = data.zys[i1].by.length; i2 < len2; i2++)
                            data.zys[i1].by[i2].ChangedBool = 0;
                    data.zys.sort(Compare('Stamp'));
                    that.ZYs = data.zys.reverse();
                    that.Show = true
                });
            },
            ToHistory() {
                // this.ZYs = this.$store.state.ZYMsgs;
                let that = this;
                this.SeleI = -1;
                myMongoDBPost("GetMyYYs", {
                    fyUserName: that.$store.state.UserName,
                    EndBool: true
                }, function (data) {//读取邀约信息数据　
                    data.zys.sort(Compare('Stamp'));
                    that.ZYs = data.zys.reverse();
                    //let ZYs2=data.zys;
                    //for (let i = data.zys.length-1; i >0; i--) ZYs2.splice(data.zys.length - 1 - i, 1, data.zys[i]);//数组更新 数组刷新　数组元素 更新问题 刷新问题
                    //console.log(that.ZYs);
                    that.Show = false
                });

            }
        }
    }
</script>

<style scoped>
</style>
