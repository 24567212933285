<template>
    GXCard1
</template>

<script>
    export default {
        name: "GXCard1"
    }
</script>

<style scoped>

</style>