<template>
    <div class="carousel DispIB">
        <div class="carousel-inner DispIB" :style="{ 'transform': 'translateX(' + currentSlideIndex * -100 + '%)' }">
            <div v-for="(item, index) in ShowItems" :key="index" class="TF DispIB carousel-item">
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyMarquee",
        props: ['ShowItems'],

        data() {
            return {
                currentSlideIndex: 0
            };
        },
        mounted() {
            setInterval(this.next, 3000);
        },
        methods: {
            next() {
                this.currentSlideIndex = (this.currentSlideIndex + 1) % this.ShowItems.length;
            }
        }
    };
</script>

<style scoped>
    .carousel {
        position: relative;
        overflow: hidden;
        max-width: 45vw;
        margin: auto;
    }

    .carousel-inner {
        display: flex;
        transition: transform 0.5s ease;
    }

    .carousel-item {
        flex: 0 0 100%;
        height: 6mm;
        /* Set your slide's width here */
    }
</style>