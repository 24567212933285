<template>
    <div>
        <PopOneAN @FromPopUp="Quit">
            <div class="XLR YC BottGray"
                 style="position: fixed;width: 100vw;height:3.5rem;background-color:white;padding-top: 1mm;">
                <div class="W12"></div>
                <div class="AlignC">
                    <div class="BottGray">积分来源分布</div>
                    <div class="TF T2">&nbsp;网址：www.fy135.vip&nbsp;</div>
                </div>&nbsp;
                <div :class="'AN5 '+ (Show===1 ? 'AN52':'AN53')" @click="ReShow(1)">全显</div>
                <div :class="'AN5 '+ (Show===2 ? 'AN51':'AN53')" @click="ReShow(2)">有效</div>
                <div :class="'AN5 '+ (Show===3 ? 'AN532':'AN53')" @click="ReShow(3)">无效</div>&nbsp;
            </div>
            <div class="H4"></div>
            <div class="XLR" style="background-color: #efefef;min-height: 100vh">&nbsp;
                <div v-if="Show>0" class="MT2 AlignC">
                    <JFNode v-if="Tree!==null" :Show="Show" :CurrNode="Tree"></JFNode>
                    <JFTree v-if="Tree && Tree.Defeats && Tree.Defeats.length>0" :Show="Show"
                            :Defeats="Tree.Defeats"></JFTree>
                </div>&nbsp;
            </div>
        </PopOneAN>
    </div>
</template>

<script>
    import {myMongoDBPost, CloneObject} from '@/components/SharedVues/Shared0.js'
    import JFNode from "./JFNode";
    import JFTree from "./JFTree";
    import PopOneAN from "../MyPopWin/PopOneAN";
    // NodeType: 'Root','Branch'=分支节点 Transit=过渡节点 Leaf'=叶子节点

    export default {
        name: "ShowJF",
        components: {PopOneAN, JFTree, JFNode},
        props: ['UserName', 'ItemCode', 'CurrJF'],
        data() {
            return {
                NodeObjs: [],
                Tree: null,
                Show: 1
            }
        },
        mounted() {
            let that = this;
            myMongoDBPost("GetFymcNodeObjs", {UserName: this.UserName, ItemCode: this.ItemCode}, function (Docs) {
                //要计划使用递归,就必须要先形成能够进行递归的树形数据结构,基本思路是把Docs中的每1条都挂到树上去

                that.NodeObjs = Docs;
                that.ReShow(1);

            })
        },
        methods: {
            Quit() {
                this.$emit("FromShowJF", this.Tree.JF);
            },
            ReShow(Bool) {
                function GetNodeOBJ(ParentNode, NodeName) {
                    let RetObj = {};
                    for (let i = 0, len = Docs.length; i < len; i++)
                        if (Docs[i].ParentNode === ParentNode && Docs[i].NodeName === NodeName) RetObj = CloneObject(Docs[i]);
                    return RetObj;
                }

                function BuildTree(CurrTree) {
                    for (let i = 0; i < CurrTree.Defeats.length; i++) {
                        CurrTree.Defeats[i] = GetNodeOBJ(CurrTree.NodeName, CurrTree.Defeats[i]);
                        BuildTree(CurrTree.Defeats[i]);
                    }
                }

                this.Show = 0;
                let Docs = CloneObject(this.NodeObjs);

                if (Bool === 1) for (let i = 0, len = Docs.length; i < len; i++) Docs[i].LeavesN = Docs[i].LeavesN1 + Docs[i].LeavesN2;
                if (Bool === 2) for (let i = 0, len = Docs.length; i < len; i++) Docs[i].LeavesN = Docs[i].LeavesN1;
                if (Bool === 3) for (let i = 0, len = Docs.length; i < len; i++) Docs[i].LeavesN = Docs[i].LeavesN2;
                if (Docs.length > 0) {
                    this.Tree = Docs[0];
                    this.Tree.OpenBool = true;
                    if (Bool === 1 && this.Tree.JF !== this.CurrJF) //由于原来无效的节点有可能已注册,从而变成有效,因此对名次库中数据进行更新;
                        myMongoDBPost("UpdateDoc/fymc-" + this.ItemCode, {
                            UPTJ: {_id: this.Tree.NodeName},
                            UPObj: {JF: this.Tree.JF}
                        }, function (data2) {
                        });
                    BuildTree(this.Tree);

                    this.$nextTick(() => {
                        this.Show = Bool;
                        for (let i = 0, len = Docs.length; i < len; i++)
                            console.log(Docs[i].ParentNode, Docs[i].NodeName, Docs[i].LeavesN, Docs[i].LeavesN1, Docs[i].LeavesN2)
                    })
                }


            }
        }
    }
</script>

<style scoped>

</style>
