<template>
    <div v-if="IsLoading">IsLoading...</div>
    <div v-else>
      <table  style="background-color:#fff;">
        <thead>
        <tr class="TF">
          <th>申请用户</th>　
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(AU,index) in AskUsers" :key="index">
          <td>{{AU.UserName}}</td>　
          <td>
            <div class="TB" @click="CheckYes(index)">现在注销</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
  //原则上,只要用户提出申请,后台早晚会同意并进行注销操作

  import {myMongoDBPost, AutoAlert} from '@/components/SharedVues/Shared0.js'
  export default {
    name: "UserWithdrawCheck",
    data(){
      return {
        IsLoading:true,
        AskUsers:[]
      }
    },
    mounted() {
      let that=this;
      myMongoDBPost("GetDocs/fyuser0", {RealTimeStr:'申请注销用户....'}, function (Docs) {
        that.IsLoading = false;
        that.AskUsers = Docs;
      })
    },
    methods:{
      CheckYes(index){
        let that=this;
        let U=this.AskUsers[index].UserName;
        myMongoDBPost('DeleteThisUser2', { fyUserName: U}, function (data) {
          that.IsLoading = true;
          myMongoDBPost("GetDocs/fyuser0", {RealTimeStr:'申请注销用户....'}, function (Docs) {
            that.IsLoading = false;
            that.AskUsers = Docs;
            AutoAlert(U, '注 销 完 毕!',);
          })
        });//删除一个用户时,应当把所有与该用户有关的文档全部删掉
      }
    },
  }
</script>

<style scoped>

</style>
