<template>
  <div>
    <table>
      <thead>
      <tr class="TF">
        <th v-for="(T,index1) in Ts" :key="index1"  style="white-space:nowrap;">{{T}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(Ds,index2) in Dss" :key="index2" class="TC">
        <td v-for="(D,index3) in Ds" :key="index3"  style="white-space:nowrap;">{{D}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>


  export default {
    name: "STable",
    props: ['Ts', 'Dss'],
    components: {}
  }
</script>

<style scoped>

</style>
