<template>

    <MyInput v-if="InputI===1" Title="泛约昵称/项目名称" Type="text" :Begin="SStr" @FromMyI="MyInputVal"></MyInput>

    <PopTitle  v-if="InputI===0" :Title="Title2" @FromPopUp="$emit('FromFYSearch')">
      <p v-if="UserNames.length+aList.length===0" class="TF">什么也没有找到啊！</p>
      <div v-else class="BottGray">
        <div class="BottO" v-for="(UN,index) in UserNames" :key="index">
          <div class="XLR YC  MP4" @click="SetShow6(UN.UserName)">
            <div class="XL YC">
              <img :src="'UserUpload/1/S'+UN.UserLogo" class="BDCircle WH99">
              &nbsp;{{UN.UserName}}
            </div>
            <div>
              <el-icon><ArrowRight /></el-icon>
            </div>
          </div>
        </div>
        <div class="BottO" v-for="(x,index) in aList" :key="index">
          <div class="XLR YC  MP4" @click="SetItemStr(x)">
            <div class="XL YC">
              <div>{{index+1}}.&nbsp;{{x.itemChannel}}</div>
              →
              <div>&nbsp;{{x.ItemType}}</div>
              →
              <div>&nbsp;{{x.ItemName}}</div>
            </div>
            <div>
              <el-icon><ArrowRight /></el-icon>
            </div>
          </div>
        </div>
      </div>
    </PopTitle>

    <PopTitle v-if="Show6" :Title="'您正在访问“'+FindedUser+'”的用户空间'" @FromPopUp="Show6=false">
      <OpenUser :He="FindedUser"></OpenUser>
    </PopTitle>
    <XMYY v-if="Show2" :ItemStr="ItemStr" @eventFromXMYY="Show2=false"></XMYY>

</template>

<script>
  import MyInput from "../SharedVues/MyInput";
  import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
  import PopTitle from "../MyPopWin/PopTitle";
  import XMYY from "../ListYY/XMYY";
  import OpenUser from "./OpenUser";

  export default {
    name: "FYSearch",
    components: {OpenUser, XMYY, PopTitle,  MyInput},
    data() {
      return {
        MoreOpen: false,
        Show: -1,
        Show2: false,
        Show6: false,
        SStr: '',
        InputI: 1,
        UserNames: [],
        FindedUser: '',
        aList: [],
        ItemStr: '',
        Title2: ''
      }
    },
    unmounted() {
      this.InputI = -1;
    },
    methods: {
      MyInputVal(Val) {
        let that = this;
        if (Val && this.InputI === 1 && Val !== '') {
          this.SStr = Val;
          myMongoDBPost("GetDocs/fyuser0", {UserName: {$regex: Val}}, function (Docs) {
            that.UserNames = [];
            let len = Math.min(Docs.length, 50);
            for (let i = 0; i < len; i++)
              if (Docs[i].UserName !== that.$store.state.UserName) that.UserNames.push({
                UserName: Docs[i].UserName,
                UserLogo: Docs[i].UserLogo
              });
            myMongoDBPost("GetDocsByItemName", {ItemName: Val}, function (data) {
              that.aList = data;
              that.Title2='['+that.SStr+']相关搜索结果：'+(that.UserNames.length +that.aList.length)+'个';
              that.InputI = 0;
            })
          });
        } else this.$emit('FromFYSearch');
        this.Show6 = false;
      },
      SetShow6(UN) {
        this.FindedUser = UN;
        this.Show6 = true;
      },
      SetItemStr(x) {
        this.ItemStr = x.ItemStr;
        this.$store.commit('ChangeCurrItemStr', x.ItemStr);
        this.Show2 = true;
      }
    }
  }
</script>

<style scoped>

</style>
