<template>
    我的泛约应用动态:
    <div class="MLR2">
    <el-divider/>
    <div class="XLR MP4 TB" @click="Show21 = !Show21">
        泛约指数与排名
        <div v-if="Show9">
            <el-icon>
                <ArrowDown/>
            </el-icon>
        </div>
        <div v-else>
            <el-icon>
                <ArrowUp/>
            </el-icon>
        </div>
    </div>
    <MyIndexs v-if="Show21"></MyIndexs>

    <el-divider/>
    <div class="XLR MP4 TB" @click="Show=43">
        邀约设置信息
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <PopTitle v-if="Show===43" Title="我的邀约设置信息" @FromPopUp="Show=-1">
        <p v-if="$store.state.UserName==='游客'" class="TF">本功能游客用户操作无效！</p>
        <MyBySet v-else></MyBySet>
    </PopTitle>
    <el-divider/>
    <div class="XLR MP4 TB" @click="Show=44">
        关联用户/泛约人脉圈
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <PopTitle v-if="Show===44" Title="我的泛约人脉圈" @FromPopUp="Show=-1">
        <RUsers></RUsers>
    </PopTitle>
    <div v-if="1===2" title="记账卡收支记录">

        <el-divider/>
        <div class="XLR MP4 TB" @click="Show4 = true">
            发卡方记账
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>

        <el-divider/>
        <div class="XLR MP4 TB" @click="Show5= true">
            用卡方记账
            <el-icon>
                <ArrowRight/>
            </el-icon>
        </div>
    </div>
    </div>
    <PopTitle v-if="Show4" Title="发卡方账簿" @FromPopUp="Show4=false">
        <CardLists2 Bool='发卡方'></CardLists2>
    </PopTitle>
    <PopTitle v-if="Show5" Title="用卡方账簿" @FromPopUp="Show5=false">
        <CardLists2 Bool='用卡方'></CardLists2>
    </PopTitle>

</template>

<script>
    import MyIndexs from "./MyIndexs";
    import MyBySet from "./MyBySet";
    import PopTitle from "../MyPopWin/PopTitle";
    import RUsers from "./RUsers";

    export default {
        name: "FYDT",
        components: { RUsers, PopTitle, MyBySet, MyIndexs},
        data() {
            return {
                Show4: false,
                Show5: false,

                Show: -1,
                Show21: false,
            }
        }
    }
</script>

<style scoped>

</style>
