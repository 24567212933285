<template>
    <div>
        <PopUpDown v-if="Show" Title="占用时间窗选择" Pos="btt" :ANs="2" @FromPop5="SelectTimeWin">
            <div class="MT2"></div>
            <TopMenu2 :Menus="Menus" :BVal="TypeSele" @TopMenu2="TopMenu2">
                <div class="TB MP4">
                    <div v-if="TypeSele===0" class="BoxDot AlignC">
                        <MyPicker :data='WPData' v-model='WP' @change='WPChanged'></MyPicker>
                    </div>
                    <div v-if="TypeSele===1" class="BoxDot AlignC">
                        <MyPicker :data='YMData' v-model='YM' @change='YMChanged'></MyPicker>
                    </div>
                    <div v-if="TypeSele===2" class="XLR MP4 BoxGray1">&nbsp;
                        <div>
                            <div class="AlignC TF BottGray">&nbsp;&nbsp;全体公民假&nbsp;&nbsp;</div>
                            <div class="MT2 TG ML2" @click="SetHoliday(x)" v-for="(x,index) in Hs1" :key="index">{{x}}
                            </div>
                        </div>

                        <div>
                            <div class="AlignC TF BottGray">&nbsp;&nbsp;部分公民假&nbsp;&nbsp;</div>
                            <div class="MT2 TG ML2" @click="SetHoliday(x)" v-for="(x,index) in Hs2" :key="index">{{x}}
                            </div>
                        </div>

                        <div>
                            <div class="AlignC TF BottGray">&nbsp;&nbsp;周末&nbsp;&nbsp;</div>
                            <div class="MT2 TG" @click="SetHoliday(x)" v-for="(x,index) in Hs3" :key="index">{{x}}</div>
                        </div>
                        &nbsp;

                    </div>


                </div>
            </TopMenu2>
        </PopUpDown>
    </div>
</template>

<script>
    import SideMenu2 from "./SideMenu2";
    import {Years, Months, Holidays} from '@/components/SharedVues/Shared0.js';
    import PopTitle from "../MyPopWin/PopTitle";
    import TopMenu2 from "@/components/SharedVues/TopMenu2";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import MyPicker from "../MyUiForVue3/MyPicker/MyPicker";
    //import {Picker} from "../../../node_modules/vux"

    export default {
        name: "TimeWindowSele",
        components: {MyPicker, PopUpDown, TopMenu2, PopTitle, SideMenu2},
        data() {
            return {
                Show: true,
                TypeSele: 0,
                WPData: [['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'], ['晨练时间', '早点', '上午', '午餐', '下午', '课外活动', '晚餐', '晚上']],
                WP: ['星期四', '下午'],
                WP2: ['星期四', '下午'],

                YMData: [['2019年', '2020年', '2021年', '2022年', '2023年', '2024年', '2025年', '2026年', '2027年', '2028年', '2029年', '2030年', '2031年'],
                    ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']],
                YM: ['2019年', '九月'],
                YM2: ['2019年', '九月'],

                timeWindow: {timeType: "初值", week: '星期四-3', period: '下午-4', holidays: ''},
                Menus: ['某日时段', '某年某月', '节假日'],
                Years: Years,
                Months: Months,
                Holidays: Holidays,
                Hs1: ['元旦', '春节', '清明节', '五一劳动节', '端午节', '中秋节', '十一国庆节'],
                Hs2: ['妇女节', '青年节', '儿童节', '建军节'],
                Hs3: ['星期六', '星期日'],
                showValue: '',//这是用于网页显示的
                countValue: '',//这是要发送到服务端进行验证的时间代码值;
                CurrItemType: ''
            }
        },
        methods: {
            TopMenu2(Val) {
                this.TypeSele = Val;
                this.timeWindow.timeType = this.Menus[this.TypeSele];
                this.CurrItemType = this.Menus[this.TypeSele];
            },
            toParentComp() {
                this.$emit("eventFromTimeWindow", this.TimeWindow)
            },
            WPChanged(value) {
                this.timeWindow.week = value[0] + '-' + this.WPData[0].indexOf(value[0]);
                this.timeWindow.period = value[1] + '-' + this.WPData[1].indexOf(value[1]);
                this.WP2 = value;
            },//某日时段
            YMChanged(value) {
                this.timeWindow.week = value[0] + '-' + this.YMData[0].indexOf(value[0]);
                this.timeWindow.period = value[1] + '-' + this.YMData[1].indexOf(value[1]);
                this.YM2 = value;
            },//某日时段
            SetHoliday(x) {
                this.timeWindow.holidays = x + '=H:' + this.Holidays.indexOf(x);
                this.SelectTimeWin('入口２');
            },//法定假日
            SelectTimeWin(Val) {
                this.Show = false;
                if (Val === -1) this.$emit("eventFromTimeWindowSele", -1); else {
                    let tt = this.CurrItemType;
                    let tf = '', tw = this.timeWindow.week, tp = this.timeWindow.period;//HourSeg=>HS
                    if (tt === '某日时段' && tw.length > 0 && tp.length > 0) tf = tw.split("-")[0] + tp.split("-")[0] + "=HS:" + tw.split("-")[1] + "," + tp.split("-")[1];
                    if (tt === '某年某月' && tw.length > 0 && tp.length > 0) tf = tw.split("-")[0] + tp.split("-")[0] + "=YM:" + tp.split("-")[1] + "," + tw.split("-")[1];

                    if (tt === '节假日') tf = this.timeWindow.holidays;
                    if (tf.length === 0) {
                        alert('请至少进行一次选择后再提交!');
                    } else {
                        this.showValue = tf.split("=")[0];//这是用于网页显示的
                        this.countValue = tf.split("=")[1];//这是要发送到服务端进行验证的时间代码值;
                    }

                    this.WP = this.WP2;
                    this.YM = this.YM2;
                    this.$emit("eventFromTimeWindowSele", {showValue: this.showValue, countValue: this.countValue})
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .XYC { /*子元素居中*/
        display: flex;
        flex-direction: column;
        justify-content: center; /*子元素水平居中*/
        align-items: center; /*子元素垂直居中,前提是当前Box有height值*/
    }

    .YC { /*纵向居中*/
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        align-items: center;
    }

    .TypeSele-item {
        display: block;
        border: 1px solid #04597d;
        /*border-radius: 0.4rem;*/
        border-top-right-radius: 0.4em;
        border-bottom-right-radius: 0.4em;
        background-color: #ffffff;
        padding-left: 5px;
        width: 100%;
        color: #04597d;
        height: 7vh;
        .YC
    }

    .TypeSele-item-selected {
        display: block;
        background-color: #eeeeee;
        border: none;
        text-align: center;
        color: #68726d;
        width: 100%;
        height: 7vh;
        .XYC
    }

    .MBottom {
        margin-bottom: 1rem;
    }
</style>
