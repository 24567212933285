<template>
    <el-drawer v-model="Show7" :append-to-body="true" :direction="Pos" :with-header="false" size="60%">
        <div :class="Pos==='ttb' ? 'BGLightGray':'BGWhite'" :style="'min-height:30vh;z-index:'+(Zindex+1)">
            <br>
            <MyDivider v-if="ANs===2" :ShowStr="Title"></MyDivider>
            <div v-else class="XLR YC PA2 MT2">
                {{Title}}：
                <div v-if="Pos==='ttb'" class="ANGray BDCircle WHbb XYC LH120" @click="Return(-1)">确认</div>
                <img v-else src="../../../static/img/ButtonPic/CB6.png" class="WH77" @click="Return(-1)">
            </div>
            <div class="MT2">
                <slot></slot>
            </div>
            <div v-if="ANs===2" class="XLR YC">&nbsp;
                <div class="PA3 BD_Radius1 BGGray TW T5" @click="Return(-1)">取消</div>
                <div class="PA3 BD_Radius1 BGBlue TW T5" @click="Return(1)">确定</div>
                &nbsp;
            </div>
            <br>
        </div>
    </el-drawer>
</template>
<script>
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "PopUpDown",

        props: ['Title', 'ANs', 'Pos'],
        components: {MyDivider},
        data() {
            return {
                Show7: true,
                Timer: 0,
                MZindex: 0,
                Zindex: 0,
                windowhistory: null,
            }
        },
        mounted() {
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex + 50);
            this.Zindex = this.$store.state.CurrZindex;
            this.MZindex = this.$store.state.CurrZindex - 2;
            //console.log(this.Title)
        },
        beforeUnmount() {
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex - 50);//切记10000和2000改小了,还不行
            if (this.Timer) clearTimeout(this.Timer);
        },//如果定时器还在运行 或者直接关闭，不用判断
        methods: {
            Return(Bool) {
                this.Show7 = false;
                this.$emit('FromPop5', Bool);
            }
        }
    }
</script>

<style scoped>
</style>

