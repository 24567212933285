<template>
    <br><br>
    <div class="TC XLR YC MLR4">
        <div class="AlignC" @click="N=0">
            <img :src="'UserUpload/static/SY/HLZJ.png'" class="WHaa">
            <div>{{Ns[0]}}</div>
        </div>
        <div class="AlignC" @click="N=1">
            <img :src="'UserUpload/static/SY/PWPL.png'" class="WHaa">
            <div>{{Ns[1]}}</div>
        </div>
        <div class="AlignC" @click="N=2">
            <img :src="'UserUpload/static/SY/ZPXX.png'" class="WHaa">
            <div>{{Ns[2]}}</div>
        </div>
        <div class="AlignC" @click="N=3">
            <img :src="'UserUpload/static/SY/YSBM.png'" class="WHaa">
            <div>{{Ns[3]}}</div>
        </div>
    </div>
    <div class="TC XLR YC MLR4 MT2" style="padding-top:4mm">
        <div class="AlignC" @click="N=4">
            <img :src="'UserUpload/static/SY/KWLS.png'" class="WHaa">
            <div>{{Ns[4]}}</div>
        </div>
        <div class="AlignC" @click="N=5">
            <img :src="'UserUpload/static/SY/YSBR.png'" class="WHaa">
            <div>{{Ns[5]}}</div>
        </div>
        <div class="AlignC" @click="N=6">
            <img :src="'UserUpload/static/SY/JJYR.png'" class="WHaa">
            <div>{{Ns[6]}}</div>
        </div>
        <div class="AlignC" @click="N=7">
            <img :src="'UserUpload/static/SY/ZJMR.png'" class="WHaa">
            <div>{{Ns[7]}}</div>
        </div>
    </div>
    <div class="TC XLR YC MLR4 MT2" style="padding-top:4mm">
        <div class="AlignC" @click="N=8">
            <img :src="'UserUpload/static/SY/XRQS.gif'" class="WHaa">
            <div>{{Ns[8]}}</div>
        </div>
        <div class="AlignC" @click="N=9">
            <img :src="'UserUpload/static/SY/YFKJ.png'" class="WHaa">
            <div>{{Ns[9]}}</div>
        </div>
        <div style="opacity: 0">四个汉字</div>
        <div style="opacity: 0">四个汉字</div>
    </div>
    <br><br><br><br><br>
    <PopMenu v-if="[0,1,2,3,4,5,6,7,8,9].indexOf(N)>=0" :BeginN="BeginN" :Menus="NMenus[N]"
             @FromPopMenu="PopMenuVal">
        <div v-for="(NM,i) in NMenus[N]" :key="i">
            <QYsSele v-if="SeleM===i" :Channel="Ns[N]" :TopKey="NM" :NSeleM="{N:N,SeleM:SeleM}"
                     :SideMenus="QYMenus[N][i]"></QYsSele>
        </div>
    </PopMenu>
</template>
<script>
    import PopOneAN from "../MyPopWin/PopOneAN";
    import PopTitle from "../MyPopWin/PopTitle";
    import PopMenu from "../MyPopWin/PopMenu";
    import QYsSele from "./QYsSele";
    import FYBBing from "./FYBBing";
    import FYBB from "./FYBB";
    import WXLink from "../IndependentVues/WXLink";

    let Menus2 = ['20岁下', '21-25', '26-30', '31-35', '36-40', '41-50', '51-60', '61-70', '71岁上'];
    let MenusB = ['五官科', '内外科', '骨科', '肿瘤科', '皮肤性病', '妇产儿科', '传染科', '美容整形', '影像检验', '精神心理', '中医科'];
    let MenusC = ['语文', '数学', '英语', '物理', '化学', '生物', '历史', '地理', '政治', '美术', '体育', '音乐', '其它'];

    export default {
        name: "YYFW",
        components: {WXLink, FYBB, FYBBing, QYsSele, PopMenu, PopTitle, PopOneAN},
        data() {
            return {

                BeginN: 0,
                Ns: ['婚恋知己', '做伴陪练', '招工招聘', '保姆月嫂', '课外老师', '医生病人', '临时用人', '专家名人', '寻人启事', '缘分空间'],
                NMenus: [
                    ['我是男', '我是女', '寻找男', '寻找女'],
                    ['旅游', '运动', '休闲', '文娱', '学外语'],
                    ['用工', '求职', '求兼职', '合伙'],
                    ['照顾对象', '男保姆', '女保姆', '月嫂'],
                    ['老师', '学生'],
                    ['离退医生', '在职医生', '杂症患者'],
                    ['用人信息', '有偿听用', '志愿者'],
                    ['名人', '名师', '裁判', '专家'],
                    ['失踪', '失联', '我在这里'],
                    ['贵人', '粉丝', '伴侣', '同行', '团队']
                ],
                QYMenus: [
                    [Menus2, Menus2, Menus2, Menus2],
                    [['徒步', '自行车', '自驾游', '跟团游', '郊游', '火车远游', '出国游'],
                        ['乒乓球', '羽毛球', '台球', '保龄球', '网球', '高尔夫球', '滑冰', '轮滑', '游泳', '其它'],
                        ['围棋', '中国象棋', '扑克', '打麻将', '钓鱼', '喝水聊天', '喝酒'],
                        ['唱歌', '广场舞', '舞厅舞', '乐器合奏', '戏剧', '其它'],
                        ['英语', '日语', '俄语', '法语', '意大利语', '德语', '阿拉伯语', '其它']],
                    [['外企', '国企', '私企', '小微'],
                        ['互联网', '文娱', '大消费', '大健康', '高科技', '生产制造', '能源环保', '农林牧渔', '政府民生', '其它'],
                        Menus2,
                        ['法定节日', '寒假', '暑假', '周末', '白班', '夜班'],
                        ['出力', '投资', '技术专利', '其它资源']],
                    [['婴儿', '幼儿', '中小学生', '病人', '老人'], Menus2, Menus2, Menus2],
                    [MenusC, ['学前', '小学', '初中', '高中', '成年', '老年'], []],
                    [MenusB, MenusB, ['高血压', '冠心病', '糖尿病', '头晕头痛', '颈椎腰椎', '痛风瘫痪', '白癜风', '不孕不育', '老年痴呆', '其它']],
                    [['家政服务', '建筑工地', '家庭装修', '保安保卫', '大型活动', '项目测试', '其它'],
                        ['无技能', '建筑工', '装修工', '模特', '文员', '在校生', '宅家网虫', '离退人员'],
                        ['男学生', '女学生', '中青男', '中青女', '老年男', '老年女']],
                    [['戏曲演员', '影视演员', '歌唱演员', '作家', '主持人', '企业家', '书法家', '画家', '运动员', '相声演员', '小品演员'],
                        ['健康教育', '时事政治', '家庭理财', '科普知识', '电子商务', '艺术欣赏', '收藏艺术', '做人之道', '生命预测', '现代军事', '精神世界', '管理艺术', '膳食营养', '法律普及', '金融证券', '科技前沿', '历史地理'],
                        ['篮球', '足球', '乒乓球', '羽毛球', '高尔夫球', '排球', '网球', '桌球', '橄榄球', '棒球', '游泳', '棋牌', '赛车', '文艺节目', '影视作品', '田径运动会', '歌咏比赛', '残运会', '农运会', '少数民族运动会'],
                        ['农林牧渔', '冶金矿产', '能源开发', '机械机电', '化工制药', '纺织服装', '建筑建材', '轻工家用', '物流运输', '商务社保', '信息传媒', '药医保健', '教育培训', '吃住旅游', '环保安全', '金融投资', '文体艺术', '军警政府', '社会服务']],
                    [['男', '女'], ['男', '女'], ['男', '女']],
                    [['升学志愿', '找工作', '创业', '求救急', '求救济', '看病求医'],
                        ['综合秀场', '专业技能', '绝技秘方', '体育项目', '音乐舞蹈', '原创作品', '创意活动', '特别项目', '开式聚会'],
                        ['婚姻寻男', '婚姻寻女', '知己寻男', '知己寻女', '搭档寻男', '搭档寻女', '合租寻男', '合租寻女', '干亲', '爱串门儿'],
                        ['行业不限', '互联网＋', '高新产业', '传统实业', '金融证券', '文化传媒', '生活服务', '奇特新怪'],
                        ['志同道合', '家族老乡', '同学战友', '工作同事', '合作项目', '产品服务', '音乐舞蹈', '体育项目', '吃货旅游', '行业交流', '学习交流', '疾病交流', '公益慈善', '抱团养老', '民非组织', '特别缘份']]
                ],
                N: -1,
                SeleM: 0,
                To: '当前',
                CurrCode: '123654',
                ShowWXLink: true,//外层显示微信链接标志
            }
        },
        mounted() {
        },
        methods: {
            PopMenuVal(Val) {
                if (Val === -1) this.N = -1;
                else if (Val === undefined) this.SeleM = 0; else this.SeleM = Val;
            },
        }
    }
</script>

<style scoped>

</style>
