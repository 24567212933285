<template>
  <div class="BGY">
    <div class="MP4 T4 TC MT2">
      <MyDivider ShowStr="生成微信宣传链接"></MyDivider>
      <div class="XR">
        <div class="AN ANGreen MLR4 T3" @click="Send()">确认</div>
      </div>
      标题：<br>
      <textarea class="Mtextarea1 T5 MT2" v-model="WTitle2" placeholder="最长28个汉字"></textarea><br>
      内容：<br>
      <textarea class="Mtextarea1 T5 MT2" v-model="WText2" placeholder="最长38个汉字"></textarea>
      <div v-if="Pic===''" class="XL YC">
        链接中显示的照片：
        <img v-if="Pic2===''" src="../../../static/img/Media/PicColor.png" class="WH99" @click="Active=true">
        <img v-else :src="Pic2" @click="ShowPic=Pic2" class="WHab"/>
      </div>
      <br>
    </div>
    <PopUpDown v-if="ShowPic!==''" :ANs='1' Title="照片" Pos="btt" @FromPop5="ShowPic=''">
      <div class="PA2">
        <img :src="ShowPic" width="100%" height="auto">
      </div>
    </PopUpDown>
    <UpFiles1 v-if="Active" VP="1"  @FromU1="SetPic"></UpFiles1>
  </div>
</template>

<script>
  //再说一遍:切忌使用纯中文方式的双引号,否则链接图片就显示不出来
  import {  SendWX_PTMsg} from '@/components/SharedVues/Shared0.js'

  import UpFiles1 from "../MyPVTs/UpFiles1";
  import PopUpDown from "../MyPopWin/PopUpDown";
  import MyDivider from "../SharedVues/MyDivider";

  export default {
    name: "WXLink",
    components: {MyDivider, PopUpDown, UpFiles1},
    props: ['WTitle', 'WText', 'WLandTo', 'Pic'],
    data() {
      return {
        Active: false,
        ShowPic: '',
        WTitle2: this.WTitle,
        WText2: this.WText,
        Pic2: '',
        Url: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd4ed5f6c863d26c3&redirect_uri=" + "http://www.fy135.vip/?LandTo=WXLink" + this.WLandTo + "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
      }
    },
    mounted() {
      if (this.Pic === 'UserLogo')
        this.Pic2 = this.$store.state.UserLogo === '' ? 'UserUpload/static/After2021/Head.jpg' : 'UserUpload/1/S' + this.$store.state.UserLogo;
      else if (this.Pic !== '') this.Pic2 = this.Pic;
    },
    methods: {
      SetPic(Val) {
        this.Pic2 = "UserUpload/1/" + Val;//注意:这个照片不能太大.
        this.Active = false;
      },
      Send() {
        let Me = this.$store.state.UserName;
        if (this.WTitle.indexOf("“") >= 0 || this.WText.indexOf("“") >= 0)
            alert('在微信链接的文字中，切忌使用纯中文方式的双引号！');
        else {
          SendWX_PTMsg(Me, this.WTitle2, '　　' + this.WText2, this.Url, "http://www.fy135.vip/" + this.Pic2);
            alert('操作成功，请退出到公众号微信界面查看');
        }
      }
    }
  }
</script>

<style scoped>

</style>
