<template>
    <div class="MLR2">
        &nbsp;&nbsp;基本资料:
        <el-divider/>
        <div v-if="TJ1" class="XLR MP4 TE" @click="Show0 = true">
            尚未实名认证，现在进行
            <el-icon><ArrowDown/></el-icon>
        </div>
        <el-divider/>
        
        <div class="XLR MP4 TB" @click="Show1 = true">
            当前注册信息(绝对私密)
            <el-icon><ArrowRight/></el-icon>
        </div>
        <el-divider/>
        <div class="XLR MP4 TB" @click="Show2 = true">
            附加用户信息(可以公开)
            <el-icon><ArrowRight/></el-icon>
        </div>
        <el-divider/>
        <div class="XLR MP4 TG" @click="Show3 = true">默认打开项目更改
            <el-icon> <ArrowRight/> </el-icon>
        </div>
        <el-divider/>
    </div>
    <PopBott v-if="Show3">
        <SeleOneItem v-if="Show3" Title="请先选择缺省打开项目" @FromSeleOneItem="UpdateDefaultItem"></SeleOneItem>
    </PopBott>
    <PopBott v-if="Show0">
        <AskRealName @FromAskRealName="Show0=false"></AskRealName>
    </PopBott>
    <PopTitle v-if="Show1" Title="当前注册信息" @FromPopUp="Show1=false">
        <DLandReg></DLandReg>
    </PopTitle>
    <PopTitle v-if="Show2" Title="附加用户信息编辑" @FromPopUp="Show2=false">
        <FJXX></FJXX>
    </PopTitle>
</template>

<script>
    import {myMongoDBPost, myDataFileGet, CloneObject} from '@/components/SharedVues/Shared0.js'
    import PopUpDown from "../MyPopWin/PopUpDown";
    import PopTitle from "../MyPopWin/PopTitle";
    import FJXX from "./FJXX";
    import DLandReg from "./DLandReg";
    import ShowUpdatePV from "../MyPVTs/ShowUpdatePV";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import PopBott from "../MyPopWin/PopBott";
    import AskRealName from "../M0_RegLogin/AskRealName";

    export default {
        name: "BasicSetup",
        components: {AskRealName, PopBott, SeleOneItem, ShowUpdatePV, DLandReg, FJXX, PopTitle, PopUpDown},
        data() {
            return {
                TJ1: this.$store.state.UserName !== '游客' && (this.$store.state.Certified === '尚未' || this.$store.state.Certified === '' || this.$store.state.Certified === '失败'),
                Show0: false,
                Show1: false,
                Show2: false,
                Show3: false
            }
        },
        methods: {
            UpdateDefaultItem(Val) {
                let that = this;
                myMongoDBPost("AddbySetsItem", {UserName: this.$store.state.UserName, ItemStr: Val}, function (data) {
                });
                myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: {UserName: this.$store.state.UserName}, UPObj: {DefaultItem: Val}}, function (data) {
                    console.log("Val=" + Val, data);
                    that.Show3 = false;
                    that.$store.commit('ChangeDefaultItem', Val);
                });
            },
        }
    }
</script>

<style scoped>

</style>