<template>
     <div class="XYC MT2">
      <table class="Table0 T4">
        <tr>
          <th rowspan="2">时间</th>
          <th rowspan="2">收支</th>
          <th colspan="2">数量</th>
          <th rowspan="2">摘要</th>
        </tr>
        <tr>
          <th class="W6 NoWrap">原有</th>
          <th class="W6 NoWrap">本笔</th>
        </tr>
        <tr v-for="(A,index) in ZXs" :key="index" @click="alert(A.Describe)" :class="A.IOType==='支' ? 'TF':'TC'">
          <td class="NoWrap T2">{{(''+A.Y).substr(2,2)}}.{{A.M}}.{{A.D}} {{A.H}}:{{A.M2}}</td>
          <td>{{A.IOType}}</td>
          <td >{{A.Balance0}}</td>
          <td >{{A.ZXn}}</td>
          <td ><div class="W32 TB T3 NoWrap" style="overflow: hidden">{{A.Describe}}</div></td>
        </tr>
      </table>
    </div>
</template>

<script>
  import {myMongoDBPost,Compare} from '@/components/SharedVues/Shared0.js';

  export default {
    name: "ZXAccounts",
    data() {
      return {
        ZXs: [],
        MaxA: 0
      }
    },
    mounted() {
      let that = this;
      myMongoDBPost("GetZXs", {fyUserName: that.$store.state.UserName}, function (data) {
        that.ZXs = data.sort(Compare('_id')).reverse();
        for (let i = 0, len = that.ZXs.length; i < len; i++) {
          that.ZXs[i]._id = i + 1;
          let asd = that.ZXs[i].Balance0 + that.ZXs[i].ZXn * (that.ZXs[i].IOType === '收' ? 1 : -1);
          if (asd > that.MaxA) that.MaxA = asd;
        }
      });
    }
  }
</script>

<style scoped>
  .W6 {
    width: 6mm
  }

  .W12 {
    width: 12vw
  }

  .Bar1 {
    position: absolute;
    top: 1px;
    height: 1.7rem
  }

  .PosA {
    position: absolute;
  }

  .PosR {
    position: relative;
  }

  .BoxR {
    background: #fc342b
  }

  /*背景色------红色：用于收支明细*/
  .Box8 {
    background: #ff9c2c;
  }

  /*背景色------橙色：用于收支明细*/
  .Box32 {
    background: #02ff71;
  }

  .Box82 {
    background: #fcff7c;
  }

  /*背景色------黄色：用于收支明细*/

</style>
