<template>
  <div class="MT2">
    <el-divider> • 本人相关比分登记表 •</el-divider>
    <table class="MT2">
      <thead>
      <tr class="TC LH200">
        <th>日期</th>
        <th>星期</th>
        <th>上午</th>
        <th>下午</th>
        <th>晚上</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(PD,index1) in PastDays" :key="index1">
        <td>{{PD.ThisDate}}</td>
        <td>{{PD.Week}}</td>
        <td v-for="(Noon,index2) in PD.Noons" :key="index2" class="LH120">
          <div v-if="Noon==='―'" class="PA3">―</div>
          <div v-else @click="OpenCell(index1,index2)"
               :class="Noon.split('|')[1]==='待确认'? 'TE PA1':(Noon.split('|')[1]==='待输入' ? 'TB PA1':'TG PA1')">
            <div v-if="Noon.split('|')[0]!=='内部'" class="BottGray">{{Noon.split("|")[0]}}</div>
            <div>{{Noon.split("|")[1]}}</div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <p class="TF T3">提示：填写比分需要一方填写，另一方确认，才能有效。</p>
    <br>
    <PopTitle v-if="I1>-1 && NoonIndex>-1" :Title="PastDays[I1].ThisDate+NoonIndexes[NoonIndex]+'比分登记'"
             @FromPopUp="RefreshPastDays()">
      <div v-if="CurrCell.SiteShortName!=='内部'" class="BottGray W50 PA3">场地:{{CurrCell.SiteShortName}}</div>
      <div v-if="AddPKs.length>1" class="MP4">
        ☞{{ShowWhat($store.state.UserName)}} :&nbsp;
        <div class="DispIB PA3 TG BDGreen W12" @click="AddBool='AddLoser',ZGIndex=0">― &nbsp;?</div>
      </div>
      <div v-if="AddPKs.length===1 && ActDoc.ActStyle.indexOf('多循环') >= 0" class="MP4">
        ☞{{ShowWhat($store.state.UserName)}} :&nbsp;
        <div class="DispIB PA3 TG BDGreen W12" @click="AddBool='AddLoser',ZGIndex=0">― &nbsp;?</div>
      </div>
      <div class="PA3 LH120" v-for="(ZG,index2) in CurrCell.ZGs" :key="index2">
        <div v-if="ZG.BFCertified.charAt(0)==='C'" class="XLR TF">
          {{ShowWhat(ZG.Winner)}} : {{ShowWhat(ZG.Loser)}} = {{ZG.BF}}
          <div>双方认可</div>
        </div>
        <div v-else>
          <div v-if="!(ZG.Winner!=='―' && ZG.Loser==='―')" class="XLR YC">
            {{index2===0 && ZG.Winner===$store.state.UserName ? '☞':''}}
            <div>
              {{ShowWhat(ZG.Winner)}} : {{ShowWhat(ZG.Loser)}}
              <div v-if="[ZG.Winner,ZG.Loser].indexOf($store.state.UserName)>=0" @click="AddBool='',ZGIndex=index2"
                   class="TG">=
                <div class="DispIB PA2 W20 AlignC BoxGray1 BD_Radius1">{{(ZG.BF==='―' || ZG.BF==='无') ? "? : ?" :
                  ZG.BF}}
                </div>
              </div>
              <div v-else class="TF">=
                <div class="DispIB PA2 W20 AlignC BoxGray1 BD_Radius1">{{(ZG.BF==='―' || ZG.BF==='无') ? "? : ?" :
                  ZG.BF}}
                </div>
              </div>
            </div>
            &nbsp;
            <div v-if="ZG.BFCertified.charAt(0)==='F' && [ZG.Winner,ZG.Loser].indexOf($store.state.UserName)>=0">
              <div v-if="ZG.BFCertified.split(':')[1]!==$store.state.UserName" class="TB BottO"
                   @click="SetZG(index2,'确认比分')">&nbsp;&nbsp;认可&nbsp;&nbsp;
              </div>
              <div v-else class="TF">待对方<br>确认</div>
            </div>
            <div v-if="ZG.BFCertified.charAt(0)!=='C' && $store.state.UserName===ActDoc.UserName" class="TE"
                 @click="SetZG(index2,'撤销PK对子')">撤销
            </div>
          </div>
        </div>
      </div>

      <PopUpDown v-if="AddBool!=='' && ZGIndex>-1" Title="请选择" Pos="btt" :ANs="1" @FromPop5="ZGIndex=-1">
        <div class="XL YC">
          <div class="PA2 ML2 MT2 AlignC BoxGray1 BD_Radius1" v-for="(PK, index) in AddPKs" :key="index"
               @click="AddPK1(PK)">
            <div v-if="ActDoc.ActForm==='单打'">{{PK}}</div>
            <div v-else class="AlignC">
              <div class="BottGray">{{PK}}</div>
              {{ShowWhat(PK)}}
            </div>
          </div>
        </div>
      </PopUpDown>

      <MySele2 v-if="AddBool==='' && ZGIndex>-1"
               :Title="ShowWhat(CurrCell.ZGs[ZGIndex].Winner)+' ： '+ShowWhat(CurrCell.ZGs[ZGIndex].Loser)"
               :Objs="BFs" @MySele2="SetZG($event,'填写比分')"></MySele2>
    </PopTitle>

  </div>
</template>

<script>
  import {myMongoDBPost, AutoAlert, CloneObject, Compare, StampToDate} from '@/components/SharedVues/Shared0.js'


  import PopTitle from "../MyPopWin/PopTitle";
  import MySele2 from "../SharedVues/MySele2";
  import MySele1 from "../SharedVues/MySele1";
  import PopUpDown from "../MyPopWin/PopUpDown";

  export default {
    name: "Act0FillBF",
    components: {
      PopUpDown,
      MySele1,
      MySele2,
      PopTitle,


    },
    props: ['ActDoc'],
    data() {
      return {
        CurrJFMethod: '泛约',
        PastDays: [],
        MatchSites: [],
        Weeks: ['日', '一', '二', '三', '四', '五', '六'],
        I1: -1,
        NoonIndex: -1,
        NoonIndexes: ['上午', '下午', '晚上'],
        MyCurrSite: '―',
        CurrTimeStrs: [],
        CurrCell: {},
        BFs: [
          {Type: '3局2胜制', Opts: ['0:2', '1:2', '2:0', '2:1']},
          {Type: '5局3胜制', Opts: ['0:3', '1:3', '2:3', '3:2', '3:1', '3:0']},
          {Type: '7局4胜制', Opts: ['0:4', '1:4', '2:4', '3:4', '4:0', '4:1', '4:2', '4:3']}],
        ZGIndex: -1,
        AddPKs: [],
        AddBool: '',
        CurrPK: '―'
      }
    },
    mounted() {
      let Today = (new Date()).getTime();
      for (let i = 1; i < 10; i++) {//程序调试通过以后要把5 改成1
        let asd = StampToDate(Today + i * 24 * 3600 * 1000) + ":" + this.Weeks[(new Date(Today + i * 24 * 3600 * 1000)).getDay()];
        this.CurrTimeStrs.push(asd + ":0", asd + ":1", asd + ":2");
      }
      this.RefreshPastDays();

      let that = this;
      if (this.ActDoc.JFPool !== '泛约')
        myMongoDBPost("GetDocs/fysites", {SiteShortName: that.ActDoc.JFPool}, function (Docs) {
          if (Docs.length > 0) that.CurrJFMethod = Docs[0].JFMethod
        });
    },
    methods: {
      ShowWhat(UserName) {
        let aIndex = this.CurrCell.BMs.indexOf(UserName);
        return this.ActDoc.ActForm === '单打' ? UserName : this.CurrCell.BM2s[aIndex]
      },
      AddPK1(Val) {
        this.AddOnePKDoc(Val);
        this.ZGIndex = -1;
      },
      RefreshPastDays() {
        let that = this;
        myMongoDBPost("Act0RefreshPastDays", {
          ActId: that.ActDoc._id,
          ActStyle: that.ActDoc.ActStyle,
          UserName: that.$store.state.UserName,
          CurrTimeStrs: that.CurrTimeStrs
        }, function (Docs) {
          that.PastDays = Docs;
          that.PastDays.sort(Compare('ThisDate'));
          that.I1 = that.NoonIndex = -1;
        });
      },
      OpenCell(I1, NoonIndex) {
        let that = this, I = that.$store.state.UserName;
        //以下开始获取对应的Act0文档
        if (this.PastDays[I1].TimeWinAct0IDs[NoonIndex] !== '') myMongoDBPost("GetDocs/act0", {_id: this.PastDays[I1].TimeWinAct0IDs[NoonIndex]}, function (Docs) {
          that.CurrCell = Docs[0];
          that.I1 = I1;
          that.NoonIndex = NoonIndex;

          that.AddPKs = CloneObject(that.CurrCell.BMs);
          that.AddPKs.splice(that.AddPKs.indexOf(I), 1);//除本人以外所有报名者
          /*
          for (let i = 0; i < that.CurrCell.ZGs.length; i++) {//1.找出除本人以外所有的Winner,即报名者
            let iWinner = that.CurrCell.ZGs[i].Winner;
            if (I !== iWinner && that.AddPKs.indexOf(iWinner) === -1) {
              let Finded = false;//2.如果该备选报名者iWinner的PK对子不存在,才能将其加入
              for (let j = 0; j < that.CurrCell.ZGs.length; j++) {
                let jWinner = that.CurrCell.ZGs[j].Winner, jLoser = that.CurrCell.ZGs[j].Loser;
                if (jWinner === I && jLoser === iWinner || jWinner === iWinner && jLoser === I) Finded = true;
              }
              if (!Finded) that.AddPKs.push(iWinner);
            }
          }
          */
          let aObj = {ActId: that.ActDoc._id, AddPKs: that.AddPKs, I: I};
          if (that.ActDoc.ActStyle.indexOf('单循环') >= 0) myMongoDBPost("GetNewAddPKs", aObj, function (NewAddPKs) {
            that.AddPKs = NewAddPKs;
            if (that.AddPKs.length === 1) {
              that.AddOnePKDoc(that.AddPKs[0]);
              that.AddPKs = [];
            }
          }); //else if (that.AddPKs.length === 1) that.AddOnePKDoc(that.AddPKs[0]);
        })
      },
      AddOnePKDoc(aLoser) {
        let that = this, I = that.$store.state.UserName;
        myMongoDBPost("GetDocs/Act0Main", {ActId: that.ActDoc._id}, function (Docs) {
          myMongoDBPost("GetDocs/act0", {_id: that.PastDays[that.I1].TimeWinAct0IDs[that.NoonIndex]}, function (Docs1) {
            that.CurrCell = Docs1[0];
            myMongoDBPost("Act0IfCurrPKExisted", {
              ActId: that.ActDoc._id,
              UserName: that.$store.state.UserName,
              CurrPK: aLoser
            }, function (data1) {//先检查当前pk对子是否已存在，若已存在，则禁止其报名
              if (data1 === '首次相遇')
                that.CurrCell.ZGs.unshift({Winner: I, Loser: aLoser, BF: '―', BFCertified: '―', MeetBool: '首次最新'});
              else if (data1 === '过渡状态') AutoAlert('您和' + aLoser + '有未完成的比赛!', '请注意比分输入后，对方还需要确认。');
              else if (that.ActDoc.ActStyle.indexOf('多循环') >= 0) {//重复相遇时，把已有的最新相遇改为中间相遇，增加一个最新相遇

                for (let i = 0; i < Docs.length; i++)
                  if (Docs[i].ZGs) for (let j = 0; j < Docs[i].ZGs.length; j++) {
                    let ZG = Docs[i].ZGs[j], PK1 = ZG.Winner, PK2 = ZG.Loser;
                    let TJ = (PK1 === I && PK2 === aLoser || PK2 === I && PK1 === aLoser);

                    if (TJ && ZG.MeetBool === '首次最新') ZG.MeetBool = '首次';
                    if (TJ && ZG.MeetBool === '最新') ZG.MeetBool = '中间';

                  }
                //for (let i = 0; i < Docs.length; i++) console.log(Docs[i].ZGs)

                myMongoDBPost("UpdateObjs/Act0Main", Docs, function (Docs2) {
                  that.CurrCell.ZGs.unshift({
                    Winner: I,
                    Loser: aLoser,
                    BF: '―',
                    BFCertified: '―',
                    MeetBool: '最新'
                  });
                  myMongoDBPost("UpdateDoc/act0", {
                    UPTJ: {_id: that.CurrCell._id},
                    UPObj: {ZGs: that.CurrCell.ZGs}
                  }, function (data2) {

                  });
                })
              } else myMongoDBPost("UpdateDoc/act0", {
                UPTJ: {_id: that.CurrCell._id},
                UPObj: {ZGs: that.CurrCell.ZGs}
              }, function (data2) {

                if (that.ActDoc.ActStyle.indexOf('单循环') >= 0) that.AddPKs.splice(that.AddPKs.indexOf(aLoser), 1);//如果是单循环
              });
              that.ZGIndex = -1;
            });
          })
        })
      },
      SetZG(Val, Bool) {
        let that = this, aObj;
        if (Bool === '填写比分') {
          aObj = this.CurrCell.ZGs[this.ZGIndex];
          aObj.BF = Val, aObj.BFCertified = 'F:' + this.$store.state.UserName;
        } //填写BF时:BFCertified='F:'+填充者用户名  确认时:BFCertified='C:'+确认者用户名
        if (Bool === '确认比分') {
          this.ZGIndex = Val;

          aObj = this.CurrCell.ZGs[this.ZGIndex];
          aObj.BFCertified = 'C:' + this.$store.state.UserName;
          aObj.ItemStr = that.ActDoc.ItemStr;
          if (aObj.ItemStr.charAt(0).toLowerCase() === 'a') {
            console.log("aObj.Winner,aObj.Loser,aObj.BF=", aObj.Winner, aObj.Loser, aObj.BF);
            if (that.CurrJFMethod === '泛约') myMongoDBPost("Act0FillToFymc", aObj, function (asd) {
            });
            if (that.CurrJFMethod === 'ChinaTT') {
              aObj.JFPool = that.ActDoc.JFPool;
              myMongoDBPost("ChinaTTToByset0", aObj, function (asd) {
              })
            }
          }
        }
        if (Bool === '撤销PK对子') this.CurrCell.ZGs.splice(Val, 1); else this.CurrCell.ZGs.splice(this.ZGIndex, 1, aObj);

        myMongoDBPost("UpdateDoc/act0", {
          UPTJ: {_id: this.PastDays[this.I1].TimeWinAct0IDs[this.NoonIndex]},
          UPObj: {ZGs: this.CurrCell.ZGs}
        }, function (data) {
          //that.RefreshPastDays();
          that.ZGIndex = -1;
        })
      } //填写BF时:BFCertified='F:'+填充者用户名  确认时:BFCertified='C:'+确认者用户名
    }
  }
</script>

<style scoped>

</style>
