<template>
  <div>
    <div class="MLR4">
      <p> 一、总则</p>
      <p>1.泛约网是一个严肃的正能量传递网站, 其所有权和运营权归天津泛约科技有限公司所有。</p>
      <p>2.用户在注册前应仔细阅读本协议，并同意遵守本协议后方可成为注册用户。</p>
      <p>3.由本站发布的通知、公告、声明或其它类似内容是本协议的一部分,用户应及时关注,本站不承担另行通知义务。</p>
      <p>二、服务内容</p>
      <p>泛约网平台主要向用户提供线下社交活动的线上邀约服务，其它服务内容根据用户需求情况不断更新。</p>
      <p>三、用户账号</p>
      <p>1.泛约昵称是本站规定的用户账号，不允许有重名现象出现，可以但不必须是真实姓名。</p>
      <p>2.账号密码是注册用户访问本站的钥匙，用户有义务保证其安全。凡是利用该密码和账号所进行的一切活动由用户自行承担全部责任；
        如发现账号遭到未授权的使用，应立即修改账号密码并妥善保管，如有必要，请通知本站；因黑客行为或用户保管疏忽导致账号非法使用的，本站不承担任何责任。</p>
      <p>四、信用托管</p>
      <p>用户需严格遵守本站规定的以下各条信用监管规则：</p>
      <p>1.用户注册信息确保是真实的，当有关信息发生变化时会及时更新。用户同意本网站因邀约需求而在相应页面显示用户信息。</p>
      <p>2.邀约活动结束后，能够及时真实地向网站反馈系统所需要的有关活动信息，如：战果/达标成绩、履约情况、消费金额等。</p>
      <p>3.对于系统按照相应规则自动生成的项目名次空间、人气信用指数排名，不得以任何理由和方式进行干涉。确有异议者，应通过网站内的“提建议”功能进行反映。网站有对用户注册信息保密的义务。因公司职员泄露用户信息并查证属实者，由公司承担相应的法律责任。</p>
      <p>五、用户使用规则</p>
      <p>1.遵守中华人民共和国有关计算机互联网规定和知识产权的法律法规、实施办法和其它一切相关法律法规。</p>
      <p>2.用户对其自行发表、上传或传送的内容负全部责任；绝不利用本站进行任何违法违规或侵犯他人利益或国家利益的活动。</p>
      <p>3.无条件认可本站在不同页面发布的各项规则、实施细则、各项指数统计算法，认可排名空间和排行榜的名次。</p>
      <p>4.当本站或第三方认为用户发表或上传的信息涉嫌侵权或非法、虚假、涉黄涉赌涉毒时，用户必须无条件同意本站自行判断是否删除。</p>
      <p>如用户在接受泛约服务时违反上述任何规定，本站有权要求用户改正或直接采取一切必要的措施以减轻用户不当行为而造成的负面影响。</p>
      <p>六、隐私保护</p>
      <p>1.本站不对外公开或向第三方提供单个用户的注册资料及用户在使用网站时存储在本站的非公开内容，但下列情况除外：</p>
      <p>(1)事先获得用户的明确授权；</p>
      <p>(2)根据有关的法律法规要求；</p>
      <p>(3)按照相关政府主管部门的要求；</p>
      <p>(4)为维护社会公众的利益。基于本站第三方信用托管的性质,如用户间发生民事或刑事纠纷,需警方介入的,本网站将无条件向警方提供用户的个人信息,以协助调查。</p>
      <p>2.本站可能会与第三方合作向用户提供相关的信息服务，在此情况下，如该第三方同意承担与本站同等的保护用户隐私的责任，则本站有权将用户的注册资料等提供给该第三方。</p>
      <p>3.在不透露单个用户隐私资料的前提下，本站有权对整个用户数据库进行统计 分析和商业上的利用。</p>
      <p>七、版权声明</p>
      <p>1.本站的文字、图片、音视频等版权均归泛约公司或与作者共同享有，未经本站许可，不得任意转载。</p>
      <p>2.本站特有的标识、版面设计、编排方式等版权均属泛约公司享有，未经本站许可，不得任意转载。</p>
      <p>3.使用本站的任何内容均应注明“来源于泛约网”及署上作者姓名，按法律规定需要支付稿酬的，应当通知本站及作者并支付稿酬，并独立承担一切法律责任。</p>
      <p>4.本站享有所有作品用于其它用途的优先权，但在使用前会通知作者，并按同行业的标准支付稿酬。</p>
      <p>5.恶意转载本站内容的，本站保留将其诉诸法律的权利。</p>
      <p>八、责任声明</p>
      <p>1.本站无法保证所提供的信息服务一定能满足所有用户的要求。</p>
      <p>2.本站不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该外部链接指向的不由本站实际控制的任何网页上的内容，本站不承担任何责任。</p>
      <p>3.对于因不可抗力或本站不能控制的原因造成的网络服务中断或其它缺陷，本站不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
      <p>4.对于网站向用户提供的下列产品或者服务质量的缺陷及其引发的损失，本站无需承担任何责任：</p>
      <p>(1)本站向用户免费提供的各项网络服务；</p>
      <p>(2)本站向用户赠送的任何产品或者服务。</p>
      <p>5.本站有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论通知与否，本站对用户和任何第三人均无需承担任何责任。</p>
      <p>九、附则</p>
      <p>1.本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法。</p>
      <p>2.以上任何条款无论何种原因完全或部分无效或不具有执行力，其余条款仍应有效并且有约束力。</p>
      <p>3.本协议解释权及修订权归天津泛约科技有限公司所有。</p>
    </div>

  </div>
</template>

<script>

  export default {
    name: "FYProtocol",
  }
</script>

<style scoped>
  p {
    margin: 0.2rem;
    text-align: justify;
  }

  p:first-letter, h1, h2 {
    margin-left: 1.6rem;
  }
</style>
