<template>
    <div>

        <div class="BottO">
            <div class="XLR YC TF T2 MP4">
                <div>
                    <div v-if="Area.split(':')[0]==='全国'" class="DispIB TF">区域:
                        <div class="DispIB TB" @click="Show1=true">全国</div>
                    </div>
                    <div v-else class="DispIB TB T4" @click="Show1=true">
                        {{Area.split(':')[0]}} <span class="TF"> : </span>{{Area.split(':')[2]}}
                    </div>
                    <SeleArea v-if="Show1" :Areas="Areas" @FromSeleArea="SetArea"></SeleArea>
                </div>
                <div>
                    项目:
                    <div class="DispIB TB T4" @click="Show2=true">{{ItemName}}</div>
                    <MySele1 v-if="Show2" :Opts="ItemNames" :ColN="3" @FromMySele1="SetItemName"></MySele1>
                </div>

            </div>
        </div>
        <table style="background-color:#fff;">
            <thead>
            <tr class="TF LH120">
                <th>
                    <div class="MTB2">序号</div>
                </th>
                <th>场地名称</th>
                <th>
                    <div class="NoWrap">人气</div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Site,index) in Sites" :key="index" class="LH120" style="padding: 1mm 0">
                <td>{{index+1}}</td>
                <td>
                    <div class="AlignL MTB2 TC PA3">{{Site.SiteName}}</div>
                </td>
                <td>{{Site.XYedUserN}}</td>
                <td>
                    <div class="TG T5" @click="SeleI=index">
                        <el-icon>
                            <ArrowRight/>
                        </el-icon>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <PopTitle v-if="SeleI !== -1" :Title="Sites[SeleI].SiteName" @FromPopUp="SeleI=-1">
            <SiteLook :Msg="Sites[SeleI]"></SiteLook>
        </PopTitle>
    </div>
</template>

<script>
    import {Compare, CloneObject, myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import FyAddr from "@/components/SharedVues/FyAddr";
    import SiteEdit from "./SiteEdit";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import SeleArea from "@/components/SharedVues/SeleArea";
    import MySele1 from "@/components/SharedVues/MySele1";
    import MyInput from "@/components/SharedVues/MyInput";
    import SiteLook from "./SiteLook";
    import MySelect from "../SharedVues/MySelect";
    import SiteCreate from "./SiteCreate";

    export default {
        name: "SitesLook",
        components: {SiteCreate, MySelect, SiteLook, MyInput, MySele1, SeleArea, PopTitle, SiteEdit, FyAddr},
        data() {
            return {
                Sites: [],

                Areas: [{P: '不限', Cs: [{C: '', DCs: []}]}],//每个P一个元素
                Area: '全国::',
                Show1: false,

                ItemNames: ['全部'],
                ItemName: '全部',
                Show2: false,
                Show3: false,


                TJ: {XYedUserN: {$gte: 0}},

                SeleI: -1,
                IsSiteMannager: false,
                SetSiteNameI: -1,
                SetStarI: -1,
            }
        },
        mounted() {
            let that = this;
            myMongoDBPost("GetDocs/fysites", {}, function (Docs) {//$gte大于等于0
                Docs.sort(Compare('XYedUserN')).reverse();
                that.Sites = Docs;
                for (let i = 0, len1 = Docs.length; i < len1; i++) {
                    //console.log(Docs[i].Province, Docs[i].City, Docs[i].DC, Docs[i].SiteName)
                    if (that.Areas.every(function (x) {
                        return x.P !== Docs[i].Province
                    })) that.Areas.push({P: Docs[i].Province, Cs: [{C: '不限', DCs: []}]});//如果每一个数组元素对象的P都不等于Docs[i].Province

                    for (let k = 0, len3 = that.Areas.length; k < len3; k++)
                        if (that.Areas[k].P === Docs[i].Province) {
                            if (that.Areas[k].Cs.every(function (x) {
                                return x.C !== Docs[i].City
                            })) that.Areas[k].Cs.push({C: Docs[i].City, DCs: ['不限']});
                            for (let m = 0, len4 = that.Areas[k].Cs.length; m < len4; m++)
                                if (that.Areas[k].Cs[m].C === Docs[i].City && that.Areas[k].Cs[m].DCs.indexOf(Docs[i].DC) === -1) that.Areas[k].Cs[m].DCs.push(Docs[i].DC);
                        }
                    for (let j = 0, len2 = Docs[i].Items.length; j < len2; j++)
                        if (that.ItemNames.indexOf(Docs[i].Items[j].ItemName) === -1)
                            that.ItemNames.push(Docs[i].Items[j].ItemName);


                }

                let LandTo = that.$store.state.LandTo;
                if (LandTo.indexOf("TJSite") >= 0)
                    for (let i = 0, len1 = that.Sites.length; i < len1; i++)
                        if (that.Sites[i]._id === LandTo.split("TJSite_")[1]) {
                            that.SeleI = i;
                            break;
                        }
            });
        },
        watch: {
            Area(Val) {//泛约区域一变,就立即重新获取场地数据,须同时考虑当前项目条件
                let that = this;
                if (Val.split(":")[1] === 'ALL') {
                    delete this.TJ.Province;
                    delete this.TJ.City;
                    delete this.TJ.DC;
                }
                if (Val.split(":")[1] === 'P') {
                    this.TJ.Province = Val.split(":")[2];
                    if (this.TJ.City) delete this.TJ.City;
                    if (this.TJ.DC) delete this.TJ.DC;
                }
                if (Val.split(":")[1] === 'C') {
                    if (this.TJ.Province) delete this.TJ.Province;
                    this.TJ.City = Val.split(":")[2];
                    if (this.TJ.DC) delete this.TJ.DC;
                }
                if (Val.split(":")[1] === 'DC') {
                    if (this.TJ.Province) delete this.TJ.Province;
                    if (this.TJ.City) delete this.TJ.City;
                    this.TJ.DC = Val.split(":")[2];
                }
                myMongoDBPost("GetDocs/fysites", this.TJ, function (Docs) {
                    Docs.sort(Compare('XYedUserN')).reverse();
                    that.Sites = Docs;
                })
            },
            ItemName(Val) {//项目名称一变,就立即重新获取场地数据,不要破坏其它查询条件
                let that = this;
                if (Val === '全部') delete this.TJ['Items.ItemName']; else this.TJ['Items.ItemName'] = Val;//此处有技巧 数组查询
                console.log(this.TJ)
                myMongoDBPost("GetDocs/fysites", this.TJ, function (Docs) {
                    Docs.sort(Compare('XYedUserN')).reverse();
                    that.Sites = Docs;
                })
            }
        },
        methods: {
            SetArea(Val) {
                this.Area = Val;
                this.Show1 = false
            },
            SetItemName(Val) {
                this.ItemName = Val;
                this.Show2 = false;
            }
        }
    }
</script>
<style scoped>
</style>
