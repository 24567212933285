<template>
    <div>
        <div class="XLR YC MP2 TG" @click="SetMySiteBool">&nbsp;<div>{{CurrSiteShortName}}</div>
            <el-icon v-if="MySiteBool"><Check/></el-icon>
                                                          设为喜约场地
        </div>
        <div class="BottGray MP2">
            <div>
                <h5 class="BottGray">&nbsp;&nbsp;本场地泛约资格裁判员：&nbsp;&nbsp;</h5>
            </div>
            <div class="MP2 NoWrap DispIB" v-for="(Judge,index2) in Judges" :key="index2"> {{Judge.Judge}}</div>
        </div>
        <p class="T32 MP2">如果您有意向参加在本场地举办的打擂活动，请在您喜爱的打擂方式(允许多选)下面点击预报名：</p>
        <div v-for="(DLP,index) in DLPres" :key="index">
            <div class="XLR YC MTB4">
                <h5 class="BottGray">&nbsp;&nbsp;{{DLP.DLStyle}}&nbsp;&nbsp;</h5>
                <div v-if="DLP.PreBMs.indexOf($store.state.UserName)<0" class="BGBlue TW PA3 BD_Radius1 NoWrap" @click="Add(index)">预报名</div>
                &nbsp;
                <div class="TB T3" @click="ShowArticleFind=['DLQ1', 'DLQ2', 'DLQ3', 'DLQ4', 'DLQ5', 'DLQ6'][index]">
                    打擂规则
                    <el-icon><Comment/></el-icon>
                </div>
            </div>
            <div class="PA1 NoWrap TC DispIB" v-for="(JP,index2) in DLP.PreBMs" :key="index2">
                {{JP}}
                <div v-if="JP===$store.state.UserName" class="DispIB TG" @click="Delete(index,index2)">[ 撤 ]</div>
            </div>
            <hr>
        </div>
    </div>
    <PopBlank v-if="ShowArticleFind!==''">
        <ShowArtical :FindWhat="ShowArticleFind" @FromShowArticle="ShowArticleFind=''"></ShowArtical>
    </PopBlank>
</template>

<script>
    import {myMongoDBPost, CloneObject} from '@/components/SharedVues/Shared0.js'
    import PopBott from "../MyPopWin/PopBott";
    import ShowArtical from "../MyPVTs/ShowArtical";
    import PopBlank from "../MyPopWin/PopBlank";

    export default {
        name: "PreEnroll",
        components: {PopBlank, ShowArtical, PopBott},
        props: ['AOBJ'],

        data() {
            return {
                Role: '',
                AdministratorId: '',
                AdminZGs: '',
                ZGSitesIndex: '',
                Judges: [],
                DLPres: [],
                MyPreBMs: [],
                MySiteBool: false,
                CurrSiteShortName: '',
                ShowArticleFind: '',
            }
        },
        mounted() {
            this.Role = this.AOBJ.Role;
            this.AdministratorId = this.AOBJ.AdministratorId;
            this.AdminZGs = this.AOBJ.AdminZGs;
            this.ZGSitesIndex = this.AOBJ.ZGSitesIndex;
            this.Judges = this.AdminZGs[this.ZGSitesIndex].Judges;
            this.DLPres = this.AdminZGs[this.ZGSitesIndex].DLPres;
            this.CurrSiteShortName = this.AdminZGs[this.ZGSitesIndex].SiteShortName;
            this.GetMySiteBool();
        },
        methods: {
            GetMySiteBool() {
                let that = this, TJ = {UserName: this.$store.state.UserName, ItemStr: this.$store.state.DefaultItem};
                myMongoDBPost('GetDocs/byset0', TJ, function (Docs) {
                    if (Docs.length > 0)
                        for (let i = 0; i < Docs[0].MySites.length; i++)
                            if (Docs[0].MySites[i].SiteId === that.AdminZGs[that.ZGSitesIndex].SiteId) that.MySiteBool = true;
                })
            },
            SetMySiteBool() {
                let that = this;
                this.MySiteBool = !this.MySiteBool;
                if (this.MySiteBool) {
                    that.AdminZGs[that.ZGSitesIndex].aIndex += 1;
                    that.AdminZGs[that.ZGSitesIndex].MySite = true;
                } else {
                    that.AdminZGs[that.ZGSitesIndex].aIndex -= 1;
                    that.AdminZGs[that.ZGSitesIndex].MySite = false;
                }
                let TJ = {_id: that.AdminZGs[that.ZGSitesIndex].SiteId};
                myMongoDBPost('GetDocs/fySites', TJ, function (Docs) {
                    let asd = Docs[0];
                    let aObj = {
                        UserName: that.$store.state.UserName,
                        ItemStr: that.$store.state.DefaultItem,
                        Bool: that.MySiteBool,//如果为true,则表示设为喜好
                        SiteId: that.AdminZGs[that.ZGSitesIndex].SiteId,
                        City: asd.City,
                        SiteShortName: asd.SiteShortName,
                        SiteName: asd.SiteName,
                        Address: asd.Address,
                        Lati: asd.Lati,
                        Longi: asd.Longi,
                        JF: 1600
                    };
                    myMongoDBPost('UpdateMySite', aObj, function (Docs) {
                        //that.GetMySiteBool();
                        that.SaveToDLQsZGs(that.AdminZGs);

                    })
                })
            },

            Add(index) {
                if (this.$store.state.UserName === '游客') alert('游客用户报名无效'); else {
                    if (this.Judges.length === 1 && this.Judges[0].Judge === this.$store.state.UserName)
                        alert('预报名无效! 您试图参加自己组织的打擂活动!');
                    else {
                        let ASDs = this.DLPres[index].PreBMs, PreBMsAlls = [], NewPreBMs = [];
                        if (ASDs.indexOf(this.$store.state.UserName) === -1) {
                            ASDs.push(this.$store.state.UserName);
                            this.DLPres[index].PreBMs = ASDs;
                            for (let i = 0; i < 6; i++) PreBMsAlls = PreBMsAlls.concat(this.DLPres[i].PreBMs);
                            for (let i = 0; i < PreBMsAlls.length; i++) if (NewPreBMs.indexOf(PreBMsAlls[i]) === -1) NewPreBMs.push(PreBMsAlls[i]);
                            this.AdminZGs[this.ZGSitesIndex].Sn = NewPreBMs.length;
                        }

                        this.AdminZGs[this.ZGSitesIndex].DLPres = this.DLPres;
                        console.log(this.DLPres)
                        this.SaveToDLQsZGs(this.AdminZGs);
                    }
                }
            },
            Delete(index, index2) {
                let that = this;
                console.log(this.DLPres)
                this.DLPres[index].PreBMs.splice(index2, 1);


                this.AdminZGs[this.ZGSitesIndex].Sn -= 1;
                this.AdminZGs[this.ZGSitesIndex].DLPres = this.DLPres;
                this.SaveToDLQsZGs(this.AdminZGs);
            },
            SaveToDLQsZGs(AdminZGs) {
                let that = this;
                myMongoDBPost('UpdateDoc/DLQs', {UPTJ: {_id: that.AdministratorId}, UPObj: {ZGs: AdminZGs}}, function (Docs) {
                    //that.$emit('FromPreEnroll');
                })
            }
        }
    }
</script>

<style scoped>

</style>