<template>
  <div>
    <div class="XLR TF">
      <div>当前：{{Msg2.Channel}}➢{{Msg2.ItemType}}</div>
      <div>数量：<span class="TE">{{SN}}</span></div>
    </div>
    <div class="BottGray">
      <div v-if="IsLoading">IsLoading...</div>
      <div v-else class="MyCell XLR PA1 YC" v-for="(aItem,index) in ItemLists" :key="index">
        <div v-if="aItem.Checked" class="XLR TB YC">
          <div class="TB Circle1 XYC" @click="InputI=index">
            <div class="T2">{{aItem.ItemStr.charAt(0)==='A'?'赢取':'收取'}}<br>FY币</div>
          </div>

          <div class=" ML2 TG Circle1 XYC">
            <TimeWindowSet :Msg="{ItemStr:aItem.ItemStr,bySets:bySets}"
                           @eventFromTWS="ResetTimeWinStrsN($event,index)"></TimeWindowSet>
          </div>
          <el-badge v-if="aItem.TimeWinStrsN>0" class="RightTop" :value="aItem.TimeWinStrsN"></el-badge>
          <div :ref="'Item1'+index" class=" ML2 TG Circle1 XYC">
            <div class="T2" @click="SetSeleI(index)">喜好<br>场地</div>
          </div>
          <el-badge v-if="aItem.MySitesN>0" class="RightTop" :value="aItem.MySitesN"></el-badge>
          <div :ref="'Item2'+index" class="ML2 DispIB">
            <ShowUpdatePVT :PVTF="{P:aItem.Psrc,V:aItem.Vsrc,T:aItem.Tsrc, TextTitle:'给主约的留言'}"
                           @ShowUT="CallBack1($event,index)"></ShowUpdatePVT>
          </div>
        </div>
        <div v-if="!aItem.Checked" class="Gray XLR YC">
          <div class="XYC">
            <ShowIcon T="TF" :ItemStr="aItem.ItemStr"></ShowIcon>
          </div>
          <div class=" ML2 Circle1 XYC">
            <div class="T2">喜好<br>时间</div>
          </div>
          <div class=" ML2 Circle1 XYC">
            <div class="T2">喜好<br>场地</div>
          </div>
          <img class="ML2 WH99" src="../../../static/img/Media/PicGray.jpg">
          <img class="ML2 WH99" src="../../../static/img/Media/VideoGray.jpg">
          <img class="ML2 WH99" src="../../../static/img/Media/TextGray.jpg">
        </div>
        <div class="AlignC LH120">
          <div :class="'NoWrap T2 '+(aItem.Checked ? 'TG':'TF')">{{aItem.ItemStr.split(":")[1]}}</div>
          <input type="checkbox" class="aui-switch" v-model="aItem.Checked" @change="SetCurrItem(aItem.Checked,index)"/>
        </div>
      </div>
    </div>

    <br>
    <MyInput v-if="InputI!==-1 && Msg2.Channel!=='技能服务'" :Title="(Msg2.Channel==='打擂竞技' ?'赢取':'收取')+ '泛约币数量'" Type="number" :Begin="ItemLists[InputI]"
             @FromMyI="MyInputVal"></MyInput>
    <PopOneAN v-if="SeleI!==-1" @FromPopUp="ResetMySitesN(1)">
      <SetMySites :ItemStr="ItemLists[SeleI].ItemStr" @FromSetMySites="ResetMySitesN($event)"></SetMySites>
    </PopOneAN>

  </div>
</template>

<script>
  import {myMongoDBPost,   CloneObject} from '@/components/SharedVues/Shared0.js'
  import {ItemIcons} from '../FrontPage/ItemIcons'
  import TimeWindowSet from "@/components/SharedVues/TimeWindowSet";
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import SiteCreate from "../M4_Sites/SiteCreate";
  import ShowIcon from "../FrontPage/ShowIcon";
  import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
  import MyInput from "../SharedVues/MyInput";
  import PopOneAN from "../MyPopWin/PopOneAN";
  import SetMySites from "../M4_Sites/SetMySites";

  export default {
    name: "SetItems",
    components: {
      SetMySites,
      PopOneAN,
      MyInput,
      ShowUpdatePVT,
      ShowIcon,
      SiteCreate,
      PopTitle,
      TimeWindowSet
    },
    props: ['Msg2'],
    data() {
      return {
        ItemIcons: ItemIcons,
        CurrItemC: '',
        IsLoading: true,//数据准备不就绪,就不让它显示
        bySets: [],
        ItemLists: [{
          ItemStr: '',
          Checked: false,
          Psrc: "",
          Vsrc: "",
          Tsrc: ""
        }],
        SN: '',
        Curr_k: 0,
        Show3: false,
        Curr_index: 0,
        NewItem: null,
        SDescribe0: '',
        InputI: -1,
        SeleI: -1
      }
    },
    mounted() {
      this.Refresh();
    },
    methods: {
      MyInputVal(Val) {
        let that = this;
        if (this.Msg2.Channel === '打擂竞技' && this.$store.state.ZX < Val * 6)
            alert("平台要求:赢取泛约币数量<=库存数量的1/6。您可设定的最大数量是"+Math.trunc(this.$store.state.ZX/6)+",请重新设定");
        else {
          that.ItemLists[that.InputI].AppearanceFee = Val;
          that.Curr_index = that.InputI;
          let CurrItem = CloneObject(that.ItemLists[that.Curr_index]);//一个对象;
          myMongoDBPost("UpdateDoc/bySet0", {
            UPTJ: {UserName: that.$store.state.UserName, ItemStr: CurrItem.ItemStr},
            UPObj: {AppearanceFee: Val}
          }, function (data2) {
            that.ItemLists.splice(that.Curr_index, 1, CurrItem);
            let Curr_k;
            for (let i = 0, len = that.bySets.length; i < len; i++)
              if (that.bySets[i].ItemStr === CurrItem.ItemStr) {
                Curr_k = i;
                break;
              }
            that.bySets[Curr_k].AppearanceFee = Val
          });
        }
        that.InputI = -1;
      },
      GetSN() {
        let that = this, SN = 0;
        for (let i = 0, len = that.ItemLists.length; i < len; i++)
          if (that.ItemLists[i].Checked) SN++;
        that.SN = SN + "/" + that.ItemLists.length;
      },
      Refresh() {
        let that = this;
        that.IsLoading = true;
        myMongoDBPost("GetDocs/byset0", {UserName: this.$store.state.UserName}, function (data) {
          that.bySets = data;
          myMongoDBPost("GetItemLists", {
            itemChannel: that.Msg2.Channel,
            ItemType: that.Msg2.ItemType
          }, function (Docs) {
            that.ItemLists = [];
            let ItemLists0 = Docs;
            for (let i = 0, len = ItemLists0.length; i < len; i++) { //老用户初始化
              let aItem = {ItemStr: ItemLists0[i], Checked: false, Psrc: '', Vsrc: '', Tsrc: '', TimeWinStrsN: 0, MySitesN: 0};
              for (let k = 0; k < that.bySets.length; k++) {  //如果希望被约项目数组中已有该项目,则标记为选中状态
                if (that.bySets[k].ItemStr === aItem.ItemStr) {
                  aItem.Checked = true;
                  if (that.bySets[k].Pic.length > 0) aItem.Psrc = that.bySets[k].Pic;
                  if (that.bySets[k].Video.length > 0) aItem.Vsrc = that.bySets[k].Video;
                  if (that.bySets[k].Text.length > 0) aItem.Tsrc = that.bySets[k].Text;
                  if (that.bySets[k].TimeWinStrs) aItem.TimeWinStrsN = (that.bySets[k].TimeWinStrs.split(";").length - 1);
                  if (that.bySets[k].MySites.length > 0) aItem.MySitesN = that.bySets[k].MySites.length;
                  aItem.AppearanceFee = that.bySets[k].AppearanceFee === undefined ? 0 : that.bySets[k].AppearanceFee;
                }
              }
              that.ItemLists.push(aItem);
            }
            that.IsLoading = false;
            that.GetSN();
          });
        });//读取当前用户设置数据
      },

      IconClass(aItem) {
        return ItemIcons[aItem.ItemStr.split(':')[0]] === undefined ? 'iconfont icon-tubiao' : 'iconfont ' + ItemIcons[aItem.ItemStr.split(':')[0]];
      },

      SetCurrItem(Checked, Index) {
        let that = this;
        if (this.$store.state.UserName !== '游客') {
          let CurrItem = CloneObject(that.ItemLists[Index]);//此时为一个对象,只转移地址
          CurrItem.Checked = Checked;
          let aObj = {
            UserName: that.$store.state.UserName,
            ItemStr: CurrItem.ItemStr,
            Pic: '',
            Video: '',
            Text: '',
            TimeWinStrs: '',
            AppearanceFee: 0
          };
          that.ItemLists.splice(Index, 1, {
            ItemStr: CurrItem.ItemStr,
            Checked: Checked,
            Psrc: '',
            Vsrc: '',
            Tsrc: '',
            TimeWinStrsN: 0,
            MySitesN: 0,
            AppearanceFee: 0
          });
          if (Checked) {
            myMongoDBPost("AddbySetsItem", {UserName: this.$store.state.UserName, ItemStr: CurrItem.ItemStr}, function (data) {
              that.bySets.push(aObj);
              //that.ItemLists.splice(Index,1,{ItemStr:CurrItem.ItemStr, Checked: true, Psrc: '', Vsrc: '', Tsrc: '', TimeWinStrsN: 0});

            })
          } else {//当由选中变为不选中时,将该项目从bySets中删除
            myMongoDBPost("DeletebySetsItem", {
              UserName: this.$store.state.UserName,
              CurrItem: CurrItem
            }, function (data) {


              for (let i = 0, len = that.bySets.length; i < len; i++)
                if (that.bySets[i].ItemStr === CurrItem.ItemStr) {
                  that.bySets.splice(i, 1);
                  break;
                }//3.从当前喜好项目数组中删除


            });
          }
          that.GetSN();
        }
      },
      CallBack1(Val, I) {//$emit发射后,用两个参数调用的情况
        this.Curr_index = I;
        let UPObj = null, CurrItem = CloneObject(this.ItemLists[this.Curr_index]);//一个对象;
        //console.log("Val="+Val+"   this.Curr_index="+this.Curr_index);
        //console.log(CurrItem);

        if (Val.P) UPObj = {Pic: Val.P};
        if (Val.V) UPObj = {Video: Val.V};
        if (Val.T) UPObj = {Text: Val.T};
        let that = this;
        if (UPObj !== null) myMongoDBPost("UpdateDoc/bySet0", {
          UPTJ: {UserName: this.$store.state.UserName, ItemStr: CurrItem.ItemStr},
          UPObj: UPObj
        }, function (data2) {
          //that.Refresh();
          CurrItem.Psrc = Val.P;
          CurrItem.Vsrc = Val.V;
          CurrItem.Tsrc = Val.T;
          that.ItemLists.splice(that.Curr_index, 1, CurrItem);

          let Curr_k;
          for (let i = 0, len = that.bySets.length; i < len; i++)
            if (that.bySets[i].ItemStr === CurrItem.ItemStr) {
              Curr_k = i;
              break;
            }
          if (Val.P) that.bySets[Curr_k].Pic = Val.P;
          if (Val.V) that.bySets[Curr_k].Video = Val.V;
          if (Val.T) that.bySets[Curr_k].Text = Val.T;

        })
      },
      ResetTimeWinStrsN(asd, I) {
        this.ItemLists[I].TimeWinStrsN = asd;
      },
      ResetMySitesN(Val) {
        let that = this;
        if (Val !== -1) myMongoDBPost("GetDocs/byset0", {UserName: this.$store.state.UserName, ItemStr: this.ItemLists[this.SeleI].ItemStr}, function (data) {
          that.ItemLists[that.SeleI].MySitesN = data[0].MySites.length;
          that.ItemLists.splice(that.SeleI, 1, that.ItemLists[that.SeleI]);
          that.SeleI = -1;
        });else  that.SeleI = -1;
      },
      SetSeleI(index) {
        this.SeleI = index;
        this.$store.commit('ChangeCurrItemStr', this.ItemLists[index].ItemStr);
      }
    }
  }
</script>

<style>
  .MyCell {
    border: solid #d5d5d5;
    border-width: 1px 0 0 0
  }

  .LeftTop {
    position: absolute;
    width: 3rem;
    height: 15px;
    left: -0.8rem;
    top: -0.5rem
  }

  .Css2 {
    display: none;
  }

  .Gray {
    color: #c9c9c9
  }

  .Circle1 {
    border: 1px solid;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* 所有角都使用半径为5px的圆角，此属性为CSS3标准属性 */
    -moz-border-radius: 50%; /* Mozilla浏览器的私有属性 */
    -webkit-border-radius: 50%; /* Webkit浏览器的私有属性 */
  }
</style>
