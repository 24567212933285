<template>
    <div class="MaskCss" :style="'z-index:'+MZindex"></div>
    <transition>
        <div v-if="Show7" class="PopWin" :style="'z-index:'+(Zindex+1)+';top:'+NewTop+'px'">
            <div class="Header YB" :style="'z-index:'+ (Zindex+2)">
                <div v-if="Zindex<100" class="XLR  MT2 MLR2">
                    <img src="../../../static/img/ButtonPic/SY2.png" @click="Return" class="WH77">
                    <div v-for="(M,index) in Menus" :key="index">
                        <div v-if="SeleI===index" :key="'M1'+index" class="SCss SCss2">{{M}}</div>
                        <div v-else class="SCss SCss1" :key="'M2'+index" @click="Selected(index)">{{M}}</div>
                    </div>
                </div>
                <div v-else class="XLR MT2 MLR2">
                    <img src="../../../static/img/ButtonPic/CB3.png" @click="Return" class="WH99">
                    <div v-for="(M,index) in Menus" :key="index">
                        <div v-if="SeleI===index" :key="'M1'+index" class="SCss SCss2">{{M}}</div>
                        <div v-else class="SCss SCss1" :key="'M2'+index" @click="Selected(index)">{{M}}</div>
                    </div>
                </div>
            </div>
            <div class="H4 ">&nbsp;</div>
            <slot></slot>
        </div>
    </transition>
</template>
<script>//一个返回值
import SubEntrance from "../SharedVues/SubEntrance";
export default {
    name: "PopMenu",
    components: {SubEntrance},
    props: ['Menus', 'BeginN'],

    data() {
        return {
            Show7: false,
            Timer: 0,
            MZindex: 0,
            Zindex: 0,
            Menus2: this.Menus,
            SeleI: 0,
            NewTop: 0
        }
    },
    computed: {
        //CurrZindex() {      return this.$store.state.CurrZindex    },
        CurrBackN() {
            return this.$store.state.BackN
        }
    },
    watch: {
        //CurrZindex(Val) {      this.Zindex = Val;      this.MZindex = Val - 2;    },
        CurrBackN(Val) {
            this.Return();
        }
    },
    mounted() {
        this.NewTop = window.scrollTop || document.documentElement.scrollTop;
        document.body.classList.add('body-locked');
        this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex + 50);
        this.Zindex = this.$store.state.CurrZindex;
        this.MZindex = this.$store.state.CurrZindex - 2;
        this.Show7 = true;
        this.Selected(this.BeginN);
    },
    beforeUnmount() {
        document.body.classList.remove('body-locked');
        this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex - 50);
        if (this.Timer) clearTimeout(this.Timer);
    },
    methods: {
        Selected(Val) {
            this.SeleI = Val;
            this.$emit("FromPopMenu", Val)
        },
        Return() {
            this.Show7 = false;
            let that = this;
            this.Timer = setTimeout(function () {
                that.$emit("FromPopMenu", -1)
            }, 1000)//确保动画完成
        }
    }
}
</script>

