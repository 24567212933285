<template>
  <div>
    <div class="MT2 MP4" v-for="(Act1Main,index1) in CurrAct1s" :key="index1">
      <div class="BottGray W80 PA3 XLR YC">
        <div>{{index1+1}}.{{Act1Main.Title}}：</div>
        &nbsp;
        <div></div>
      </div>
      <div :class="Act1Main.SubActType.indexOf('团体')>=0 ? 'AN ANGreen MT2':''">
        <div v-if="Act1Main.SubActType.indexOf('团体')>=0" class="XL YC">{{Act1Main.A}}☞</div>
        <div class="XLR YC">&nbsp;
          <div class="XL YC">
            <div v-if="Act1Main.BMAs.length===0" class="W40"></div>
            <div class="XL YC PA2" v-for="(BMA,index2) in Act1Main.BMAs" :key="index2">
              <img :src="'UserUpload/1/'+BMA.UserLogo" @click="ShowUserLogo=BMA.UserLogo" class="AvatarWH"/>
              <div class="ML2">
                {{BMA.UserName}}<br>
                <div class="TF T3">{{BMA.Sex}}&nbsp;&nbsp;{{BMA.Age}}岁</div>
                <div v-if="$store.state.UserName===BMA.UserName || $store.state.UserName===ActDoc.UserName" class="AlignC TG"
                     @click="CancelAsBM(index1,index2)">
                  [ <span class="TE">撤</span> ]
                </div>
              </div>
            </div>
            <div v-if="Act1Main.AsCanAdd" class="AN5 AN52 T4" @click="AsBM(index1)">报名</div>
          </div>
          &nbsp;
        </div>
      </div>
      <div v-if="Act1Main.SubActType.indexOf('团体')>=0" class="AN ANGray MT2">
        <div class="XL YC">{{Act1Main.B}}☞</div>
        <div class="XLR YC">&nbsp;
          <div class="XL YC">
            <div v-if="Act1Main.BMBs.length===0" class="W40"></div>
            <div class="XL YC PA2" v-for="(BMB,index2) in Act1Main.BMBs" :key="index2">
              <img :src="'UserUpload/1/'+BMB.UserLogo" @click="ShowUserLogo=BMB.UserLogo" class="AvatarWH"/>
              <div class="ML2">
                {{BMB.UserName}}<br>
                <div class="TF T3">{{BMB.Sex}}&nbsp;&nbsp;{{BMB.Age}}岁</div>
                <div v-if="$store.state.UserName===BMB.UserName || $store.state.UserName===ActDoc.UserName" class="AlignC TG"
                     @click="CancelBsBM(index1,index2)">
                  [ <span class="TE">撤</span> ]
                </div>
              </div>
            </div>
            <div v-if="Act1Main.BsCanAdd" class="AN5 AN52 T4" @click="BsBM(index1)">报名</div>
          </div>
          &nbsp;
        </div>
      </div>
    </div>
    <div v-if="ShowUserLogo!==''">
      <img :src="'UserUpload/1/'+ShowUserLogo" @click="ShowUserLogo=''" style="position:fixed;left:0;top:0;width: 100vw;height:auto"/>
    </div>
  </div>
</template>

<script>
  import {myMongoDBPost, AutoAlert, CloneObject, Compare, StampToDate} from '@/components/SharedVues/Shared0.js'

  export default {
    name: "Act1BM",
    props: ['ActDoc', 'Act1s'],
    data() {
      return {
        ShowUserLogo: '',
        CurrAct1s: this.Act1s
      }
    },
    mounted() {
      for (let i = 0; i < this.CurrAct1s.length; i++) {
        let AsCanAdd = true, BsCanAdd = true;

        for (let j = 0; j < this.CurrAct1s[i].BMAs.length; j++)
          if (this.CurrAct1s[i].BMAs[j].UserName === this.$store.state.UserName) AsCanAdd = false, BsCanAdd = false;
        for (let j = 0; j < this.CurrAct1s[i].BMBs.length; j++)
          if (this.CurrAct1s[i].BMBs[j].UserName === this.$store.state.UserName) AsCanAdd = false, BsCanAdd = false;
        this.CurrAct1s[i].AsCanAdd = AsCanAdd;
        this.CurrAct1s[i].BsCanAdd = BsCanAdd;
      }
    },
    methods: {
      AsBM(index1) {
        if (this.$store.state.UserName === '游客') AutoAlert('游客报名无效', '请返回后注册或登录!'); else {
          let I = this.CurrAct1s[index1].BMAs.length;
          let aOBJ = {UserName: this.$store.state.UserName, UserLogo: this.$store.state.UserLogo, Sex: this.$store.state.Sex, Age: this.$store.state.Age};
          this.CurrAct1s[index1].BMAs.splice(I, 0, aOBJ);
          myMongoDBPost("UpdateDoc/act1", {UPTJ: {_id: this.CurrAct1s[index1]._id}, UPObj: {BMAs: this.CurrAct1s[index1].BMAs}}, function (data2) {
          });
          this.CurrAct1s[index1].AsCanAdd = false;
          this.CurrAct1s[index1].BsCanAdd = false;
        }
      },
      CancelAsBM(index1, index2) {
        this.CurrAct1s[index1].BMAs.splice(index2, 1);
        myMongoDBPost("UpdateDoc/act1", {UPTJ: {_id: this.CurrAct1s[index1]._id}, UPObj: {BMAs: this.CurrAct1s[index1].BMAs}}, function (data2) {
        });
        this.CurrAct1s[index1].AsCanAdd = true;
        this.CurrAct1s[index1].BsCanAdd = true;
      },

      BsBM(index1) {
        if (this.$store.state.UserName === '游客') AutoAlert('游客报名无效', '请返回后注册或登录!'); else {
          let I = this.CurrAct1s[index1].BMBs.length;
          let aOBJ = {UserName: this.$store.state.UserName, UserLogo: this.$store.state.UserLogo, Sex: this.$store.state.Sex, Age: this.$store.state.Age};
          this.CurrAct1s[index1].BMBs.splice(I, 0, aOBJ);
          myMongoDBPost("UpdateDoc/act1", {UPTJ: {_id: this.CurrAct1s[index1]._id}, UPObj: {BMBs: this.CurrAct1s[index1].BMBs}}, function (data2) {
          });
          this.CurrAct1s[index1].AsCanAdd = false;
          this.CurrAct1s[index1].BsCanAdd = false;
        }
      },
      CancelBsBM(index1, index2) {
        this.CurrAct1s[index1].BMBs.splice(index2, 1);
        myMongoDBPost("UpdateDoc/act1", {UPTJ: {_id: this.CurrAct1s[index1]._id}, UPObj: {BMBs: this.CurrAct1s[index1].BMBs}}, function (data2) {
        });
        this.CurrAct1s[index1].AsCanAdd = true;
        this.CurrAct1s[index1].BsCanAdd = true;
      }
    }
  }
</script>

<style scoped>

</style>
