<template>
    <div class="MT2 TC BottGray">
        <div class="MLR2">
            <div v-if="CurrSiteUser.Video!==''" class="ML2" style="float: right;">
                <ShowVFromThumb :Msg="{Video:CurrSiteUser.Video,Update:false}"></ShowVFromThumb>
            </div>
            <p>{{CurrSiteUser.Text}}</p>
        </div>
        <div class="BottGray" style="clear: both;"></div>
        <el-carousel height="150px" :autoplay="true" @change="SwiperChange">
            <el-carousel-item v-for="(Coaches,idx1) in Coachess" :key="idx1">
                <div class="XLR YC BottGray PA2">
                    <div v-for="(Coache,idx2) in  Coaches" :key="idx2">
                        <div v-if="Coache==='～'" class="W25 H25vw XYC">～</div>
                        <div v-if="Coache===null" class="W25 XYC">
                            <div class="AN ANBlue T2" @click="PutCoache">
                                <el-icon><Avatar/></el-icon>
                                <br>申请加入<br>教练团队
                            </div>
                        </div>
                        <div v-if="Coache!=='～' && Coache!==null" class="MT2 W25 AlignC">
                            <ShowVFromThumb :Msg="{Video:Coache.Video,Update:false}"></ShowVFromThumb>
                            <a class="T3" @click="CoacheIdx1=idx1,CoacheIdx2=idx2">[简介]</a><br>
                            <div v-if="Coache.Pic2===''" class="TF">应聘中..</div>
                            <a v-else class="T4 TB" @click="PutStudent(Coache.CoacheUser,Coache.Pic2)">{{Coache.Pic2}}</a>
                        </div>
                    </div>
                    <div v-if="Coachess===[]" class="TF MR4 AlignC">
                        <h1 class="TC">当前无教练!</h1>
                        <div>(培训报名尚未开始)</div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="T3 ">
            <p>操作提示：</p>
            <p>1.直接选择一个教练，即完成参加培训报名。</p>
            <p>2.点击加入教练团队，可应聘教练。</p>
        </div>
        <div style="clear: both;"></div>
        <div class="MLR4 LH120">
            地址：{{CurrSiteMsg.DC}}{{CurrSiteMsg.Address}}
            <div class="XLR YC MT2">电话：{{CurrSiteMsg.Telephone}}
                <div>联系人：{{CurrSiteUser.CAdminUser}}</div>
            </div>
        </div>&nbsp;
        <PopBott v-if="CoacheIdx1>=0 && CoacheIdx2>=0">
            <div class="XLR YC MP1">{{Coachess[CoacheIdx1][CoacheIdx2].Pic2}}教练情况简介：
                <div class="BD_Radius1 WHcc XYC" @click="CoacheIdx1=-1,CoacheIdx2=-1">×</div>
            </div>
            <p class="MLR2">{{Coachess[CoacheIdx1][CoacheIdx2].Text}}</p>
            <div class="XR MP1">
                <img  src="../../../static/img/ButtonPic/CB6.png" class="WH77" @click="CoacheIdx1=-1,CoacheIdx2=-1">
            </div>
        </PopBott>
    </div>
</template>

<script>
    import ShowVFromThumb from "../MyPVTs/ShowVFromThumb";
    import {myMongoDBPost, Compare} from '@/components/SharedVues/Shared0.js'
    import PopBott from "../MyPopWin/PopBott";

    export default {
        name: "OneYKSite",
        components: {PopBott, ShowVFromThumb},
        props: ['CurrSiteUser'],
        data() {
            return {
                CoacheIdx1:-1,
                CoacheIdx2:-1,
                CurrSiteMsg: {},
                Coachess: [],
            }
        },
        mounted() {
            let that = this;
            myMongoDBPost("GetDocs/fySites", {_id: this.CurrSiteUser.SiteId}, function (res) {
                if (res.length > 0) that.CurrSiteMsg = res[0];
            });
            that.GetCoachess(that.CurrSiteUser.CAdminUser);
        },
        methods: {
            SwiperChange(Val) {
                console.log(Val)
            },
            GetCoachess(CAdminUser) {
                let that = this, aObj = {CAdminUser: CAdminUser, DocBool: '教练'};
                myMongoDBPost("GetDocs/SiteUsers", aObj, function (res) {
                    let Coaches = res;
                    //下边要把Coaches改造成1个二维数组,每个1级数组元素中要包含4个教练
                    let Coachess = [[]], M = 0, N = 0;
                    for (let i = 0; i < Coaches.length; i++) {
                        Coachess[M].push(Coaches[i]);
                        N++;
                        if (N === 4) {
                            Coachess.push([]);
                            M++;
                            N = 0;
                        }
                    }
                    if (Coachess[M].length === 0) Coachess[M].push(null);
                    else if (Coachess[M].length === 1) Coachess[M].push('～', '～', null);
                    else if (Coachess[M].length === 2) Coachess[M].push('～', null);
                    else if (Coachess[M].length === 3) Coachess[M].push(null);
                    that.Coachess = Coachess;
                    //console.log(that.Coachess)
                })
            },
            PutCoache() {
                let that = this, aObj = {CAdminUser: this.CurrSiteUser.CAdminUser, CoacheUser: that.$store.state.UserName, DocBool: '教练'};
                if (this.$store.state.UserName === '游客') alert('游客用户无效！');
                else myMongoDBPost("RemoveDoc/SiteUsers", aObj, function (res) {
                    let aObj2 = that.CurrSiteUser;
                    delete aObj2._id;
                    aObj2.Pic = '';
                    aObj2.Video = '';
                    aObj2.Text = '';
                    aObj2.Pic2 = '';
                    aObj2.CoacheUser = that.$store.state.UserName;
                    aObj2.DocBool = '教练';
                    myMongoDBPost("PutDoc/SiteUsers", aObj2,
                        function (res) {
                            alert('教练申请操作成功! 请线下通知' + that.CurrSiteMsg.SiteName + '课程管理员！');
                        })

                })
            },//创建1条教练文档
            PutStudent(CoacheUser, Pic2) {
                let that = this, aObj = this.CurrSiteUser;
                if (this.$store.state.UserName === '游客') alert('游客用户无效！'); else {
                    delete aObj._id;
                    aObj.Pic = that.$store.state.UserLogo;
                    aObj.RealName = that.$store.state.RealName;
                    aObj.Mobile = that.$store.state.Mobile;
                    aObj.CoacheUser = CoacheUser;
                    aObj.Pic2 = Pic2;
                    aObj.StudentUser = this.$store.state.UserName;
                    aObj.DocBool = '学生';
                    myMongoDBPost("RemoveDoc/SiteUsers", {CAdminUser: aObj.CAdminUser, StudentUser: aObj.StudentUser, DocBool: '学生'}, function (res) {
                        myMongoDBPost("PutDoc/SiteUsers", aObj, function (res) {
                                alert('报名完毕! 请线下通知' + that.CurrSiteMsg.SiteName + '课程管理员！');
                            })

                    })
                }
            },//创建1条学生文档
        }

    }
</script>

<style scoped>

</style>