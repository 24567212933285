<template>
    <PopUpDown Title="新场地注册(管理员)" :ANs="2" Pos="btt" @FromPop5="SaveCurrSiteDoc">
        <div v-if="IsSiteMannager || Recommend">
            <div class="MP4 LH200">
                <div class="XLR YC">
                    所在地：
                    <FyAddr @FromFyAddr="SetSiteAddr"></FyAddr>
                </div>
                <div class="XLR T3 YC MT2">详细地址：<input type="text" class="W60" v-model="CurrSiteDoc.Address"/>
                    <div></div>
                </div>
                <div class="XLR T3 YC MT2">场地名称：<input type="text" class="W60" v-model="CurrSiteDoc.SiteName"/>
                    <div></div>
                </div>
                <div class="XLR T3 YC MT2">联系电话：<input type="text" class="W60" v-model="CurrSiteDoc.Telephone"/>
                    <div></div>
                </div>
                <div class="XL YC MT2">
                    场地环境情况：　
                    <ShowUpdatePVT :PVTF="{P:CurrSiteDoc.Pic,V:CurrSiteDoc.Video,T:CurrSiteDoc.Remark,
          TextTitle:'场地情况简要介绍'}" @ShowUT="CallBack"></ShowUpdatePVT>
                </div>
                <div v-if="Recommend" class="XL YC MT2">
                    {{CurrSiteDoc.Items[0].ItemName}}项目收费及相关说明：
                    <ShowUpdatePVT :PVTF="{P:CurrSiteDoc.Items[0].Pic,V:CurrSiteDoc.Items[0].Video,T:CurrSiteDoc.Items[0].Remark,
          TextTitle:'场地情况简要介绍'}" @ShowUT="CallBack2"></ShowUpdatePVT>
                </div>
                <div v-else class="TF T3">
                    <hr>
                    <p>备注：</p>
                    <p>1.创建者即为场地管理员。</p>
                    <p>2.场地经纬度数据须通过“泛约在线地图”，到现场实时定位获得。</p>
                    <p>3.场地项目信息须在“查询与修改”中完成。</p>
                </div>
            </div>
        </div>
        <div v-else class="MP4 AlignC TE">
            请您先取得场地管理员资格!
        </div>
    </PopUpDown>
</template>

<script>
    import FyAddr from "@/components/SharedVues/FyAddr";
    import {AutoAlert, myMongoDBPost, GetItemType} from '@/components/SharedVues/Shared0.js'
    import ShowPVT from "@/components/MyPVTs/ShowPVT";
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import MyInput from "@/components/SharedVues/MyInput";
    import PopUpDown from "../MyPopWin/PopUpDown";
    //
    export default {
        name: "SiteCreate",
        components: {PopUpDown, MyInput, ShowUpdatePVT, ShowPVT, FyAddr},
        props: ["Recommend"],
        data() {
            return {
                Show2: false,
                CurrSiteDoc: {},
                IsSiteMannager: false,
                InputI: -1
            }
        },
        mounted() {
            let asd = this.$store.state.FYArea.split(":");
            if (asd[0] === 'P') this.CurrSiteDoc.Province = asd[1];
            if (asd[0] === 'C') this.CurrSiteDoc.City = asd[2];
            this.IsSiteM();
        },
        methods: {
            SetSiteAddr(Val) {
                this.CurrSiteDoc = {Province: Val.P, City: Val.C, DC: Val.DC};
            },
            MyInputVal(Val) {
                if (this.InputI === 1) this.CurrSiteDoc.Address = Val;
                else if (this.InputI === 2) this.CurrSiteDoc.SiteName = Val;
                else if (this.InputI === 3) this.CurrSiteDoc.Telephone = Val;
                this.InputI = -1;
            },

            IsSiteM() {
                let that = this;
                myMongoDBPost("GetDocs/fyuser0", {
                    UserName: this.$store.state.UserName,
                    UserBools: '场地管理员'
                }, function (Docs) {
                    if (Docs.length > 0) that.IsSiteMannager = true;
                })
            },
            CallBack(Val) {
                if (Val.P) this.CurrSiteDoc.Pic = Val.P;
                if (Val.V) this.CurrSiteDoc.Video = Val.V;
                if (Val.T) this.CurrSiteDoc.Remark = Val.T;
            },
            CallBack2(Val) {
                if (Val.P) this.CurrSiteDoc.Items[0].Pic = Val.P;
                if (Val.V) this.CurrSiteDoc.Items[0].Video = Val.V;
                if (Val.T) this.CurrSiteDoc.Items[0].Remark = Val.T;
            },
            SaveCurrSiteDoc(Val) {
                let that = this, asd = this.CurrSiteDoc;
                this.CurrSiteDoc.SiteAdmins = [this.$store.state.UserName, '待定', '待定'];
                this.CurrSiteDoc.ScrollTexts = ['待定', '待定', '待定'];
                this.CurrSiteDoc.Items = [{
                    ItemType: GetItemType(this.$store.state.CurrItemStr.split(":")[0]),
                    ItemCode: this.$store.state.CurrItemStr.split(":")[0],
                    ItemName: this.$store.state.CurrItemStr.split(":")[1]
                }]; //如果是新建场地,则用该初值,
                console.log("Val=", Val)
                console.log(this.CurrSiteDoc)
                if (Val === -1) this.$emit("FromSiteCreate"); else {
                    if (this.Recommend) this.CurrSiteDoc.XYedUserN = -2; else {
                        this.CurrSiteDoc.XYedUserN = 0;
                        this.CurrSiteDoc.Items = [];
                    }
                    if (asd.Province !== '省份' && asd.City !== '城市' && asd.DC !== '区县' && asd.SiteName !== '')
                        myMongoDBPost("SaveCurrSiteDoc", this.CurrSiteDoc, function (data) {
                            if (that.Recommend) AutoAlert('推荐成功', '场地信息已提交场地管理员处理!'); else AutoAlert('场地信息已创建完成', '具体项目尚需进一步完善!');
                            that.$emit("FromSiteCreate")
                        }); else {
                        AutoAlert('信息不完整', '保存无效!');
                        that.$emit("FromSiteCreate");
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>
