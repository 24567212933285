<template>
  <div class="XLR YC MTB2">
    &nbsp;
    <div class="NoWrap TF MTB2">── • {{ShowStr}} • ──</div>
    &nbsp;
  </div>
</template>

<script>
  export default {
    name: "MyDivider",
    props: ['ShowStr']
  }
</script>

<style scoped>

</style>
