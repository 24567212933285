<template>
    <div class="MP4">

      <p>一、项目邀约</p>
      <p>为提高泛约网的有效性、权威性和严肃性，平台鼓励被约收取泛约币,以抑制滥约现象发生。</p>
      <p>1.当主约向被约发起邀约时，如果被约设置了“收取泛约币”，则应先将主约的泛约币提取出来（优先提取流通泛约币，数量不足时再提取原始泛约币）；如果泛约币总量不足以邀请被约，则给出提示，取消本次邀约。</p>
      <p>2.邀约过程正常结束后，被约收取泛约币。</p>
      <p>3.当被约履约、主约爽约时，被约收取的泛约币不退回；当主约履约、被约爽约时，之前被提取的泛约币还回主约。</p>
      <p>二、泛约帮办</p>　
      <p>任何泛约用户都可以发布任意的帮忙求助信息，但同时还要发布相应的“悬赏泛约币数量”信息，帮忙的人不一定收取现金，但应该收取等量的泛约币。</p>
      <p>三、上传视频</p>　
      <p>因上传视频需要占用的平台资源比较大，因此平台将根据视频上传的大小收取一定数量的泛约币。初定5M以内视频，无需耗费泛约币，超过5M后，每5M增加１个泛约币。</p>

    </div>
</template>

<script>
    export default {
        name: "ZXrules"
    }
</script>

<style scoped>

</style>
