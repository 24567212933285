<template>
  <div>
    <PopUpDown :Title="Title" Pos="btt" :ANs="1" @FromPop5="$emit('FromMySele1', '')">
      <div class="XLR YC" v-for="(NO, index1) in NewOpts" :key="index1">&nbsp;
        <div class="XLR YC">
          <div v-for="(Str, index2) in NO"  :key="index2" @click="SetStr(Str)">
            <div v-if="Str==='....'" :class="'SubBox '+Ws[ColN-1]" style="opacity:0">{{''}}</div>
            <div v-else :class="'TG SubBox '+Ws[ColN-1]">{{Str}}</div>
          </div>
        </div>
        &nbsp;
      </div>
      <div v-if="AddAN" class="XR MP4 TG" @click="$emit('FromMySele1', AddAN)">{{AddAN}}</div>
    </PopUpDown>
  </div>
</template>

<script>
  import {AutoAlert} from '@/components/SharedVues/Shared0.js';
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import PopUpDown from "../MyPopWin/PopUpDown";
  //出口参数说明:''=没有操作 '无'=入口待选数组为空 AddAN=选择了AddAN 其他=正常返回一个字符串
  export default {
    name: "MySele1",
    components: {PopUpDown, PopTitle},
    props: ['Opts', 'ColN', 'AddAN'],
    data() {
      return {
        Title: "请选择",
        NewOpts: [],
        Ws:['W86','W40','W26','W20','W12']
      }
    },
    mounted() {
      //console.log(this.Opts)
      //console.log(this.ColN)
      if (this.Opts.length === 0) {
        AutoAlert('没有备选项', '选择无效!');
        this.$nextTick(() => {
          this.$emit("FromMySele1", '无');
        });
      } else {
        let i = 0;
        this.NewOpts = new Array(Math.ceil(this.Opts.length / this.ColN));
        for (let M = 0; M < this.NewOpts.length; M++) {
          this.NewOpts[M]=[];
          for (let N = 0; N < this.ColN; N++) {
            if (i < this.Opts.length) this.NewOpts[M].push(this.Opts[i]);else this.NewOpts[M].push('....');
            i++
          }
        }
        console.log(this.NewOpts)
      }
    },//当把$emit("FromMySele1", '无')放在mounted()中时,一定要放在$nextTick之内,否则会出现TypeError: Cannot read properties of null (reading 'classList')
    methods: {
      SetStr(Str) {
        this.$emit("FromMySele1", Str)
      }
    }
  }
</script>

<style scoped>

</style>
