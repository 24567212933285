<template>
    <div>
        <el-divider> 通知 • 公告</el-divider>
        <div class="TF2 PA2 BottGray">
            <div v-if="$store.state.WXId==='未关注'">
                <img :src="'UserUpload/CZSM/Two.png'" class="ML2 W26" style="float: right">
                <p class="MR4">☞请通过微信公众号“泛约网”访问使用，长按右侧二维码并关注置顶。联系电话: 18622582303</p>
            </div>
            　
            <div style="line-height: 1.8rem">
                <div class="DispIB" style="margin-left: 1.6rem;"> &nbsp;☞开放型共享卡要求预存</div>
                <div class="DispIB AN ANBlue" @click="Show=3">
                    泛约币
                </div>
                ；Vip共享卡不涉及费用，平台只记录用卡时间和次数。<br>

                <div class="DispIB" style="margin-left: 1.6rem;"> &nbsp;☞泛约币不够用时，</div>
                须通过打擂竞技、
                <div class="DispIB TG" @click="Show=1">诚信担保</div>
                、
                <div class="DispIB TG" @click="Show=2">泛约帮办</div>
                、技能服务等平台内应用挣取，也可以直接充值。
            </div>

            <p>
                ☞实名：<img src="../../../static/img/IDed.png" width="20">=已认证&nbsp;
                <img src="../../../static/img/ID2.png" width="20">=认证失败&nbsp;
                <img src="../../../static/img/ID3.png" width="20">=待认证
            </p>
            <p>
                ☞本平台当前正在天津通过乒乓球项目试用中，如有问题会借助“泛约天津乒乓球友群”发布信息，因此建议新注册用户通过手机微信号：18622582303，加入本群。
            </p>
            <!--div style="line-height: 1.8rem">
              <div class="DispIB" style="margin-left: 1.6rem;">☞</div>
              新用户注册是泛约币的唯一来源，总量有限，通过
              可以作为求人帮忙、资源占用的统一标的物使用。
            </div>
            <div style="line-height: 1.8rem">
              <div class="DispIB" style="margin-left: 1.6rem;">☞</div>
              已完成实名认证的用户，可以通过操作，直接将其担保对象注册成实名用户，同时获赠12个流通泛约币。
            </div>

            <p>本平台将持续不断地，向广大用户提供各类社交信息化服务。如果您有好的创意或需求，请与我们联系，只要具有通用性，我们就会把它用代码实现，并且在此处提供操作接口。</p>
            <p>,注册信息越完整,获赠泛约币数越多,每个完成实名认证的用户最多可以获赠100个泛约币，
              ☞参与深度有偿测试，请注意平台<span class="T4 TB" @click="ShowBug=true">已知bug</span>不能重复上报，最好先打电话18622582303联系一下！
            </p-->
        </div>
        <PopTitle v-if="Show===1" Title="诚信担保" @FromPopUp="Show=-1">
            <CXDB></CXDB>
        </PopTitle>
        <PopTitle v-if="Show===2" Title="泛约帮办服务中心" @FromPopUp="Show=-1">
            <FYBB></FYBB>
        </PopTitle>

        <PopTitle v-if="Show===3" :Title="$store.state.UserName+'的泛约币'" @FromPopUp="Show=-1">
            <ZXGL></ZXGL>
        </PopTitle>


        <ShowJF v-if="Show===9" :UserName="$store.state.UserName" ItemCode="a004" :CurrJF="9" @FromShowJF="Show=-1"></ShowJF>
    </div>
</template>

<script>
    import {myMongoDBPost, AutoAlert2} from '@/components/SharedVues/Shared0.js'

    import ZXGL from "../IndependentVues/ZXGL";
    import PopTitle from "../MyPopWin/PopTitle";
    import FYBB from "../QYs/FYBB";
    import CXDB from "./CXDB";
    import ShowJF from "../ListYY/ShowJF";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import GXCard from "../M11_GXCards/GXCard";
    import SitesEdit from "../M4_Sites/SitesEdit";
    //本平台将以“续家谱”服务为引线，为您提供一系列免费的社交服务项目。目前主推“续家谱”服务，其它功能还不够成熟，如果您在试用中发现bug，请及时与我们联系。
    //本平台正在招募合伙人，如果您对平台中开展的任何一项业务感兴趣，欢迎来电洽谈合伙/合作事宜
    export default {
        name: "TZGG",
        components: {SitesEdit, GXCard, PopOneAN, ShowJF, CXDB, FYBB, PopTitle, ZXGL},
        data() {
            return {
                CurrU: this.$store.state.UserName,
                Show: -1,
                fymcs1: [{_id: '紫丁香', Defeats: ['张大龙', '张小龙', '张三龙', '张四龙', '张五龙']},
                    {_id: '张小龙', Defeats: ['王大凤', '王小凤', '王三凤', '王四凤', '王五凤']},
                    {_id: '张四龙', Defeats: ['李大平', '李小平', '李三平', '李四平', '李五平']},
                    {_id: '王小凤', Defeats: ['赵大明', '赵小明', '张大龙', '赵三明', '赵四明', '赵五明']},
                    {_id: '李三平', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '王四凤', Defeats: []},
                    {_id: '王五凤', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '张大龙', Defeats: []},
                    {_id: '钱大户', Defeats: ['张三龙', '张四龙', '王小凤']},
                    {_id: '钱三户', Defeats: ['紫丁香']},
                    {_id: '张五龙', Defeats: ['初学者１', '初学者2']},
                    {_id: '赵小明', Defeats: []},
                    {_id: '赵五明', Defeats: []},
                ],
                fymcs2: [{_id: '马冠军', Defeats: ['王晓峰', '张大龙', '庄晓峰', '牛晓峰']},
                    {_id: '王晓峰', Defeats: ['张大龙', '李晓明', '陆大明']},
                    {_id: '张大龙', Defeats: ['孙大虎', '怪球手', '孙小虎']},
                    {_id: '怪球手', Defeats: ['马冠军', '初学者', '孙小虎']},
                    {_id: '李晓峰', Defeats: ['马冠军']},
                    {_id: '后起之秀', Defeats: ['队友一', '队友二', '马冠军', '樊亚军', '许季军', '挑战者', '打擂者', '国际友人']},
                    {_id: '国际友人', Defeats: ['欧洲五号', '伊藤美胜', '非洲三号']},
                    {_id: '欧洲五号', Defeats: ['小石村']},
                    {_id: '非洲三号', Defeats: ['瓦尔特', '本地虎']},
                    {_id: '李晓明', Defeats: []},
                    {_id: '陆大明', Defeats: []},
                    {_id: '孙大虎', Defeats: []},
                    {_id: '初学者', Defeats: []},
                    {_id: '孙小虎', Defeats: []},
                    {_id: '李晓明', Defeats: []},
                    {_id: '许季军', Defeats: []},
                    {_id: '小石村', Defeats: []},
                    {_id: '本地虎', Defeats: []}
                ],
                fymcs3: [
                    {_id: '紫丁香', Defeats: ['张大龙', '张小龙', '张三龙', '张四龙', '张五龙']},
                    {_id: '张小龙', Defeats: ['王大凤', '王小凤', '王三凤', '王四凤', '王五凤']},
                    {_id: '张四龙', Defeats: ['李大平', '李小平', '李三平', '李四平', '李五平']},
                    {_id: '王小凤', Defeats: ['赵大明', '赵小明', '紫丁香', '赵三明', '赵四明', '赵五明']},
                    {_id: '李三平', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '王四凤', Defeats: []},
                    {_id: '王五凤', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '钱大户', Defeats: ['张三龙', '张四龙']},
                    {_id: '钱三户', Defeats: []},
                    {_id: '张大龙', Defeats: ['孙大小', '孙二小', '孙三儿小', '孙不溜儿', '孙小']},
                    {_id: '张三龙', Defeats: []},
                    {_id: '李大平', Defeats: ['马家军１', '马家军２', '马家军３', '马家军４', '马家军５', '钱五户']},
                    {_id: '赵大明', Defeats: ['刘家军１', '刘家军２', '刘家军３', '刘家军４', '刘家军５', '刘五户']},
                    {_id: '赵五明', Defeats: ['童家军１', '童家军２', '童家军３', '童家军４', '童家军５', '童五户']},
                    {_id: '马家军２', Defeats: []},
                    {_id: '刘家军４', Defeats: ['童家军１', '童家军２']},
                    {_id: '童五户', Defeats: []},
                    {_id: '刘家军１', Defeats: ['杨家将1', '杨家将2', '杨家将3', '杨家将4', '杨家将5', '杨家将6']},
                    {_id: '杨家将1', Defeats: ['李家将1', '李家将2', '李家将3', '李家将4', '李家将5', '李家将6']},
                    {_id: '杨家将2', Defeats: ['牛家将1', '牛家将2', '牛家将3', '牛家将4', '牛家将5', '牛家将6']},
                    {_id: '杨家将3', Defeats: ['周家将1', '周家将2', '周家将3', '周家将4', '周家将5', '周家将6']},
                    {_id: '杨家将4', Defeats: ['郑家将1', '郑家将2', '郑家将3', '郑家将4', '郑家将5', '郑家将6']},
                    {_id: '杨家将5', Defeats: ['谭家将1', '谭家将2', '谭家将3', '谭家将4', '谭家将5', '谭家将6']},
                    {_id: '杨家将6', Defeats: ['穆家将1', '穆家将2', '穆家将3', '穆家将4', '穆家将5', '穆家将6']},
                    {_id: '穆家将1', Defeats: ['龙家将1', '龙家将2', '龙', '龙家将4', '龙家将5', '龙家将6']},
                    {_id: '穆家将2', Defeats: ['胡家将1', '胡家将2', '胡家将3', '胡家将4', '胡家将5', '胡家将6']},
                    {_id: '穆家将3', Defeats: ['田家将1', '田家将2', '田家将3', '田家将4', '田家将5', '田家将6']},
                    {_id: '穆家将4', Defeats: ['梁家将1', '梁家将2', '梁家将3', '梁家将4', '梁家将5', '梁家将6']},
                    {_id: '穆家将5', Defeats: ['齐家将1', '齐家将2', '齐家将3', '齐家将4', '齐家将5', '齐家将6']},
                    {_id: '穆家将6', Defeats: ['向家将1', '向家将2', '向家将3', '向家将4', '向家将5', '向家将6']},
                    {_id: '李家将2', Defeats: ['隋便1']},
                    {_id: '李家将3', Defeats: ['隋便']},
                    {_id: '李家将5', Defeats: ['隋便2']},
                    {_id: '牛家将1', Defeats: ['隋便']},
                    {_id: '牛家将4', Defeats: ['隋便']},
                    {_id: '牛家将6', Defeats: ['隋便3']},
                    {_id: '牛家将2', Defeats: ['童家将1', '童家将2', '童家将3', '童家将4', '童家将5', '童家将6']},
                ],
            }
        },
        watch: {
            Show(Val) {
                if (Val === -1) document.getElementById('TitleId').innerText = '泛约活动服务平台 > 首页';
            },
        },
        mounted() {
            if (this.$store.state.LandTo === 'ZXGL') {
                if (this.$store.state.UserName === '游客') AutoAlert2('游客操作无效', '请先注册登录!'); else this.Show = 3;
            }
            if (this.$store.state.LandTo === 'WXLinkFYBB') {
                if (this.$store.state.UserName === '游客') AutoAlert2('游客操作无效', '请先注册登录!'); else this.Show = 2;
            }
        },
        methods: {

            Setfymc(Bool) {
                let fymcs = (Bool === 1 ? this.fymcs1 : this.fymcs2);
                myMongoDBPost("RemoveDoc/fymc-a004", {}, function (data) {
                    myMongoDBPost("PutDocs/fymc-a004", fymcs, function (Docs) {
                        console.log(1, Docs)
                    })
                })
            }

        }
    }
</script>

<style scoped>
    .TF2 {
        color: #363233;
    }
</style>
