<template>
  <div>
    <div v-if="$store.state.LandTo === 'ZY2'" class="TF MT2">
      <el-divider style="height: 1.2rem"> • {{CurrYY.ItemStr.split(":")[1]}} •</el-divider>
    </div>
    <MyToast v-if="Show1 && PJed" :Tip="'响应信息已发送!'" @Tiped="$emit('FromZY3')"></MyToast>

    <div class="XLR YC MP4">
      <div class="TG BottO" @click="ZYMsgSave(2)">&nbsp;作废本邀约！&nbsp;</div>
      <div v-if="PJed" class="TF">已完成结束评价!</div>
      <div v-else>
        <div v-if="AllChanged" class="AN3" @click="ZYMsgSave(1)">通知被约</div>
        <div v-else class="TF BottGray">通知被约</div>
      </div>
    </div><!--只有对所有被约都进行了评价,才能保存发送信息-->
    <div class="MLR4 LH150 TF MT2">
      时间:{{CurrYY.meetTimeStr}}<br>
      地点：{{CurrYY.meetAddrStr}}
      <div v-if="CurrYY.fySitefee>0" class="XLR">
        <div>场地费：{{CurrYY.fySitefee}}</div>
        <div>付费方式：{{CurrYY.paymentWay}}</div>&nbsp;
      </div>
      <div v-if="CurrYY.jdfy.length>0">简短附言：{{CurrYY.jdfy}}</div>

      <div class="XR MR4 YC MT2">
        <div class="TC">线下活动情况：&nbsp;</div>
        <div>
          <ShowUpdatePVT :PVTF="{P:CurrYY.Pic,V:CurrYY.Video,T:CurrYY.Text,TextTitle:'线下活动情况'}"
                         @ShowUT="CallBack2"></ShowUpdatePVT>
        </div>
      </div>
    </div>
    <div v-for="(y,I2) in CurrYY.by" :key="I2">

      <div class="MLR4">
        <el-divider>被约{{I2+1}}：{{y._id}}</el-divider>
      </div>
      <div v-if="y.xy==='同意'">
        <!--ZY3CCard v-if="CurrYY.ItemStr.charAt(0) === 'C'" :CurrYY="CurrYY" :I2="I2"></ZY3CCard-->
        <div class="XR YC">
          线下情况：
          <ShowPVT :PVT="{P:y.Pic,V:y.Video,T:y.Text,Update:false}"></ShowPVT>
        </div>
        <table :cell-bordered="true" :content-bordered="true" style="background-color:#fff;">
          <thead>
          <tr class="TF">
            <th></th>
            <th>履约情况</th>
            <th>{{CurrYY.ItemStr.charAt(0)==='A' ? "战果":""}}</th>
            <th>互评</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="TF W26">主约观点:</td>
            <td>
              <div v-if="y.xy === '同意' && !PJed" @click="SelectX=2,SelectI=I2" class="XYC">
                <div v-if="y.z_bylyqk==='初值'" class="AN ANBlue">点这里</div>
                <div v-else class="AN ANGray ">{{y.z_bylyqk}}</div>
              </div>
              <div v-else>
                <div v-if="PJed ">{{y.z_bylyqk==='初值'? '—':y.z_bylyqk}}</div>
                <div v-else>—</div>
              </div>
            </td>
            <td>
              <div v-if="y.xy === '同意'">
                <div v-if="PJed">{{y.z_zg==='初值'? '—':y.z_zg}}</div>
                <div v-else>
                  <div v-if="CurrYY.ItemStr.charAt(0)==='A'" @click="SelectX=10,SelectI=I2" class="XYC">
                    <div v-if="y.z_zg==='初值'" class="AN ANBlue">点这里</div>
                    <div v-else class="AN ANGray ">{{y.z_zg}}</div>
                  </div>
                  <div v-else></div>
                </div>
              </div>
              <div v-else>—</div>
            </td>
            <td>
              <div v-if="y.xy === '同意' && !PJed" @click="SelectX=3,SelectI=I2" class="XYC">
                <div v-if="y.z_ts==='初值'" class="AN ANBlue">点这里</div>
                <div v-else class="AN ANGray ">{{y.z_ts}}</div>
              </div>
              <div v-else>
                <div v-if="PJed ">{{y.z_ts==='初值'? '—':y.z_ts}}</div>
                <div v-else>—</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="TF W26">被约反馈:</td>
            <td>{{y.b_zylyqk==='初值'? '—':y.b_zylyqk}}</td>
            <td v-if="CurrYY.ItemStr.charAt(0)==='A'">{{y.b_zg==='初值'? '—':y.b_zg}}</td>
            <td v-else></td>
            <td>{{y.b_ts==='初值'? '—':y.b_ts}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="MP4 TF">响应情况：<span class="TC">{{y.xy}}</span></div>

      <MySelect v-if="SelectX===10 && SelectI!==-1" :Options="['主约胜','被约胜','平局']"
                @FromMySelect="MySelect1"></MySelect>
      <MySelect v-if="SelectX===2 && SelectI!==-1" :Options="['被约迟到','被约爽约','被约准时']"
                @FromMySelect="MySelect2"></MySelect>
      <MySelect v-if="SelectX===3 && SelectI!==-1" :Options="['差评','一般','点赞']" @FromMySelect="MySelect3"></MySelect>

    </div>
  </div>
</template>

<script>
  import ZY3BY3Mix from '@/components/ListYY/ZY3BY3Mix'
  import {
    MyUrl,
    myMongoDBPost,
    myDataFileGet,
    UrlParse,
    SendWXMsg,
    GetItemStr3,
    Tofixed, GetMyYYs,
    GetItemType
  } from '@/components/SharedVues/Shared0.js'
  import MySelect from "@/components/SharedVues/MySelect";
  import MyInput from "@/components/SharedVues/MyInput";
  import ZY3CCard from "./ZY3CCard";

  export default {
    name: "ZY3",
    components: {ZY3CCard, MyInput, MySelect},
    mixins: [ZY3BY3Mix],
    data() {
      return {
        AllChanged: false,
        SelectX: -1,
        SelectI: -1,
        ForceEndBool: false
      }
    },
    mounted() {
      let Now = new Date().getTime(), meetStamp = new Date(this.CurrYY.meetTimeStr + ":00").getTime(); //时间字符串转为时间戳
      this.ForceEndBool = (Now > meetStamp);//碰头时间已超过

      this.PJed = this.CurrYY.jdfy.indexOf("/主约已评价！") >= 0; //这样就不会导致主约发送信息后，邀约未结束，下次进来，依然可以再发送的情况了！

      this.GetAllChanged();
    },
    methods: {
      MySelect1(Val) {
        this.CurrYY.by[this.SelectI].z_zg = Val;
        this.Changed();
      },
      MySelect2(Val) {
        this.CurrYY.by[this.SelectI].z_bylyqk = Val;
        if (Val === '被约爽约') {
          this.CurrYY.by[this.SelectI].z_zg = '被约爽约';
          this.CurrYY.by[this.SelectI].z_ts = '差评';
        }
        this.Changed();
      },
      MySelect3(Val) {
        this.CurrYY.by[this.SelectI].z_ts = Val;
        this.Changed();
      },

      CallBack2(Val) {//历史信息
        if (Val.P) this.CurrYY.Pic = Val.P;
        if (Val.V) this.CurrYY.Video = Val.V;
        if (Val.T) this.CurrYY.Text = Val.T;
      },
      GetAllChanged() {
        let that = this;
        that.AllChanged = true;
        for (let i = 0, len = that.CurrYY.by.length; i < len; i++) {
          let y = that.CurrYY.by[i], ChangedMsg = '';
          let y2 = that.CurrYY.ItemStr.charAt(0) === 'A' && (y.z_zg === '' || y.z_zg === '初值');
          let y3 = (y.z_bylyqk === '' || y.z_bylyqk === '初值');
          let y4 = (y.z_ts === '' || y.z_ts === '初值');
          if (y.xy === '同意') {
            if (y2 || y3 || y4) that.AllChanged = false;
            if (that.CurrYY.ItemStr.charAt(0) === 'A' && y.z_zg !== '' && y.z_zg !== '初值') ChangedMsg += '战果:' + y.z_zg + "；";
            if (!y3) ChangedMsg += '履约:' + y.z_bylyqk + "；";
            if (!y4) ChangedMsg += '互评:' + y.z_ts + "。";
          } else {
            if (y.xy === '未响应') that.AllChanged = false; else ChangedMsg += "您" + y.xy + "了主约的邀约请求!";
            if (y.xy === '暂拒') ChangedMsg += "（" + y.zg + ")"
          }
          that.CurrYY.by[i].ChangedBool = ChangedMsg;
        }
        //console.log("that.AllChanged="+that.AllChanged);
        return that.AllChanged
      },
      Changed() {
        this.SelectI = -1;
        this.GetAllChanged();
        if (!this.GetAllChanged()) myMongoDBPost("SaveZY/?ItemCode=" + this.CurrYY.ItemStr.split(':')[0], this.CurrYY, function (data) {
          if (data.EndBool) alert("内部错误:ZY3");//只要改变一次,就立即保存起来;评价未结束时,不应该data.EndBool===true
        });
      },
      ZYMsgSave(N) {//要遍历被约,向每一个有信息变化的被约推送:您有一条被约信息更新!
        let CurrYYDoc = this.CurrYY, asd = this.CurrYY.ItemStr.split(':');
        let ItemCode = asd[0], ItemName = asd[1];
        let ItemType = GetItemType(ItemCode);
        if (this.$store.state.UserName === '游客') return alert('游客操作无效! 请先注册、登录后，再进行该操作。');
        let that = this;
        if (N === 1) CurrYYDoc.jdfy += "/主约已评价！"; else CurrYYDoc.jdfy = "主约想强制结束！";
        myMongoDBPost("SaveZY/?ItemCode=" + ItemCode, CurrYYDoc, function (data) {
          //console.log(data)
          that.Show1 = true;
          that.PJed = true;
          if (data.EndBool) {//结束标志
            let CurrYYDoc = data.YYDoc;
            SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(CurrYYDoc.ItemStr) + (N === 1 ? '☞项目邀约活动已结束！' : '☞主约取消了本次邀约！'));
            for (let i1 = 0, len1 = CurrYYDoc.by.length; i1 < len1; i1++)  //遍历每一个被约
              (function (i1) {
                that.CurrByEndProcess(that, ItemType, ItemCode, ItemName, CurrYYDoc, CurrYYDoc.by[i1], N);
              })(i1);
            GetMyYYs(that, function () {
            });//为刷新ZYn而调用
          } else {
            for (let i1 = 0, len1 = CurrYYDoc.by.length; i1 < len1; i1++)  //通知每一个被约
              (function (i1) {
                let MyUrlPara = 'LandTo=BY2QWERItemCode=' + ItemCode + 'QWERItemName=' + ItemName + 'QWERCurrYYId=' + CurrYYDoc._id + '___';
                let MyUrlShow = '查看/评价';
                //let Msg = GetItemStr3(CurrYYDoc.ItemStr) +'☞主约“' + CurrYYDoc.zyUserName + '”已进行了评价▷' + CurrYYDoc.by[i1].ChangedBool + ' \n[ <a href="' + Url + '">查看/评价</a> ]';//
                let Msg = GetItemStr3(CurrYYDoc.ItemStr) + '☞主约『' + CurrYYDoc.zyUserName + '』已进行了评价▷' + CurrYYDoc.by[i1].ChangedBool
                  + ' \n[ MyUrlHere ]MyUrlHereMyUrl=' + MyUrlPara + 'MyUrl=' + MyUrlShow;

                SendWXMsg(CurrYYDoc.by[i1]._id, Msg);
              })(i1)
          }
        });
      }//每次保存，都要在jdfy中追加"/主约已评价！"，这样就不会出现重复保存的事情了！
    }
  }
</script>

<style scoped>
  .W12 {
    width: 12vw
  }
</style>
