<template>
  <div>
    <div v-if="QY.UserName===$store.state.UserName" class="MP4 T4 TF">
      <br>
      <div class="XL YC">
        当前状态：
        <ShowUpdatePVT :PVTF="{P:CurrQY.Pic,V:CurrQY.Video,T:CurrQY.Text,  TextTitle:'文字内容表述'}" @ShowUT="CallBack"></ShowUpdatePVT>
      </div><br><br>
      <div class="XLR YC MLR4">
        <div class="DispIB BottGray">　生成微信宣传链接　</div>
        <div class="TB" @click="Send()">确认发送</div>
      </div>
      <br>
      标题：<br>
      <textarea class="Mtextarea1 T5" v-model="WTitle" placeholder="最长28个汉字"></textarea>
      内容：<br>
      <textarea class="Mtextarea2 T5" v-model="WText" placeholder="最长38个汉字"></textarea>
    </div>
    <div v-else class="XLR MP4">
      <div>
        <img v-if="QY.UserLogo!==''" :src="'UserUpload/1/S' + QY.UserLogo" class="AvatarWH">
        <img v-else :src="'UserUpload/static/After2021/Head.jpg'" class="AvatarWH"/>
        <div class="TF T3">
          {{QY.UserName}}
        </div>
      </div>
      <div>
        <p>{{QY.Text}}</p>
        <div class="XR MLR4">
          <span v-if="QY.Pic.length===0">─</span>
          <ShowPFromThumb v-else :Msg="{Pic:QY.Pic,Update:false}"></ShowPFromThumb>
          <div class="MLR4">
            <span v-if="QY.Video.length===0">─</span>
            <ShowVFromThumb v-else :Msg="{Video: QY.Video,Update:false}"></ShowVFromThumb>
          </div>
        </div>
        <div class="XR TF">{{StampToTimeStr(QY.Time)}}发布</div>
      </div>
    </div>
  </div>

</template>

<script>
  import {AutoAlert,StampToTimeStr, myMongoDBPost,SendWX_PTMsg} from '@/components/SharedVues/Shared0.js'
  import ShowPFromThumb from "./ShowPFromThumb";
  import ShowVFromThumb from "./ShowVFromThumb";
  import ShowUpdatePVT from "./ShowUpdatePVT";

  export default {
    name: "ShowPVT",
    components: {ShowUpdatePVT, ShowVFromThumb, ShowPFromThumb},
    props: ['Curr', 'QY'],
    data() {
      return {
        CurrQY: this.QY,
        WTitle: '',
        WText: '',
        Url: null,
        PicUrl: null
      }
    },
    mounted() {
      if (this.Curr.Channel === '个性定制' && this.Curr.Type === '血糖监测') {
        this.WTitle = '它把我每天吃的什么，何时血糖值多高都能记下来';
        this.WText = "得了糖尿病并不可怕，怕的是依然想吃吃、想喝喝。通过精细化管理自己的饮食起居，我居然把血糖控制住了。";
        this.Url = "http://www.fy135.vip/?LandTo=SJGJ_TNB";
        this.PicUrl = "http://www.fy135.vip/static/After2021/SmallSJGJ.png"
      }
    },
    methods: {
      StampToTimeStr(X) {
        return StampToTimeStr(X)
      },

      CallBack(Val) {
        if (Val.P) this.CurrQY.Pic = Val.P;
        if (Val.V) this.CurrQY.Video = Val.V;
        if (Val.T) this.CurrQY.Text = Val.T;
        let that = this;
        if (that.CurrQY && that.CurrQY._id!==undefined) myMongoDBPost("RemoveDoc/QYs", {_id: that.CurrQY._id}, function (data) {
          myMongoDBPost("PutDoc/QYs", that.CurrQY, function (data) {
          });
        })
      },
      Send(){
        let Me=this.$store.state.UserName;
        SendWX_PTMsg(Me,this.WTitle,'　　'+this.WText,this.Url,this.PicUrl);
        AutoAlert('发送成功','请退出到公众号微信界面查看');
        this.$emit('FromQYDoc');
      }
    }
  }
</script>

<style scoped>

</style>
