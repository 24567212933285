<template>
  <div>
    <PopTitle v-if="ShowPoppup" :Title="QY.Title" @FromPopUp="Quit">
      <div v-if="QY.UserName===$store.state.UserName" title="打开自己的求缘文档">
        <div class="XLR YC MT2">
          <div class="BottGray TF PA3">有关说明:</div>
          <div>
            <div class="DispIB">
              <span v-if="QY.Pic.length===0">─</span>
              <ShowPFromThumb v-else :Msg="{Pic:QY.Pic,Update:false}"></ShowPFromThumb>
            </div>
            <div class="DispIB ML2">
              <span v-if=" QY.Video.length===0">─</span>
              <ShowVFromThumb v-else :Msg="{Video: QY.Video,Update:false}"></ShowVFromThumb>
            </div>
          </div>
          <div class="XR MR4 ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SetEndBool(QY._id)">设为<br>完成</div>
        </div>
        <div class="MP4">
          <p>{{QY.Text}}</p>
          <div class="XR MLR4 TF">{{StampToTimeStr(QY.Time)}}</div>
        </div>
        <el-divider> • 响应信息列表 •</el-divider>
        <div class="PA2 BottGray" v-for="(DY,index) in DYs" :key="index">
          <div class="XLR YC">
            <img :src="'UserUpload/1/S' + DY.UserLogo" class="AvatarWH2">
            <div class="ML2" style="width: 75vw;overflow: hidden">
              <div class="BottO">
                {{DY.Title}}
              </div>
              <div class="XLR YC">
                {{DY.UserName}}
                <div>
                  <ShowPVT :PVT="{P:DY.Pic,V:DY.Video,T:DY.Text,Update:false}"></ShowPVT>
                </div>
              </div>
              <div v-if="DY.DYContact.length>0">联系方式：{{DY.DYContact}}</div>
            </div>
          </div>
          <div v-if="DY.UserName==='游客'" class="PA3 AlignC TF">
            游客用户的响应信息不可信!
          </div>
          <div v-else class="XLR YC TF">&nbsp;
            <img src="../../../static/gif2/ArrowR.gif" style="width: 1.5rem;">
            <div class="XLR YC BMP BGLightGreen">&nbsp;
              <div class="T2">留言：</div>
              <div class="BoxGray1 H W60 MP4 TG" @click="InputI1=index">{{DY.QYContact===''?"―":DY.QYContact}}</div>
            </div>
          </div>
        </div>
        <MyInput v-if="InputI1!==-1" Title="我的联系方式" Type="text" :Begin="DYs[InputI1].QYContact"
                 @FromMyI="MyInputVal1"></MyInput>
      </div>
      <div v-else title="否则,开始对缘响应">
        <ShowPVT3 Curr="" :QY="Msg"></ShowPVT3>
        <el-divider>给他／她留言</el-divider>
        <div class="R00">
          <div class="R04">
            <img src='../../../static/img/ButtonPic/ACancel.png' @click="Quit" class="RCB  Box41">
            <img src='../../../static/img/ButtonPic/AConfirm.png' @click="SaveCurrDYDoc" class="RCB  Box42 ML2">
          </div>
        </div>
        <div v-if="DY.QYContact!==''" class="MP4 BottO">
          {{CallFrom==='QY' ? '缘主':DY.QYUserName}}留言：{{DY.QYContact}}
        </div>
        <div class="MP4 TF LH150">
          <div class="XL YC">{{CallFrom==='QY' ? '表白': '留言'}}：
            <div class="BoxGray1 W60 H MP4 TG DispIB" @click="InputI2=1">{{DY.Title===''?"―":DY.Title}}</div>
          </div>
          （一句话或关键词组合）<br><br>
          <div class="XL YC">
            详细描述信息：　
            <ShowUpdatePVT v-if="Show1"
                           :PVTF="{P:DY.Pic,V:DY.Video,T:DY.Text, TextTitle:(CallFrom==='QY' ? '求缘内容': '留言信息')}"
                           @ShowUT="CallBack2"></ShowUpdatePVT>
          </div>
          <br>
          <div class="XLR YC">
            联系方式：
            <div class="BoxGray1 W60 H PA3 TG DispIB" @click="InputI2=2">{{DY.DYContact===''?"―":DY.DYContact}}</div>
          </div>
          <br><br>
          <div v-if="!RepeatBool && CallFrom==='BB' && DY.ZXn<0" class="XLR YC ">
            {{QY.UserName}}转来{{-1*DY.ZXn}}个泛约币
            <div class="AN ANGreen T2" @click="ZXOut2()">接收确认</div>
          </div>


          <br>
          <MyInput v-if="InputI2===1" :Title="CallFrom==='QY' ? '缘份表白': '留言信息'" Type="text" :Begin="DY.Title"
                   @FromMyI="MyInputVal2"></MyInput>
          <MyInput v-if="InputI2===2" Title="联系方式" Type="text" :Begin="DY.DYContact"
                   @FromMyI="MyInputVal2"></MyInput>
          <br>
        </div>
      </div>
    </PopTitle>
  </div>
</template>

<script>
  import {myMongoDBPost, AutoAlert, TransferZX, StampToTimeStr} from '@/components/SharedVues/Shared0.js'
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
  import ShowPVT3 from "@/components/MyPVTs/ShowPVT3";
  import ShowPFromThumb from "@/components/MyPVTs/ShowPFromThumb";
  import ShowVFromThumb from "@/components/MyPVTs/ShowVFromThumb";
  import MyInput from "@/components/SharedVues/MyInput";
  import ShowPVT from "../MyPVTs/ShowPVT";

  export default {
    name: "OpenOneQY",
    components: {ShowPVT, ShowPVT3, MyInput, ShowVFromThumb, ShowPFromThumb, ShowUpdatePVT, PopTitle},
    props: ['CallFrom', 'Msg'],
    data() {
      return {
        ShowPoppup: false,
        QY: this.Msg,
        DY: {
          QYUserName: this.Msg.UserName,
          QYId: this.Msg._id,

          UserName: this.$store.state.UserName,
          Certified: this.$store.state.Certified,
          UserLogo: this.$store.state.UserLogo,

          ZXn: 0,

          Title: '',
          Pic: '',
          Video: '',
          Text: '',
          Time: (new Date()).getTime(),
          DYContact: '',
          QYContact: ''
        },
        DYs: [],
        P: '',
        C: '',
        DC: '',
        Show1: false,
        InputI1: -1,
        InputI2: -1,
        RepeatBool: false
      }
    },
    mounted() {
      let that = this;
      if (that.$store.state.UserName === this.QY.UserName) {
        myMongoDBPost("GetDocs/DYs", {QYId: that.QY._id}, function (Docs) {
          let aObj = {UPTJ: {_id: that.Msg._id}, UPObj: {DYn:Docs.length,NewDY: false}};
          myMongoDBPost("UpdateDoc/QYs", aObj, function (data2) {
          })//一个求缘被打开,是由发布者自己打开的,则应将相应的对缘相应标志设为false
        })
      } else {
        let aObj = {UPTJ: {_id: that.DY.QYId}, UPObj: {$inc: {LookedTimes: 1}}};
        myMongoDBPost("UpdateDoc2/QYs", aObj, function (data2) {
        })
      } //一个求缘被打开,但不是由发布者打开的,则应将相应的LookedTimes:自动增1

      myMongoDBPost("GetDocs/DYs", {QYId: that.Msg._id}, function (Docs) {
        that.DYs = Docs;
        myMongoDBPost("GetDocs/DYs", {QYId: that.Msg._id, UserName: that.$store.state.UserName}, function (Docs2) {
          if (Docs2.length > 0) that.DY = Docs2[0];
          that.Show1 = true;
          that.ShowPoppup = true;
        });
      });
    },
    methods: {
      MyInputVal1(Val) {
        this.DYs[this.InputI1].QYContact = Val;
        this.SaveCurrDYQYContact(this.DYs[this.InputI1]._id, this.DYs[this.InputI1].QYContact);
        this.InputI1 = -1;
      },
      MyInputVal2(Val) {
        if (this.InputI2 === 1) this.DY.Title = Val;
        if (this.InputI2 === 2) this.DY.DYContact = Val;
        this.InputI2 = -1;
      },
      StampToTimeStr(X) {
        return StampToTimeStr(X)
      },
      CallBack1(Val) {
        if (Val.P) this.QY.Pic = Val.P;
        if (Val.V) this.QY.Video = Val.V;
        if (Val.T) this.QY.Text = Val.T;
      },
      SaveCurrQYDoc(Id) {
        let that = this;
        //delete that.QY._id;
        //let aObj = {UPTJ: {_id: Id}, UPObj: that.QY};
        myMongoDBPost("UpdateObjs/QYs", [that.QY], function (data1) {
          //myMongoDBPost("UpdateObjs/DYs", that.DYs, function (data2) { });
          that.ShowPoppup = false;
          that.$emit("OpenOneQY");

        });
      },
      SaveCurrDYQYContact(Id, QYContact) {
        let aObj = {UPTJ: {_id: Id}, UPObj: {QYContact: QYContact}};
        myMongoDBPost("UpdateDoc/DYs", aObj, function (data2) {
        })
      },

      Delete(Id) {
        let that = this;
        if (Id !== undefined) myMongoDBPost("RemoveDoc/QYs", {_id: Id}, function (data) {
          myMongoDBPost("RemoveDoc/DYs", {QYId: Id}, function (data) {
            that.ShowPoppup = false;
            that.$emit("OpenOneQY");
          })
        })
      },
      SetEndBool(Id) {
        let that = this, aObj = {UPTJ: {_id: Id}, UPObj: {EndBool: 2}};
        myMongoDBPost("UpdateDoc/QYs", aObj, function (data1) {
          myMongoDBPost("UpdateDoc/DYs", aObj, function (data2) {
            that.ShowPoppup = false;
            that.$emit("OpenOneQY");
          })
        })
      },
      CallBack2(Val) {
        if (Val.P) this.DY.Pic = Val.P;
        if (Val.V) this.DY.Video = Val.V;
        if (Val.T) this.DY.Text = Val.T;
      },
      SaveCurrDYDoc() {
        let that = this;//如果存在则修改,如果不存在则存入
        let aObj = {UPTJ: {_id: this.DY.QYId}, UPObj: {NewDY: true}};
        myMongoDBPost("UpdateDoc/QYs", aObj, function (data2) {

          let Fee2 = that.DY.ZXn;
          if (Fee2 > 0) {
            AutoAlert('向' + that.DY.QYUserName + '转移' + Fee2 + '个泛约币！', '正在等待对方接收...');
            that.DY.ZXn = -1 * Fee2;
          }

          if (that.DY._id) myMongoDBPost("UpdateObjs/DYs", [that.DY], function (data2) {
            that.ShowPoppup = false;
            that.$emit("OpenOneQY");
          }); else myMongoDBPost("PutDoc/DYs", that.DY, function (data) {
            that.ShowPoppup = false;
            that.$emit("OpenOneQY");
          });
        });
      },
      Quit() {
        this.ShowPoppup = false;
        this.$emit("OpenOneQY");
      },

      ZXOut1(ToUserName, ZXn, index) {//出让
        let that = this;
        let ASD = that.DYs[index];
        if (ZXn > 0) {
          if (ZXn <= that.$store.state.ZX) {
            ASD.ZXn = -1 * ZXn;
            that.DYs.splice(index, 1, ASD);
            myMongoDBPost("UpdateDoc/DYs", {UPTJ: {_id: ASD._id}, UPObj: {ZXn: ASD.ZXn}}, function (data2) {
              AutoAlert('向' + ToUserName + '转移' + ZXn + '个泛约币！', '正在等待对方接收...');
            })
          } else AutoAlert('出让失败', '剩余泛约币数量不足!');
        }
        if (ZXn === 0 || ZXn === null) AutoAlert('转让泛约币数量有误', '请重新输入!');
      },
      ZXOut2() {//出让时接受确认
        let that = this, FromUserName = that.QY.UserName;
        this.RepeatBool = true;
        TransferZX(that, FromUserName, that.$store.state.UserName, -1 * that.DY.ZXn, function (asd) {
          if (asd === '泛约币转移成功!') {
            AutoAlert('收到泛约币', '来自' + FromUserName + '，共计' + (-1 * that.DY.ZXn) + '个！');
            myMongoDBPost("UpdateDoc/DYs", {UPTJ: {_id: that.DY._id}, UPObj: {ZXn: 0}}, function (data2) {
              that.DY.ZXn = 0;
            })
          }
        })

      },
    }
  }
</script>

<style scoped>
</style>
