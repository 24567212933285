<template>
  <div>
    <div v-if="CardType==='现金付'" class="TF MLR4">
      当前结账方式：现金付
    </div>
    <div v-else class="BoxBR MP4">
      <div v-if="FirstXYBool<2 && CurrCard===null">
        结账方式：
        <div class="XLR YC TF" style="padding: 2mm 0">

          <el-radio-group  @change="Selected">
            <el-radio-button v-for="(Sele,i) in ['计费卡', '计次卡','泛约币付','现金付']" :key="i" :value="Sele"/>
          </el-radio-group>
        </div>
        <div v-if="CardType==='计费卡' || CardType==='计次卡'" class="XLR YC TE PA3">
          <div class="TG" @click="InputI=3">预存：
            <div class="DispIB BoxGray1 PA2">{{CardVal}}</div>
            {{CardType==='计费卡' ? '元':'次'}}
          </div>
          <div v-if="CardVal>0">请主约线下完成付款！</div>
        </div>
      </div>
      <div v-else>
        <div v-if="CardType==='计费卡' || CardType==='计次卡'">
          <div v-if="CurrCard.Bool==='待激活'" class="TC XLR">
            这里有一个{{CurrCard.CardType}}待激活！
            <div class="TB" @click="ActiveCard">现在激活</div>
          </div>
          <div v-else class="XLR YC">
            <div class="LH150">
              <span class="TF T3">该用户{{CurrCard.CardType}}</span><br>剩余：{{CurrCard.CardVal}}{{CurrCard.CardType==='计费卡' ? '元':'次'}}
            </div>
            &nbsp;
            <div class="T3 PA2 BDCircle Box41 LH120" @click="ShowLists=true">消费<br>记录</div>
            <div class="T3 PA2 BDCircle Box42 LH120" @click="InputI=4">给卡<br>充值</div>
            <div class="T3 PA2 BDCircle Box41 LH120 AlignC" @click="InputI=5">
              <span v-if="CurrVal===0">本次<br>消费</span>
              <span v-else>待付<br>{{CurrVal}}{{CurrCard.CardType==='计费卡' ? '元':'次'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="CardType==='泛约币付'">
        <div v-if="CardVal===0" class="XLR YC">
          本次所需泛约币：<input type="number" class="W12" v-model.number="CurrVal" style="height: 2rem"/>个
          <div></div>&nbsp;
          <div class="AN ANGreen T2" @click="ZXIn1(CurrVal)">确认</div>
        </div>
        <div v-else class="XLR YC">
          收到来自{{CurrYY.zyUserName}}的泛约币{{Math.abs(CurrCard.CardVal)}}个
          <div v-if="CardVal<0" class="AN ANGreen T2" @click="ZXIn2()">确认</div>
          <div v-else class="TF">已确认</div>
        </div>
      </div>
    </div>
    <MyInput v-if="InputI===3" Title="优惠卡预存元/次" Type="number" :Begin="CardVal" @FromMyI="MyInputVal"></MyInput>
    <MyInput v-if="InputI===4" Title="本次充值金额或次数" Type="number" :Begin="CurrVal" @FromMyI="MyInputVal"></MyInput>
    <MyInput v-if="InputI===5" Title="本次消费金额" Type="number" :Begin="Charge" @FromMyI="MyInputVal"></MyInput>
    <PopTitle v-if="ShowLists" :Title="'当前'+CurrCard.CardType+'用卡记录'" @FromPopUp="ShowLists=false">
      <CardLists :CLs="CurrCard.Lists" :CType="CurrCard.CardType"></CardLists>
    </PopTitle>
  </div>
</template>

<script>
  import {
    CloneObject,
    myMongoDBPost,
    SendWXMsg,
    GetItemType, MyUrl
  } from '@/components/SharedVues/Shared0.js'
  import MyInput from "../SharedVues/MyInput";
  import PopTitle from "../MyPopWin/PopTitle";

  export default {
    name: "BY3BCard",
    components: {PopTitle, MyInput},
    props: ['CurrYY', 'FirstXYBool'],
    data() {
      return {
        ItemCode: '',
        ItemType: '',
        ItemName: '',

        CardVal: 0,
        CardType: '',
        CurrVal: 0,
        CurrCard: null,
        Charge: 0,
        ShowLists: false,

        InputI: -1
      }
    },
    mounted() {
      this.ItemCode = this.CurrYY.ItemStr.split(':')[0];
      this.ItemType = GetItemType(this.ItemCode);
      this.ItemName = this.CurrYY.ItemStr.split(':')[1];
      let that = this;
      let aOBJ = {ItemCode: this.ItemCode, BUserName: this.$store.state.UserName, CUserName: this.CurrYY.zyUserName};
      myMongoDBPost("GetDocs/Cards", aOBJ, function (Docs) {
        if (Docs.length > 0) {
          that.CurrCard = Docs[0];
          that.CardType = that.CurrCard.CardType;//卡类型
          that.CardVal = that.CurrCard.CardVal;//卡内余额
          that.CurrVal = that.CurrCard.CurrVal;//当前消费
          //that.$emit("FromBY3BCard", {CardType: that.CardType, CardVal: that.CardVal});
          if (that.FirstXYBool < 2 && that.CardType === '泛约币付') myMongoDBPost("UpdateDoc/Cards", {UPTJ: aOBJ, UPObj: {CardVal: 0}}, function (data2) {
            that.CardVal = 0;
          });
          if (that.FirstXYBool < 2 && that.CardType === '现金付') that.$emit("FromBY3BCard", {CardType: '现金付', CardVal: 0});
        }
      });
    },
    methods: {
      Selected(Val) {
        this.CardType = Val;
        if (Val === '现金付') this.$emit("FromBY3BCard", {CardType: '现金付', CardVal: 0});
      },
      ActiveCard() {
        let that = this, TJ = CloneObject(that.CurrCard);
        delete TJ.Bool;
        myMongoDBPost("UpdateDoc/Cards", {UPTJ: TJ, UPObj: {Bool: '已激活'}}, function (data2) {
          that.CurrCard.Bool = '已激活';
        })
      },
      MyInputVal(Val) {
        let Url = MyUrl('LandTo=ZY2!ItemCode=' + this.ItemCode + '!ItemName=' + this.ItemName + '!CurrYYId=' + this.CurrYY._id + '___');
        let fyM0Url = '<a href="' + Url + '"> [ 查看 ]</a>';
        if (this.InputI === 3) {
          this.CardVal = Val;
          this.$emit("FromBY3BCard", {CardType: this.CardType, CardVal: this.CardVal});
        }//返回值用于首次建立当前项目上的主被约关系的Cards记录
        if (this.InputI === 4 || this.InputI === 5) {
          let that = this, TJ = {
            ItemCode: this.ItemCode,
            BUserName: this.$store.state.UserName,
            CUserName: this.CurrYY.zyUserName
          };


          if (this.InputI === 4 && Val > 0) {
            this.Charge = Val;
            let aObj = {
              UPTJ: TJ, UPObj: {
                $inc: {CardVal: Val},
                $push: {Lists: {Stamp: (new Date()).getTime(), Do: '充值', Val: Val}}
              }
            };
            myMongoDBPost("UpdateDoc2/Cards", aObj, function (data2) {
              that.CurrCard.CardVal += that.Charge;
              let SendStr = that.ItemName + '项目，被约“' + that.$store.state.UserName + '”☞向您的优惠卡内充值了' + Val +
                (that.CurrCard.CardType === '计费卡' ? '元。' : '次。') + fyM0Url;
              console.log("1.SendStr=" + SendStr);
              that.CurrCard.Lists.push({Stamp: (new Date()).getTime(), Do: '充值', Val: Val});
              SendWXMsg(that.CurrYY.zyUserName, SendStr);
                alert('当前充值金额(次数)已通知对方！');
            })
          }
          if (this.InputI === 5 && Val > 0) {
            if (that.CurrCard.CardVal - Val > 0) {
              this.CurrVal = Val;
              myMongoDBPost("UpdateDoc/Cards", {UPTJ: TJ, UPObj: {CurrVal: this.CurrVal}}, function (data2) {
                let SendStr = that.ItemName + '项目，被约“' + that.$store.state.UserName +
                  '”☞通知您需刷卡消费：' + Val + (that.CurrCard.CardType === '计费卡' ? '元。' : '次。') + fyM0Url;
                console.log("2.SendStr=" + SendStr);
                SendWXMsg(that.CurrYY.zyUserName, SendStr);
                  alert(  '刷卡(' + Val + (that.CurrCard.CardType === '计费卡' ? '元' : '次') + ')已通知对方！');
                that.$emit("FromBY3BCard", '本次消费');
              })
            } else alert(  '卡内余额不足,操作无效！');
          }
        }
        this.InputI = -1;
      },
      ZXIn1(Val) {
        if (Val > 0) {
          this.CurrVal = Val;
          this.$emit("FromBY3BCard", {CardType: '泛约币付', CardVal: 0, CurrVal: Val});
        } else alert(  '必须输入泛约币数量！');
      },//返回值用于首次建立当前项目上的主被约关系的Cards记录
      ZXIn2() {
        let that = this, TJ = {
          ItemCode: this.ItemCode,
          BUserName: this.$store.state.UserName,
          CUserName: this.CurrYY.zyUserName
        };
        myMongoDBPost("UpdateDoc/Cards", {UPTJ: TJ, UPObj: {CardVal: -1 * that.CardVal}}, function (data2) {
          SendWXMsg(that.CurrYY.zyUserName, that.ItemName + '项目，被约“' + that.$store.state.UserName + '”☞通知您已收到泛约币：' + Math.abs(that.CardVal) + '个');
          that.CardVal = -1 * that.CardVal;
        })
      }
    }
  }
</script>

<style scoped>

</style>
