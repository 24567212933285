<template>
  <div>
    <div v-if="!(PV.PT==='无照片')" class="XL YC MP4">
      {{PV.PT}}
      <img v-if="Pic.length===0" src='../../../static/img/Media/PicColor.png' class="Cursor0 WH99" @click="VPBool='1',Active=true">
      <ShowPFromThumb v-else :Msg="{Pic:Pic,Update:true}" @FromChildP="VPBool='1',Active=true"></ShowPFromThumb>
    </div>
    <div v-if="!(PV.VT==='无视频')" class="XL YC MP4">
      {{PV.VT}}
      <img v-if="Video.length===0" src='../../../static/img/Media/VideoColor.png' class="Cursor0 WH99" @click="VPBool='2',Active=true">
      <ShowVFromThumb v-else :Msg="{Video:Video,Update:true}" @FromChildV="VPBool='2',Active=true"></ShowVFromThumb>
    </div>
    <UpFiles1 v-if="Active" :VP="VPBool"  @FromU1="UpEnd"></UpFiles1>
  </div>
</template>

<script>
  import ShowVFromThumb from '@/components/MyPVTs/ShowVFromThumb'
  import ShowPFromThumb from '@/components/MyPVTs/ShowPFromThumb'

  import UpFiles1 from "./UpFiles1";

  export default {
    name: "ShowUpdatePV",
    components: {UpFiles1, ShowVFromThumb, ShowPFromThumb},
    props: ['PV'],
    data() {
      return {
        Pic: '',
        Video: '',
        Active: false,
        VPBool: ''
      }
    },
    mounted() {
      if (this.PV.P !== undefined) this.Pic = this.PV.P;
      if (this.PV.V !== undefined) this.Video = this.PV.V;
    },
    computed: {
      CurrPVP() {
        return (this.PV.P === undefined) ? '' : this.PV.P;
      },
      CurrPVV() {
        return (this.PV.V === undefined) ? '' : this.PV.V;
      }
    },
    watch: {
      CurrPVP(Val) {
        this.Pic = Val;
      },
      CurrPVV(Val) {
        this.Video = Val;
      }
    },
    methods: {
      UpEnd(VAL) {
        let that = this;
        this.Active = false;
        if (VAL === '未选择') {
          if (that.VPBool === '1') that.$emit("ShowUT", {P: that.Pic});
          if (that.VPBool === '2') that.$emit("ShowUT", {V: that.Video});
        } else {
          if (that.VPBool === '1') {
            that.$emit("ShowUT", {P: VAL});
            that.Pic = VAL;
          }
          if (that.VPBool === '2') {
            that.$emit("ShowUT", {V: VAL});
            that.Video = VAL;
          }
        }
      }
    }
  }
</script>

<style scoped>
  .MLR15 {
    margin-left: 1.5mm;
    margin-right: 1.5mm;
  }

  .PA2 {
    padding: 1mm 2mm;
  }
</style>
