<template>
  <div class="MT2">
    <div class="TF MT2">
      <el-divider v-if="$store.state.LandTo === 'BY2'" style="height: 1.2rem"> • {{ItemType}}:{{ItemName}} •</el-divider>
    </div>
    <div class="MLR4 LH150 TF MT2">
      <div class="XLR">
        <div>时间：{{CurrYY.meetTimeStr}}</div>
        <img src="../../../static/img/XMYY/InvitationSmall.jpg" @click="ShowInvitation=true" class="WH99">
      </div>
      <div v-if="ShowInvitation"
           :style="'position:fixed;width:100%;height:100%;left:0;top:0;color:white;background:black;z-index:'+ShowZindex">
        <div @click="ShowInvitation=false"
             style='position:fixed;width:100%;height:100%;left:3vw;top:20vw;background:0 0 / 94% url("../../../static/img/XMYY/Invitation.jpg") no-repeat'>
          <div style="position:absolute;left: 24vw;top: 12vw">{{$store.state.UserName}}</div>
          <div style="position:absolute;left: 42vw;top: 55vw">{{CurrYY.meetTimeStr}}</div>
          <div style="position:absolute;left: 14vw;top: 63vw">...{{CurrYY.meetAddrStr.slice(-12)}}</div>
          <div style="position:absolute;left:10vw;top:73vw">{{CurrYY.ItemStr.split(":")[1]}}</div>
          <div style="position:absolute;left:61vw;top:85vw">{{CurrYY.zyUserName}}</div>
          <div class="TG" style="position:absolute;left:61vw;top:105vw;">邀请函出示完毕<br><span class="TW">点击这里...</span>
          </div>
        </div>
      </div>
      <div class="XLR YC">
        地点：{{CurrYY.meetAddrStr}}
        <div v-if="CurrYY.Longi!==116.38"><img src="../../../static/img/SY/Map1.png" @click="ShowMap=true"
                                               class="WH99 BD_Radius1"></div>
      </div>
      <div v-if="CurrYY.fySitefee>0" class="XLR">
        <div>场地费：{{CurrYY.fySitefee}}</div>
        <div>付费方式：{{CurrYY.paymentWay}}</div>&nbsp;
      </div>
      <div v-if="CurrYY.jdfy.length>0">简短附言：{{CurrYY.jdfy}}</div>
    </div>

    <FYMap v-if="ShowMap"
           :Msg="{Name:CurrYY.ItemStr.split(':')[1]+'项目地址:',Addr:CurrYY.meetAddrStr,Longi:CurrYY.Longi,Lati:CurrYY.Lati}"
           OP="Look" @FromFYMap2="ShowMap=false"></FYMap>


    <div class="BottO">
      <el-divider>主约：{{CurrYY.zyUserName}}</el-divider>
      <div class="MP4 XLR TF">
        <img :src="'/UserUpload/1/'+CurrYY.UserLogo" @click="Show=1" class="AvatarWH"/>
        <div class="W80 ML2">
          <div class="XLR YC">
            <div class="TC T3">
              {{CurrYY.sex}}<br>{{CurrYY.age}}岁
            </div>
            <div class="TC T3 AlignC">
              <div class="T2 TF BottGray">&nbsp;积分:&nbsp;</div>
              {{CurrYY.JF}}
            </div>
            <div class="MT2">
              <ShowPVT :PVT="{P:CurrYY.fyUserPic,V:CurrYY.fyUserVideo,T:CurrYY.fyUserText,Update:false}"></ShowPVT>
            </div>
          </div>
          <div class="T2 XLR">
            <div>人气信用:{{Tofixed(CurrYY.PopularityCredit,2)}}</div>
            <div>综合才艺:{{Tofixed(CurrYY.QN,2)}}</div>
          </div>
        </div>
      </div>
      <div v-if="FirstXYBool===2" class="XR YC MR4 MT2">
        <div class="TC">线下活动情况：&nbsp;</div>
        <ShowPVT :PVT="{P:CurrYY.Pic,V:CurrYY.Video,T:CurrYY.Text,Update:false}"></ShowPVT>
      </div>
    </div>

    <img v-if="Show===1" :src="'UserUpload/1/'+CurrYY.UserLogo" @click="Show=-1"
         style="position:fixed;left:0;top:0;width: 100vw;height:auto"/>

    <div class="XLR YC MP4">
      <div>
        <span class="TG">响应：</span>
        <div v-if="FirstXYBool<2" class="BoxGray1 TG PA3 DispIB" @click="SelectI=0">{{CurrYY.by.xy}}</div>
        <span v-else>{{CurrYY.by.xy}}</span>
      </div>

      <div v-if="FirstXYBool===1" class="AN3" @click="FirstXY()">通知主约</div>
      <div v-if="FirstXYBool===2" class="T2">
        <div v-if="PJed" class="TF">已完成互评!</div>
        <div v-else>
          <div v-if="Changed1 && Changed2 && Changed3" class="AN3" @click="BYMsgSave()">通知主约</div>
          <p v-else class="TF">尚未互评！</p>
        </div>
      </div>
    </div>

    <div v-if="FirstXYBool===1 && CurrYY.by.xy==='暂拒'" class="MP4">
      暂拒理由 或 反馈建议 :<br>
      <input type="text" class="W80" v-model="CurrYY.by.zg"/>
      <br>
    </div>
    <!--BY3BCard v-if="CurrYY.ItemStr.charAt(0) === 'C' && CurrYY.by.xy==='同意'"
              :CurrYY="CurrYY" :FirstXYBool="FirstXYBool" @FromBY3BCard="SetCard"></BY3BCard-->

    <div v-if="FirstXYBool===2">
      <div v-if="CurrYY.by.xy==='同意'">
        <div class="XR YC MR4 MT2">
          <div class="TC">线下活动情况：&nbsp;</div>
          <ShowUpdatePVT :PVTF="{P:CurrYY.by.Pic,V:CurrYY.by.Video,T:CurrYY.by.Text,TextTitle:'线下活动情况'}"
                         @ShowUT="CallBack2"></ShowUpdatePVT>
        </div>
        <table  style="background-color:#fff;">
          <thead>
          <tr class="TF">
            <th>{{CurrYY.ItemStr.charAt(0)==='A' ? '战况':''}}</th>
            <th>履约情况</th>
            <th>
              <div v-if="CurrYY.ItemStr.charAt(0)==='A'">战果</div>
              <div v-else></div>
            </th>
            <th>互评</th>
          </tr>
          <tr>
            <th class="W26">主约反馈:</th>
            <th> {{CurrYY.by.z_bylyqk==='初值'? '—':CurrYY.by.z_bylyqk }}</th>
            <th>
              <div v-if="CurrYY.ItemStr.charAt(0)==='A'">{{CurrYY.by.z_zg==='初值'? '—': CurrYY.by.z_zg}}</div>
              <div v-else></div>
            </th>
            <th> {{CurrYY.by.z_ts==='初值'? '—': CurrYY.by.z_ts}}</th>
          </tr>
          <tr>
            <th class="W26">被约观点:</th>
            <th>
              <div v-if="CurrYY.by.xy === '同意' && !PJed" @click="SelectI=2" class="XYC">
                <div v-if="CurrYY.by.b_zylyqk==='初值'" class="AN ANGray">点这里</div>
                <div v-else class="AN ANGreen ">{{CurrYY.by.b_zylyqk}}</div>
              </div>
              <div v-else class="AN ANGray ">
                <div v-if="PJed ">{{CurrYY.by.b_zylyqk==='初值'? '—':CurrYY.by.b_zylyqk}}</div>
                <div v-else>—</div>
              </div>
            </th>
            <th>
              <div v-if="CurrYY.by.xy === '同意' && !PJed">
                <div v-if="CurrYY.ItemStr.charAt(0)==='A'" @click="SelectI=1" class="XYC">
                  <div v-if="CurrYY.by.b_zg==='初值'" class="AN ANGray ">点这里</div>
                  <div v-else class="AN ANGreen">{{CurrYY.by.b_zg}}</div>
                </div>
                <div v-else></div>
              </div>
              <div v-else class="AN ANGray">
                <div v-if="PJed && CurrYY.ItemStr.charAt(0)==='A'">{{CurrYY.by.b_zg==='初值'? '—':CurrYY.by.b_zg}}</div>
                <div v-else>—</div>
              </div>
            </th>
            <th class="MR4">
              <div v-if="CurrYY.by.xy === '同意' && !PJed" @click="SelectI=3" class="XYC">
                <div v-if="CurrYY.by.b_ts==='初值'" class="AN ANGray">点这里</div>
                <div v-else class="AN ANGreen TG">{{CurrYY.by.b_ts}}</div>
              </div>
              <div v-else>
                <div v-if="PJed">{{CurrYY.by.b_ts==='初值'? '—':CurrYY.by.b_ts}}</div>
                <div v-else>—</div>
              </div>
            </th>
          </tr>
          </thead>
        </table>
      </div>
    </div>
    <MyToast v-if="Show1" :Tip="'响应信息已发送!'" @Tiped="$emit('FromBY3')"></MyToast>
    <MySelect v-if="SelectI===1" :Options="['主约胜','被约胜','平局']" @FromMySelect="MySelect1"></MySelect>
    <MySelect v-if="SelectI===2" :Options="['主约迟到','主约爽约','主约准时']" @FromMySelect="MySelect2"></MySelect>
    <MySelect v-if="SelectI===3" :Options="['差评','一般','点赞']" @FromMySelect="MySelect3"></MySelect>
    <MySelect v-if="SelectI===0" :Options="['同意','拉黑','暂拒']" @FromMySelect="MySelect0"></MySelect>

  </div>
</template>

<script>
  //需要考虑到：当本页正在编辑的同时，主约或别的被约发送了评价信息．因此，不能全文档存回!
  import ZY3BY3Mix from '@/components/ListYY/ZY3BY3Mix';
  import {
    MyUrl,
    CloneObject,
    GetItemStr3,
    GetItemType,
    myDataFileGet,
    GetMyYYs,
    myMongoDBPost,
    SendWXMsg,
    Tofixed
  } from '@/components/SharedVues/Shared0.js'
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import MySelect from "@/components/SharedVues/MySelect";
  import MyInput from "@/components/SharedVues/MyInput";
  import BY3BCard from "./BY3BCard";
  import FYMap from "../SharedVues/FYMap";
  import ShowUpdatePVT from "../MyPVTs/ShowUpdatePVT";

  import MyToast from "../SharedVues/MyToast";
  import ShowPVT from "../MyPVTs/ShowPVT";

  export default {
    name: "BY3",
    components: {
      ShowPVT,
      MyToast,
      ShowUpdatePVT,
      FYMap,
      BY3BCard,
      MyInput,
      MySelect,
      PopTitle
    },
    mixins: [ZY3BY3Mix],
    data() {
      return {
        Show: -1,
        ShowMap: false,
        CardType: '',
        CardVal: 0,
        CurrVal: 0,

        ItemCode: '',
        ItemType: '',
        ItemName: '',

        FirstXYBool: 0,//首次响应标志
        Changed1: false,
        Changed2: false,
        Changed3: false,
        SelectI: -1,
        ShowInvitation: false,
        ShowZindex: this.$store.state.CurrZindex + 70
      }
    },
    mounted() {
      this.ItemCode = this.CurrYY.ItemStr.split(':')[0];
      this.ItemType = GetItemType(this.ItemCode);
      this.ItemName = this.CurrYY.ItemStr.split(':')[1];

      if (this.CurrYY.by.xy === '同意' || this.CurrYY.by.xy === '拉黑' || this.CurrYY.by.xy === '暂拒') {
        this.FirstXYBool = 2;
        if (this.CurrYY.by.b_ts !== '' && this.CurrYY.by.b_ts !== '初值') this.PJed = true;//取其中一项来作为判断是否评价过的标志
      }//若已经响应过
      if (this.CurrYY.ItemStr.split(':')[0].charAt(0) !== 'A') this.Changed1 = true;

      let Now = new Date().getTime(), meetStamp = new Date(this.CurrYY.meetTimeStr + ":00").getTime(); //时间字符串转为时间戳
      if (this.CurrYY.by.xy === "未响应" && Now > meetStamp) {// 如果尚未响应,但已超过碰头时间,则应作结束处理
        this.CurrYY.by.xy = "超时";
        this.BYMsgSave();
      }


    },

    methods: {
      /*
      SetCard(Val) {
        if (Val === '本次消费') {
          if (this.FirstXYBool < 2) myMongoDBPost("SaveBY/?ItemCode=" + this.ItemCode, {_id: this.CurrYY._id, BY: this.CurrYY.by}, function (data) {
          });//如果是首次响应,则直接发送一个响应消息．同时，如果是泛约币付，则应将CardVal清０
        } else {
          this.CardType = Val.CardType;
          this.CardVal = Val.CardVal;
          this.CurrVal = Val.CurrVal;
          if (this.FirstXYBool === 0 && this.CurrYY.by.xy === '同意' && this.CurrYY.ItemStr.charAt(0) === 'C' && this.CardType !== '') this.FirstXYBool = 1;
        }
      },
*/
      MySelect0(Val) {
        this.CurrYY.by.xy = Val;
        this.FirstXYBool = 1;
        this.CurrYY.by.zg = '';
        this.SelectI = -1;
        //if (Val === '同意' && this.CurrYY.ItemStr.charAt(0) === 'C' && this.CardType === '') this.FirstXYBool = 0;
      },
      MySelect1(Val) {
        this.CurrYY.by.b_zg = Val;
        this.Changed1 = true;
        this.SelectI = -1;
      },
      MySelect2(Val) {
        this.CurrYY.by.b_zylyqk = Val;
        if (Val === '主约爽约') {
          this.CurrYY.by.b_zg = '主约爽约';
          this.Changed1 = true;
          this.CurrYY.by.b_ts = '差评';
          this.Changed3 = true;
        }
        this.Changed2 = true;
        this.SelectI = -1;
      },
      MySelect3(Val) {
        this.CurrYY.by.b_ts = Val;
        this.Changed3 = true;
        this.SelectI = -1;
      },
      CallBack2(Val) {//历史信息
        if (Val.P) this.CurrYY.by.Pic = Val.P;
        if (Val.V) this.CurrYY.by.Video = Val.V;
        if (Val.T) this.CurrYY.by.Text = Val.T;
      },
      FirstXY() {
        if (this.$store.state.UserName === '游客') return alert('游客操作无效! 请先注册、登录后，再进行该操作。');
        this.Show1 = true;
        let that = this;
        /*
        if (this.CurrYY.ItemStr.charAt(0) === 'C' && (this.CardType !== '' && this.CardVal > 0 || this.CardType === '泛约币付' || this.CardType === '现金付')) {
          let TJ = {
            ItemCode: this.ItemCode,
            BUserName: this.$store.state.UserName,
            CUserName: this.CurrYY.zyUserName,
          }, aOBJ = {
            ItemCode: this.ItemCode,
            ItemName: this.ItemName,
            BUserName: this.$store.state.UserName,
            CUserName: this.CurrYY.zyUserName,
            CardType: this.CardType,
            CardVal: this.CardVal,
            CurrVal: this.CardType === '现金付' ? 0 : this.CurrVal,
            Lists: this.CardVal > 0 ? [{Stamp: (new Date()).getTime(), Do: '充值', Val: this.CardVal}] : [],
            Bool: (this.CardType === '泛约币付' || this.CardType === '现金付') ? '已激活' : '待激活'
          };
          myMongoDBPost("GetDocs/Cards", TJ, function (Docs) {
            if (Docs.length > 0) myMongoDBPost("UpdateDoc/Cards", {UPTJ: TJ, UPObj: {CardVal: 0, CurrVal: that.CurrVal}}, function (data2) {
            }); else myMongoDBPost("PutToCards", aOBJ, function () {
            });
          });
        }*/
        myMongoDBPost("SaveBY/?ItemCode=" + this.ItemCode, {_id: that.CurrYY._id, BY: that.CurrYY.by}, function (data) {
          let CardTip = (that.CardType === '泛约币付' ? '(您需要支付' + that.CurrVal + '个泛约币)' : (that.CardType === '' ? '' : '(' + that.CardType + '需要线下完成支付)'));


          let MyUrlPara = 'LandTo=ZY2QWERItemCode=' + that.ItemCode + 'QWERItemName=' + that.ItemName + 'QWERCurrYYId=' + that.CurrYY._id + '___';
          let MyUrlShow = '查看/评价';
          let Msg = GetItemStr3(that.CurrYY.ItemStr) + '☞被约『' + that.$store.state.UserName + '』同意了您的邀约' + CardTip + '。' + '[ MyUrlHere ]MyUrlHereMyUrl=' + MyUrlPara + 'MyUrl=' + MyUrlShow;


          if (that.CurrYY.by.xy === '同意') SendWXMsg(that.CurrYY.zyUserName, Msg); else that.BYMsgSave();
        })
      },
      BYMsgSave() {
        if (this.$store.state.UserName === '游客') return alert('游客操作无效! 请先注册、登录后，再进行该操作。');
        let that = this;
        let ItemType = GetItemType(this.ItemCode);
        myMongoDBPost("SaveBY/?ItemCode=" + this.ItemCode, {_id: that.CurrYY._id, BY: that.CurrYY.by}, function (data) {
          that.Show1 = true;
          that.PJed = true;

          if (data.EndBool) {//当前邀约结束时，应当向主约和每一个被约发送通知
            let TJ = '';
            if (that.CurrYY.by.xy === '暂拒') TJ = '(被约' + that.CurrYY.by._id + '因' + that.CurrYY.by.zg + '暂拒了您)';
            if (that.CurrYY.by.xy === '拉黑') TJ = '(被约' + that.CurrYY.by._id + '拉黑了您)';
            if (that.CurrYY.by.xy === '超时') TJ = '(被约' + that.CurrYY.by._id + '在您约定的碰头时间之前，未能及时响应)';
            if (TJ === '') TJ = "(战果：" + that.CurrYY.by.zg + ")";
            if (that.CurrYY.by.AppearanceFee === 0) SendWXMsg(that.CurrYY.zyUserName, GetItemStr3(that.CurrYY.ItemStr) + '☞项目邀约活动已结束！' + TJ);
            let CurrYYDoc = data.YYDoc;
            console.log(CurrYYDoc.by);
            for (let i1 = 0, len1 = CurrYYDoc.by.length; i1 < len1; i1++)  //遍历每一个被约
              (function (i1) {
                that.CurrByEndProcess(that, ItemType, that.ItemCode, that.ItemName, CurrYYDoc, CurrYYDoc.by[i1], 1);
              })(i1);
            GetMyYYs(that, function () {
            });//为刷新ZYn而调用
          } else {
            let Url = MyUrl('LandTo=ZY2!ItemCode=' + that.ItemCode + '!ItemName=' + that.ItemName + '!CurrYYId=' + that.CurrYY._id + '___');
            let asd = '';
            if (that.CurrYY.ItemStr.charAt(0) === 'A' && that.CurrYY.by.b_zg !== '' && that.CurrYY.by.b_zg !== '初值') asd += '战果:' + that.CurrYY.by.b_zg + "；";
            if (that.CurrYY.by.b_zylyqk !== '' && that.CurrYY.by.b_zylyqk !== '初值') asd += '履约:' + that.CurrYY.by.b_zylyqk + "。";
            if (that.CurrYY.by.b_ts !== '' && that.CurrYY.by.b_ts !== '初值') asd += '互评:' + that.CurrYY.by.b_ts + "；";
            if (that.CurrYY.by.xy !== '同意') asd = that.CurrYY.by.xy + '了您的邀约。';
            if (that.CurrYY.by.xy === '暂拒') asd += "（" + that.CurrYY.by.zg + ")";
            //let Msg =GetItemStr3(that.CurrYY.ItemStr) + '☞被约“' + that.$store.state.UserName +'”已完成评价▷' + asd + '\n[ <a href="' + Url + '">查看/评价</a> ]';


            let MyUrlPara = 'LandTo=ZY2QWERItemCode=' + that.ItemCode + 'QWERItemName=' + that.ItemName + 'QWERCurrYYId=' + that.CurrYY._id + '___';
            let MyUrlShow = '查看/评价';
            let Msg = GetItemStr3(that.CurrYY.ItemStr) + '☞被约『' + that.$store.state.UserName + '』已完成评价▷' + asd + '\n[ MyUrlHere ]MyUrlHereMyUrl=' + MyUrlPara + 'MyUrl=' + MyUrlShow;


            SendWXMsg(that.CurrYY.zyUserName, Msg);
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
