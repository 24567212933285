<template>
  <div >
    <div class="MaskCss" :style="'z-index:'+MZindex">
      <div class="BoxShadow BGWhite" :style="'position:relative;left:5vw;top:40vh;width:90vw;height:40vh;z-index:'+Zindex">
        <br>
        <MyDivider ShowStr="请选择"></MyDivider>
        <div class="XL">
          <div v-for="(Option,index) in Options" :key="index" class="TB MP4 Cursor0" @click="Selected(index)">{{Option}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MyDivider from "./MyDivider";

  export default {
    name: "MySelect",
    props: ['Options'],

    components: {MyDivider},
    data() {
      return {
        MZindex: 0,
        Zindex: 0,
      }
    },
    computed: {
      CurrZindex() {
        return this.$store.state.CurrZindex
      },
    },
    watch: {
      CurrZindex(Val) {
        this.Zindex = Val+1;
        this.MZindex = Val - 2;
      }
    },
    mounted() {
      this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex + 50);
    },
    beforeUnmount() {
      this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex - 50);//切记10000和2000改小了,还不行
    },
    methods: {
      Selected(index) {
        this.$emit("FromMySelect", this.Options[index]);
      }
    }
  }
</script>

<style scoped>
  .MaskCss {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    outline: 0;
    -webkit-overflow-scrolling: touch;
    background-color: #fffa9d;
    filter: alpha(opacity=60);
    background-color: rgba(0, 0, 0, 0.6);
    /*z-index:50;*/
  }
</style>
