<template>
  <div>
    <PopUpDown :Title="Title" Pos="btt" :ANs="1" @FromPop5="$emit('FromMySele3', '')">
      <div class="TF">单项选择：</div>
      <div class="XLR YC">&nbsp;
        <div class="XL YC">
          <div v-for="(Str, index1) in Opts" :key="index1">
            <div class="W26 DispIB SubBox AlignC TB" @click="SetStr(Str)">{{Str}}
            </div>
          </div>
        </div>
        &nbsp;
      </div>
      <hr>
      <div class="XLR YC PA2">
        <div class="TF">组合选择=
          <div class="SubBox DispIB">{{aStr}}</div>
        </div>
        <div class="AN3" @click="SetStr(aStr)">确认</div>
      </div>
      <div class="XL TG">
        <div class="PA2" v-for="(Str, index1) in Opts" :key="index1" @click="SetaStr(Str)">{{Str}}</div>
      </div>
      <br>
    </PopUpDown>
  </div>
</template>

<script>
  import {AutoAlert} from '@/components/SharedVues/Shared0.js';
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import PopUpDown from "../MyPopWin/PopUpDown";

  export default {
    name: "MySele3",
    components: {PopUpDown, PopTitle},
    props: ['Opts'],
    data() {
      return {
        Title: "请选择",
        aStr: '?'
      }
    },
    mounted() {
      if (this.Opts.length === 0) {
        AutoAlert('没有备选项', '选择无效!');
        this.$nextTick(() => {
          this.$emit("FromMySele3", '无');
        });
      }
    },//当把$emit("FromMySele3", '无')放在mounted()中时,一定要放在$nextTick之内,否则会出现TypeError: Cannot read properties of null (reading 'classList')
    methods: {
      SetStr(Str) {
        this.$emit("FromMySele3", Str)
      },
      SetaStr(Str) {
        if (this.aStr === '?') this.aStr = Str; else this.aStr += "/" + Str;
      }
    }
  }
</script>

<style scoped>

</style>

