<template>
  <div>
    <div v-if="Show===1">
      <div class="XLR TF T3">&nbsp;<div></div>
        <div></div>
        推荐数量：{{ZyByXYSites.length}}
        <div></div>
      </div>
      <table  style="background-color:#fff;line-height:1.2rem;">
        <thead>
        <tr class="TF">
          <th>序号</th>
          <th>定位</th>
          <th>场地名称</th>
          <th>
            <div class="MTB2 T3">喜好<br>人数</div>
          </th>
          <th>选择</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(x,index) in ZyByXYSites" :key="index">
          <td class="BottGray">{{index+1}}</td>
          <td>
            <img src="../../../static/gif2/location.gif" @click="ShowMapI1=index" class="WH99 MT2">
          </td>
          <td>{{x.SiteName}}</td>
          <td>{{x.XYedUserN}}</td>
          <td class="TB T6" @click="SetLocation(x.Province,x.City,x.DC,x.Address,x.SiteName,x.Longi,x.Lati)">□</td>
        </tr>
        </tbody>
      </table>
      <br>
      <div v-if="ZyByXYSites.length===0" class="TF">
        <p>主被约在当前项目上,均未设置喜好场地。请：</p>
        <p>1.返回到首页，寻找［添加与设置］按钮，点击进入。</p>
        <p>2.找到您的喜好项目，如：乒乓球。然后点击［喜好场地］按钮进入喜好场地设置页面。</p>
        <p>3.圈选您喜欢光临的场地后再回到这里操作。</p>
      </div>
    </div>
    <div v-if="Show===2" class="T3">
      <div v-for="(x,index) in CityItemSites" :key="index" class="MP4">
        <div class="XLR YC T4 TC">
          <div class="YC" style="width: 60vw">{{index+1}}.{{x.SiteName}}</div>
          <img src="../../../static/gif2/location.gif" @click="ShowMapI2=index" class="WH99">
          <div class="TG" @click="SetLocation(x.Province,x.City,x.DC,x.Address,x.SiteName,x.Longi,x.Lati)">□选择</div>
        </div>
        <div class="XLR TF T3">
          <div class="TE T3">喜好人数：{{x.XYedUserN}}</div>
          电话:{{x.Telephone}}
        </div>
        <div v-if="x.Remark==='' && x.Pic.length===0 && x.Video.length===0" class="BottGray"></div>
        <div v-else class="Box5 XLR">
          <p class="TF">{{x.Remark}}</p>
          <div>
            <ShowPFromThumb v-if="x.Pic.length>0" :Msg="{Pic:x.Pic,Update:false}"></ShowPFromThumb>
            <ShowVFromThumb v-if="x.Video.length>0" :Msg="{Video:x.Video,Update:false}"></ShowVFromThumb>
          </div>
        </div>
      </div>
      <div v-if="CityItemSites.length===0" class="XYC  TE">当前项目暂没有可以推荐的场地！</div>
    </div>
    <FYMap v-if="ShowMapI1>-1"
           :Msg="{Name:ZyByXYSites[ShowMapI1].SiteName,Addr:ZyByXYSites[ShowMapI1].Address,Lati:ZyByXYSites[ShowMapI1].Lati,Longi:ZyByXYSites[ShowMapI1].Longi}"
           OP="Look" @FromFYMap2="ShowMapI1=-1"></FYMap>
    <FYMap v-if="ShowMapI2>-1"
           :Msg="{Name:CityItemSites[ShowMapI2].SiteName,Addr:CityItemSites[ShowMapI2].Address,Lati:CityItemSites[ShowMapI2].Lati,Longi:CityItemSites[ShowMapI2].Longi}"
           OP="Look" @FromFYMap2="ShowMapI2=-1"></FYMap>
  </div>
</template>

<script>
  import {Compare, myMongoDBPost, AutoAlert} from '@/components/SharedVues/Shared0.js'
  import ShowPFromThumb from "@/components/MyPVTs/ShowPFromThumb";
  import ShowVFromThumb from "@/components/MyPVTs/ShowVFromThumb";

  import FYMap from "../SharedVues/FYMap";
  import PopOneAN from "../MyPopWin/PopOneAN";

  export default {
    name: "SitesShow",

    components: {PopOneAN, FYMap,  ShowVFromThumb, ShowPFromThumb},
    props: ['ItemStr', 'CurrYYDoc'],
    data() {
      return {
        ZyByXYSites: [],
        CityItemSites: [],
        CurrArea: '',
        CurrCity: '',
        Show: 0,
        ShowMapI1: -1,
        ShowMapI2: -1
      }
    },
    mounted() {
      let that=this,ZBUserNames = [this.CurrYYDoc.zyUserName];//主被约用户名列表
      for (let i = 0; i < this.CurrYYDoc.by.length; i++) ZBUserNames.push(this.CurrYYDoc.by[i]._id);
      myMongoDBPost("ReadZyByXYSiteData", {ItemStr: this.ItemStr, ZBUserNames: ZBUserNames}, function (data) {
        that.ZyByXYSites = data;
        that.ZyByXYSites.sort(Compare('XYedUserN')).reverse();
        if (that.ZyByXYSites.length === 0) AutoAlert('主被约都没有设置喜好场地', '无法自动生成碰头地点');
        that.Show = 1;//显示主被约喜好场地数据
      });
    },
    computed: {
      CurrAddr() {
        return this.$store.state.CurrInputP + ":" + this.$store.state.CurrInputC + ":" + this.$store.state.CurrInputDC;
      }
    },
    watch: {
      CurrAddr(Val) {
        let asd = Val.split(":");
        if (this.Show > 0) this.RefreshSiteData(asd);
      }
    },
    methods: {
      RefreshSiteData(asd) {
        //console.log(asd)
        let that = this;
        if (asd[0] === '不限' || asd[0] === '省份' || asd[1] === '不限' || asd[1] === '城市') {//读取主被约喜好场地数据
          let ZBUserNames = [this.CurrYYDoc.zyUserName];//主被约用户名列表
          for (let i = 0; i < this.CurrYYDoc.by.length; i++) ZBUserNames.push(this.CurrYYDoc.by[i]._id);

          myMongoDBPost("ReadZyByXYSiteData", {ItemStr: this.ItemStr, ZBUserNames: ZBUserNames}, function (data) {
            that.ZyByXYSites = data;
            that.ZyByXYSites.sort(Compare('XYedUserN')).reverse();
            if (that.ZyByXYSites.length === 0) AutoAlert('主被约都没有设置喜好场地', '无法自动生成碰头地点');
            that.Show = 1;//显示主被约喜好场地数据
          });
        } else {
          this.CurrCity = asd[1];
          let aObj = {ItemName: this.ItemStr.split(":")[1]};
          if (asd[2] !== '区县' && asd[2] !== '不限' && asd[2] !== '') aObj.DC = asd[2];
          if (asd[1] !== '城市' && asd[1] !== '不限' && asd[1] !== '') aObj.City = asd[1];
          if (asd[0] !== '不限' && asd[0] !== '') aObj.Province = asd[0];
          myMongoDBPost("ReadCitySiteData", aObj, function (data) {
            that.CityItemSites = data;
            that.CityItemSites.sort(Compare('XYedUserN')).reverse();
            that.Show = 2;//显示场地详细数据
          });
        }
      },
      SetLocation(P, C, DC, DA, SiteName, Longi, Lati) {
        //生成碰头地址串,
        this.$store.commit('ChangeBool1', '邀约地址输入中...');
        this.$emit("eventFromSitesShow", {P: P, C: C, DC: DC, DA: DA, SiteName: SiteName, Longi: Longi, Lati: Lati});
      },
    }
  }
</script>

<style scoped>

</style>
