<template>
    <div>
        <!--以下 是 主页显示内容-->
        <div class="Header">
            <div class="XLR YC BGLightGray PA2">
                <img :src="'UserUpload/static/After2021/ListAN1.png'" @click="Show=1" class="WHaa">
                <div class="BoxGray1 PA3 XL YC BD_Radius1 W60" @click="Show=2">
                    <img src="../../static/img/ButtonPic/Magnifier.png" class="WH77 MR4">
                    <div class="DispIB">
                        <MyMarquee :ShowItems="['用户昵称','项目名称','模糊关键字']"></MyMarquee>
                    </div>
                </div>
                <img src="../../static/img/SY/Map1.png" @click="Show=3" class="WH99 BD_Radius1 BDGreen">
                <!--‌Robots协议中的Disallow: /static/表示禁止所有搜索引擎抓取网站根目录下名为static的目录中的内容,因此
                <img :src="'static/img/SY/Map1.png'" 不被允许，但<img :src="'UserUpload/ 则没有问题-->
            </div>
            <div class="XLR MLR2" style="margin-top: 1mm">&nbsp;
                <div v-for="(M,index) in Menus" :key="index">
                    <transition v-if="SeleI===index">
                        <div v-if="SeleI===index" class="SCss SCss2">{{M}}</div>
                    </transition>
                    <div v-else class="SCss SCss1" @click="Selected(index)">{{M}}</div>
                </div>
                                                          &nbsp;
            </div>
        </div>
        <div class="H6"></div>
        <div v-if="$store.state.UserName==='游客'" class="MT2 XLR YC">&nbsp;
            <SubEntrance ShowMode="同时显示" ref="Child1" @FromSubEntrance="ProcessFromSubEntrance"></SubEntrance>&nbsp;
        </div>
        <SeleItem v-if="SeleI===0" Channel="打擂竞技" Url="GetChannelHopeItems"></SeleItem>
        <SeleItem v-if="SeleI===1" Channel="休闲娱乐" Url="GetChannelHopeItems"></SeleItem>
        <SeleItem v-if="SeleI===2" Channel="技能服务" Url="GetChannelItems"></SeleItem>
        <SeleItem v-if="SeleI===3" Channel="特邀" Url="GetChannelItems"></SeleItem>
        
        
        <!--原理：以下弹出窗口分别来自：主页页面点击和微信自定义菜单。若要处理微信链接，在需要穿过这些窗口-->
        <!--OpenItem v-if="Show===40" :ItemStr="ItemStr" @FromOpenItem="Show=-1"></OpenItem>
        <OpenItem v-if="Show===41" :ItemStr="ItemStr" @FromOpenItem="Show=-1"></OpenItem>
        <OpenItem v-if="Show===42" :ItemStr="ItemStr" @FromOpenItem="Show=-1"></OpenItem>
        <OpenItem-- v-if="Show===43" :ItemStr="ItemStr" @FromOpenItem="Show=-1"></OpenItem-->
        
        <PopTitle v-if="Show===1" Title="我的常用邀约模板" @FromPopUp="Show=-1">
            <MBSelect></MBSelect>
        </PopTitle>
        <FYSearch v-if="Show===2" @FromFYSearch="Show=-1"></FYSearch>
        <PopOneAN v-if="Show===3" @FromPopUp="Show=-1">
            <RealTime></RealTime>
        </PopOneAN>
        <!--PopTitle v-if="Show===4" :Title="$store.state.UserName+'的泛约币'" @FromPopUp="Show=-1">
            <ZXGL></ZXGL>
        </PopTitle-->
        
        <PopBott v-if="DefaultItemed===1">
            <SeleOneItem Title="请先选择缺省打开项目" @FromSeleOneItem="UpdateDefaultItem"></SeleOneItem>
        </PopBott>
        <PopMenu v-if="Show === 5 && DefaultItemed===2" :Menus="Menus2" :BeginN="0" @FromPopMenu="PopMenuVal">
            <XMYY v-if="SelectedN===0" :ItemStr="$store.state.DefaultItem"></XMYY>
            <ZY v-if="SelectedN===1"></ZY>
            <BY v-if="SelectedN===2"></BY>
        </PopMenu>
        
        <PopTitle v-if="Show===6 && DefaultItemed===2" :Title="$store.state.DefaultItem" @FromPopUp="Show=-1">
            <DLQ :ItemStr="$store.state.DefaultItem"></DLQ>
        </PopTitle>
        <PopTitle v-if="Show===7 && DefaultItemed===2" :Title="$store.state.DefaultItem" @FromPopUp="Show=-1">
            <GXCard v-if="SelectedN===0" :ItemStr="$store.state.DefaultItem"></GXCard>
            <GXCard1 v-if="SelectedN===1" :ItemStr="$store.state.DefaultItem"></GXCard1>
            <GXCard2 v-if="SelectedN===2" :ItemStr="$store.state.DefaultItem"></GXCard2>
        </PopTitle>
        <PopTitle v-if="Show===8 && DefaultItemed===2" :Title="$store.state.DefaultItem" @FromPopUp="Show=-1">
            <ActMain :ItemStr="$store.state.DefaultItem"></ActMain>
        </PopTitle>
        <PopBlank v-if="Show===9 && DefaultItemed===2">
            <YKQ :ItemStr="$store.state.DefaultItem" @FromYKQ="Show=-1"></YKQ>
        </PopBlank>
        
        <PopOneAN v-if="Show===10" @FromPopUp="Show=-1">
            <GXXC></GXXC>
        </PopOneAN>
        <PopTitle v-if="Show===11" Title="寻人启事=快捷找人" @FromPopUp="Show=-1">
            <KJZR></KJZR>
        </PopTitle>
        
        <PopTitle v-if="Show===12" Title="请把困难换算成泛约币" @FromPopUp="Show=-1">
            <FYBB></FYBB>
        </PopTitle>
        
        <PopTitle v-if="Show===13" Title="设置管理" @FromPopUp="Show=-1">
            <SetupMain @FromSetupMain="Show=-1"></SetupMain>
        </PopTitle>
        
        <PopTitle v-if="Show===14" Title="基本设置" @FromPopUp="Show=-1">
            <BasicSetup></BasicSetup>
        </PopTitle>
        
        <PopTitle v-if="Show===15" Title="泛约项目场地" @FromPopUp="Show=-1">
            <SiteManage></SiteManage>
        </PopTitle>
        
        <PopTitle v-if="Show===16" Title="授权操作 • 用户注销" @FromPopUp="Show=-1">
            <QXZG></QXZG>
            <QuitFY v-if="$store.state.UserName!=='游客'"></QuitFY>
        </PopTitle>
        
        <!--以下来自微信链接-->
        
        <PopTitle v-if="Show===19" Title="操作说明" @FromPopUp="Show=-1">
            <CZSM></CZSM>
        </PopTitle>
        
        <PopTitle v-if="Show===20" :Title="'泛约共享卡场地：'+GXCardObj.SiteShortName" Pos="ltr" @FromPopUp="Show=-1">
            <GXCardOpen :Id="GXCardObj.Id" :aLinkKey="GXCardObj.SiteShortName"
                        :ItemStr="GXCardObj.ItemStr" :CardType="GXCardObj.CardType" @GXCardOpen="Refresh()"></GXCardOpen>
        </PopTitle>
        
        <div v-if="Show4">
            <div class="MaskCss"></div>
            <div class="FullScr1 XYC">
                <img :src="'UserUpload/static/Img/lens.png'" style="position: relative;width: 85vw;height: 85vw;">
                <div class="BGBlack BDCircle XYC" style="position:fixed;width: 76vw;height: 76vw;overflow: hidden">
                    <div class="T2 TW AlignC" style="height:15vw;">
                        <br>
                        <div class="BottO"> • 擂主展示 •</div>
                        &nbsp;请注册登录后开始打擂 ！
                    </div>
                    <video :src="'UserUpload/2/' + WXLinkVideo" autoplay="autoplay" playsinline="" controls
                           style="height: 46vw;width: 76vw"></video>
                    <div class="AlignC"><img :src="'UserUpload/1/'+WXLinkPic" @click=" Show5=true"
                                             style="height: 15vw;width: auto;"></div>
                </div>
            </div>
            <div style="position:fixed; bottom: 0;height: 22vh;width:100vw;top:78vh;background-color: #ff2837;opacity: 0.8">
                <div class="XR PA2" @click="Show4=false">
                    <img src="../../static/img/ButtonPic/CB6.png" class="WH77">
                </div>
                <div class="TW T3 PA2">☞该平台网址：http://www.fy135.vip</div>
                <div class="XLR YC PA2">
                    <p class="TW LH150 ">更建议通过微信公众号“泛约网”访问使用，请长按右侧二维码并关注置顶。</p>
                    <img :src="'UserUpload/CZSM/Two.png'" class=" ML2 WHcc">
                </div>
            </div>
            <div v-if="Show5" class="FullScr1 XYC">
                <div @click="Show5=false" class="W90 BoxShadow PA3 BGY">
                    <img :src="'UserUpload/1/'+WXLinkPic" width="100%" height="auto"/>
                    <p>{{WXLinkText}}</p>
                </div>
            </div>
        </div>
        
        <div class="H1"></div>
        <div class="TC BD_Radius1 BGLightGray PA2">
            <div v-if="aTip!==null" class="XLR YC LH120 BGWhite PA2" @click="ClearTip">
                <div class="NoWrap">
                    {{aTip.Time1}}<br>{{aTip.Time2}}
                </div>
                <img src="../../static/gif2/ArrowR.gif" class="W12"/>
                <div class="TE">{{aTip.Tip}}</div>
            </div>
            <TZGG></TZGG>
            <NewsToMe v-if="$store.state.UserName!=='游客'"></NewsToMe>
        </div>
        <br>
        <div class="AlignC">&nbsp;&nbsp;
                            ★★★<a target="_" href="https://beian.miit.gov.cn/">津ICP备16007353号</a>★★★
                            &nbsp;&nbsp;
        </div>
        <p class="MT2 TE" style="font-size:48px"> ️🌏️ </p>
        <br><br><br><br><br><br>
        <div v-if="$store.state.UserName!=='游客'" class="MyFooter">
            <div class="PA3 XLR YC NoWrap TW">
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="SetShow(11)">
                    寻人<br>启示
                </div>
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="SetShow(10)">
                    共享<br>相册
                </div>
                
                <div class="AlignC T3 TB">
                    <div class="XL YC">
                        <HeadShot></HeadShot>
                        <div>
                            <img v-if="$store.state.Certified==='成功'" src="../../static/img/IDed.png" width="20">
                            <div v-else>
                                <img v-if="$store.state.Certified==='失败'" src="../../static/img/ID2.png" width="20">
                                <img v-else src="../../static/img/ID3.png" width="20">
                            </div>
                            <div class="TE T1 NoWrap">&nbsp;{{$store.state.ZX}}&nbsp;</div>
                        </div>
                    </div>
                    {{$store.state.UserName}}
                </div>
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="Show=12">
                    泛约<br>帮办
                </div>
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="Show=13">
                    设置<br>管理
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {AutoAlert, AutoAlert2, FillUserlogs, myMongoDBPost} from './SharedVues/Shared0.js'
    import MyDivider from "./SharedVues/MyDivider";
    import NewsToMe from "./FrontPage/NewsToMe";
    import FYSearch from "./FrontPage/FYSearch";
    import RealTime from "./IndependentVues/RealTime";
    import TZGG from "./FrontPage/TZGG";
    import MBSelect from "./FrontPage/MBSelect";
    import PopOneAN from "./MyPopWin/PopOneAN";
    import FJXX from "./SetupManage/FJXX";
    import DLandReg from "./SetupManage/DLandReg";
    import QXZG from "./SetupManage/QXZG";
    import QuitFY from "./SetupManage/QuitFY";
    import SitesEdit from "./M4_Sites/SitesEdit";
    import FYDT from "./SetupManage/FYDT";
    import SubEntrance from "./SharedVues/SubEntrance";
    import ZXGL from "./IndependentVues/ZXGL";
    import OpenItem from "./FrontPage/OpenItem";
    import YYFW from "../../Z_ImportantBak/YYFW";
    import PopTitle from "./MyPopWin/PopTitle";
    import SeleItem from "./FrontPage/SeleItem";
    import GXCardOpen from "./M11_GXCards/GXCardOpen";
    import MyMarquee from "./SharedVues/MyMarquee";
    import SetupMain from "./SetupManage/SetupMain";
    import DLQ from "./DLQ/DLQ";
    import ActMain from "./M3_Acts/ActMain";
    import YKQ from "./YKQ/YKQ";
    import GXCard from "./M11_GXCards/GXCard";
    import PopUpDown from "./MyPopWin/PopUpDown";
    import SeleOneItem from "./FrontPage/SeleOneItem";
    import KJZR from "./QYs/KJZR";
    import FYBB from "./QYs/FYBB";
    import PopMenu from "./MyPopWin/PopMenu";
    import SiteManage from "./SetupManage/SiteManage";
    import BasicSetup from "./SetupManage/BasicSetup";
    import GXXC from "./QYs/GXXC";
    import XMYY from "./ListYY/XMYY";
    import ZY from "./ListYY/ZY";
    import BY from "./ListYY/BY";
    import GXCard2 from "./M11_GXCards/GXCard2";
    import GXCard1 from "./M11_GXCards/GXCard1";
    import CZSM from "./FrontPage/CZSM";
    import PopBlank from "./MyPopWin/PopBlank";
    import PopBott from "./MyPopWin/PopBott";
    import ShowPFromThumb from "./MyPVTs/ShowPFromThumb";
    import ShowUpdateP from "./MyPVTs/ShowUpdateP";
    import HeadShot from "./SharedVues/HeadShot";

    export default {
        name: "FrontPage",
        components: {
            HeadShot,
            ShowUpdateP,
            ShowPFromThumb,
            PopBott,
            PopBlank,
            CZSM,
            GXCard1,
            GXCard2,
            BY,
            ZY,
            XMYY,
            GXXC,
            BasicSetup,
            SiteManage,
            PopMenu, KJZR, FYBB,
            SeleOneItem,
            PopUpDown,
            GXCard,
            YKQ,
            ActMain,
            DLQ,
            SetupMain,
            MyMarquee,
            GXCardOpen,
            SeleItem,
            YYFW,
            MyDivider,
            OpenItem,
            ZXGL,
            SubEntrance,
            FYDT,
            SitesEdit,
            QuitFY,
            QXZG,
            DLandReg,
            FJXX,
            PopOneAN,
            MBSelect,
            TZGG,
            RealTime,
            FYSearch,
            PopTitle,
            NewsToMe
        },
        data() {
            return {
                Menus: ['打擂竞技', '休闲娱乐', '技能服务', '特邀'],
                Menus1: ["人次卡", "时限卡", "VIP卡"],
                Menus2: ["邀约发起", "主约信息", "被约信息"],
                Show: -1,
                DefaultItemed: 0,
                Show4: false,
                Show5: false,
                SeleI: -1,
                BeginVal: 0,
                SelectedN: 0,
                XYZ: {},
                InputI: -1,
                SStr: '',
                ItemStr: '',
                WXLinkPic: '',
                WXLinkVideo: '',
                WXLinkText: '',
                aTip: null,
                GXCardObj: {Id: '', SiteShortName: '', ItemStr: '', CardType: ''}
            }
        },
        mounted() {
            //容错处理
            let asd = this.$store.state.FYArea.split(":");
            if (['N', 'P', 'C', 'D'].indexOf(asd[0]) === -1 || asd[1] === undefined) this.$store.commit("ChangeFYArea", 'P:天津:C:D');
            this.SeleI = 0;
            console.log("FrontPageMounted");
            let that = this;
            this.Show = -1;
            let LandTo = this.$store.state.LandTo;//凡是来自微信的访问都会有LandTo，来自浏览器的访问则LandTo==undefined
//以下来自首页自定义菜单
            if (LandTo.indexOf('MBYY') >= 0) this.Show = 1;//常用邀约
            if (LandTo.indexOf('SearchYY') >= 0) this.Show = 2;//自定义菜单过来的尊享管理
            if (LandTo.indexOf('RealTime') >= 0) this.Show = 3;//在线地图呼叫
            if (LandTo.indexOf('ZXGL') >= 0) this.Show = 4;

            let ListYY = LandTo.indexOf('ListYY') >= 0, DLQ = LandTo.indexOf('DLQ') >= 0, GXCard = LandTo.indexOf('FYGXK') >= 0,
                YKQ = LandTo.indexOf('YKQ') >= 0, SGQ = LandTo.indexOf('SGQ') >= 0;
            if (this.$store.state.UserName !== '游客' && (ListYY || DLQ || GXCard || YKQ || SGQ)) {
                this.DefaultItemed = this.$store.state.DefaultItem === '' || this.$store.state.DefaultItem === undefined ? 1 : 2;
                if (ListYY) this.Show = 5;
                if (DLQ) this.Show = 6;
                if (GXCard) this.Show = 7;
                if (SGQ) this.Show = 8;
                if (YKQ) this.Show = 9;
                document.getElementById('TitleId').innerText = '项目 > ' + this.$store.state.DefaultItem;
            }
            console.log("this.Show,this.DefaultItemed,this.$store.state.DefaultItem=", this.Show, this.DefaultItemed, this.$store.state.DefaultItem)
            if (LandTo.indexOf('GXXC') >= 0) this.Show = 10;//共享相册
            if (LandTo.indexOf('XRQS') >= 0) this.Show = 11;//寻人启事
            if (LandTo.indexOf('FYBB') >= 0) this.Show = 12;//泛约帮办
            if (LandTo.indexOf('BasicSet') >= 0) this.Show = 14;//基本设置
            if (LandTo.indexOf('XMCD') >= 0) this.Show = 15;//项目场地
            if (LandTo.indexOf('QXZG') >= 0) this.Show = 16;//权限资格

            //以下来自微信链接
            myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs) {
                if (LandTo.indexOf('REG') >= 0) {
                    if (Docs[0]) alert('        您已注册过。若要再注册，须先申请注销当前用户。'); else that.$emit('FromFrontPage', 'ToReg');
                }
                if (LandTo.indexOf('Login') >= 0) {
                    if (Docs[0]) alert('        自动登录，请开始您的泛约体验之旅吧。'); else that.$emit('FromFrontPage', 'ToLogin');
                }
                if (Docs[0] && Docs[0].XYZ && Docs[0].XYZ.Tip) that.aTip = Docs[0].XYZ;
            })
            if (LandTo.indexOf('CZSM') >= 0) this.Show = 19;//操作说明

            if (LandTo.split("TJXM")[0] === "WXInput") {　//微信里输入了项目类型名称时
                myMongoDBPost("GetItemStrByItemCode", {ItemCode: LandTo.split("TJXM")[1]}, function (data) {
                    that.ItemStr = data;
                    that.$store.commit("ChangeCurrItemStr", that.ItemStr);
                    that.$store.commit('ChangeDefaultItem', data.DefaultItem);
                    this.DefaultItemed = 2;
                    that.Show = 5;
                });
            }
            if (LandTo.split("XYXM")[0] === "WXInput") {　//微信里输入了喜好项目名称时
                that.ItemStr = LandTo.split("XYXM")[1];
                that.$store.commit("ChangeCurrItemStr", that.ItemStr);
                that.$store.commit('ChangeDefaultItem', data.DefaultItem);
                this.DefaultItemed = 2;
                that.Show = 5;
            }//直接邀约


            /*
           if (LandTo.indexOf("FyGxk_") >= 0) {//来自打擂器小程序
               that.ItemStr = LandTo.split("FyGxk_")[2];
               that.$store.commit("ChangeCurrItemStr", that.ItemStr);
               that.GXCardObj = {
                   Id: LandTo.split("FyGxk_")[1],
                   SiteShortName: LandTo.split("FyGxk_")[2],
                   ItemStr: LandTo.split("FyGxk_")[3],
                   CardType: '开放共享'
               };
               that.Show = 18;
           }//共享卡

           if (LandTo.indexOf("VIPZXK") >= 0) {
               that.ItemStr = LandTo.split("VIPZXK")[1];
               that.$store.commit("ChangeCurrItemStr", that.ItemStr);
               that.Show = 7;
           }//VIP专享卡
           if (LandTo.split("CXCD")[0] === "WXInput") {
               that.ItemStr = LandTo.split("CXCD")[1];
               that.$store.commit("ChangeCurrItemStr", that.ItemStr);
               that.Show = 43;
           }//查询场地
           if (LandTo.indexOf("Act_") >= 0) {
               that.ItemStr = LandTo.split("Act_")[1];
               that.$store.commit("ChangeCurrItemStr", that.ItemStr);
               that.Show = 42;
           }//参加比赛
           if (LandTo.split("XMYY")[0] === "WXLink") {　//点击微信链接进入时
               myMongoDBPost("GetItemStrByItemCode", {ItemCode: LandTo.split("XMYY")[1]}, function (data) {
                   that.ItemStr = data;
                   that.$store.commit("ChangeCurrItemStr", that.ItemStr);
                   if (that.$store.state.UserName === '游客') myMongoDBPost("GetDocs/byset0", {
                       UserName: LandTo.split("XMYY")[2],
                       ItemStr: that.ItemStr
                   }, function (Docs2) {
                       that.WXLinkPic = Docs2[0].Pic;
                       that.WXLinkVideo = Docs2[0].Video;
                       that.WXLinkText = Docs2[0].Text;
                       that.Show4 = true;
                   }); else that.Show = 40;
               });
           }

           if (LandTo.split("YYFW").length > 1 || LandTo === 'JZ' || LandTo === 'TPDF' || LandTo === 'GXXC') this.Show = 7;//所有应用服务都从这里进入


           if (LandTo === 'XMCD' || LandTo.indexOf("TJSite") >= 0) this.Show = 13;//项目场地
           if (LandTo === 'FYDT') this.Show = 14;//泛约动态
           if (LandTo.indexOf("TJXMLX") >= 0) {　//微信里输入了['运动运动','锻炼','动动','活动'],['结伴','玩伴','一起玩','一块儿玩','找个伴'],['找人帮忙', '找个人帮忙'],['特邀','请','郑重']等关键字时
               //this.$store.commit('ChangeSideSele', parseInt(LandTo.split("TJXMLX")[1]));
               this.TM = '-1';
               this.$nextTick(() => {
                   this.TM = LandTo.split("TJXMLX")[0];
               });
           }
*/
            //if (this.$store.state.WXId !== '') SendWXMsg(this.$store.state.UserName, "谢谢您关注泛约网，烦请回复输入数字１领取每日祝福语!");

        },
        methods: {
            ProcessFromSubEntrance(Val) {
                console.log("RefreshFrontPage=", Val);
                if (Val === 'ToReg') this.$emit('FromFrontPage', 'ToReg');
                if (Val === 'ToLogin') this.$emit('FromFrontPage', 'ToLogin');
            },
            Selected(index) {
                this.SeleI = index;
                this.Show4 = false;
                this.Show = -1;
                console.log(this.SeleI)
                if (index === 0) document.getElementById('TitleId').innerText = '泛约 > 竞技 • 打擂';
                if (index === 1) document.getElementById('TitleId').innerText = '泛约 > 休闲娱乐';
                if (index === 2) document.getElementById('TitleId').innerText = '泛约 > 劳动技能服务';
                if (index === 3) document.getElementById('TitleId').innerText = '泛约 > 特殊重要人物邀约';
                FillUserlogs(this, this.Menus[index]);
            },
            PopMenuVal(Val) {
                if (Val === -1) this.Show = -1; else this.SelectedN = Val;
            },
            UpdateDefaultItem(Val) {
                let that = this;this.SeleI =3;
                myMongoDBPost("AddbySetsItem", {UserName: this.$store.state.UserName, ItemStr: Val}, function (data) {
                    that.SeleI =0;
                });
                myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: {UserName: this.$store.state.UserName}, UPObj: {DefaultItem: Val}}, function (data) {
                    console.log("Val=" + Val, data);
                    that.$store.commit('ChangeDefaultItem', Val);
                    that.DefaultItemed = 2;
                });
            },
            ClearTip() {
                let that = this;
                myMongoDBPost("UpdateDoc/fyuser0", {
                    UPTJ: {UserName: this.$store.state.UserName},
                    UPObj: {XYZ: {}}
                }, function (Docs) {
                    that.aTip = null;
                    //that.Show = 4;
                })
            },
            MyInputVal(Val) {
                let that = this;
                if (Val && this.InputI === 1 && Val !== '') {
                    this.SStr = Val;
                    myMongoDBPost("GetDocs/fyuser0", {UserName: {$regex: Val}}, function (Docs) {
                        that.UserNames = [];
                        let len = Math.min(Docs === undefined ? 0 : Docs.length, 50);
                        for (let i = 0; i < len; i++)
                            if (Docs[i].UserName !== that.$store.state.UserName) that.UserNames.push({
                                UserName: Docs[i].UserName,
                                UserLogo: Docs[i].UserLogo
                            });
                        myMongoDBPost("GetDocsByItemName", {ItemName: Val}, function (data) {
                            that.aList = data;
                            that.Show = 2;
                        })
                    });
                }
                this.InputI = 0;
            },
            SetShow(Val) {
                this.Show = Val;
                console.log(this.Show, typeof this.Show);
                if (Val === -1) document.getElementById('TitleId').innerText = '泛约活动服务平台 > 首页';
                if (Val === 1) document.getElementById('TitleId').innerText = '泛约 > 经常性邀约';
                if (Val === 2) document.getElementById('TitleId').innerText = '泛约 > 站内搜索邀约目标';
                if (Val === 3) document.getElementById('TitleId').innerText = '泛约 > 泛约在线地图';
                if (Val === 4) document.getElementById('TitleId').innerText = '泛约 > 活动管理子平台';
                if (Val === 5) document.getElementById('TitleId').innerText = '泛约 > 寻人启示';
                if (Val === 6) document.getElementById('TitleId').innerText = '泛约 > 共享相册';
                if (Val === 7) document.getElementById('TitleId').innerText = '泛约 > 社交活动云服务子平台';
                if (Val === 8) document.getElementById('TitleId').innerText = '泛约 > 管理与设置我的泛约';
                //this.$store.commit('ChangeLandTo', '');
            }
        }
    }
</script>

<style scoped>
    .demo-tabs > .el-tabs__content {
        padding: 32px 0 1px 0;
        color: #6b778c;
        }
    
    .RightTop0 {
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0
        }
    
    .RightTop1 {
        position: relative;
        height: 15px;
        top: -1.5rem;
        left: 0.6rem;
        overflow: visible;
        white-space: nowrap;
        }
    
    .MyFooter {
        position: fixed;
        bottom: 0;
        width: 100vw;
        min-height: 2.8rem;
        border: solid #bbbbbb;
        border-width: 1px 0 0 0;
        background-color: #e6e6e6;
        }
</style>

